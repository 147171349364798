import { BaseFeature } from '@core/shared/feature';
export class PflegethekeAppBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-pflegetheke-app-feature","featureId":"pflegetheke-app"},
      icons: [],
      abilityActions: false,
      plugins: [
        // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'PflegethekeAppFrontendFeature'},
        // {forPlatform:'admin', type:FeatureType.Permanent, pluginClassName:'PflegethekeAppFrontendAdminFeature'},
        // {forPlatform:'backend', type:FeatureType.Permanent, pluginClassName:'PflegethekeAppBackendFeature'},
      ]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {
          'login': {
            priority: 1,
            resolve: () => '/login'
          }
        }
      }
    };
  }
}