import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe, LocalMessageHandlerService, ModelColumnService, takeWhileAlive } from '@core/frontend-shared';
import { BehaviorSubject, filter, take } from 'rxjs';
import { CrudControllerFactory } from '../controller/controller-factory';
import { CrudManagerService } from '../crud-manager';
import * as i0 from "@angular/core";
import * as i1 from "../controller/controller-factory";
import * as i2 from "../crud-manager";
import * as i3 from "@angular/router";
import * as i4 from "@core/frontend-shared";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/shared/shared";
import * as i8 from "../../../../../ui/frontend-shared/src/lib/orderlist/orderlist";
import * as i9 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i10 from "./crud-list-item.component";
function CrudListComponent_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
  if (rf & 2) {
    const item_r5 = ctx.$implicit;
    const loadingState_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("item", item_r5)("loadingState", loadingState_r1)("itemInfo", ctx_r2.getItemInfo(item_r5))("layout", ctx_r2.itemLayout);
  }
}
function CrudListComponent_ng_template_0_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function CrudListComponent_ng_template_0_button_4_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.navigate(["new"]));
    });
    i0.ɵɵelementEnd();
  }
}
function CrudListComponent_ng_template_0_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function CrudListComponent_ng_template_0_button_5_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r9.deleteSelected($event));
    });
    i0.ɵɵelementEnd();
  }
}
function CrudListComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-orderList", 1);
    i0.ɵɵlistener("selectionChange", function CrudListComponent_ng_template_0_Template_ui_orderList_selectionChange_0_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.selectedItems = $event);
    })("onSelectionChange", function CrudListComponent_ng_template_0_Template_ui_orderList_onSelectionChange_0_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.updateSelection($event));
    })("onReorderItems", function CrudListComponent_ng_template_0_Template_ui_orderList_onReorderItems_0_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.onReorderItems($event));
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtemplate(2, CrudListComponent_ng_template_0_ng_template_2_Template, 1, 4, "ng-template", 2);
    i0.ɵɵelementStart(3, "ui-footer");
    i0.ɵɵtemplate(4, CrudListComponent_ng_template_0_button_4_Template, 1, 0, "button", 3)(5, CrudListComponent_ng_template_0_button_5_Template, 1, 0, "button", 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const loadingState_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", i0.ɵɵpipeBind1(1, 9, ctx_r0.items$))("header", ctx_r0.header || ctx_r0.crudManager.getItemText("items"))("selection", ctx_r0.selectedItems)("metaKeySelection", true)("dragdrop", ctx_r0.crudManager.config.list.reordering)("loading", loadingState_r1.allLoading)("trackBy", ctx_r0.trackBy);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r0.crudManager.enableCreate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.crudManager.enableDelete && ctx_r0.selectedItems.length);
  }
}
let CrudListComponent = class CrudListComponent {
  set selection(val) {
    this.selectedItems = val && val != null ? [val] : [];
  }
  get config() {
    return this.crudManager.config;
  }
  get service() {
    return this.crudManager.service;
  }
  get filterFormService() {
    return this.listController.getFilterFormService();
  }
  get state() {
    throw new Error('not implemented currrently');
  }
  constructor(controllerFactory, crudManager, route, router, cd, modelColumnService) {
    this.controllerFactory = controllerFactory;
    this.crudManager = crudManager;
    this.route = route;
    this.router = router;
    this.cd = cd;
    this.modelColumnService = modelColumnService;
    this.itemLayout = 'default';
    this.selectedItems = [];
    this.items$ = new BehaviorSubject(null);
    this.trackBy = (index, item) => {
      return item.id;
    };
    this.listController = crudManager.getListController();
    const obs = this.listController.getSubscriptions();
    this.loadingState$ = obs.loadingState$.pipe(takeWhileAlive(this));
    this.isLoadingDependencies$ = obs.isLoadingDependencies$.pipe(takeWhileAlive(this));
    this.listController.createItemsObservable().pipe(takeWhileAlive(this)).subscribe(items => {
      this.items$.next(items);
    });
    this.setupSelectionUpdateByRouteChanges();
  }
  setupSelectionUpdateByRouteChanges() {
    // handle item deselection when editor is closed or not in a item-specific mode
    this.crudManager.crudRouteState$.pipe(takeWhileAlive(this)).subscribe(changeEvent => {
      if (changeEvent.type === 'list' || typeof changeEvent.id !== 'number') {
        this.selectedItems = [];
        this.cd.markForCheck();
      } else {
        // in some situations navigation to item is done outside of CrudListComponent.
        // e.g. when a new item was created and a redirect to edit view is executed.
        // so if the routeState contains usable information and nothing is selected, this will set selection programmatically.
        const items = this.items$.getValue();
        if (items) {
          const itemToSelect = items.find(item => item.id === changeEvent.id);
          if (!this.selectedItems.length && itemToSelect) {
            this.selectedItems = [itemToSelect];
            this.cd.markForCheck();
          }
        }
      }
    });
    // restore correct selection on window navigation
    // TODO: note this works only on page reload, not for history navigation state!
    this.crudManager.modeAndItem$.pipe(filter(data => data.mode !== null), take(1), takeWhileAlive(this)).subscribe(data => {
      if (data.mode === 'edit') {
        this.selectedItems = [data.item];
        this.cd.markForCheck();
      }
    });
  }
  getItemInfo(item) {
    return {
      name: item.name,
      icon: null
    };
  }
  navigate(commands) {
    this.crudManager.navigate(commands);
  }
  updateSelection(event) {
    if (event.value[0]) {
      this.navigate([event.value[0].id]);
    } else {
      this.navigate(['./']);
    }
  }
  deleteSelected(event) {
    if (event) event.stopPropagation();
    if (this.selectedItems.length > 1) throw new Error('deleting multiple items at once is not supported');
    const itemController = this.controllerFactory.createItemController(this.crudManager);
    return itemController.deleteItem(this.selectedItems[0]).subscribe(deletionExecuted => {
      if (deletionExecuted) this.crudManager.navigateTo('list');
    });
  }
  onReorderItems(event) {
    this.service.crud.updateOrdering(event.result).subscribe();
  }
  static {
    this.ɵfac = function CrudListComponent_Factory(t) {
      return new (t || CrudListComponent)(i0.ɵɵdirectiveInject(i1.CrudControllerFactory), i0.ɵɵdirectiveInject(i2.CrudManagerService), i0.ɵɵdirectiveInject(i3.ActivatedRoute), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i4.ModelColumnService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudListComponent,
      selectors: [["crud-item-list"]],
      inputs: {
        header: "header",
        itemLayout: "itemLayout",
        selection: "selection"
      },
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 2,
      vars: 3,
      consts: [[3, "ngLet"], ["selectionLimit", "1", "dragHandlePosition", "left", 3, "value", "header", "selection", "metaKeySelection", "dragdrop", "loading", "trackBy", "selectionChange", "onSelectionChange", "onReorderItems"], ["uiTemplate", "item"], ["uiButton", "", "label", "neu", "icon", "plus", 3, "click", 4, "ngIf"], ["uiButton", "", "label", "l\u00F6schen", "class", "ui-button-danger", "icon", "trash", "title", "l\u00F6schen", 3, "click", 4, "ngIf"], ["crud-list-item", "", 3, "item", "loadingState", "itemInfo", "layout"], ["uiButton", "", "label", "neu", "icon", "plus", 3, "click"], ["uiButton", "", "label", "l\u00F6schen", "icon", "trash", "title", "l\u00F6schen", 1, "ui-button-danger", 3, "click"]],
      template: function CrudListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrudListComponent_ng_template_0_Template, 6, 11, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx.loadingState$));
        }
      },
      dependencies: [i5.NgIf, i6.NgLetDirective, i7.Footer, i7.PrimeTemplate, i8.OrderList, i9.UiButtonComponent, i10.CrudListItemComponent, i5.AsyncPipe],
      styles: ["[_nghost-%COMP%]{padding:0;display:flex;flex-direction:column;height:100%}\n\n/*# sourceMappingURL=crud-list.component.ts-angular-inline--38.css.map*/", "[_nghost-%COMP%]    >ui-orderList ui-footer{display:flex}\n\n/*# sourceMappingURL=crud-list.component.ts-angular-inline--39.css.map*/", "[_nghost-%COMP%]    >ui-orderList ui-footer button{flex:1}\n\n/*# sourceMappingURL=crud-list.component.ts-angular-inline--40.css.map*/"],
      changeDetection: 0
    });
  }
};
CrudListComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [CrudControllerFactory, CrudManagerService, ActivatedRoute, Router, ChangeDetectorRef, ModelColumnService])], CrudListComponent);
export { CrudListComponent };