import { DynamicFieldInstruction } from './shared';
export class InputSwitchInstruction extends DynamicFieldInstruction {
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      labels: 'inlineLabels'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-switch').then(bundle => {
      return {
        component: bundle.InputSwitch,
        module: bundle.UiInputSwitchModule
      };
    });
  }
}