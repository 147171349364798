import { ModelFormService } from '@common/forms/frontend-shared/form-service'; // deep import for tests (loading whole package leads to a weird babel error)
import { AccessService, RestraintsFactory } from "@core/frontend-shared";
import { FormServiceSaveHandlerApiCrudItem } from './crud-service-save-handler';
// note: FormService does not use injection system, its dependencies are handled by ModelFormServiceFactory!
export class CrudItemFormService extends ModelFormService {
  constructor(formStateService, message, modelFactoryProvider, injector) {
    super(formStateService, message, modelFactoryProvider, injector);
    this.message = message;
    this.modelFactoryProvider = modelFactoryProvider;
    this.injector = injector;
    // this.setupRestraintsBasedReadonlyState();
  }
  setFactory(factory) {
    super.setFactory(factory);
    this.createRestraints();
  }
  setRestraints(restraints) {
    if (this._debug) console.log('[debug] set restraints:', restraints);
    this.restraints = restraints;
  }
  // before building form we want to be sure that accessState has already fetched all data required for restraint/permission logic.
  resolveFormDependencies() {
    const access = this.injector.get(AccessService);
    return access.assertPluginIsReady();
  }
  createRestraints() {
    // console.log('[debug] create restrinats? existing:',this.restraints);
    if (this.restraints) return;
    // const acp = this.injector.get(FrontendAccessServiceProvider);
    const restraintsFactory = this.injector.get(RestraintsFactory);
    const restraints = restraintsFactory.createForModel(this.modelFactory.Model);
    this.setRestraints(restraints);
    if (this._debug) console.log('[debug] created restraints', this.restraints);
    this.setupRestraintsBasedReadonlyState();
  }
  setupRestraintsBasedReadonlyState() {
    this.onUpdateFormControlReadonlyState.subscribe(fg => {
      // MUST use getValue(true), only then readonly props will be available!
      // without them, restraint checks may fail because of lacking data!
      const value = this.getValue(true);
      if (this._debug) console.log('[DEBUG] setupRestraintsBasedReadonlyState', fg, value);
      const canToggle = this.restraints.canToggleItem(value) === true;
      let canEdit;
      if (this.editMode === 'edit') {
        canEdit = this.restraints.canUpdateItem(value);
      } else {
        canEdit = this.restraints.canCreateItem(value);
      }
      if (canEdit !== true) {
        fg.disable();
        console.log('Restraints denied editing/creating.', this.restraints, this.getValue(), canEdit);
        this.message?.create('Fehler: Sie haben keine Berechtigungen, dieses Item zu verändern!').setSeverity('warn').setLife(10000);
        return;
      } else {
        fg.enable();
      }
      const fieldEnabled = fg.get('enabled');
      if (fieldEnabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        canToggle ? fieldEnabled.enable() : fieldEnabled.disable();
      }
    });
  }
  determineAppropriateSaveHandler() {
    const definitionOptions = this.getEditorModelInfo().definition.options;
    if (definitionOptions.useCrudEndpoint) {
      return FormServiceSaveHandlerApiCrudItem;
    } else if (definitionOptions.useAppRequest) {
      throw new Error('determineAppropriateSaveHandler failed. CrudItemFormService must not be used with a app-request based save configuration.');
    } else {
      throw new Error('determineAppropriateSaveHandler failed. No appropriate handler could be found. Please pass a handler to formService.setSaveHandler manually.');
    }
  }
}