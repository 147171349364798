import { __decorate, __metadata } from "tslib";
import { Exclude } from "class-transformer-global-storage";
let Model = class Model {
  constructor() {}
  toString() {
    return this.constructor.name;
  }
};
Model = __decorate([Exclude(), __metadata("design:paramtypes", [])], Model);
export { Model };
;