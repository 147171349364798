import { Config } from '../config';
export function ConfiguratedValidator(configPropPath, options, validator) {
  return function (object, propertyName) {
    const defaultValue = typeof options.defaultValue === 'boolean' ? options.defaultValue : true;
    const value = Config.get(configPropPath, defaultValue);
    const comparator = options.invert ? !value : value;
    if (comparator === true || comparator === 1) {
      validator(object, propertyName);
    }
  };
}