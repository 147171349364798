import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { __decorate, __metadata } from "tslib";
import { CommonModule, isPlatformServer } from '@angular/common';
import { ElementRef, PLATFORM_ID } from '@angular/core';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { ConfigService, CoreFrontendSharedModule } from '@core/frontend-shared';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { MembershipPaypalService, MembershipRegistrationState } from '../state';
import { RegistrationSummaryComponent } from './registration-summary.component';
import { Select } from '@ngxs/store';
import { Observable, firstValueFrom, map } from 'rxjs';
import { MembershipCartItemAndValue, MembershipCartTotals, MembershipPluginsService } from '@common/membership/shared';
import { MembershipRegistrationCartService } from '../state/cart.service';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "../state";
import * as i3 from "../state/cart.service";
import * as i4 from "@common/membership/shared";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../core/frontend-shared/src/lib/utils/pipes/currency.pipe";
const _c0 = ["ppContainer"];
function RegistrationCheckoutComponent_table_3_tr_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tr")(1, "td", 13);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td", 12);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "currency");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const cartEntry_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(cartEntry_r3.item.title);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(5, 2, cartEntry_r3.value.grossValue));
  }
}
function RegistrationCheckoutComponent_table_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 8)(1, "tbody")(2, "tr")(3, "td", 9);
    i0.ɵɵelement(4, "hr");
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(5, RegistrationCheckoutComponent_table_3_tr_5_Template, 6, 4, "tr", 10);
    i0.ɵɵelementStart(6, "tr")(7, "td", 9);
    i0.ɵɵelement(8, "hr");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "tr")(10, "td");
    i0.ɵɵtext(11, "\u00A0");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "td", 11);
    i0.ɵɵtext(13, "inkl. MwSt.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "td", 12);
    i0.ɵɵtext(15);
    i0.ɵɵpipe(16, "currency");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(17, "tr")(18, "td");
    i0.ɵɵtext(19, "\u00A0");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "td", 11);
    i0.ɵɵtext(21, "Gesamt");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "td", 12);
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "currency");
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngForOf", ctx_r0.cartEntries);
    i0.ɵɵadvance(10);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(16, 3, ctx_r0.cartTotals.taxValue));
    i0.ɵɵadvance(8);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 5, ctx_r0.cartTotals.grossValue));
  }
}
const _c1 = () => ({
  maxWidth: "300px",
  margin: "0 auto"
});
// TODO: membership pricing should be provided by plugin
export class RegistrationCheckoutComponent {
  constructor(ngPlatformId, config, paypal, cartService, pluginsService) {
    this.config = config;
    this.paypal = paypal;
    this.cartService = cartService;
    this.pluginsService = pluginsService;
    this.activePlugin$ = this.membershipType$.pipe(map(type => this.pluginsService.getPluginInstance(type)));
    this.pageName = this.config.get('pageTitle', '');
    this.isSSR = isPlatformServer(ngPlatformId);
  }
  ngAfterViewInit() {
    var _this = this;
    return _asyncToGenerator(function* () {
      if (_this.isSSR) return;
      _this.setupCheckout();
    })();
  }
  setupCheckout() {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      const plugin = yield firstValueFrom(_this2.activePlugin$);
      const cart = plugin.getCart();
      _this2.cartService.reset();
      cart.forEach(item => {
        _this2.cartService.addCartItem(item);
      });
      _this2.cartEntries = _this2.cartService.getItemsAndValue();
      _this2.cartTotals = _this2.cartService.getCartTotals();
      const ppComponent = yield _this2.paypal.createPaypalButtonComponent();
      ppComponent.render(_this2.ppContainer.nativeElement);
    })();
  }
  static {
    this.ɵfac = function RegistrationCheckoutComponent_Factory(t) {
      return new (t || RegistrationCheckoutComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i1.ConfigService), i0.ɵɵdirectiveInject(i2.MembershipPaypalService), i0.ɵɵdirectiveInject(i3.MembershipRegistrationCartService), i0.ɵɵdirectiveInject(i4.MembershipPluginsService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationCheckoutComponent,
      selectors: [["membership-registration-checkout"]],
      viewQuery: function RegistrationCheckoutComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ppContainer = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 5,
      consts: [[3, "mode"], [1, "cart-title"], ["class", "cart-table", 4, "ngIf"], [1, "ui-margin", "ui-text-center"], ["id", "smart-button-container"], [2, "text-align", "center"], ["id", "paypal-button-container"], ["ppContainer", ""], [1, "cart-table"], ["colspan", "3"], [4, "ngFor", "ngForOf"], [1, "right"], [1, "price"], ["colspan", "2"]],
      template: function RegistrationCheckoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "membership-registration-summary", 0);
          i0.ɵɵelementStart(1, "h3", 1);
          i0.ɵɵtext(2, "Ihre Bestellung");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, RegistrationCheckoutComponent_table_3_Template, 25, 7, "table", 2);
          i0.ɵɵelementStart(4, "div", 3)(5, "div", 4)(6, "div", 5);
          i0.ɵɵelement(7, "div", 6, 7);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("mode", "display");
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.cartEntries);
          i0.ɵɵadvance(4);
          i0.ɵɵstyleMap(i0.ɵɵpureFunction0(4, _c1));
        }
      },
      dependencies: [CoreFrontendSharedModule, CommonModule, i5.NgForOf, i5.NgIf, UtilsFrontendSharedModule, i6.CurrencyPipe, RegistrationSummaryComponent, UiMessageModule],
      styles: [".bottom-bar[_ngcontent-%COMP%]{display:flex;justify-content:center;--inputBorderRadius:20px}@media (max-width: 419px){.steps[_ngcontent-%COMP%]{display:none}}@media (min-width: 420px){.steps[_ngcontent-%COMP%]{list-style:none;display:flex;justify-content:center;align-items:center}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{text-align:center}.steps[_ngcontent-%COMP%]   .step.completed[_ngcontent-%COMP%]{color:var(--disabledTextColor)}.steps[_ngcontent-%COMP%]   .step.current[_ngcontent-%COMP%]{color:var(--primaryColor)}.steps[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{font-weight:700;font-size:40px}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]{margin:0 20px;height:1px;display:block;background:var(--disabledTextColor);flex:1;opacity:.5;max-width:30%}}.forms[_ngcontent-%COMP%]{padding:50px 0 30px}.cart-title[_ngcontent-%COMP%]{margin:30px 0 -10px}.cart-table[_ngcontent-%COMP%]{width:100%;margin-bottom:20px}.cart-table[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]:first-child{min-width:40%}.cart-table[_ngcontent-%COMP%]   td.right[_ngcontent-%COMP%]{text-align:right}.cart-table[_ngcontent-%COMP%]   td.price[_ngcontent-%COMP%]{width:70px;text-align:right}"],
      changeDetection: 0
    });
  }
}
__decorate([Select(MembershipRegistrationState.membershipType), __metadata("design:type", Observable)], RegistrationCheckoutComponent.prototype, "membershipType$", void 0);