import { __decorate, __metadata } from "tslib";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AccessState, AutoUnsubscribe } from '@core/frontend-shared';
import { ConfigService } from '@core/shared';
import { AppMessage } from '@core/frontend-shared';
import { NavigationService } from '@common/page/frontend-shared';
import { FormStateService } from '@common/forms/shared';
import { Store } from '@ngxs/store';
import { defaultUploaderLinks, defaultUploaderOptions } from './uploader/uploader.options';
import { UploadController } from './controller/controller';
import { OnAfterAddItem, OnItemConflict } from './events/events';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { HEADER_UNIQUE_UPLOAD_ID } from '@common/media/shared';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@core/shared";
import * as i3 from "@common/page/frontend-shared";
import * as i4 from "@common/forms/shared";
import * as i5 from "@angular/common/http";
export const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 5; // 5mb are the smallest chunk size cloudinary allows
/**
 * MediaUploaderService should be provided on component level, else uploaders would share the same service and act like clones!
 */
let MediaUploaderService = class MediaUploaderService {
  get items() {
    return [this.controller.item];
  }
  get queueLimit() {
    return 1;
  }
  get multiple() {
    return false;
  }
  get isReady() {
    return this.ready$.getValue();
  }
  get events() {
    if (!this.controller) throw new Error('events are only available after executing UploaderService.setup()!');
    return this.controller.events;
  }
  constructor(store, config, navigationService, formStateService, http) {
    this.store = store;
    this.config = config;
    this.navigationService = navigationService;
    this.formStateService = formStateService;
    this.http = http;
    this.links = {
      ...defaultUploaderLinks
    };
    this.options = {
      ...defaultUploaderOptions
    };
    this.destroySubscriptions$ = new Subject();
    this.ready$ = new BehaviorSubject(false);
    this.hooks = {};
  }
  cancel() {
    this.controller.cancel();
  }
  clear() {
    this.controller.clear();
  }
  upload(overwrite = false) {
    this.controller.upload(overwrite);
  }
  setCustomHeadersForCurrentFile(headers) {
    this.controller.setCustomHeadersForCurrentFile(headers);
  }
  setup(config) {
    if (this.controller) throw new Error('setup failed: Controller already exists. Call reset before re-setup!');
    let path = config.path;
    path = this.config.buildPlatformUrl('backend', '', path);
    const sessionId = this.store.selectSnapshot(AccessState.sessionId);
    const queue = config.limit > 1;
    if (queue) throw new Error('Queued uploader is not implemented currently!');
    const uploaderOptions = {
      ...defaultUploaderOptions,
      hooks: this.createUploaderHooks(),
      createMethod: 'POST',
      updateMethod: 'POST',
      authorizationHeaderName: 'Authorization',
      tokenPattern: `Bearer #token#`,
      token: sessionId,
      links: {
        downloadEntry: path,
        updateEntry: path,
        createEntry: path,
        deleteEntry: path
      }
    };
    const uploadStart = typeof config.uploadStart !== 'undefined' ? config.uploadStart : 'immediate';
    const autoUpload = uploadStart === 'immediate' && (typeof config.autoUpload !== 'undefined' ? config.autoUpload : true);
    const customHeaders = [];
    if (config.headers) {
      Object.keys(config.headers).forEach(name => {
        customHeaders.push({
          name,
          value: config.headers[name]
        });
      });
    }
    const chunkSize = typeof config.chunks != 'undefined' ? config.chunks === true ? DEFAULT_CHUNK_SIZE : config.chunks : 0;
    const controllerOptions = {
      allowedFileTypes: config.allowedFileTypes,
      queueLimit: typeof config.limit !== 'undefined' ? config.limit : 1,
      chunkSize,
      chunkingFileSizeThreshold: config.chunkingFileSizeThreshold || 0,
      autoUpload,
      uploadStart,
      headers: [...customHeaders, {
        name: "x-ms-blob-type",
        value: "BlockBlob"
      }]
    };
    this.controller = new UploadController(controllerOptions, uploaderOptions, this.http);
    this.bindToControllerEvents();
    this.ready$.next(true);
  }
  reset() {
    this.ready$.next(false);
    if (this.controller) {
      this.controller.destroy();
      this.destroySubscriptions$.next(null);
      this.navigationService.disableWarning({
        id: 'fileuploader'
      });
      this.formStateService.endFormBusy('fileuploader');
      this.controller = null;
    }
  }
  bindToControllerEvents() {
    // this.destroySubscriptions$.subscribe(()=>{console.log('destroy subscriptuions triggered subscriber in bindToControllerEvents')})
    this.controller.state$.pipe(takeUntil(this.destroySubscriptions$)).subscribe(state => {
      if (state === 'uploading') {
        this.navigationService.enableWarning({
          id: 'fileuploader',
          navigate: true,
          leave: true
        });
        this.formStateService.setFormBusy('fileuploader');
      } else {
        this.navigationService.disableWarning({
          id: 'fileuploader'
        });
        this.formStateService.endFormBusy('fileuploader');
      }
    });
    this.events.pipe(takeUntil(this.destroySubscriptions$)).subscribe(event => {
      if (event instanceof OnAfterAddItem) {
        const item = event.item;
        item.events.subscribe(event => {
          if (event instanceof OnItemConflict) {
            // this._state.next('conflict');
            if (event.response instanceof AppMessage) {
              event.response.discard();
            }
          }
        });
      }
    });
  }
  setHooks(hooks) {
    this.hooks = hooks;
  }
  clearQueue() {
    this.clear();
  }
  createUploaderHooks() {
    return {
      setChunkHeaders: (headers, chunk, item, options, uploaderOptions) => {
        headers[HEADER_UNIQUE_UPLOAD_ID] = chunk.uniqueIdentifier;
        return headers;
      },
      getFileInfoFromResponse: response => {
        if (response instanceof AppMessage) {
          return response.data;
        } else if (response instanceof HttpErrorResponse) {
          return response.error['data']['data'];
        } else {
          return response.body['data'];
        }
      },
      ...this.hooks
    };
  }
  ngOnDestroy() {
    this.destroySubscriptions$.next(true);
  }
  static {
    this.ɵfac = function MediaUploaderService_Factory(t) {
      return new (t || MediaUploaderService)(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.NavigationService), i0.ɵɵinject(i4.FormStateService), i0.ɵɵinject(i5.HttpClient));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: MediaUploaderService,
      factory: MediaUploaderService.ɵfac
    });
  }
};
MediaUploaderService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Store, ConfigService, NavigationService, FormStateService, HttpClient])], MediaUploaderService);
export { MediaUploaderService };