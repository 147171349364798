import { TransformationType } from "class-transformer-global-storage";
import { getMetadataStorage } from "class-validator";
import { ClassTransformService } from '../class-transform';
// note that this will NOT find parent metadata!
export function getAllClassTransformerMetadata(classCtor) {
  if (typeof classCtor !== 'function') throw new Error('hasClassTransformerMetadata: argument has invalid type, only class constructor is allowed!');
  // we need to get a bit hacky because methods are protected/private
  const metadataStorage = ClassTransformService.getMetadataStorage();
  const unlockedStorage = metadataStorage;
  // 
  // private _typeMetadatas = new Map<Function, Map<string, TypeMetadata>>();
  // private _transformMetadatas = new Map<Function, Map<string, TransformMetadata[]>>();
  return {
    exposed: metadataStorage.getExposedMetadatas(classCtor),
    excluded: metadataStorage.getExcludedMetadatas(classCtor),
    type: unlockedStorage._typeMetadatas.get(classCtor) || new Map(),
    transform: unlockedStorage._transformMetadatas.get(classCtor) || new Map()
  };
}
export function generateClassMetadataMap(classCtor) {
  const rawMeta = getAllClassTransformerMetadata(classCtor);
  const map = new Map();
  function addToMap(key, patch) {
    if (!map.has(key)) {
      map.set(key, patch);
    } else {
      map.set(key, {
        ...map.get(key),
        ...patch
      });
    }
  }
  rawMeta.exposed.forEach(meta => {
    addToMap(meta.propertyName, {
      exposed: meta
    });
  });
  rawMeta.excluded.forEach(meta => {
    addToMap(meta.propertyName, {
      excluded: meta
    });
  });
  Array.from(rawMeta.type.values()).forEach(meta => {
    addToMap(meta.propertyName, {
      type: meta
    });
  });
  Array.from(rawMeta.transform.values()).forEach(metas => {
    if (metas?.length) addToMap(metas[0].propertyName, {
      transform: metas
    });
  });
  return map;
}
// we need to get a bit hacky to access private storage, API would only return filtered results
export function getAllClassValidatorMetadata(classCtor) {
  return getMetadataStorage().validationMetadatas.filter(metadata => {
    return metadata.target === classCtor;
  });
}
export function getExposedModelProperties(classCtor, trnsformationType = TransformationType.PLAIN_TO_CLASS) {
  return ClassTransformService.getMetadataStorage().getExposedProperties(classCtor, trnsformationType);
}