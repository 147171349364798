import { UserBasedAdminOnlyItemRestraints } from '../user-item.restraints';
export class CompanyRestraints extends UserBasedAdminOnlyItemRestraints {
  canViewItem(item) {
    if (this.hasPermission('ADMINISTRATOR')) return true;
    const company = this.access.getAccessData('company', null, 'null');
    const managedCompany = this.access.getAccessData('managedCompany', null, 'null');
    if (company?.id === item.id) return true;
    if (managedCompany?.id === item.id) return true;
    return 'Sie haben keine Berechtigung, Daten dieses Unternehmens einzusehen.';
  }
  canUpdateItem(item, oldItem) {
    if (this.isExecutedBySystem()) return true;
    return super.canUpdateItem(item);
  }
}