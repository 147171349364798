'use strict';

// inspired by camelcase package
export function transformEnvNameToConfigName(input) {
  if (input.substring(0, 5) === 'APP__') input = input.substring(5);
  input = input.toLowerCase();
  return input.replace(/[_]/g, '.');
  // if(input.toUpperCase() !== input) {
  // 	// mixed case
  // 	return input;
  // } else {
  // 	// all upper case
  // 	const input2 = input
  // 	.replace(/^[_\- ]+/, '')
  // 	.toLowerCase()
  // 	.replace(/[_\- ]+(\w|$)/g, (_, p1) => p1.toUpperCase())
  // 	.replace(/\d+(\w|$)/g, m => m.toUpperCase());
  // 	// console.log('convert to camel',input,input2);
  // 	return input2;
  // }
}
export function transformKeysToLowerCase(oldObj) {
  let newObj;
  if (Array.isArray(oldObj)) {
    newObj = [];
    for (let i = 0; i < oldObj.length; i++) {
      newObj[i] = transformKeysToLowerCase(oldObj[i]);
    }
  } else if (typeof oldObj === 'object' && oldObj !== null) {
    newObj = {};
    for (const k in oldObj) {
      if (Object.prototype.hasOwnProperty.call(oldObj, k)) newObj[k.toLowerCase()] = transformKeysToLowerCase(oldObj[k]);
    }
  } else {
    return oldObj;
  }
  return newObj || null;
}
;