import { __decorate, __metadata } from "tslib";
import { InternalCreateOneCrudRequest, InternalDeleteOneCrudRequest, InternalDeleteOneCrudResponse, InternalGetManyCrudRequest, InternalGetManyItemsCrudResponse, InternalGetOneCrudRequest, InternalSingleItemCrudResponse, InternalUpdateOneCrudRequest } from '@common/crud/shared';
import { AppRequest } from '@core/shared';
import { Expose, Model } from '@core/shared/model';
import { CompanyItem } from '../models';
import { UserItem } from '../models/user.item';
class SingleUserResponse {}
__decorate([Expose(), __metadata("design:type", UserItem)], SingleUserResponse.prototype, "user", void 0);
let LoginUserRequest = class LoginUserRequest extends Model {
  static {
    this.Response = SingleUserResponse;
  }
};
__decorate([Expose(), __metadata("design:type", String)], LoginUserRequest.prototype, "username", void 0);
__decorate([Expose(), __metadata("design:type", String)], LoginUserRequest.prototype, "password", void 0);
LoginUserRequest = __decorate([AppRequest('users', 'login-user', {
  httpMethod: false,
  internalElevated: true
})], LoginUserRequest);
export { LoginUserRequest };
export class InternalGetManyCompaniesCrudResponse extends InternalGetManyItemsCrudResponse {}
export class InternalSingleCompanyCrudResponse extends InternalSingleItemCrudResponse {}
export class InternalDeleteOneCompanyCrudResponse extends InternalDeleteOneCrudResponse {}
let GetManyCompaniesCrudRequest = class GetManyCompaniesCrudRequest extends InternalGetManyCrudRequest {
  static {
    this.Response = InternalGetManyCompaniesCrudResponse;
  }
};
GetManyCompaniesCrudRequest = __decorate([AppRequest('users-companies', 'crud-get-many', {
  httpMethod: false
})], GetManyCompaniesCrudRequest);
export { GetManyCompaniesCrudRequest };
let GetOneCompanyCrudRequest = class GetOneCompanyCrudRequest extends InternalGetOneCrudRequest {
  static {
    this.Response = InternalSingleCompanyCrudResponse;
  }
};
GetOneCompanyCrudRequest = __decorate([AppRequest('users-companies', 'crud-get-one', {
  httpMethod: false
})], GetOneCompanyCrudRequest);
export { GetOneCompanyCrudRequest };
export class InternalGetManyUsersCrudResponse extends InternalGetManyItemsCrudResponse {}
export class InternalSingleUserCrudResponse extends InternalSingleItemCrudResponse {}
export class InternalDeleteOneUserCrudResponse extends InternalDeleteOneCrudResponse {}
let GetManyUsersCrudRequest = class GetManyUsersCrudRequest extends InternalGetManyCrudRequest {
  static {
    this.Response = InternalGetManyUsersCrudResponse;
  }
};
__decorate([Expose(), __metadata("design:type", Array)], GetManyUsersCrudRequest.prototype, "permissions", void 0);
GetManyUsersCrudRequest = __decorate([AppRequest('users', 'crud-get-many', {
  httpMethod: false
})], GetManyUsersCrudRequest);
export { GetManyUsersCrudRequest };
let GetOneUserCrudRequest = class GetOneUserCrudRequest extends InternalGetOneCrudRequest {
  static {
    this.Response = InternalSingleUserCrudResponse;
  }
};
GetOneUserCrudRequest = __decorate([AppRequest('users', 'crud-get-one', {
  httpMethod: false
})], GetOneUserCrudRequest);
export { GetOneUserCrudRequest };
// handles referrerCode correctly if passed
let CreateOneUserCrudRequest = class CreateOneUserCrudRequest extends InternalCreateOneCrudRequest {
  static {
    this.Response = InternalSingleUserCrudResponse;
  }
};
CreateOneUserCrudRequest = __decorate([AppRequest('users', 'crud-create-one', {
  httpMethod: false
})], CreateOneUserCrudRequest);
export { CreateOneUserCrudRequest };
let UpdateOneUserCrudRequest = class UpdateOneUserCrudRequest extends InternalUpdateOneCrudRequest {
  static {
    this.Response = InternalSingleUserCrudResponse;
  }
};
UpdateOneUserCrudRequest = __decorate([AppRequest('users', 'crud-update-one', {
  httpMethod: false
})], UpdateOneUserCrudRequest);
export { UpdateOneUserCrudRequest };
let DeleteOneUserCrudRequest = class DeleteOneUserCrudRequest extends InternalDeleteOneCrudRequest {
  static {
    this.Response = InternalDeleteOneCrudResponse;
  }
};
DeleteOneUserCrudRequest = __decorate([AppRequest('users', 'crud-delete-one', {
  httpMethod: false
})], DeleteOneUserCrudRequest);
export { DeleteOneUserCrudRequest };
export class GetUserAccessDataInternalResponse extends Model {}
__decorate([Expose(), __metadata("design:type", Boolean)], GetUserAccessDataInternalResponse.prototype, "enabled", void 0);
__decorate([Expose(), __metadata("design:type", UserItem)], GetUserAccessDataInternalResponse.prototype, "user", void 0);
__decorate([Expose(), __metadata("design:type", Array)], GetUserAccessDataInternalResponse.prototype, "ownedUsersIds", void 0);
__decorate([Expose(), __metadata("design:type", CompanyItem)], GetUserAccessDataInternalResponse.prototype, "company", void 0);
__decorate([Expose(), __metadata("design:type", CompanyItem)], GetUserAccessDataInternalResponse.prototype, "managedCompany", void 0);
__decorate([Expose(), __metadata("design:type", Array)], GetUserAccessDataInternalResponse.prototype, "managedUserIds", void 0);
__decorate([Expose(), __metadata("design:type", Array)], GetUserAccessDataInternalResponse.prototype, "activatedFeatures", void 0);
__decorate([Expose(), __metadata("design:type", Array)], GetUserAccessDataInternalResponse.prototype, "abilities", void 0);
let GetUserAccessDataInternalRequest = class GetUserAccessDataInternalRequest extends Model {};
__decorate([Expose(), __metadata("design:type", Number)], GetUserAccessDataInternalRequest.prototype, "id", void 0);
GetUserAccessDataInternalRequest = __decorate([AppRequest('users', 'user-access-data', {
  httpMethod: false,
  internalElevated: false
})], GetUserAccessDataInternalRequest);
export { GetUserAccessDataInternalRequest };
class GetUserNamesByIdResponse extends Model {}
__decorate([Expose(), __metadata("design:type", Object)], GetUserNamesByIdResponse.prototype, "list", void 0);
let GetUserNamesByIdRequest = class GetUserNamesByIdRequest extends Model {
  static {
    this.Response = GetUserNamesByIdResponse;
  }
};
__decorate([Expose(), __metadata("design:type", Array)], GetUserNamesByIdRequest.prototype, "ids", void 0);
GetUserNamesByIdRequest = __decorate([AppRequest('users', 'get-user-names', {
  httpMethod: false,
  internalElevated: false
})], GetUserNamesByIdRequest);
export { GetUserNamesByIdRequest };
class GetAllUserIdsResponse extends Model {}
__decorate([Expose(), __metadata("design:type", Array)], GetAllUserIdsResponse.prototype, "ids", void 0);
let GetAllUserIdsRequest = class GetAllUserIdsRequest extends Model {
  static {
    this.Response = GetAllUserIdsResponse;
  }
};
GetAllUserIdsRequest = __decorate([AppRequest('users', 'get-all-user-ids', {
  httpMethod: false,
  internalElevated: false
})], GetAllUserIdsRequest);
export { GetAllUserIdsRequest };