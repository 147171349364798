import { UserBasedItemRestraints } from '../user-item.restraints';
const roleId = {
  SUPERUSER: 1,
  ADMIN: 2,
  REGISTERED: 3,
  MANAGER: 4
};
// TODO: Should not use roles, but permissions.
export class UserRestraints extends UserBasedItemRestraints {
  userEditsSelf(item) {
    if (!item.id) return false; // cannot error here because it may be a new entity without existing ID
    const userId = this.access.getAccessData('userId', null, 'throw');
    return this.access.isLoggedIn ? item.id === userId : false;
  }
  canUserSetRole(item) {
    const isAdmin = this.hasPermission('ADMINISTRATOR');
    if (this.access.isSU) {
      return true;
    } else if (isAdmin) {
      if (item.roleId === roleId.SUPERUSER) return 'Superuser können nur von Superusern erstellt werden.';
      return true;
    } else {
      if (item.roleId === roleId.SUPERUSER || item.roleId === roleId.ADMIN) return 'Superuser/Admins können nur von Superusern/Admins erstellt werden.';
      return true;
    }
  }
  canCreateItem(item) {
    // system will create item when a new account shall be registered
    if (this.isExecutedBySystem()) return true;
    const isAdmin = this.hasPermission('ADMINISTRATOR');
    if (isAdmin) {
      return this.canUserSetRole(item);
    }
    ;
    if (this.currentUserManagesCompany(item)) {
      return this.canUserSetRole(item);
    } else {
      return 'Sie können nur User für Ihr Unternehmen erstellen.';
    }
  }
  canToggleItem(item) {
    // return this.requirePermission('REGISTERED',()=>{
    if (this.userEditsSelf(item)) {
      return 'Sie können Ihren eigenen Account nicht deaktivieren.';
    } else if (this.access.isAdmin) {
      if (item.id <= 2) {
        return 'Dieser Nutzer darf nicht deaktiviert werden.';
      }
      return true;
    } else {
      return 'Sie dürfen den Status dieses Nutzers nicht verändern.';
    }
    // })
  }
  canUpdateItem(item, oldItem) {
    // ensure updating in general is allowed
    if (this.isExecutedBySystem()) return true;
    const isAdmin = this.hasPermission('ADMINISTRATOR');
    if (!isAdmin && !this.currentUserManagesCompany(item) && !this.userEditsSelf(item)) {
      return 'Sie haben keine Erlaubnis, diesen User zu bearbeiten.';
    }
    // from here on, we can assume editing user is either admin, edits own data, or manages company of the user.
    // ensure toggling is allowed
    const setsEnabledState = typeof item.enabled === 'boolean';
    if (oldItem && setsEnabledState && item.enabled !== oldItem.enabled) {
      const canToggle = this.canToggleItem(item);
      if (canToggle !== true) return canToggle;
    }
    // ensure setting roleId is allowed
    if (typeof item.roleId !== 'undefined') {
      if (item.id <= 2 && item.roleId !== roleId.SUPERUSER) {
        return 'Die Superuser-Rolle dieses Users kann nicht entfernt werden.';
      }
      const tmp = this.canUserSetRole(item);
      if (tmp !== true) return tmp;
      if (this.userEditsSelf(item)) {
        if (oldItem && oldItem.roleId !== item.roleId) {
          return 'Sie können die Rolle ihres Accounst nicht selbst verändern';
        }
        // in case oldItem is not available for some reason, we do an additional check to ensure SU/Admin accounts don't loose role by accident.
        if (this.access.isSU) {
          if (item.roleId !== roleId.SUPERUSER) return 'Sie können die Rolle ihres Accounst nicht selbst verändern';
        } else if (isAdmin) {
          if (item.roleId !== roleId.ADMIN) return 'Sie können die Rolle ihres Accounst nicht selbst verändern';
        }
      }
    }
    return true;
  }
  canRemoveItem(item) {
    const isAdmin = this.hasPermission('ADMINISTRATOR');
    const isManager = this.access.getAccessData('isManager', null, 'null');
    if (!isAdmin && !isManager) return 'Sie können keine Nutzer entfernen.';
    if (item.id <= 2) return 'Dieser Nutzer darf nicht entfernt werden.';
    if (this.userEditsSelf(item)) return 'Sie können Ihren eigenen Account nicht löschen.';
    if (isAdmin) {
      if (item.roleId === roleId.SUPERUSER && !this.access.isSU) return 'Superuser können nur von Superusern gelöscht werden.';
      return true;
    }
    ;
    if (item.roleId === roleId.MANAGER) {
      // TODO: Implement manager deletion
      return 'Manager können momentan noch nicht gelöscht werden.';
    }
    if (isManager) {
      if (item.roleId !== roleId.REGISTERED) return 'Sie dürfen nur normale User entfernen. (User roleId ist ' + item.roleId + ')';
      if (!this.currentUserManagesCompany(item)) return 'Sie dürfen nur Mitglieder Ihres eigenen Teams entfernen.';
      return true;
    }
  }
  currentUserManagesCompany(item) {
    if (!this.hasPermission('MANAGER')) return false;
    const companyId = item.companyId || item.company?.id;
    if (!companyId) throw new Error('currentUserManagesCompany failed: User has no info on managed company available.');
    const managedCompany = this.access.getAccessData('managedCompany', null, 'null');
    if (!managedCompany) return false;
    return companyId === managedCompany.id;
  }
}