import { __decorate, __metadata } from "tslib";
import { Default, EditorDefinition, Expose, ItemModel, Model, Trim } from '../model';
import { ItemRestraints, UseRestraints } from '../restraints';
let VirtualPropertyItem = class VirtualPropertyItem extends ItemModel {
  toString() {
    return `Virtual Property (${this.assetId}) ${this.name}=${this.value}`;
  }
};
__decorate([Expose(), Default(null), __metadata("design:type", Number)], VirtualPropertyItem.prototype, "assetId", void 0);
__decorate([Expose(), Trim(), __metadata("design:type", String)], VirtualPropertyItem.prototype, "name", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", Object)], VirtualPropertyItem.prototype, "value", void 0);
VirtualPropertyItem = __decorate([EditorDefinition({
  assetId: 'readonly',
  name: 'readonly',
  value: true
}), UseRestraints(ItemRestraints)], VirtualPropertyItem);
export { VirtualPropertyItem };
/**
 * base class for virtualProperty data object outside of server
 */
export class VirtualPropertyMapModel extends Model {}