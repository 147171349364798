export function arrayMapMergeOne(map, key, value) {
  if (map.has(key)) {
    map.set(key, [...map.get(key), value]);
  } else {
    map.set(key, [value]);
  }
}
export function arrayMapMergeMany(map, key, value) {
  if (map.has(key)) {
    map.set(key, [...map.get(key), ...value]);
  } else {
    map.set(key, [...value]);
  }
}