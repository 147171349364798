var MembershipRegistrationState_1;
import { __decorate, __metadata } from "tslib";
import { AccessState, ConfigService, convertFrontendErrorToString } from '@core/frontend-shared';
import { AppRequestService } from '@core/frontend-shared/api';
import { Action, Selector, State, StateContext, Store, getStoreMetadata } from '@ngxs/store';
import { MembershipApprovePaypalPaymentRequest, MembershipPaymentAssignOrderIdRequest, MembershipPluginsService, MembershipRegistrationRequest } from '@common/membership/shared';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { filter, take } from 'rxjs';
import { loadPaypalJs } from '../load-paypal-js';
import { RegistrationStep, defaultRegistrationSteps } from './interfaces';
import { MembershipDisplayMode, MembershipRegistrationActions, MembershipRegistrationMode } from './registration.actions';
import { AccessFacade } from '@core/frontend-shared/access';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared/api";
import * as i2 from "@ngxs/store";
import * as i3 from "@core/frontend-shared";
import * as i4 from "@core/frontend-shared/access";
import * as i5 from "@common/membership/shared";
let MembershipRegistrationState = class MembershipRegistrationState {
  static {
    MembershipRegistrationState_1 = this;
  }
  static currentStep(state) {
    return state.currentStep;
  }
  static redirectInProgress(state) {
    return state.redirectInProgress;
  }
  static membershipType(state) {
    return state.membershipPluginInfo?.id || null;
  }
  static membershipPluginInfo(state) {
    return state.membershipPluginInfo;
  }
  static allFormData(state) {
    return state.formData;
  }
  static paymentError(state) {
    return state.paymentError;
  }
  static submissionError(state) {
    return state.submissionError;
  }
  static submissionResponse(state) {
    return state.submissionResponse;
  }
  static isBusy(state) {
    return state.isBusy;
  }
  static mode(state) {
    return state.mode;
  }
  static displayMode(state) {
    return state.displayMode;
  }
  static enablePayment(state) {
    return state.enablePayment;
  }
  static pluginFormState(state) {
    return state.pluginFormState;
  }
  static enabledSteps(state) {
    return state.enabledRegistrationSteps;
  }
  static enabledSummaryStep(state) {
    return state.enabledRegistrationSteps.includes(RegistrationStep.summary);
  }
  static requireAccountData(state, loggedIn) {
    return !loggedIn;
  }
  static credentialsByMail(state, requireAccountData) {
    return requireAccountData;
  }
  static requiresPayment(state) {
    const info = state.membershipPluginInfo;
    return info ? info.paymentRequired : null;
  }
  constructor(api, store, config, accessFacade, pluginsService) {
    this.api = api;
    this.store = store;
    this.config = config;
    this.accessFacade = accessFacade;
    this.pluginsService = pluginsService;
  }
  ngxsOnInit(ctx) {
    this.setupInitialState(ctx);
    // preload paypal API if user chooses to select a membershipType requiring payment
    this.store.select(MembershipRegistrationState_1.membershipPluginInfo).pipe(filter(info => info?.paymentRequired), take(1)).subscribe(() => {
      const paypalClientId = this.config.get('membership.paypalClientId', 'AcIpz-bvZIPWaDBO22dy2Vl9h7QGkvJIsNReHAq1Givl2dv5aYc1elCgf0jrz2vxGxKBYqncYuCylSUV');
      loadPaypalJs(paypalClientId);
    });
    this.store.select(AccessState.loggedIn).pipe(takeWhileAlive(this)).subscribe(() => {
      // in case user has begun registration process, ensure it is reset when changing login state.
      // The state must not be reset if registration is complete because session/state can change DURING last registration step.
      const registrationCompleted = ctx.getState().currentStep === RegistrationStep.complete;
      if (!registrationCompleted) {
        ctx.dispatch(new MembershipRegistrationActions.Reset());
      }
    });
  }
  setupInitialState(ctx) {
    const enablePayment = this.config.get('membership.enablePayment', 0);
    ctx.patchState({
      enablePayment
    });
    const pluginsList = this.pluginsService.getPluginsList();
    if (pluginsList.length === 0) {
      throw new Error('Membership System Misconfiguration: No registration plugin was registered');
    }
    const enabledSteps = this.getEnabledRegistrationSteps();
    if (pluginsList.length === 1) {
      // skip type selection step, there are no choices 
      const pluginInfo = pluginsList[0];
      ctx.dispatch(new MembershipRegistrationActions.SetMembershipType(pluginInfo.id));
      ctx.dispatch(new MembershipRegistrationActions.SetEnabledSteps(enabledSteps));
    } else {
      const pluginInfo = pluginsList[0];
      ctx.dispatch(new MembershipRegistrationActions.SetMembershipType(pluginInfo.id));
      ctx.dispatch(new MembershipRegistrationActions.SetEnabledSteps(enabledSteps));
    }
  }
  getEnabledRegistrationSteps() {
    const pluginsList = this.pluginsService.getPluginsList();
    const enableSummaryStep = this.config.get('membership.enableSummaryStep', true);
    const enabledSteps = [];
    // if (pluginsList.length > 1) enabledSteps.push(RegistrationStep.membershipTypeSelection);
    enabledSteps.push(RegistrationStep.forms);
    if (enableSummaryStep) enabledSteps.push(RegistrationStep.summary);
    enabledSteps.push(RegistrationStep.complete);
    return enabledSteps;
  }
  setRegistrationMode(ctx, {
    mode
  }) {
    if (mode === MembershipRegistrationMode.create) {
      // TODO: This should include default options calculating in ngxsOnInit / steps, pluginSelection
      ctx.dispatch(new MembershipRegistrationActions.Reset());
    } else {
      // registered mode - will be used for any logged in user, 
      // independent of if the user ever had a membership before!
      ctx.patchState({
        isBusy: false,
        mode
      });
      // atm we dont restore any membership-specific detail so all of this can be skipped.
      // this.api.sendRequest(FindCurrentMembershipRequest,{withRegistrationData:true}).subscribe((response)=>{
      // setting a mode different than create makes no sense if no membership is found, so abort.
      // if(!response.membership) {
      // 	// if no existing membership is found, only thing we can do is reset and display default registration.
      // 	// TODO: handle membership creation for logged-in users correctly
      // 	ctx.dispatch(new MembershipRegistrationActions.Reset())
      // 	return;
      // }
      // const membership = response.membership as MembershipItem;
      // const registrationData = response.registrationData as RegistrationFormDataMap;
      // ctx.dispatch(new MembershipRegistrationActions.RestoreExistingMembership(membership,registrationData));
      // })
    }
  }
  setDisplayMode(ctx, {
    mode
  }) {
    ctx.patchState({
      displayMode: mode
    });
  }
  // atm we dont restore any membership-specific detail!
  restoreExistingMembership(ctx, {
    membership,
    registrationData
  }) {
    throw new Error('restoreExistingMembership is disabled!');
    // const formData = this.convertLegacyFormData(registrationData);
    // const type = membership.membershipType;
    // const pluginsList = this.pluginsService.getPluginsList();
    // const pluginIsAvailable = pluginsList.find(i=>i.id===type);
    // ctx.patchState({
    // 	isBusy:false,
    // })
    // if(pluginIsAvailable) {
    // 	// only apply membership type and data if the plugin exists + is enabled.
    // 	// otherwise stay with default plugin or type selection step.
    // 	ctx.patchState({ formData })
    // 	ctx.dispatch(new MembershipRegistrationActions.SetMembershipType(membership.membershipType))
    // }
  }
  setEnabledSteps(ctx, {
    steps
  }) {
    ctx.patchState({
      enabledRegistrationSteps: steps
    });
    ctx.dispatch(new MembershipRegistrationActions.GoToStep(steps[0]));
  }
  goToStep(ctx, action) {
    ctx.patchState({
      currentStep: action.name
    });
  }
  confirmStep(ctx, action) {
    const enabledSteps = ctx.getState().enabledRegistrationSteps;
    const currentStep = action.completedStep || ctx.getState().currentStep;
    const currentStepIndex = enabledSteps.findIndex(s => s === currentStep);
    const nextStep = enabledSteps[currentStepIndex + 1];
    if (!nextStep) throw new Error('there is no next step. calling confirmStep on last step is not allowed.');
    // let stepCompletionResult:{continueToNextStep} = {continueToNextStep:true};
    // // ...
    // if(!stepCompletionResult.continueToNextStep) return;
    // TODO: Submission could get its own registration step, might make things cleaner
    const shouldSubmit = nextStep === RegistrationStep.complete;
    if (shouldSubmit) {
      // in case of submitting, we do not patch currentStep here.
      // it will be handled in Submit/SubmitCompleted handlers instead.
      ctx.dispatch(new MembershipRegistrationActions.Submit());
    } else {
      ctx.patchState({
        currentStep: nextStep
      });
    }
  }
  setMembershipType(ctx, action) {
    const pluginId = action.ref;
    const newPluginInfo = this.pluginsService.getPluginInfo(pluginId);
    const currentPluginInfo = ctx.getState().membershipPluginInfo;
    // if IDs are the same, we can assume that nothing has changed.
    if (newPluginInfo && newPluginInfo.id !== currentPluginInfo?.id) {
      ctx.patchState({
        membershipPluginInfo: newPluginInfo
      });
      if (!IS_PRODUCTION) {
        this.setDemoData(ctx, newPluginInfo);
      }
    }
  }
  setDemoData(ctx, pluginInfo) {
    /**
     * some valid Dummy insurance IDs:
     * A123456780 - C987654322 - X111111112 - Z888888885
     */
    const randomEmail = Math.round(Math.random() * 10000) + '@test.com';
    const randomPw = 'Test123';
    switch (pluginInfo.id) {
      case 'insurance-simple':
        ctx.patchState({
          formData: {
            // allowed here, this is data managed by insurance plugin!
            insuranceId: 'barmer',
            insuranceMembershipId: 'Z888888885',
            firstName: 'test',
            lastName: 'user',
            email: randomEmail,
            phone: '1234567',
            allow3rdPartyContact: true
          }
        });
        break;
      case 'payment':
      case 'mpf-payment':
        ctx.patchState({
          formData: {
            // 'account':{
            email: randomEmail,
            firstName: 'test',
            lastName: 'user',
            password: randomPw,
            password2: randomPw,
            // },
            // 'invoice':{
            zipcode: '12345',
            city: 'testcity',
            address: 'teststreet 123'
            // },
          }
        });
        break;
    }
  }
  setPluginFormState(ctx, {
    state
  }) {
    ctx.patchState({
      pluginFormState: state
    });
  }
  setFormData(ctx, {
    formType,
    data
  }) {
    if (formType) {
      ctx.patchState({
        formData: {
          ...ctx.getState().formData,
          [formType]: data
        }
      });
    } else {
      ctx.patchState({
        formData: data
      });
    }
  }
  // Submit action is either triggered directly by button click or through paypal service.
  // its task is to post the membership item to api, then dispatch SubmitCompleted with server response or eventual error.
  submit(ctx, action) {
    const existingResponse = ctx.getState().submissionResponse;
    if (existingResponse) throw new Error('Registration has already been submitted! Cannot submit again!');
    const pluginId = ctx.getState().membershipPluginInfo.id;
    const plugin = this.pluginsService.getPluginInstance(pluginId);
    const formData = ctx.getState().formData;
    const dataToPersist = plugin.getDataToPersist(formData);
    const pluginInfo = this.store.selectSnapshot(MembershipRegistrationState_1.membershipPluginInfo);
    const requiresPayment = this.store.selectSnapshot(MembershipRegistrationState_1.requiresPayment);
    const requireAccountData = this.store.selectSnapshot(MembershipRegistrationState_1.requireAccountData);
    const referrerCode = this.store.selectSnapshot(AccessState.referrerCode);
    const account = requireAccountData ? plugin.getAccountData(formData) : null;
    const membershipTypeCategorization = plugin.getCategorization(formData);
    // in case of payment, isBusy MUST NOT be set to true!
    // this would remove component and display a loader ... which would detach paypal from DOM, aborting payment process.
    if (!requiresPayment) ctx.patchState({
      isBusy: true
    });
    const requestData = {
      membershipTypeCategorization,
      membershipType: pluginInfo.id,
      referrerCode,
      formData: dataToPersist,
      account
    };
    if (requiresPayment && !this.config.get('membership.enablePayment', false)) {
      ctx.patchState({
        submissionError: "Die Zahlung via Paypal ist aktuell nicht verfügbar. Bitte kontaktieren Sie den Support, um Zugang zu erhalten.",
        isBusy: false
      });
      ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
      return;
    }
    this.api.sendRequest(MembershipRegistrationRequest, requestData).subscribe({
      next: submissionResponse => {
        ctx.dispatch(new MembershipRegistrationActions.SubmitCompleted(submissionResponse, null));
      },
      error: error => {
        ctx.dispatch(new MembershipRegistrationActions.SubmitCompleted(null, error));
      }
    });
  }
  submitCompleted(ctx, {
    response,
    error
  }) {
    if (error) {
      const stringifiedError = convertFrontendErrorToString(error);
      ctx.patchState({
        submissionError: stringifiedError,
        isBusy: false
      });
      ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
    } else if (response) {
      const paymentPending = response.paymentPending;
      ctx.patchState({
        submissionError: null,
        submissionResponse: response,
        isBusy: false,
        paymentPending
        // membershipId:response.membershipId
      });
      // reminder - in case of payment, this will be called 2 times!
      // ... when starting payment and when payment is completed (dispatched from CompletePaypalOrder).
      if (paymentPending) {
        // let paypal API handle payment validation, CompletePaypalOrder will do the rest
        return;
      } else {
        // 
        // all done! No payment pending, so go to COmplete step immediately
        const loggedIn = this.store.selectSnapshot(AccessState.loggedIn);
        if (response.sessionId) {
          this.accessFacade.setSessionId(response.sessionId);
        } else if (loggedIn) {
          this.accessFacade.refreshSession();
        }
        ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
      }
    }
  }
  setPaypalOrderId(ctx, action) {
    const submissionResponse = ctx.getState().submissionResponse;
    if (!submissionResponse?.membershipUuid) throw new Error('Invalid state: SetPayPalOrderId failed because no membershipUuid is set.');
    ctx.patchState({
      ppOrderId: action.orderId
    });
    this.api.sendRequest(MembershipPaymentAssignOrderIdRequest, {
      membershipUuid: submissionResponse.membershipUuid,
      ppOrderId: action.orderId
    }).subscribe();
  }
  /**
   * step 2 of pp payment.
   * SubmitCompleted has already been called with paymentPending being true.
   * it is task of completePaypalOrder to either re-execute SubmitCompleted with an updated, non-paymentPending membership item
   * or to set paymentError state so that user can be informed of created, but not-yet-activated membership.
   */
  completePaypalOrder(ctx, action) {
    const submissionResponse = ctx.getState().submissionResponse;
    if (!submissionResponse?.membershipUuid) throw new Error('Invalid state: called CompletePaypalOrder, but no membershipUuid has been created yet!');
    const {
      error,
      orderDetails
    } = action.data;
    const handleSessionUpdate = () => {
      const loggedIn = this.store.selectSnapshot(AccessState.loggedIn);
      if (submissionResponse?.sessionId) {
        this.accessFacade.setSessionId(submissionResponse.sessionId);
      } else if (loggedIn) {
        this.accessFacade.refreshSession();
      }
    };
    if (error) {
      ctx.patchState({
        paymentError: error
      });
      ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
      handleSessionUpdate();
    } else {
      ctx.patchState({
        paymentTransactionDetails: orderDetails
      });
      this.api.sendRequest(MembershipApprovePaypalPaymentRequest, {
        membershipUuid: submissionResponse.membershipUuid,
        transactionDetails: orderDetails
      }).subscribe({
        next: updatedSubmissionResponse => {
          ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
          ctx.dispatch(new MembershipRegistrationActions.SubmitCompleted(updatedSubmissionResponse, null));
          // session refreshed in Submit Completed
        },
        error: approvalError => {
          ctx.patchState({
            paymentError: approvalError
          });
          ctx.dispatch(new MembershipRegistrationActions.GoToStep(RegistrationStep.complete));
          handleSessionUpdate();
        }
      });
    }
  }
  reset(ctx, action) {
    const meta = new Object(getStoreMetadata(MembershipRegistrationState_1));
    ctx.setState({
      ...meta.defaults,
      formData: {}
    });
    this.setupInitialState(ctx);
  }
  static {
    this.ɵfac = function MembershipRegistrationState_Factory(t) {
      return new (t || MembershipRegistrationState)(i0.ɵɵinject(i1.AppRequestService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.AccessFacade), i0.ɵɵinject(i5.MembershipPluginsService));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: MembershipRegistrationState,
      factory: MembershipRegistrationState.ɵfac
    });
  }
};
__decorate([Action(MembershipRegistrationActions.SetRegistrationMode), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetRegistrationMode]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setRegistrationMode", null);
__decorate([Action(MembershipRegistrationActions.SetDisplayMode), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetDisplayMode]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setDisplayMode", null);
__decorate([Action(MembershipRegistrationActions.RestoreExistingMembership), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.RestoreExistingMembership]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "restoreExistingMembership", null);
__decorate([Action(MembershipRegistrationActions.SetEnabledSteps), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetEnabledSteps]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setEnabledSteps", null);
__decorate([Action(MembershipRegistrationActions.GoToStep), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.GoToStep]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "goToStep", null);
__decorate([Action(MembershipRegistrationActions.ConfirmStep), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.ConfirmStep]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "confirmStep", null);
__decorate([Action(MembershipRegistrationActions.SetMembershipType), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetMembershipType]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setMembershipType", null);
__decorate([Action(MembershipRegistrationActions.SetPluginFormState), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetPluginFormState]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setPluginFormState", null);
__decorate([Action(MembershipRegistrationActions.SetFormData), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetFormData]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setFormData", null);
__decorate([Action(MembershipRegistrationActions.Submit), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.Submit]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "submit", null);
__decorate([Action(MembershipRegistrationActions.SubmitCompleted), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SubmitCompleted]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "submitCompleted", null);
__decorate([Action(MembershipRegistrationActions.SetPaypalOrderId), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.SetPaypalOrderId]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "setPaypalOrderId", null);
__decorate([Action(MembershipRegistrationActions.CompletePaypalOrder), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.CompletePaypalOrder]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "completePaypalOrder", null);
__decorate([Action(MembershipRegistrationActions.Reset), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, MembershipRegistrationActions.Reset]), __metadata("design:returntype", void 0)], MembershipRegistrationState.prototype, "reset", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "currentStep", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "redirectInProgress", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "membershipType", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "membershipPluginInfo", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "allFormData", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "paymentError", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "submissionError", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "submissionResponse", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "isBusy", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "mode", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "displayMode", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "enablePayment", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "pluginFormState", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "enabledSteps", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "enabledSummaryStep", null);
__decorate([Selector([AccessState.loggedIn]), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, Boolean]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "requireAccountData", null);
__decorate([Selector([MembershipRegistrationState.requireAccountData]), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, Boolean]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "credentialsByMail", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], MembershipRegistrationState, "requiresPayment", null);
MembershipRegistrationState = MembershipRegistrationState_1 = __decorate([State({
  name: 'membership_registration',
  defaults: {
    mode: MembershipRegistrationMode.create,
    displayMode: MembershipDisplayMode.full,
    enabledRegistrationSteps: defaultRegistrationSteps,
    enablePayment: false,
    // TODO: currentStep was membershipTypeSelection, but currently membershipTypeSelection + forms are displayed on a single page.
    // so right now there is no "membershipTypeSelection step" present.
    currentStep: RegistrationStep.forms,
    membershipPluginInfo: null,
    pluginFormState: null,
    formData: {},
    ppOrderId: null,
    paymentTransactionDetails: null,
    paymentPending: false,
    paymentError: false,
    redirectInProgress: false,
    isBusy: false,
    submissionError: null,
    // membershipId:null,
    submissionResponse: null
  }
}), AutoUnsubscribe(), __metadata("design:paramtypes", [AppRequestService, Store, ConfigService, AccessFacade, MembershipPluginsService])], MembershipRegistrationState);
export { MembershipRegistrationState };