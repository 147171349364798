import { __decorate, __metadata } from "tslib";
import { AppEvent } from '@core/shared';
import { Expose, Model } from '@core/shared/model';
import { UserItem } from '../models/user.item';
let CreatedUserEvent = class CreatedUserEvent extends UserItem {};
CreatedUserEvent = __decorate([AppEvent('users', 'created-user')], CreatedUserEvent);
export { CreatedUserEvent };
let UpdatedUserEvent = class UpdatedUserEvent extends Model {};
__decorate([Expose(), __metadata("design:type", UserItem)], UpdatedUserEvent.prototype, "oldItem", void 0);
__decorate([Expose(), __metadata("design:type", UserItem)], UpdatedUserEvent.prototype, "newItem", void 0);
UpdatedUserEvent = __decorate([AppEvent('users', 'updated-user')], UpdatedUserEvent);
export { UpdatedUserEvent };
let DeletedUserEvent = class DeletedUserEvent extends UserItem {};
DeletedUserEvent = __decorate([AppEvent('users', 'deleted-user')], DeletedUserEvent);
export { DeletedUserEvent };