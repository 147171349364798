import { MediaAssetType } from '../interfaces';
export const DEFAULT_MEDIA_TRANSFORM_PRESET_ID = 'default';
/** injectable in both platforms! */
export class MediaTransformationsRegistry {
  constructor() {
    this.transformations = {
      image: new Map(),
      video: new Map(),
      document: new Map(),
      raw: new Map()
    };
    // each preset consists of a list of transformation names 
    this.presets = {
      image: new Map(),
      video: new Map(),
      document: new Map(),
      raw: new Map()
    };
    this.registerTransformation(MediaAssetType.image, 'sd', {
      width: 480
    });
    this.registerTransformation(MediaAssetType.video, 'fullHd', {
      width: 1920,
      quality: 75
    });
    this.registerTransformation(MediaAssetType.video, 'hd', {
      width: 1280,
      quality: 75
    });
    this.registerTransformation(MediaAssetType.video, 'eco', {
      width: 768,
      quality: 50
    });
    this.registerPreset(MediaAssetType.image, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
    this.registerPreset(MediaAssetType.video, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, ['fullHd', 'hd', 'eco']);
    this.registerPreset(MediaAssetType.document, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
    this.registerPreset(MediaAssetType.raw, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
  }
  registerTransformation(type, name, transformation) {
    this.transformations[type].set(name, {
      transformation
    });
  }
  getTransformation(type, name) {
    const trans = this.transformations[type].get(name);
    if (!trans) throw new Error('MediaTransformation ' + type + ':' + name + ' does not exist!');
    return trans;
  }
  getAllTransformationsForType(type) {
    return this.transformations[type];
  }
  registerPreset(type, name, transformations) {
    const allTrans = this.getAllTransformationsForType(type);
    for (const tName of transformations) {
      if (!allTrans.has(tName)) throw new Error('Registering Cloudinary Preset failed: Transformation named ' + tName + ' is unknown');
    }
    this.presets[type].set(name, transformations);
  }
  getPreset(type, name) {
    if (name === true) name = DEFAULT_MEDIA_TRANSFORM_PRESET_ID;
    const presetContents = this.presets[type].get(name);
    if (!presetContents) {
      throw new Error('MediaTransformationsRegistry: unknown resource preset ' + type + ' - ' + name + ' requested!');
    }
    const allTrans = this.getAllTransformationsForType(type);
    const transMap = new Map();
    presetContents.forEach(tName => {
      transMap.set(tName, allTrans.get(tName));
    });
    return {
      name,
      transformations: transMap
    };
  }
  getTransformationsFromPreset(preset) {
    return Array.from(preset.transformations.values()).map(info => info.transformation);
  }
  getAllPresetsForType(type) {
    const presets = this.presets[type];
    const out = [];
    presets.forEach((data, name) => {
      out.push(this.getPreset(type, name));
    });
    return out;
  }
}