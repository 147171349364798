import { __decorate } from "tslib";
import { EventEmitter } from '@angular/core';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { BaseFormService } from "./base-form.service";
/**
 * keep in mind that every simple-fieldset and array-fieldset will auto-create an own instance of ModelFormService!
 */
let ModelFormService = class ModelFormService extends BaseFormService {
  constructor() {
    super(...arguments);
    this.onUpdateFormControlReadonlyState = new EventEmitter();
  }
  getDefaultValue() {
    if (this.modelFactory) return this.modelFactory.newInstance();else return {};
  }
  getInitialValue() {
    if (this.initialValue) {
      if (this.modelFactory) return this.modelFactory.fromData(this.initialValue);else return this.initialValue;
    } else if (this.modelFactory) {
      return this.modelFactory.newInstance();
    } else {
      return {};
    }
  }
  dataIsArray() {
    return false;
  }
};
ModelFormService = __decorate([AutoUnsubscribe()], ModelFormService);
export { ModelFormService };