import { __decorate, __metadata } from "tslib";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, scan, Subject, timeInterval } from 'rxjs';
import { DynamicDialogService } from '@common/ui/frontend-shared/dynamic-dialog';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { loadAndShowConsole } from './console-loader';
import * as i0 from "@angular/core";
import * as i1 from "@common/ui/frontend-shared/dynamic-dialog";
/**
 * listenes for a combination of mouseover on host + typing a predefined hotkey in order to open console.
 */
let OpenConsoleDirective = class OpenConsoleDirective {
  onMouseOver(e) {
    this.isMouseOver$.next(true);
  }
  onMouseLeave(e) {
    this.isMouseOver$.next(false);
  }
  constructor(document, ngPlatformId, dialogService) {
    this.dialogService = dialogService;
    this.hotkey = 'ccc';
    this.isMouseOver$ = new BehaviorSubject(false);
    this.onKeypress$ = new Subject();
    // just comment in temporary for auto-opening console during development
    // ngOnInit() {
    // 	setTimeout(()=>{
    // 		this.onEnteredHotkey() 
    // 	},500)
    // }
    this.onBodyKeypress = e => {
      this.onKeypress$.next(e.key);
    };
    const isClient = isPlatformBrowser(ngPlatformId);
    if (isClient) {
      this.isMouseOver$.subscribe(isMouseOver => {
        if (isMouseOver) {
          document.body.addEventListener('keypress', this.onBodyKeypress);
        } else {
          document.body.removeEventListener('keypress', this.onBodyKeypress);
        }
      });
      this.onKeypress$.pipe(takeWhileAlive(this),
      // add info on passed time since last emit, data becomes {value,interval}
      timeInterval(),
      // just like array.reduce... just for async observables.
      scan((all, {
        value,
        interval
      }) => {
        // reset the typed string if last keypress was > 600ms ago
        if (interval > 600) all = '';
        const str = all + value;
        // cut away everything thats longer than our configurated hotkey.
        const maxLength = this.hotkey.length;
        return str.substring(str.length - maxLength);
      }, "")).subscribe(typedChars => {
        if (typedChars === this.hotkey) {
          this.onEnteredHotkey();
        }
      });
    }
  }
  onEnteredHotkey() {
    loadAndShowConsole(this.dialogService);
  }
  static {
    this.ɵfac = function OpenConsoleDirective_Factory(t) {
      return new (t || OpenConsoleDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i1.DynamicDialogService));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: OpenConsoleDirective,
      selectors: [["", "openConsole", ""]],
      hostBindings: function OpenConsoleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseover", function OpenConsoleDirective_mouseover_HostBindingHandler() {
            return ctx.onMouseOver();
          })("mouseleave", function OpenConsoleDirective_mouseleave_HostBindingHandler() {
            return ctx.onMouseLeave();
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([DynamicDialogService])]
    });
  }
};
OpenConsoleDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Document, Object, DynamicDialogService])], OpenConsoleDirective);
export { OpenConsoleDirective };