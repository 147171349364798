export const defaultUploaderLinks = {
  downloadEntry: '',
  updateEntry: '',
  createEntry: '',
  deleteEntry: ''
};
export const defaultUploaderOptions = {
  createMethod: 'POST',
  updateMethod: 'POST',
  authorizationHeaderName: 'Authorization',
  tokenPattern: 'Bearer #token#',
  token: null,
  links: defaultUploaderLinks,
  totalChunkParamName: 'total_chunks',
  currentChunkParamName: 'current_chunk',
  fileParamName: 'file',
  idAttribute: 'id'
};