import { __decorate, __metadata } from "tslib";
import { EditorDefinition, Expose, IsEmail, IsOptional, IsString, MinLength, Model } from '@core/shared/model';
// part of registration request to be sent to server, data will be used to create UserItem
let MembershipRegistrationDataPrivateInsuranceSimpleModel = class MembershipRegistrationDataPrivateInsuranceSimpleModel extends Model {};
__decorate([Expose(), IsString({
  message: 'Bitte wählen Sie Ihre Krankenkasse aus.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "insuranceId", void 0);
__decorate([Expose(), MinLength(1, {
  message: 'Bitte geben Sie Ihre Versichertennummer an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "insuranceMembershipId", void 0);
__decorate([Expose(), MinLength(2, {
  message: 'Bitte geben Sie Ihren Vornamen an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "firstName", void 0);
__decorate([Expose(), MinLength(2, {
  message: 'Bitte geben Sie Ihren Nachnamen an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "lastName", void 0);
__decorate([Expose(), IsEmail(undefined, {
  message: 'Bitte geben Sie Ihre E-Mail-Adresse an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "email", void 0);
__decorate([Expose(), IsOptional(), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "phone", void 0);
__decorate([Expose(), __metadata("design:type", Boolean)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "allow3rdPartyContact", void 0);
__decorate([Expose(), MinLength(3, {
  message: 'Bitte geben Sie Straße und Hausnr. an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "address", void 0);
__decorate([Expose(), MinLength(5, {
  message: 'Bitte geben Sie die Postleitzahl an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "zipcode", void 0);
__decorate([Expose(), MinLength(2, {
  message: 'Bitte geben Sie die Stadt an.'
}), __metadata("design:type", String)], MembershipRegistrationDataPrivateInsuranceSimpleModel.prototype, "city", void 0);
MembershipRegistrationDataPrivateInsuranceSimpleModel = __decorate([EditorDefinition(['insuranceId', 'insuranceMembershipId', 'address', 'zipcode', 'city']), EditorDefinition('with-account-fields', ['insuranceId', 'insuranceMembershipId', 'firstName', 'lastName', 'email', 'phone', 'allow3rdPartyContact', 'address', 'zipcode', 'city'])
// @EditorDefinition(['topic','reason','careDegree','insuranceMembershipId','firstName','lastName','birthdate','address','zipcode','city'])
], MembershipRegistrationDataPrivateInsuranceSimpleModel);
export { MembershipRegistrationDataPrivateInsuranceSimpleModel };