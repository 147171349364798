import { __decorate, __metadata } from "tslib";
import { StatisticRequest, StatisticsQueryParams, StatisticsResponse } from '@common/statistics/shared';
import { AppRequest, AppRequestMethod } from '@core/shared';
import { Expose, Type } from '@core/shared/model';
let TrackingConsentStatisticsRequest = class TrackingConsentStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => StatisticsQueryParams), __metadata("design:type", StatisticsQueryParams)], TrackingConsentStatisticsRequest.prototype, "params", void 0);
TrackingConsentStatisticsRequest = __decorate([AppRequest('admin/tracking', 'statistics/consent-count', {
  httpMethod: AppRequestMethod.POST
})], TrackingConsentStatisticsRequest);
export { TrackingConsentStatisticsRequest };
let TrackingConsentRatioStatisticsRequest = class TrackingConsentRatioStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => StatisticsQueryParams), __metadata("design:type", StatisticsQueryParams)], TrackingConsentRatioStatisticsRequest.prototype, "params", void 0);
TrackingConsentRatioStatisticsRequest = __decorate([AppRequest('admin/tracking', 'statistics/consent-ratio', {
  httpMethod: AppRequestMethod.POST
})], TrackingConsentRatioStatisticsRequest);
export { TrackingConsentRatioStatisticsRequest };