import { ReadonlyItemRestraints } from '@core/shared';
export class TrackingLogEntityRestraints extends ReadonlyItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return true;
  }
  canToggleItem(item) {
    return true;
  }
  canUpdateItem(item, oldItem) {
    return true;
  }
  canRemoveItem(item) {
    return true;
  }
}