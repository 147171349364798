import { __decorate, __metadata } from "tslib";
import { UseRestraints } from '@core/shared';
import { EditorDefinition, Expose, IdItemModel, ItemModelDefinition, ModelId } from '@core/shared/model';
import { SkillsetMappingRestraints } from "./skillset-mapping.restraints";
let SkillsetMappingItem = class SkillsetMappingItem extends IdItemModel {
  toString() {
    return "SkillsetMapping " + this.name;
  }
};
__decorate([Expose(), __metadata("design:type", Object)], SkillsetMappingItem.prototype, "skillset", void 0);
__decorate([Expose(), __metadata("design:type", Number)], SkillsetMappingItem.prototype, "skillsetId", void 0);
__decorate([Expose(), __metadata("design:type", String)], SkillsetMappingItem.prototype, "consumerName", void 0);
__decorate([Expose(), __metadata("design:type", Number)], SkillsetMappingItem.prototype, "consumerId", void 0);
SkillsetMappingItem = __decorate([EditorDefinition({}, {
  allowAllExposed: true,
  useCrudEndpoint: 'admin'
})
// @CrudEndpoints({admin:'/admin/abilities/skillset-mappings'})
, ItemModelDefinition({
  texts: {}
}), UseRestraints(SkillsetMappingRestraints), ModelId("SkillsetMappingItem") // autogenerated by generate-model-ids
], SkillsetMappingItem);
export { SkillsetMappingItem };