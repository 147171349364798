import { isSafePassword } from "@core/shared/model";
export function randomPassword(length = 12) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  const acceptablePwQuality = isSafePassword(result);
  if (!acceptablePwQuality) {
    return randomPassword(length);
  }
  return result;
}