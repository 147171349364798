import { customMetadataStorage } from './custom-metadata';
import { ModelHelper } from './model.helper';
/**
 * one important thing to keep in mind about models:
 * they are not loaded eagerly. Most models are used by async chunks and will be fetched on demand.
 * So any model addon has to either work name-based or by first importing that model so that it is known.
 * (which would happen automatically as soon as it is imported to associate any extra data with it.)
 */
// can be injected, provided by Core Modules!
export class ModelRegistryService {
  // constructor(
  // 	debug:DevToolsRegistry
  // ) {
  // 	debug.exposeDebugFunction('myDebugFunct',()=>{
  // 		return dataToInspect
  // 	})
  // }
  getItemModels() {
    return customMetadataStorage.getItemModels();
  }
  modelIdentifierToInternalId(modelId) {
    return ModelHelper.modelIdentifierToId(modelId);
  }
}