import { ItemRestraints } from '@core/shared';
export class MembershipRestraints extends ItemRestraints {
  canViewItem(item) {
    if (this.currentUserIsOwner(item)) return true;
    return true;
  }
  canCreateItem(item) {
    return true;
  }
  canToggleItem(item) {
    return this.isExecutedBySystem() || this.requirePermission('ADMINISTRATOR');
  }
  canUpdateItem(item, oldItem) {
    return this.isExecutedBySystem() || this.requirePermission('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    return this.isExecutedBySystem() || this.requirePermission('ADMINISTRATOR');
  }
}