import { __decorate, __metadata } from "tslib";
import { StatisticRequest, StatisticsResponse } from '@common/statistics/shared';
import { AppRequest, AppRequestMethod } from '@core/shared';
import { Expose, Type } from '@core/shared/model';
import { UserStatisticsQueryParams } from '../interfaces';
let TotalActivityStatisticRequest = class TotalActivityStatisticRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => UserStatisticsQueryParams), __metadata("design:type", UserStatisticsQueryParams)], TotalActivityStatisticRequest.prototype, "params", void 0);
TotalActivityStatisticRequest = __decorate([AppRequest('admin/users', 'statistics/activity/total', {
  httpMethod: AppRequestMethod.POST
})], TotalActivityStatisticRequest);
export { TotalActivityStatisticRequest };