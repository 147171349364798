import { __decorate } from "tslib";
import { CommonModule } from '@angular/common';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { MembershipProfileInfoboxComponent } from './membership-profile-infobox.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../ui/frontend-shared/src/lib/message/message";
import * as i3 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
import * as i4 from "../../../../../ui/frontend-shared/src/lib/button/button";
function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ui_message_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 4);
    i0.ɵɵtext(1, " Ihre Zugang wurde noch nicht aktiviert, da noch keine Zahlung eingegangen ist. Bitte schlie\u00DFen Sie die Bestellung zuerst ab. Ist etwas schief gelaufen? Gerne hilft unser Supportteam weiter. ");
    i0.ɵɵelementStart(2, "button", 5);
    i0.ɵɵlistener("click", function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ui_message_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r6.payNow());
    });
    i0.ɵɵelementEnd()();
  }
}
function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 8);
    i0.ɵɵtext(1, " Ihre Mitgliedschaft ist abgelaufen. ");
    i0.ɵɵelementStart(2, "button", 9);
    i0.ɵɵlistener("click", function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r10.renew());
    });
    i0.ɵɵelementEnd()();
  }
}
function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "button", 11);
    i0.ɵɵlistener("click", function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_1_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r12.renew());
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" Ihr Zugang ist noch ", status_r1.membershipDaysLeft, " Tage g\u00FCltig. ");
  }
}
function MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_0_Template, 3, 0, "ui-message", 6)(1, MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_ui_message_1_Template, 3, 1, "ui-message", 7);
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngIf", status_r1.hasExpiredMembership);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", status_r1.membershipIsValid && status_r1.membershipDaysLeft < 30);
  }
}
function MembershipStatusMessageComponent_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MembershipStatusMessageComponent_ng_template_0_ng_template_0_ui_message_0_Template, 3, 0, "ui-message", 2)(1, MembershipStatusMessageComponent_ng_template_0_ng_template_0_ng_template_1_Template, 2, 2, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r5 = i0.ɵɵreference(2);
    const status_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngIf", status_r1.membershipPaymentPending)("ngIfElse", _r5);
  }
}
function MembershipStatusMessageComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MembershipStatusMessageComponent_ng_template_0_ng_template_0_Template, 3, 2, "ng-template", 1);
  }
  if (rf & 2) {
    const status_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", status_r1.loggedIn);
  }
}
let MembershipStatusMessageComponent = class MembershipStatusMessageComponent extends MembershipProfileInfoboxComponent {
  static {
    this.ɵfac = /*@__PURE__*/(() => {
      let ɵMembershipStatusMessageComponent_BaseFactory;
      return function MembershipStatusMessageComponent_Factory(t) {
        return (ɵMembershipStatusMessageComponent_BaseFactory || (ɵMembershipStatusMessageComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MembershipStatusMessageComponent)))(t || MembershipStatusMessageComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MembershipStatusMessageComponent,
      selectors: [["membership-status-message"]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[3, "ngLet"], [3, "ngIf"], ["type", "warn", "class", "membership-message message-payment-pending", 4, "ngIf", "ngIfElse"], ["noPendingPayment", ""], ["type", "warn", 1, "membership-message", "message-payment-pending"], ["uiButton", "", "label", "Zahlung abschlie\u00DFen", 1, "ui-button-outline", "small", 3, "click"], ["type", "warn", "class", "membership-message message-expired", 4, "ngIf"], ["type", "info", "class", "membership-message message-days-left", 4, "ngIf"], ["type", "warn", 1, "membership-message", "message-expired"], ["uiButton", "", "label", "Mitgliedschaft fortsetzen", 1, "ui-button-outline", "small", 3, "click"], ["type", "info", 1, "membership-message", "message-days-left"], ["uiButton", "", "label", "jetzt verl\u00E4ngern", 1, "ui-button-outline", "small", 3, "click"]],
      template: function MembershipStatusMessageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MembershipStatusMessageComponent_ng_template_0_Template, 1, 1, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx.membershipStatus$));
        }
      },
      dependencies: [CommonModule, i1.NgIf, i1.AsyncPipe, UiMessageModule, i2.UiMessageComponent, UtilsFrontendSharedModule, i3.NgLetDirective, UiButtonModule, i4.UiButtonComponent],
      styles: [".membership-message[_ngcontent-%COMP%]{max-width:560px;margin:20px auto}\n\n/*# sourceMappingURL=membership-status-message.component.ts-angular-inline--64.css.map*/"],
      changeDetection: 0
    });
  }
};
MembershipStatusMessageComponent = __decorate([AutoUnsubscribe()], MembershipStatusMessageComponent);
export { MembershipStatusMessageComponent };