import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { EventEmitter, computed, input, signal } from '@angular/core';
import { CrudListController } from '@common/crud-ui/frontend-shared';
import { MediaAssetItem } from '@common/media/shared';
import { AutoUnsubscribe, ItemServiceFactory, getCurrentRouteState } from '@core/frontend-shared';
import { MediaAssetGridModule } from '../media-grid';
import { DynamicDialogRef } from '@common/ui/frontend-shared/dynamic-dialog';
import { createMediaGridFilters } from '../create-media-grid-filters';
import { DynamicFieldsetModule } from '@common/forms/frontend-shared';
import { MediaGridSettingsService } from '../media-grid/media-grid-settings/media-grid-settings.service';
import { MediaIconsModule } from '../media-icons.module';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/ui/frontend-shared/dynamic-dialog";
import * as i3 from "@common/ui/frontend-shared/input-ref";
import * as i4 from "@angular/common";
import * as i5 from "../media-grid/media-grid/media-grid.component";
import * as i6 from "../media-grid/media-grid-toolbar/media-grid-toolbar.component";
import * as i7 from "../../../../../forms/frontend-shared/src/lib/filter-fieldset/filter-fieldset.component";
function MediaAssetSelectorComponent_ng_template_0_filter_fieldset_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "filter-fieldset", 4);
    i0.ɵɵlistener("valueChanged", function MediaAssetSelectorComponent_ng_template_0_filter_fieldset_1_Template_filter_fieldset_valueChanged_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.listController.updateListState({
        filters: $event
      }, 300));
    })("formService", function MediaAssetSelectorComponent_ng_template_0_filter_fieldset_1_Template_filter_fieldset_formService_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r4 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r4.listController.bindFilterFieldsetFormService($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("instructions", ctx_r1.visibleFilterInstructions())("inputGroupBreakpoint", 800);
  }
}
function MediaAssetSelectorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "media-asset-grid-toolbar", 1);
    i0.ɵɵtemplate(1, MediaAssetSelectorComponent_ng_template_0_filter_fieldset_1_Template, 1, 2, "filter-fieldset", 2);
    i0.ɵɵelementStart(2, "media-asset-grid", 3);
    i0.ɵɵlistener("clickItem", function MediaAssetSelectorComponent_ng_template_0_Template_media_asset_grid_clickItem_2_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onClickItem($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("listController", ctx_r0.listController)("service", ctx_r0.service);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.visibleFilterInstructions().length);
    i0.ɵɵadvance();
    i0.ɵɵproperty("service", ctx_r0.service)("itemsPerPage", ctx_r0.fullConfig().itemsPerPage || -1)("itemsPerPageOptions", ctx_r0.fullConfig().itemsPerPageOptions)("listController", ctx_r0.listController);
  }
}
const listConfigDefaults = {
  useCrudEndpoint: 'admin',
  toggleable: false,
  createable: true,
  editable: true,
  deleteable: false,
  itemsPerPage: 30,
  itemsPerPageOptions: [16, 24, 30, 36, 48]
};
let MediaAssetSelectorComponent = class MediaAssetSelectorComponent {
  constructor(itemServiceFactory, dialogRef) {
    this.itemServiceFactory = itemServiceFactory;
    this.dialogRef = dialogRef;
    this.crudEndpoint = 'admin';
    this.allowedFileTypes = input('any');
    this.select = new EventEmitter();
    this.allFilterInstructions = signal(null);
    this.visibleFilterInstructions = computed(() => {
      let visible = this.allFilterInstructions(); // == createMediaGridFilters()
      const allowedFileTypes = this.allowedFileTypes();
      if (!visible) return [];
      if (allowedFileTypes !== 'any') {
        visible = visible.filter(instruction => {
          return instruction.config.formControlName !== 'type';
        });
      }
      return visible;
    });
    this._listConfig = input({}, {
      alias: 'listConfig'
    });
    this.fullConfig = computed(() => {
      return {
        ...listConfigDefaults,
        filters: createMediaGridFilters(),
        ...this._listConfig()
      };
    });
  }
  ngOnInit() {
    this.service = this.itemServiceFactory.createPagedItemService(MediaAssetItem);
    this.service.useCrudEndpoint(this.crudEndpoint);
    this.service.initialize(this.fullConfig().itemsPerPage || 20, 0);
    this.listController = new CrudListController(this.fullConfig(), this.service, getCurrentRouteState(), null);
    if (this.allowedFileTypes() !== 'any') {
      this.listController.lockListState({
        permanentFilters: [[{
          field: 'type',
          operator: '$eq',
          value: this.allowedFileTypes()
        }]]
      });
    }
    this.allFilterInstructions.set(this.listController.listConfig.filters);
  }
  onClickItem(item) {
    this.select.emit(item);
    this.dialogRef?.close(item);
  }
  static {
    this.ɵfac = function MediaAssetSelectorComponent_Factory(t) {
      return new (t || MediaAssetSelectorComponent)(i0.ɵɵdirectiveInject(i1.ItemServiceFactory), i0.ɵɵdirectiveInject(i2.DynamicDialogRef, 8));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetSelectorComponent,
      selectors: [["media-asset-selector"]],
      inputs: {
        crudEndpoint: "crudEndpoint",
        allowedFileTypes: [i0.ɵɵInputFlags.SignalBased, "allowedFileTypes"],
        _listConfig: [i0.ɵɵInputFlags.SignalBased, "listConfig", "_listConfig"]
      },
      outputs: {
        select: "select"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([MediaGridSettingsService]), i0.ɵɵHostDirectivesFeature([i3.InputRefBlockerDirective]), i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "ngIf"], [3, "listController", "service"], [3, "instructions", "inputGroupBreakpoint", "valueChanged", "formService", 4, "ngIf"], [3, "service", "itemsPerPage", "itemsPerPageOptions", "listController", "clickItem"], [3, "instructions", "inputGroupBreakpoint", "valueChanged", "formService"]],
      template: function MediaAssetSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MediaAssetSelectorComponent_ng_template_0_Template, 3, 7, "ng-template", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.listController);
        }
      },
      dependencies: [CommonModule, i4.NgIf, MediaAssetGridModule, i5.MediaAssetGridComponent, i6.MediaAssetGridToolbarComponent, DynamicFieldsetModule, i7.FilterFieldsetComponent, MediaIconsModule],
      styles: ["filter-fieldset[_ngcontent-%COMP%]     .filter-fieldset-fields{justify-content:center;margin-top:10px}media-asset-grid-toolbar[_ngcontent-%COMP%]     .active-filters-notification{display:none}\n\n/*# sourceMappingURL=media-asset-selector.component.ts-angular-inline--56.css.map*/"]
    });
  }
};
MediaAssetSelectorComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ItemServiceFactory, DynamicDialogRef])], MediaAssetSelectorComponent);
export { MediaAssetSelectorComponent };