import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, computed, input, signal } from '@angular/core';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { filter, map, merge, of, switchMap, take } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i4 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
import * as i5 from "./instruction-outlet.directive";
function InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ui_field_wrapper_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = (a0, a1, a2, a3) => ({
  instruction: a0,
  formGroup: a1,
  formService: a2,
  formControlName: a3
});
function InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ui_field_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-field-wrapper", 5);
    i0.ɵɵtemplate(1, InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ui_field_wrapper_0_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const localFormGroup_r5 = i0.ɵɵnextContext(2).$implicit;
    const instruction_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵproperty("placeholder", instruction_r1.config.placeholder || null)("label", instruction_r1.config.label || null)("orientation", instruction_r1.config.orientation || "horizontal")("description", instruction_r1.config.description)("formGroup", localFormGroup_r5)("help", instruction_r1.config.help)("noInput", !instruction_r1.isInput);
    i0.ɵɵadvance();
    i0.ɵɵproperty("instructionOutlet", i0.ɵɵpureFunction4(8, _c0, instruction_r1, localFormGroup_r5, ctx_r7.formService(), ctx_r7.localFormControlName()));
  }
}
function InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ng_template_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 7);
    i0.ɵɵtemplate(1, InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ng_template_1_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const localFormGroup_r5 = i0.ɵɵnextContext(2).$implicit;
    const instruction_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r8 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formGroup", localFormGroup_r5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("instructionOutlet", i0.ɵɵpureFunction4(2, _c0, instruction_r1, localFormGroup_r5, ctx_r8.formService(), ctx_r8.localFormControlName()));
  }
}
function InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ui_field_wrapper_0_Template, 2, 13, "ui-field-wrapper", 3)(1, InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_ng_template_1_Template, 2, 7, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r9 = i0.ɵɵreference(2);
    const instruction_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngIf", instruction_r1.useFieldWrapper)("ngIfElse", _r9);
  }
}
function InstructionOutletComponent_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InstructionOutletComponent_ng_template_0_ng_template_0_ng_template_0_Template, 3, 2, "ng-template", 0);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r2.displayState());
  }
}
function InstructionOutletComponent_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ui-spinner", 8);
  }
  if (rf & 2) {
    i0.ɵɵproperty("inline", true);
  }
}
function InstructionOutletComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InstructionOutletComponent_ng_template_0_ng_template_0_Template, 1, 1, "ng-template", 1)(1, InstructionOutletComponent_ng_template_0_ng_template_1_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r4 = i0.ɵɵreference(2);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.localFormGroup())("ngIfElse", _r4);
  }
}
let InstructionOutletComponent = class InstructionOutletComponent {
  get _hostClass() {
    return this.isPluginInstruction();
  }
  constructor(cd) {
    this.cd = cd;
    this.displayState = signal(true);
    this.isPluginInstruction = computed(() => {
      return !!this.instruction()?.config.__pluginInstructionInfo;
    });
    this.instruction = input(null);
    this.inputForm = input(null, {
      alias: 'form'
    });
    // note: formService is optional. Either form or formService must be specified.
    // if formService is passed, conditional evaluators will receive full model.
    // otherwise they will receive the raw formGroup value.
    this.formService = input(null);
    this.baseFormGroup = computed(() => {
      const customFormGroup = this.inputForm();
      const formService = this.formService();
      if (customFormGroup) return customFormGroup;else if (formService) return formService.formGroup;else return null;
    });
    /**
     * Angular/forms does not support deep accessors inside directives like formControlName.
     * In order to make deep accessors work, we extract the simple formControlName + parent path,
     * get the appropriate formGroup from parent and assign them correctly.
     */
    this.isDeepAccessor = computed(() => {
      const config = this.instruction().config;
      const isDeepAccessor = config.formControlName?.includes('.') || false;
      return isDeepAccessor;
    });
    this.localFormControlName = computed(() => {
      const config = this.instruction().config;
      if (this.isDeepAccessor()) {
        const splitted = config.formControlName.split('.');
        if (splitted.length > 2) throw new Error('deepAccessor currently only supports one layer of nesting! Deeper accessors need to be tested!');
        const formControlName = splitted.pop();
        return formControlName;
      } else {
        return config.formControlName;
      }
    });
    this.localFormGroup = computed(() => {
      const baseFormGroup = this.baseFormGroup();
      if (!baseFormGroup) return null;
      const isDeepAccessor = this.isDeepAccessor();
      const config = this.instruction().config;
      if (isDeepAccessor) {
        const splitted = config.formControlName.split('.');
        splitted.pop();
        const formGroupName = splitted.join('.');
        return baseFormGroup.get(formGroupName);
      } else {
        return baseFormGroup;
      }
    });
  }
  ngOnInit() {
    if (!this.inputForm() && !this.formService()) {
      throw new Error('InstructionOutletComponent error: Either form or formService must be passed in!');
    }
    const config = this.instruction().config;
    if (config.conditional) this.applyConditionalEvaluation(config);
  }
  /**
   * connect conditional logic where required
   */
  applyConditionalEvaluation(config) {
    // when this method is called, form value is not patched yet.
    // if we however wait for first formValue emission, this may cause flickering.
    // for that reason, we immediately apply default state if the Instruction uses conditional display.
    if (config.conditional?.display) this.displayState.set(false);
    this.getFormValueObservable(true).pipe(takeWhileAlive(this)).subscribe(value => {
      this.applyConditionalState(value, config);
    });
  }
  applyConditionalState(value, config) {
    const c = config.conditional;
    if (c.display) {
      this.displayState.set(c.display(value));
    }
    if (c.enable) {
      const enabledState = c.enable(value);
      const control = this.localFormGroup().get(this.localFormControlName());
      if (enabledState && !control.enabled) control.enable({
        onlySelf: true
      });else if (!enabledState && control.enabled) control.disable({
        onlySelf: true
      });
    }
    this.cd.markForCheck();
  }
  getFormValueObservable(includeReadonly = false) {
    const formService = this.formService();
    if (formService) {
      return formService.initializedValue$.pipe(filter(value => !!value), take(1)).pipe(switchMap(() => {
        // formValueChanges$ will not emit on form initialization.
        // so merge an emission of initial value into the observable
        const initialValue = formService.getValue(includeReadonly);
        return merge(of(initialValue), formService.formValueChanges$.pipe(map(value => {
          return includeReadonly ? formService.getValue(true) : value;
        })));
      }));
    } else {
      return this.baseFormGroup().root.valueChanges;
    }
  }
  static {
    this.ɵfac = function InstructionOutletComponent_Factory(t) {
      return new (t || InstructionOutletComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: InstructionOutletComponent,
      selectors: [["instruction-outlet"]],
      hostVars: 2,
      hostBindings: function InstructionOutletComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("plugin-instruction", ctx._hostClass);
        }
      },
      inputs: {
        instruction: [i0.ɵɵInputFlags.SignalBased, "instruction"],
        inputForm: [i0.ɵɵInputFlags.SignalBased, "form", "inputForm"],
        formService: [i0.ɵɵInputFlags.SignalBased, "formService"],
        value: "value"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "ngIf"], [3, "ngIf", "ngIfElse"], ["waitingForFormGroup", ""], [3, "placeholder", "label", "orientation", "description", "formGroup", "help", "noInput", 4, "ngIf", "ngIfElse"], ["plain", ""], [3, "placeholder", "label", "orientation", "description", "formGroup", "help", "noInput"], [4, "instructionOutlet"], [3, "formGroup"], ["label", "Initialisierung... warte auf FormGroup", 3, "inline"]],
      template: function InstructionOutletComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, InstructionOutletComponent_ng_template_0_Template, 3, 2, "ng-template", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.instruction());
        }
      },
      dependencies: [i1.NgIf, i2.NgControlStatusGroup, i2.FormGroupDirective, i3.FieldWrapperComponent, i4.SpinnerComponent, i5.InstructionOutletDirective],
      styles: ["instruction-outlet+instruction-outlet{margin-top:14px;display:block}[_nghost-%COMP%]{display:block}.plugin-instruction[_nghost-%COMP%]{border-right:2px solid var(--secondaryColor)}\n\n/*# sourceMappingURL=instruction-outlet.component.ts-angular-inline--28.css.map*/"],
      changeDetection: 0
    });
  }
};
InstructionOutletComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef])], InstructionOutletComponent);
export { InstructionOutletComponent };