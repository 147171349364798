import { DynamicFieldInstruction } from './shared';
export class InputAutocompleteInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      crudEndpointConfig: null,
      multiple: false,
      minLengthSearchString: 2,
      createItems: false,
      preloadItems: false,
      inputPlaceholder: 'Suche nach Name/ID...',
      optionLabel: 'name',
      renderOptionLabel: null,
      optionValue: null,
      dataKey: 'id',
      filterMode: 'or'
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      multiple: 'multiple',
      endpointConfig: 'crudEndpointConfig',
      minLengthSearchString: 'minLengthSearchString',
      createItems: 'createItems',
      preloadItems: 'preloadItems',
      filterMode: 'filterMode',
      placeholder: 'inputPlaceholder',
      searchQueryBuilder: 'searchQueryBuilder',
      optionValue: 'optionValue',
      optionLabel: 'optionLabel',
      renderOptionLabel: 'renderOptionLabel',
      dataKey: 'dataKey'
    };
    this.attributeMap = {};
    this.setConfig(config);
    // special case: autocomplete is the only OptionInstructionConfig-based component that does not support [filter] input.
    // so the simplest solution is to exclude that prop by deleting it
    delete this.inputMap['filter'];
  }
  load() {
    return import('@common/ui/frontend-shared/input-autocomplete').then(bundle => {
      return {
        component: bundle.InputAutocomplete,
        module: bundle.UiInputAutocompleteModule
      };
    });
  }
}