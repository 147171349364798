import { __decorate } from "tslib";
import { CommonModule } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiIconsModule } from '@common/ui/frontend-shared/icons';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { AutoUnsubscribe, UtilsFrontendSharedModule, takeWhileAlive } from '@core/frontend-shared/utils';
import { MembershipDisplayMode } from '../../state';
import { MembershipRegistrationFormDataTableComponent } from '../../components';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function MembershipRegistrationPluginSummaryComponent_membership_registration_form_data_table_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "membership-registration-form-data-table", 1);
    i0.ɵɵlistener("onClickEdit", function MembershipRegistrationPluginSummaryComponent_membership_registration_form_data_table_0_Template_membership_registration_form_data_table_onClickEdit_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r3);
      const dataset_r1 = restoredCtx.$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onChangeFormData.emit(dataset_r1.id));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataset_r1 = ctx.$implicit;
    i0.ɵɵproperty("displayEdit", true)("header", dataset_r1.title)("rows", dataset_r1.rows);
  }
}
let MembershipRegistrationPluginSummaryComponent = class MembershipRegistrationPluginSummaryComponent {
  constructor() {
    this.dataRows$ = new BehaviorSubject([]);
    // requests to go back to pluginForm in order to modify data.
    // if output emits data, it will be passed to pluginForm as state.
    this.onChangeFormData = new EventEmitter();
  }
  ngOnInit() {
    this.generateRows(this.data);
  }
  generateRows(data) {
    if (this.rowsSubscription) this.rowsSubscription.unsubscribe();
    this.rowsSubscription = this.plugin.generateSummaryData(data, this.requireAccountData).pipe(takeWhileAlive(this)).subscribe(dataRows => this.dataRows$.next(dataRows));
  }
  static {
    this.ɵfac = function MembershipRegistrationPluginSummaryComponent_Factory(t) {
      return new (t || MembershipRegistrationPluginSummaryComponent)();
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MembershipRegistrationPluginSummaryComponent,
      selectors: [["membership-registration-plugin-summary"]],
      inputs: {
        plugin: "plugin",
        data: "data",
        displayMode: "displayMode",
        requireAccountData: "requireAccountData"
      },
      outputs: {
        onChangeFormData: "onChangeFormData"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[3, "displayEdit", "header", "rows", "onClickEdit", 4, "ngFor", "ngForOf"], [3, "displayEdit", "header", "rows", "onClickEdit"]],
      template: function MembershipRegistrationPluginSummaryComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MembershipRegistrationPluginSummaryComponent_membership_registration_form_data_table_0_Template, 1, 3, "membership-registration-form-data-table", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(1, 1, ctx.dataRows$));
        }
      },
      dependencies: [CommonModule, i1.NgForOf, i1.AsyncPipe, CoreFrontendSharedModule, UiTabViewModule, UtilsFrontendSharedModule, UiButtonModule, UiInputGroupModule, UiIconsModule, MembershipRegistrationFormDataTableComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
MembershipRegistrationPluginSummaryComponent = __decorate([AutoUnsubscribe()], MembershipRegistrationPluginSummaryComponent);
export { MembershipRegistrationPluginSummaryComponent };