import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, EventEmitter, computed, input } from '@angular/core';
import { AppRequestService } from '@core/frontend-shared/api';
import { ConfigService } from '@core/shared/config';
// eslint-disable-next-line @nx/enforce-module-boundaries
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CommonModule } from '@angular/common';
import { FormDialogService, FormsDialogModule } from '@common/forms/frontend-shared/form-dialog';
import { MessagesFrontendSharedModule } from '@common/messages/frontend-shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { ConfirmDialogService, UiConfirmDialogModule } from '@common/ui/frontend-shared/confirmdialog';
import { UiContentCenteredModule } from '@common/ui/frontend-shared/content-centered';
import { UiDialogModule } from '@common/ui/frontend-shared/dialog';
import { DynamicDialogService } from '@common/ui/frontend-shared/dynamic-dialog';
import { UiMenuModule } from '@common/ui/frontend-shared/menu';
import { UiSpinnerModule } from '@common/ui/frontend-shared/spinner';
import { AppMessageFactory } from '@core/frontend-shared/messages';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { MediaAssetViewComponent } from '../../media-asset-view/media-asset.view';
import * as i0 from "@angular/core";
import * as i1 from "@common/forms/frontend-shared/form-dialog";
import * as i2 from "@common/ui/frontend-shared/confirmdialog";
import * as i3 from "@core/frontend-shared/api";
import * as i4 from "@core/frontend-shared/messages";
import * as i5 from "@common/ui/frontend-shared/dynamic-dialog";
import * as i6 from "@core/shared/config";
import * as i7 from "../../../../../../forms/frontend-shared/src/lib/form-dialog/form-dialog";
import * as i8 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i9 from "../../../../../../ui/frontend-shared/src/lib/menu/menu";
let MediaInputFieldContextmenuComponent = class MediaInputFieldContextmenuComponent {
  constructor(formDialog, confirm, apiService, messages, dialogService, cd, config) {
    this.formDialog = formDialog;
    this.confirm = confirm;
    this.apiService = apiService;
    this.messages = messages;
    this.dialogService = dialogService;
    this.cd = cd;
    this.config = config;
    this.mapping = input();
    this.contextMenuItems = computed(() => {
      const mapping = this.mapping();
      return this.generateContextMenu();
    });
    this.allowedFileTypes = 'image';
    this.buttonIcon = 'bars';
    this.removeAsset = new EventEmitter();
    this.loading = false;
    this.isPlatformAdmin = config.isPlatform('admin');
  }
  generateContextMenu() {
    const hasAsset = !!this.mapping()?.asset;
    if (!this.isPlatformAdmin) {
      return [{
        label: 'Datei entfernen',
        command: () => this.removeAsset.emit(null),
        icon: 'trash',
        disabled: !hasAsset
      }];
    } else {
      return [{
        label: 'Asset-Infos anzeigen',
        command: () => this.openAssetDetailsDialog(),
        icon: 'info-circle',
        disabled: !hasAsset
      }, {
        label: 'Entfernen',
        command: () => this.removeAsset.emit(null),
        icon: 'trash'
      }];
    }
  }
  openAssetDetailsDialog() {
    this.dialogService.open(MediaAssetViewComponent, {
      data: {
        item: this.mapping().asset,
        actions: [],
        padded: true
      },
      width: '1000px',
      height: '85%',
      header: 'Asset-Details',
      dismissableMask: true,
      padded: false
    });
  }
  static {
    this.ɵfac = function MediaInputFieldContextmenuComponent_Factory(t) {
      return new (t || MediaInputFieldContextmenuComponent)(i0.ɵɵdirectiveInject(i1.FormDialogService), i0.ɵɵdirectiveInject(i2.ConfirmDialogService), i0.ɵɵdirectiveInject(i3.AppRequestService), i0.ɵɵdirectiveInject(i4.AppMessageFactory), i0.ɵɵdirectiveInject(i5.DynamicDialogService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i6.ConfigService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaInputFieldContextmenuComponent,
      selectors: [["media-input-field-contextmenu"]],
      inputs: {
        mapping: [i0.ɵɵInputFlags.SignalBased, "mapping"],
        allowedFileTypes: "allowedFileTypes",
        buttonIcon: "buttonIcon",
        buttonLabel: "buttonLabel",
        requiredClPresets: "requiredClPresets"
      },
      outputs: {
        removeAsset: "removeAsset"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([DynamicDialogService, FormDialogService]), i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [["uiButton", "", "title", "Cloudinary...", 1, "select-manually", "ui-button-contrast", 3, "icon", "label", "click"], ["appendTo", "body", 3, "popup", "model"], ["menu", ""], ["appendTo", "body"]],
      template: function MediaInputFieldContextmenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function MediaInputFieldContextmenuComponent_Template_button_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const _r0 = i0.ɵɵreference(2);
            return i0.ɵɵresetView(_r0.toggle($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelement(1, "ui-menu", 1, 2)(3, "forms-dialog", 3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("icon", ctx.buttonIcon)("label", ctx.buttonLabel);
          i0.ɵɵadvance();
          i0.ɵɵproperty("popup", true)("model", ctx.contextMenuItems());
        }
      },
      dependencies: [CommonModule, FormsDialogModule, i7.FormsDialogComponent, i8.UiButtonComponent, UiConfirmDialogModule, MessagesFrontendSharedModule, UiMenuModule, i9.Menu, UiButtonModule, UiSpinnerModule, UiContentCenteredModule, UiDialogModule],
      styles: ["table.file-info[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]:first-child{width:25%}table.file-info[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]:last-child{width:75%;word-break:break-word}"],
      changeDetection: 0
    });
  }
};
MediaInputFieldContextmenuComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [FormDialogService, ConfirmDialogService, AppRequestService, AppMessageFactory, DynamicDialogService, ChangeDetectorRef, ConfigService])], MediaInputFieldContextmenuComponent);
export { MediaInputFieldContextmenuComponent };