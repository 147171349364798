import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { Select, Store } from '@ngxs/store';
import { MembershipPluginsService } from '@common/membership/shared';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { Observable, combineLatest, map } from 'rxjs';
import { MembershipRegistrationFacade, MembershipRegistrationState, RegistrationStep } from '../state';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { Angulartics2 } from 'angulartics2';
import * as i0 from "@angular/core";
import * as i1 from "../state";
import * as i2 from "@ngxs/store";
import * as i3 from "@common/membership/shared";
import * as i4 from "angulartics2";
import * as i5 from "../../../../../../core/frontend-shared/src/lib/dynamic-components/dynamic-component.directive";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
import * as i8 from "../../../../../ui/frontend-shared/src/lib/button/button";
const _c0 = ["ppContainer"];
function RegistrationSummaryComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function RegistrationSummaryComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "button", 4);
    i0.ɵɵlistener("click", function RegistrationSummaryComponent_ng_template_0_div_4_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r4.onClickSubmit());
    });
    i0.ɵɵelementEnd()();
  }
}
function RegistrationSummaryComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, " Pr\u00FCfen Sie noch einmal Ihre Angaben, bevor Sie die Registrierung abschlie\u00DFen. ");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, RegistrationSummaryComponent_ng_template_0_ng_container_2_Template, 1, 0, "ng-container", 1);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵtemplate(4, RegistrationSummaryComponent_ng_template_0_div_4_Template, 2, 0, "div", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("dynamicComponent", i0.ɵɵpipeBind1(3, 2, ctx_r0.componentWithParams$));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.mode === "submittable");
  }
}
export class RegistrationSummaryComponent {
  constructor(registration, store, pluginsService, angulartics2) {
    this.registration = registration;
    this.store = store;
    this.pluginsService = pluginsService;
    this.angulartics2 = angulartics2;
    this.mode = 'submittable';
    this.activePlugin$ = this.membershipType$.pipe(map(type => this.pluginsService.getPluginInstance(type)));
    this.componentWithParams$ = combineLatest({
      plugin: this.activePlugin$,
      data: this.allFormData$,
      displayMode: this.displayMode$,
      requireAccountData: this.requireAccountData$
    }).pipe(map(data => {
      if (!data.plugin) return null;
      const component = data.plugin.getSummaryComponent();
      return {
        inputs: {
          ...data
        },
        outputs: {
          onChangeFormData: this.onChangeFormData
        },
        component
      };
    }));
    this.onChangeFormData = pluginFormStateInfo => {
      this.registration.setPluginFormState(pluginFormStateInfo);
      this.registration.goToStep(RegistrationStep.forms);
    };
  }
  onClickSubmit() {
    this.angulartics2.eventTrack.next({
      action: 'summary-submit',
      properties: {
        category: 'membership-registration'
      }
    });
    this.registration.submit();
  }
  static {
    this.ɵfac = function RegistrationSummaryComponent_Factory(t) {
      return new (t || RegistrationSummaryComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.MembershipPluginsService), i0.ɵɵdirectiveInject(i4.Angulartics2));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationSummaryComponent,
      selectors: [["membership-registration-summary"]],
      viewQuery: function RegistrationSummaryComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ppContainer = _t.first);
        }
      },
      inputs: {
        mode: "mode"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[3, "ngLet"], [4, "dynamicComponent"], ["class", "ui-margin ui-align-center", 4, "ngIf"], [1, "ui-margin", "ui-align-center"], ["uiButton", "", "label", "jetzt senden", 3, "click"]],
      template: function RegistrationSummaryComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, RegistrationSummaryComponent_ng_template_0_Template, 5, 4, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx.allFormData$));
        }
      },
      dependencies: [CoreFrontendSharedModule, i5.DynamicComponentDirective, CommonModule, i6.NgIf, i6.AsyncPipe, UtilsFrontendSharedModule, i7.NgLetDirective, UiButtonModule, i8.UiButtonComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
__decorate([Select(MembershipRegistrationState.allFormData), __metadata("design:type", Observable)], RegistrationSummaryComponent.prototype, "allFormData$", void 0);
__decorate([Select(MembershipRegistrationState.displayMode), __metadata("design:type", Observable)], RegistrationSummaryComponent.prototype, "displayMode$", void 0);
__decorate([Select(MembershipRegistrationState.membershipType), __metadata("design:type", Observable)], RegistrationSummaryComponent.prototype, "membershipType$", void 0);
__decorate([Select(MembershipRegistrationState.requireAccountData), __metadata("design:type", Observable)], RegistrationSummaryComponent.prototype, "requireAccountData$", void 0);