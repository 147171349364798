import { DynamicFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class InputMultilistboxInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults,
      showToggleAll: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap,
      showToggleAll: 'showToggleAll'
    };
    this.attributeMap = {};
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import('@common/ui/frontend-shared/input-multilistbox').then(bundle => {
      return {
        component: bundle.InputMultiListbox,
        module: bundle.UiInputMultiListboxModule
      };
    });
  }
}