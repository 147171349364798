import { __decorate, __metadata } from "tslib";
import { AppEvent } from '../../app-request';
// deep imports to fix circular deps in jest
import { Model } from '../../model/model';
import { Expose } from '../../model/class-transformer';
import { SessionItem } from '../../models';
let VerifiedTokenEvent = class VerifiedTokenEvent extends Model {};
__decorate([Expose(), __metadata("design:type", String)], VerifiedTokenEvent.prototype, "token", void 0);
__decorate([Expose(), __metadata("design:type", SessionItem)], VerifiedTokenEvent.prototype, "session", void 0);
VerifiedTokenEvent = __decorate([AppEvent('access', 'token-verified')], VerifiedTokenEvent);
export { VerifiedTokenEvent };