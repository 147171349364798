import { __decorate } from "tslib";
import { AutoUnsubscribe } from '../../utils';
import { BaseItemService } from './base-item-service';
import { ClientCacheRequestMode } from '../../client-cache';
import { share, take } from 'rxjs';
/**
 * LazyLoadItemService is similar to PagedItemService but does not replace previously loaded items but appends newly loaded items.
 * So it can be used for endless-scrollers / on-demand item fetching.
 */
// not injected, use ItemServiceFactory/CrudServiceFactory to create!
let LazyLoadItemService = class LazyLoadItemService extends BaseItemService {
  constructor() {
    super(...arguments);
    this._initialized = false;
  }
  initialize(itemsPerPage) {
    // if(initialCount===false) initialCount = itemsPerPage;
    this._initialized = true;
    this.configurate(itemsPerPage);
  }
  configurate(itemsPerPage) {
    // if(initialCount===false) initialCount = itemsPerPage;
    this._initialized = true;
    this.query.setLimit(itemsPerPage);
    this.query.setOffset(0);
  }
  refreshAllItems() {
    if (!this.hasReadItems) return;
    // when reloading a lazy setup, we want to reload all items that have already been loaded!
    // get all already loaded items in one big chunk and replace this.items entirely.
    // const bu_page = this.query._page;
    const itemsPerPage = this.query.getRange().limit;
    const req = this.crud.readManyWithRangeInfo({
      range: {
        offset: 0,
        limit: this.getCurrentItems()?.length || 0
      },
      cache: ClientCacheRequestMode.wipeCache
    }).pipe(take(1), share());
    req.subscribe(chunk => {
      // rebuild "current" offset
      const currentPageIndex = Math.floor(chunk.items.length / itemsPerPage);
      const countOfLoadedItems = (currentPageIndex - 1) * itemsPerPage;
      this.query.setOffset(countOfLoadedItems);
      const fixedRangeInfo = this.buildCorrectedRangeInfo({
        totalItemCount: chunk.rangeInfo?.total,
        itemsPerPage: itemsPerPage,
        loadedItemCount: 0,
        incomingItemCount: chunk.items.length
      });
      this.updateItemListAndRange(chunk.items, fixedRangeInfo, {
        knownItemsPerPage: itemsPerPage
      });
    });
    return req;
  }
  _readItems() {
    this.assertInitialized();
    if (this.hasReadItems) return;
    this.hasReadItems = true;
    this.crud.readManyWithRangeInfo().subscribe(chunk => {
      this.updateItemListAndRange(chunk.items, chunk.rangeInfo);
    });
  }
  canLoadMoreItems() {
    if (!this.hasReadItems) return true;
    const rangeInfo = this.getCurrentRangeInfo();
    if (!rangeInfo) return true;
    const allItems = rangeInfo.total;
    const loadedItems = this.getCurrentItems()?.length || 0;
    return allItems > loadedItems;
  }
  loadMoreItems() {
    if (!this.hasReadItems) return;
    if (!this.canLoadMoreItems()) return false;
    const countOfLoadedItems = this.getCurrentItems()?.length || 0;
    // set items to be loaded to next chunk
    this.query.setOffset(countOfLoadedItems);
    // console.log('this.query',this.query)
    this.crud.readManyWithRangeInfo().subscribe(chunk => {
      // not paged, append new items to this.items
      const fixedRangeInfo = this.buildCorrectedRangeInfo({
        totalItemCount: chunk.rangeInfo?.total,
        itemsPerPage: this.query.queryObject.limit,
        loadedItemCount: countOfLoadedItems,
        incomingItemCount: chunk.items.length
      });
      console.log('fixedRangeInfo', fixedRangeInfo);
      this.updateItemListAndRange(chunk.items, fixedRangeInfo, {
        knownItemsPerPage: this.query.queryObject.limit,
        appendItems: true
      });
    });
  }
  // rangeInfo fetched from server is not correct due to behavior of LazyLoadItemService.
  // this builds the corrected rangeInfo object which will work for frontend state.
  buildCorrectedRangeInfo(info) {
    if (!info.totalItemCount) return null;
    const newLoadedCount = info.loadedItemCount + info.incomingItemCount;
    const range = {
      count: newLoadedCount,
      total: info.totalItemCount,
      page: Math.floor(newLoadedCount / info.itemsPerPage),
      pageCount: Math.ceil(info.totalItemCount / info.itemsPerPage),
      itemsPerPage: info.itemsPerPage
    };
    return range;
  }
  assertInitialized() {
    super.assertInitialized();
    if (!this._initialized) throw new Error('Tried to use LazyLoadItemService before initialize has been called!');
  }
};
LazyLoadItemService = __decorate([AutoUnsubscribe()], LazyLoadItemService);
export { LazyLoadItemService };