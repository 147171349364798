import { BehaviorSubject, map } from 'rxjs';
import { FeaturePlugin } from './feature-plugin.class';
import { checkForRequiredPermissions, checkForRequiredScope } from './feature.utils';
import { FeatureType } from './interfaces';
/**
 * tests for this class are located at libs\core\frontend-shared\src\lib\feature\feature-registry\feature-status.service.spec.ts!
 *
 * this class is NEEDED because status has to be calculated in different situations.
 * A) to determine which features to load
 * B) to determine which features can be enabled/toggled for certain users/companies (OptIn Plugins)
 */
export class FeatureStatusService {
  constructor() {
    this.featureStatusMap$ = new BehaviorSubject(new Map());
  }
  get(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    if (this.featureStatusMap$.getValue().has(pluginInfo.name)) {
      return this.featureStatusMap$.getValue().get(pluginInfo.name);
    } else {
      // default FeatureStatus values
      return this.getDefaultStatus(info);
    }
  }
  get$(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    // TODO: introduce change detection filter?
    return this.featureStatusMap$.pipe(map(statusMap => {
      let status = statusMap.get(pluginInfo.name);
      if (!status) status = this.getDefaultStatus(pluginInfo);
      return status;
    }));
  }
  getAll() {
    return this.featureStatusMap$.getValue();
  }
  getAll$() {
    return this.featureStatusMap$.asObservable();
  }
  getDefaultStatus(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    return {
      enabled: pluginInfo.type === FeatureType.Permanent ? true : false,
      loaded: false,
      canActivate: false,
      activated: false
    };
  }
  patch(info, patch) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    const prev = this.get(pluginInfo);
    const newStatus = {
      ...prev,
      ...patch
    };
    const statusMap = this.featureStatusMap$.getValue();
    statusMap.set(pluginInfo.name, newStatus);
    this.featureStatusMap$.next(statusMap);
    return newStatus;
  }
  calculate(pluginInfo, grantedPermissions, currentScope, activatedOptInFeatures) {
    const newStatus = {};
    if (pluginInfo.type === FeatureType.Permanent) {
      newStatus.enabled = true;
    } else if (pluginInfo.type === FeatureType.Dynamic) {
      const hasPermissions = checkForRequiredPermissions(pluginInfo, grantedPermissions);
      const hasScope = checkForRequiredScope(pluginInfo, currentScope);
      newStatus.enabled = hasPermissions && hasScope;
    } else if (pluginInfo.type === FeatureType.OptIn) {
      const hasPermissions = checkForRequiredPermissions(pluginInfo, grantedPermissions);
      newStatus.canActivate = hasPermissions;
      newStatus.activated = activatedOptInFeatures.includes(pluginInfo.name);
      newStatus.enabled = newStatus.canActivate && newStatus.activated;
    }
    return newStatus;
  }
  calculateFullStatus(pluginInfo, grantedPermissions, currentScope, activatedOptInFeatures) {
    const oldStatus = this.get(pluginInfo);
    const newStatus = {
      ...oldStatus,
      ...this.calculate(pluginInfo, grantedPermissions, currentScope, activatedOptInFeatures)
    };
    return newStatus;
  }
  setNewStatus(feature, grantedPermissions, currentScope, activatedOptInFeatures) {
    const newStatus = this.calculateFullStatus(feature.__info, grantedPermissions, currentScope, activatedOptInFeatures);
    this.patch(feature, newStatus);
    return newStatus;
  }
}