import { __decorate, __metadata } from "tslib";
import { Router } from '@angular/router';
import { ConfigService } from '@core/shared';
import { AccessState, LocalMessageHandlerService } from '@core/frontend-shared';
import { RegistrationActivationTypes, RegistrationRequest } from '@common/users/shared';
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import { Header } from '@common/ui/frontend-shared/shared';
import { Store } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@core/shared";
import * as i3 from "@angular/router";
import * as i4 from "@ngxs/store";
import * as i5 from "@common/forms/frontend-shared";
import * as i6 from "@angular/common";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "../../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i9 from "../../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i10 from "../../../../../../ui/frontend-shared/src/lib/card/card";
import * as i11 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i12 from "../../../../../../ui/frontend-shared/src/lib/grid/grid";
import * as i13 from "@angular/forms";
import * as i14 from "../../../../../../ui/frontend-shared/src/lib/input-text/input-text";
import * as i15 from "../../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i16 from "../../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
import * as i17 from "../../../../../../../core/frontend-shared/src/lib/feature/router-link-resolver/feature-router-link-resolver.pipe";
function RegistrationComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Die Registrierung ist derzeit deaktiviert.");
    i0.ɵɵelementEnd();
  }
}
function RegistrationComponent_ng_template_4_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
function RegistrationComponent_ng_template_4_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Registrierung");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "p");
    i0.ɵɵtext(3, "Wenn Sie bereits einen Account haben, klicken Sie den Login-Button oben rechts.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "p");
    i0.ɵɵtext(5, "Anderenfalls f\u00FCllen Sie bitte das nachfolgende Formular aus, um einen Account zu erstellen.");
    i0.ɵɵelementEnd();
  }
}
function RegistrationComponent_ng_template_4_div_7_form_1_ui_field_wrapper_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-field-wrapper", 12);
    i0.ɵɵelement(1, "ui-input-text", 21);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("label", "Email");
    i0.ɵɵadvance();
    i0.ɵɵproperty("autocomplete", false);
  }
}
const _c0 = () => ({
  label: "Jetzt registrieren"
});
function RegistrationComponent_ng_template_4_div_7_form_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "form", 11)(1, "h3");
    i0.ɵɵtext(2, "Pers\u00F6nliche Angaben");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ui-field-wrapper", 12);
    i0.ɵɵelement(4, "ui-input-text", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ui-field-wrapper", 12);
    i0.ɵɵelement(6, "ui-input-text", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "h3");
    i0.ɵɵtext(8, "Ihre Logindaten");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "ui-field-wrapper", 12);
    i0.ɵɵelement(10, "ui-input-text", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(11, RegistrationComponent_ng_template_4_div_7_form_1_ui_field_wrapper_11_Template, 2, 2, "ui-field-wrapper", 16);
    i0.ɵɵelementStart(12, "ui-field-wrapper", 12);
    i0.ɵɵelement(13, "ui-input-text", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "ui-field-wrapper", 12);
    i0.ɵɵelement(15, "ui-input-text", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(16, "app-messages");
    i0.ɵɵelementStart(17, "div", 19);
    i0.ɵɵelement(18, "button", 20);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("formGroup", ctx_r8.formService.formGroup);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", "Vorname");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("label", "Nachname");
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", ctx_r8.usernameLabel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r8.usernameIsEmail);
    i0.ɵɵadvance();
    i0.ɵɵproperty("label", "Passwort");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("label", "Passwort Wiederh.");
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("formSubmitButton", ctx_r8.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(9, _c0));
  }
}
function RegistrationComponent_ng_template_4_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, RegistrationComponent_ng_template_4_div_7_form_1_Template, 19, 10, "form", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r6.formService.formGroup);
  }
}
function RegistrationComponent_ng_template_4_div_8_p_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Bitte pr\u00FCfen Sie Ihre E-Mails. Sie erhalten von uns eine Mail, mit der der Account aktiviert werden kann.");
    i0.ɵɵelementEnd();
  }
}
function RegistrationComponent_ng_template_4_div_8_p_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Ein Administrator muss Ihren Account nun aktivieren. Sie erhalten eine Email, sobald Ihr Account freigeschaltet ist.");
    i0.ɵɵelementEnd();
  }
}
function RegistrationComponent_ng_template_4_div_8_p_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Sie k\u00F6nnen sich nun einloggen.");
    i0.ɵɵelementEnd();
  }
}
const _c1 = () => ({
  "opacity": ".2"
});
function RegistrationComponent_ng_template_4_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22)(1, "div", 23)(2, "fa-layers", 24);
    i0.ɵɵelement(3, "fa-icon", 25)(4, "fa-icon", 26);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(5, "div", 27)(6, "h2");
    i0.ɵɵtext(7, "Registrierung erfolgreich!");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, RegistrationComponent_ng_template_4_div_8_p_8_Template, 2, 0, "p", 28)(9, RegistrationComponent_ng_template_4_div_8_p_9_Template, 2, 0, "p", 28)(10, RegistrationComponent_ng_template_4_div_8_p_10_Template, 2, 0, "p", 28);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("fixedWidth", false);
    i0.ɵɵadvance();
    i0.ɵɵproperty("styles", i0.ɵɵpureFunction0(5, _c1));
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r7.registrationActivation === "email");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r7.registrationActivation === "admin");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r7.registrationActivation === "none");
  }
}
function RegistrationComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "a", 3);
    i0.ɵɵpipe(1, "resolveRouterLink");
    i0.ɵɵtemplate(2, RegistrationComponent_ng_template_4_div_2_Template, 2, 0, "div", 4)(3, RegistrationComponent_ng_template_4_ng_template_3_Template, 6, 0, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelement(5, "br")(6, "br");
    i0.ɵɵtemplate(7, RegistrationComponent_ng_template_4_div_7_Template, 2, 1, "div", 6)(8, RegistrationComponent_ng_template_4_div_8_Template, 11, 6, "div", 7);
  }
  if (rf & 2) {
    const _r5 = i0.ɵɵreference(4);
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", i0.ɵɵpipeBind1(1, 5, "login"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r2.headerFacet)("ngIfElse", _r5);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", !ctx_r2.completed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.completed);
  }
}
const _c2 = [[["ui-header"]]];
const _c3 = ["ui-header"];
let RegistrationComponent = class RegistrationComponent {
  constructor(messages, config, router, store, formServiceFactory) {
    this.messages = messages;
    this.config = config;
    this.router = router;
    this.store = store;
    this.completed = false;
    this.registrationEnabled = this.config.get('users.registration', false);
    this.usernameIsEmail = this.config.get('users.usernameIsEmail', false);
    this.registrationActivation = this.config.get('users.registrationActivation', 'admin');
    this.usernameLabel = this.usernameIsEmail ? 'E-Mail-Adresse' : 'Benutzername';
    if (!IS_PRODUCTION) {
      if (!RegistrationActivationTypes.includes(this.registrationActivation)) {
        throw new Error('Configuration users.registrationActivation has invalid value. Make sure it is one of email,none,admin');
      }
    }
    if (this.registrationEnabled) {
      this.formService = formServiceFactory.createModelForm(RegistrationRequest);
      const referrerCode = this.store.selectSnapshot(AccessState.referrerCode);
      if (referrerCode) this.formService.setImmutableValues({
        referrerCode
      });
      this.formService.onAfterSave.subscribe(({
        responseData,
        error
      }) => {
        if (error) {
          this.messages.push(error);
        } else {
          this.completed = true;
        }
      });
      this.formService.initialize();
    }
  }
  static {
    this.ɵfac = function RegistrationComponent_Factory(t) {
      return new (t || RegistrationComponent)(i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i2.ConfigService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i4.Store), i0.ɵɵdirectiveInject(i5.ModelFormServiceFactory));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationComponent,
      selectors: [["user-registration"]],
      contentQueries: function RegistrationComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, Header, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerFacet = _t.first);
        }
      },
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      ngContentSelectors: _c3,
      decls: 5,
      vars: 2,
      consts: [["size", "medium"], ["noRegistration", ""], [3, "ngIf", "ngIfElse"], ["uiButton", "", "label", "login", "icon", "unlock", 1, "ui-float-right", 3, "routerLink"], ["class", "ui-card-header", 4, "ngIf", "ngIfElse"], ["defaultHeader", ""], ["class", "box", 4, "ngIf"], ["uiGrid", "", "class", "p-align-center", 4, "ngIf"], [1, "ui-card-header"], [1, "box"], ["id", "editorForm", "autocomplete", "false", 3, "formGroup", 4, "ngIf"], ["id", "editorForm", "autocomplete", "false", 3, "formGroup"], [3, "label"], ["formControlName", "firstName", 1, "ui-width-stretch"], ["formControlName", "lastName", 1, "ui-width-stretch"], ["formControlName", "username", 1, "ui-width-stretch"], [3, "label", 4, "ngIf"], ["type", "password", "formControlName", "password", "autocomplete", "new-password", 1, "ui-width-stretch"], ["type", "password", "formControlName", "password2", "autocomplete", "new-password", 1, "ui-width-stretch"], [1, "ui-clearfix"], ["uiButton", "", 3, "formSubmitButton", "formSubmitButtonOptions"], ["formControlName", "email", 1, "ui-width-stretch", 3, "autocomplete"], ["uiGrid", "", 1, "p-align-center"], [1, "p-col-3", 2, "text-align", "right"], ["size", "6x", 1, "ui-float-right", "color-success", 3, "fixedWidth"], ["icon", "circle", 3, "styles"], ["icon", "check", "transform", "shrink-6"], [1, "p-col-9"], [4, "ngIf"]],
      template: function RegistrationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵelementStart(0, "ui-content-centered")(1, "ui-card", 0);
          i0.ɵɵtemplate(2, RegistrationComponent_ng_template_2_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(4, RegistrationComponent_ng_template_4_Template, 9, 7, "ng-template", 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const _r1 = i0.ɵɵreference(3);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", ctx.registrationEnabled)("ngIfElse", _r1);
        }
      },
      dependencies: [i6.NgIf, i7.FaIconComponent, i7.FaLayersComponent, i8.UiContentCenteredComponent, i9.FieldWrapperComponent, i10.Card, i11.UiButtonComponent, i3.RouterLink, i12.UiGridComponent, i13.ɵNgNoValidate, i13.NgControlStatus, i13.NgControlStatusGroup, i13.FormGroupDirective, i13.FormControlName, i14.InputTextComponent, i15.FormSubmitButtonDirective, i16.AppMessagesComponent, i17.ResolveRouterLinkPipe],
      styles: ["button[_ngcontent-%COMP%]{width:100%;margin:20px auto 0}"]
    });
  }
};
RegistrationComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [LocalMessageHandlerService, ConfigService, Router, Store, ModelFormServiceFactory])], RegistrationComponent);
export { RegistrationComponent };