import { CrudEditorComponent } from '../crud-editor/crud-editor.component';
export function crudManagerRoutesFactory(basePath, manager, options = {}) {
  const view = getComponentForRoute(options.view, false, null);
  const edit = getComponentForRoute(options.edit, true, CrudEditorComponent);
  const create = getComponentForRoute(options.create, true, CrudEditorComponent);
  const batch = getComponentForRoute(options.batch, false, CrudEditorComponent);
  const clone = getComponentForRoute(options.clone, false, CrudEditorComponent);
  const routeId = options.routeId || 'id';
  const routeIdToken = `:${routeId}`;
  const childPaths = [...(options.customRoutes || [])];
  if (batch) childPaths.push(addRoute('batch', 'batch', batch));
  if (clone) childPaths.push(addRoute('clone/' + routeIdToken, 'clone', {
    ...clone,
    routeId
  }));
  if (create) childPaths.push(addRoute('new', 'new', create));
  if (view) childPaths.push(addRoute(routeIdToken, 'view', {
    ...view,
    routeId
  }));else if (edit) childPaths.push(addRoute(routeIdToken, 'edit', {
    ...edit,
    routeId
  }));
  if (options.redirectIndexPage) childPaths.push({
    path: '',
    pathMatch: 'full',
    redirectTo: options.redirectIndexPage
  });
  return {
    path: basePath,
    component: manager,
    children: childPaths,
    resolve: options.resolvers
  };
}
/**
 * utility to select if + which component to use.
 * option is specified through crudManagerRoutesFactory.options.x,
 * if not present defaultOption will be used.
 * if value is a boolean then, it will resolve to defaultComponent or NULL if falsy.
 */
function getComponentForRoute(option, defaultOption, defaultComponent) {
  // explicit configuration was passed
  if (typeof option === 'object') {
    if (!Object.keys(option).length) throw new Error('Received an empty object as CrudManagerRouteComponentConfig!');
    return option;
  }
  // implicit configuration, either a boolean flag or undefined.
  const enableRoute = typeof option === 'boolean' ? option : defaultOption;
  if (enableRoute) {
    return {
      component: defaultComponent
    };
  }
  return null;
}
function addRoute(path, mode, config) {
  if (!config) return;
  const r = {
    path,
    data: {
      mode
    }
  };
  if (config.routeId) {
    // add this info to the route so that it can be used later when CrudManagerService analyzes route data
    r.data.routeIdParamName = config.routeId;
  }
  if (config.component) {
    r.component = config.component;
  } else if (config.loadChildren) {
    r.loadChildren = config.loadChildren;
  } else if (config.loadComponent) {
    r.loadComponent = config.loadComponent;
  }
  return r;
}