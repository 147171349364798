import { __decorate, __metadata } from "tslib";
import { HttpClient } from '@angular/common/http';
import { AutoUnsubscribe, takeWhileAlive } from '../../utils';
// deep imports to make jest work
import { ModelFactoryProvider } from '@core/shared/model';
import { BehaviorSubject } from 'rxjs';
import { filter, map, share, skip, take } from 'rxjs/operators';
import { ClientCacheRequestMode, ClientCacheService } from '../../client-cache';
import { CrudService, CrudServiceFactory, CrudRequestHelper } from '../crud-service';
import { CrudRequestEventType } from '../crud-service/crud.interfaces';
import { CrudRequestType } from '@core/shared';
// not injected, use ItemServiceFactory/CrudServiceFactory to create!
let BaseItemService = class BaseItemService {
  get loadingState$() {
    return this.crud.loadingState$;
  }
  get pendingRequests$() {
    return this.crud.pendingRequests$;
  }
  get hasReadItems() {
    return this._hasReadItems;
  }
  set hasReadItems(state) {
    this._hasReadItems = state;
  }
  get query() {
    if (!this.crud) throw new Error('tried to access query before CrudService is set!');
    return this.crud.query;
  }
  constructor(http, modelFactoryProvider, clientCache, crudFactory) {
    this.http = http;
    this.modelFactoryProvider = modelFactoryProvider;
    this.clientCache = clientCache;
    this.crudFactory = crudFactory;
    this.itemsAndRangeInfo$ = new BehaviorSubject(null);
    this.clientCacheConfig = false;
    this._hasReadItems = false;
  }
  setupCrudService(input) {
    if (input instanceof CrudService) {
      this.crud = input;
      this.ItemFactory = this.crud.ItemFactory;
      // } else if (extendsConstructor(input, CrudService)) {
      // 	this.crud = this.crudFactory.create(input as any);
      // 	this.crud.setModel()
    } else {
      this.ItemFactory = this.modelFactoryProvider.createModelFactory(input);
      this.crud = this.crudFactory.createCrudService(input);
    }
    this.crud.listenToCrudStateEvents$().pipe(takeWhileAlive(this)).subscribe(event => {
      function getItems(e) {
        if (!e.response) return null;
        if (Array.isArray(e.response)) return e.response; // item array response
        if (typeof e.response === 'object') {
          if (Array.isArray(e.response.items)) {
            return e.response.items; // paged items response
          } else if (typeof e.response['id'] !== 'undefined') {
            return [e.response]; // single item response
          }
        }
        return null;
      }
      if (event.eventType === CrudRequestEventType.RequestCompleted) {
        if (CrudRequestHelper.requestTypeIsRead(event.crudAction)) {
          // read-requests have no side effects as they do not modify data.
          // A RequestCompleted event will be fired when finishing a full/partial refresh request, too.
          // However, newly fetched items will be processed by request subscriber actively.
          return;
        }
        if (event.crudAction === CrudRequestType.Update) {
          // a data modification request has been completed.
          // try to update changed items without reloading everything.
          const newItems = getItems(event);
          if (newItems && event.requestInfo.ids) {
            // event contains updated items as well as 
            this.doPartialListUpdate(newItems);
          } else {
            // could not handle correctly, initiate full reload
            this.refreshAllItems();
          }
        } else {
          // CRUD remove/create actions always require a full reload
          this.refreshAllItems();
        }
      }
    });
  }
  useCrudEndpoint(name) {
    if (!this.crud) throw new Error('called useCrudEndpoint before Model/CrudService is defined!');
    this.crud.useCrudEndpoint(name);
  }
  read(completeObservable = false, refresh = false) {
    this.assertInitialized();
    let tmp = this.getItems$();
    if (!this.hasReadItems) {
      this._readItems();
    } else {
      if (refresh) {
        // skip 1 to make sure refreshed data will be emitted and not old one (due to take(1))
        tmp = tmp.pipe(skip(1));
        this.refreshAllItems();
      }
    }
    // Filter out data which is no array (e.g. initial null value)
    tmp = tmp.pipe(filter(val => {
      return Array.isArray(val);
    }));
    if (completeObservable) tmp = tmp.pipe(take(1));
    return tmp;
  }
  refreshSomeItems(ids) {
    const req = this.crud.readSome(ids, {
      cache: ClientCacheRequestMode.wipeCache
    }).pipe(take(1), share());
    req.subscribe(items => {
      this.doPartialListUpdate(items);
    });
    return req;
  }
  doPartialListUpdate(incomingItems) {
    const existingItems = [...(this.getCurrentItems() || [])];
    const rangeInfo = this.getCurrentRangeInfo();
    const updatedItems = existingItems.map(item => {
      const replacement = incomingItems.find(item2 => item2.id === item.id);
      return replacement || item;
    });
    this.itemsAndRangeInfo$.next({
      items: updatedItems,
      rangeInfo
    });
  }
  // ---- Virtual sorting -------------------------------------------------------
  setVirtualSortBy(sort, order = 'ASC') {
    this.virtualSort = sort;
    this.virtualSortOrdering = order;
    return this;
  }
  updateItemListAndRange(items, rangeInfo, options = {}) {
    if (options.appendItems) {
      // in lazy mode items are appended instead of replacing previously
      items = [...(this.getCurrentItems() || []), ...items];
    }
    if (this.virtualSort) {
      items = [...items].sort(this.virtualSort);
    }
    if (this.virtualSortOrdering === 'DESC') {
      items.reverse();
    }
    // TODO: when can rangeInfo become null?
    /**
     * Aims to fix a problem with nestjsx/crud.
     * The rangeinfo returned from server has no clear info on how many items per page were requested.
     * As services have no imperative state where itemsPerPage could be looked up,
     * this helper method tries to get the info from query builder before sending the updated rangeInfo.
     */
    if (rangeInfo !== null) {
      const queryRange = this.query.getRange();
      rangeInfo.itemsPerPage = options?.knownItemsPerPage || queryRange.limit || null;
    }
    this.itemsAndRangeInfo$.next({
      items,
      rangeInfo
    });
  }
  // ------ CrudRangeInfo / RangeInfo ---------------------------------------------------
  getItemsAndRangeInfo$() {
    return this.itemsAndRangeInfo$.pipe(takeWhileAlive(this));
  }
  getRangeInfo$() {
    return this.itemsAndRangeInfo$.pipe(map(data => data?.rangeInfo || null), takeWhileAlive(this));
  }
  getItems$() {
    return this.itemsAndRangeInfo$.pipe(map(data => data?.items || null), takeWhileAlive(this));
  }
  getCurrentItemsAndRangeInfo() {
    return this.itemsAndRangeInfo$.getValue();
  }
  getCurrentRangeInfo() {
    return this.itemsAndRangeInfo$.getValue()?.rangeInfo || null;
  }
  getCurrentItems() {
    return this.itemsAndRangeInfo$.getValue()?.items || null;
  }
  // NOTE: a bit different than CrudService which implements separate CREATE/UPDATE methods.
  // left here for reference in case it is needed again...
  // save(item:Partial<TItemModel>):Observable<any> {
  // 	this.assertInitialized();
  // 	item = this.processItemData(item);
  // 	// console.log('post item',item)
  // 	const req = this.http.post(this.generateApiPath(item),item).pipe(
  // 		tap(()=>this.clearItemClientCache()),
  // 		share(),
  // 		map((response:IApiResponse<TItemModel>):TItemModel => {
  // 			return extractDataFromApiResponse(response, 'ItemService::save '+this.ItemFactory.Model.name);
  // 		})
  // 	);
  // 	req.subscribe(
  // 		(msg)=>{this.refreshAllItems()}
  // 	);
  // 	return req;
  // }
  assertInitialized() {
    if (!this.ItemFactory) throw new Error('Tried to use ItemService before a Model has been set!');
    if (!this.crud) throw new Error('Tried to use ItemService before CrudService has been set!');
  }
};
BaseItemService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [HttpClient, ModelFactoryProvider, ClientCacheService, CrudServiceFactory])], BaseItemService);
export { BaseItemService };