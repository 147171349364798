import { RouterLink } from '@angular/router';
export const isActiveRouterLinkFactory = (router, exact = true) => {
  return link => {
    const tree = link instanceof RouterLink ? link.urlTree : link;
    return router.isActive(tree, {
      paths: exact ? 'exact' : 'subset',
      queryParams: exact ? 'exact' : 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored'
    });
  };
};
// angular extra router state data access
// DURING routing it can be fetched using Router.getCurrentNavigation().extra.state.
// This is ONLY true for routed component constructor. 
// Child components will be created AFTER routing.
// AFTER routing, the state is simply available through window history object.
export function getCurrentRouteState() {
  return window.history.state;
}