import { Component } from '@angular/core';
import { ConfigService } from '@core/shared';

@Component({
  selector: 'app-privacy',
  styles:[`
	:host {display:block; padding:20px; } svg {height:100%; max-width:100%;}
	.change-privacy { float:right; margin-bottom:15px;  }
  `],
  template: `<ui-card size="block">
	<button uiButton label="Datenschutzeinstellungen ändern" trackingChangeConsent class="ui-button-secondary small change-privacy"></button>
  	<cms-module-position position="data-privacy" [context]="moduleContext"></cms-module-position>
  </ui-card>
  `
})
export class AppPrivacyComponent {

	moduleContext:any;

	constructor(
		config:ConfigService
	) {
		this.moduleContext = {
			contactEmail:config.get('contact.mail',null),
			contactPhone:config.get('contact.phone',null),
			businessHours:config.get('contact.businessHours',null),
		}
	}

}
