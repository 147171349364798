import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import * as i0 from "@angular/core";
let BasicInput = class BasicInput {
  constructor(cd) {
    this.cd = cd;
    this.focused = false;
    this.disabled = false;
    this.readonly = false;
    this.focus = new EventEmitter();
    this.blur = new EventEmitter();
    // @Output() onChange: EventEmitter<any> = new EventEmitter();
    this.onModelChange = val => {};
    this.onModelTouched = () => {};
  }
  writeValue(value) {
    this.value = value;
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  focusInput(event) {
    this.focused = true;
    this.focus.emit();
    this.cd.markForCheck();
  }
  blurInput(event) {
    this.focused = false;
    this.blur.emit();
    this.onModelTouched();
    this.cd.markForCheck();
  }
  static {
    this.ɵfac = function BasicInput_Factory(t) {
      return new (t || BasicInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: BasicInput,
      inputs: {
        disabled: "disabled",
        readonly: "readonly"
      },
      outputs: {
        focus: "focus",
        blur: "blur"
      }
    });
  }
};
BasicInput = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef])], BasicInput);
export { BasicInput };
/**
 * links a child component's CVA methods to those of the parent.
 */
export function sidechainChildInputCVA(parent, child) {
  const original_writeValue = parent.writeValue;
  parent.writeValue = value => {
    original_writeValue.call(parent, value);
    child.writeValue(value);
  };
  const original_registerOnChange = parent.registerOnChange;
  parent.registerOnChange = value => {
    original_registerOnChange.call(parent, value);
    child.registerOnChange(value);
  };
  const original_registerOnTouched = parent.registerOnTouched;
  parent.registerOnTouched = value => {
    original_registerOnTouched.call(parent, value);
    child.registerOnTouched(value);
  };
  const original_setDisabledState = parent.setDisabledState;
  parent.setDisabledState = value => {
    original_setDisabledState.call(parent, value);
    child.setDisabledState(value);
  };
}
/**
 * links a child component's CVA methods to those of the parent.
 */
export function sidechainBasicInputToParent(parent, child) {
  sidechainChildInputCVA(parent, child);
  const original_focusInput = parent.focusInput;
  parent.focusInput = value => {
    original_focusInput.call(parent, value);
    child.focusInput(value);
  };
  const original_blurInput = parent.blurInput;
  parent.blurInput = value => {
    original_blurInput.call(parent, value);
    child.blurInput(value);
  };
  // as the implementation of BasicInput is known, we can additionally sync the current state.
  child.onModelChange = parent.onModelChange;
  child.onModelTouched = parent.onModelTouched;
  child.value = parent.value;
  child.focused = parent.focused;
  child.disabled = parent.disabled;
  child.readonly = parent.readonly;
  child.focus.pipe(takeWhileAlive(parent)).subscribe(event => parent.focus.emit(event));
  child.blur.pipe(takeWhileAlive(parent)).subscribe(event => parent.blur.emit(event));
}