import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { AccessState, FeatureRouterLinkResolverService } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { Observable, combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@core/frontend-shared";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../ui/frontend-shared/src/lib/message/message";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/button/button";
function MembershipProfileInfoboxComponent_ng_template_2_ui_message_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 4);
    i0.ɵɵtext(1, " Ihr Zugang wurde noch nicht aktiviert, da noch keine Zahlung eingegangen ist. Bitte schlie\u00DFen Sie die Bestellung zuerst ab. Ist etwas schief gelaufen? Gerne hilft unser Supportteam weiter. ");
    i0.ɵɵelementStart(2, "button", 5);
    i0.ɵɵlistener("click", function MembershipProfileInfoboxComponent_ng_template_2_ui_message_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.payNow());
    });
    i0.ɵɵelementEnd()();
  }
}
function MembershipProfileInfoboxComponent_ng_template_2_ng_template_1_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 7);
    i0.ɵɵtext(1, " Ihre Zugang l\u00E4uft in wenigen Tagen ab. Sie k\u00F6nnen die Mitgliedschaft schon jetzt verl\u00E4ngern, um die Plattform weiter benutzen zu k\u00F6nnen! ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 8);
    i0.ɵɵlistener("click", function MembershipProfileInfoboxComponent_ng_template_2_ng_template_1_ng_template_13_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r10.renew());
    });
    i0.ɵɵelementEnd();
  }
}
function MembershipProfileInfoboxComponent_ng_template_2_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 6)(1, "tbody")(2, "tr")(3, "td");
    i0.ɵɵtext(4, "Status");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "td");
    i0.ɵɵtext(6, "g\u00FCltig");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "tr")(8, "td");
    i0.ɵɵtext(9, "Zugang g\u00FCltig bis");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "td");
    i0.ɵɵtext(11);
    i0.ɵɵpipe(12, "date");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵtemplate(13, MembershipProfileInfoboxComponent_ng_template_2_ng_template_1_ng_template_13_Template, 3, 0, "ng-template", 3);
  }
  if (rf & 2) {
    const status_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(11);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(12, 2, status_r3.membership.validUntil));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", status_r3.membershipDaysLeft > 0 && status_r3.membershipDaysLeft < 10);
  }
}
function MembershipProfileInfoboxComponent_ng_template_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-message", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 9);
    i0.ɵɵlistener("click", function MembershipProfileInfoboxComponent_ng_template_2_ng_template_2_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r13.renew());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const status_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", !status_r3.hasExpiredMembership ? "Sie haben noch keine Mitgliedschaft." : "Ihre Mitgliedschaft ist abgelaufen. ", " Sie k\u00F6nnen eine neue Mitgliedschaft abschlie\u00DFen, um alle Inhalte sehen zu k\u00F6nnen. ");
  }
}
function MembershipProfileInfoboxComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MembershipProfileInfoboxComponent_ng_template_2_ui_message_0_Template, 3, 0, "ui-message", 2)(1, MembershipProfileInfoboxComponent_ng_template_2_ng_template_1_Template, 14, 4, "ng-template", 3)(2, MembershipProfileInfoboxComponent_ng_template_2_ng_template_2_Template, 3, 1, "ng-template", 3);
  }
  if (rf & 2) {
    const status_r3 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", status_r3.membershipPaymentPending);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", status_r3.membershipIsValid);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !status_r3.membershipIsValid);
  }
}
function MembershipProfileInfoboxComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered");
    i0.ɵɵelement(1, "ui-spinner");
    i0.ɵɵelementEnd();
  }
}
let MembershipProfileInfoboxComponent = class MembershipProfileInfoboxComponent {
  constructor(router, routerLinkResolver) {
    this.router = router;
    this.routerLinkResolver = routerLinkResolver;
    this.membershipStatus$ = combineLatest({
      loggedIn: this.loggedIn$,
      membership: this.membership$,
      hasExpiredMembership: this.hasExpiredMembership$,
      membershipDaysLeft: this.membershipDaysLeft$,
      membershipIsValid: this.membershipIsValid$,
      membershipPaymentPending: this.membershipPaymentPending$
    });
  }
  payNow() {
    const registrationLink = this.routerLinkResolver.resolveRouterLink('registration');
    this.router.navigate(registrationLink);
  }
  renew() {
    const registrationLink = this.routerLinkResolver.resolveRouterLink('registration');
    this.router.navigate(registrationLink);
  }
  static {
    this.ɵfac = function MembershipProfileInfoboxComponent_Factory(t) {
      return new (t || MembershipProfileInfoboxComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.FeatureRouterLinkResolverService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MembershipProfileInfoboxComponent,
      selectors: [["membership-profile-infobox"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 4,
      consts: [[3, "ngIf", "ngIfElse"], ["loading", ""], ["type", "warn", "class", "membership-message message-payment-pending", 4, "ngIf"], [3, "ngIf"], ["type", "warn", 1, "membership-message", "message-payment-pending"], ["uiButton", "", "label", "Zahlung abschlie\u00DFen", 1, "ui-button-outline", "small", 3, "click"], [1, "ui-simpletable"], ["type", "info", 1, "membership-message", "message-expired"], ["uiButton", "", "label", "jetzt verl\u00E4ngern", 3, "click"], ["uiButton", "", "label", "Los gehts!", 3, "click"]],
      template: function MembershipProfileInfoboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "h2");
          i0.ɵɵtext(1, "Status Ihrer Mitgliedschaft");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, MembershipProfileInfoboxComponent_ng_template_2_Template, 3, 3, "ng-template", 0);
          i0.ɵɵpipe(3, "async");
          i0.ɵɵtemplate(4, MembershipProfileInfoboxComponent_ng_template_4_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const _r2 = i0.ɵɵreference(5);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 2, ctx.membershipStatus$))("ngIfElse", _r2);
        }
      },
      dependencies: [CommonModule, i3.NgIf, i3.AsyncPipe, i3.DatePipe, UiMessageModule, i4.UiMessageComponent, UtilsFrontendSharedModule, UiButtonModule, i5.UiButtonComponent],
      changeDetection: 0
    });
  }
};
__decorate([Select(AccessState.loggedIn), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "loggedIn$", void 0);
__decorate([Select(AccessState.accessData('membership')), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "membership$", void 0);
__decorate([Select(AccessState.accessData('membershipDaysLeft')), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "membershipDaysLeft$", void 0);
__decorate([Select(AccessState.accessData('hasExpiredMembership')), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "hasExpiredMembership$", void 0);
__decorate([Select(AccessState.accessData('membershipIsValid')), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "membershipIsValid$", void 0);
__decorate([Select(AccessState.accessData('membershipPaymentPending')), __metadata("design:type", Observable)], MembershipProfileInfoboxComponent.prototype, "membershipPaymentPending$", void 0);
MembershipProfileInfoboxComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Router, FeatureRouterLinkResolverService])], MembershipProfileInfoboxComponent);
export { MembershipProfileInfoboxComponent };