import { DynamicFieldInstruction } from './shared';
// <ui-item-metadata-table [item]="formService.formGroup.getRawValue()" ></ui-item-metadata-table>
export class MetadataInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      label: "Item-Metadaten",
      extraRows: [],
      disableDefaultCols: false,
      priority: -1
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      extraRows: "extraRows",
      disableDefaultCols: "disableDefaultCols"
    };
    this.attributeMap = {};
    this.isInput = false;
    this.useFieldWrapper = true;
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/item-metadata-table').then(bundle => {
      return {
        component: bundle.ItemMetadataTableComponent,
        module: bundle.UiItemMetadataTableModule
      };
    });
  }
  createComponentInputsMap(formGroup, formService) {
    const map = super.createComponentInputsMap(formGroup, formService);
    map.item = formGroup.getRawValue();
    return map;
  }
}