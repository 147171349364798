import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { EventEmitter, computed, effect, input, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { CreateMediaAssetRequest, FileTypeDetectionHelper, HEADER_ASSET_ID, HEADER_ASSET_NAME, HEADER_ASSET_TRANSFORMATIONS, HEADER_PROVIDER_ID, HEADER_PROVIDER_TARGETPATH, MediaAssetItem, MediaAssetType, SupportedFileTypeGroups, SupportedFileTypes, getAssetTypeGroupInfo, supportedFileTypeToMediaAssetType } from '@common/media/shared';
import { MessagesFrontendSharedModule } from '@common/messages/frontend-shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiCardModule } from '@common/ui/frontend-shared/card';
import { UiFieldWrapperModule } from '@common/ui/frontend-shared/field-wrapper';
import { UiGridModule } from '@common/ui/frontend-shared/grid';
import { UiInputDropdownModule } from '@common/ui/frontend-shared/input-dropdown';
import { UiInputSelectButtonModule } from '@common/ui/frontend-shared/input-selectbutton';
import { UiInputSwitchModule } from '@common/ui/frontend-shared/input-switch';
import { UiInputTextModule } from '@common/ui/frontend-shared/input-text';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { UiPanelModule } from '@common/ui/frontend-shared/panel';
import { UiPropChipModule } from '@common/ui/frontend-shared/prop-chip';
import { UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { AccessState, AppRequestService, CoreDynamicComponentsModule } from '@core/frontend-shared';
import { AppMessage, LocalMessageHandlerService } from '@core/frontend-shared/messages';
import { AutoUnsubscribe, NgBytesPipeModule, UtilsFrontendSharedModule, takeWhileAlive } from '@core/frontend-shared/utils';
import { ConfigService, stringToReadableString } from '@core/shared';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subject, combineLatest, take, takeUntil } from 'rxjs';
import { MediaAssetProvidersFrontendService } from '../provider';
import { FileLikeObject, OnAfterAddItem, OnItemCanceled, OnItemConflict, OnItemError, OnItemRemoved, OnItemSuccess, MediaUploaderService, MediaUploaderServiceConfig } from '../uploader';
import { FileUploaderComponent } from '../uploader-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { MediaIconsModule } from '../media-icons.module';
import { UiInputAutocompleteModule } from '@common/ui/frontend-shared/input-autocomplete';
import { Select } from '@ngxs/store';
import { DynamicDialogRef } from '@common/ui/frontend-shared/dynamic-dialog';
import * as i0 from "@angular/core";
import * as i1 from "../uploader";
import * as i2 from "@core/frontend-shared";
import * as i3 from "@core/frontend-shared/messages";
import * as i4 from "../provider";
import * as i5 from "@core/shared";
import * as i6 from "@common/ui/frontend-shared/dynamic-dialog";
import * as i7 from "@common/ui/frontend-shared/input-ref";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../core/frontend-shared/src/lib/dynamic-components/dynamic-component.directive";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../../ui/frontend-shared/src/lib/card/card";
import * as i12 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i13 from "@fortawesome/angular-fontawesome";
import * as i14 from "../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i15 from "../../../../../ui/frontend-shared/src/lib/input-dropdown/input-dropdown";
import * as i16 from "../../../../../ui/frontend-shared/src/lib/input-switch/input-switch";
import * as i17 from "../../../../../ui/frontend-shared/src/lib/input-selectbutton/input-selectbutton";
import * as i18 from "../../../../../ui/frontend-shared/src/lib/input-text/input-text";
import * as i19 from "../../../../../ui/frontend-shared/src/lib/input-autocomplete/input-autocomplete";
import * as i20 from "../../../../../ui/frontend-shared/src/lib/prop-chip/prop-chip";
import * as i21 from "../../../../../../core/frontend-shared/src/lib/utils/pipes/bytes.pipe";
import * as i22 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
import * as i23 from "../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_field_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-field-wrapper", 5)(1, "ui-input-dropdown", 6);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_field_wrapper_0_Template_ui_input_dropdown_ngModelChange_1_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r8.selectedProvider.set($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("options", ctx_r4.mediaProviderOptions())("ngModel", ctx_r4.selectedProvider())("disabled", ctx_r4.forceProvider() !== false || ctx_r4.disableInputs());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-card", 7);
    i0.ɵɵelement(1, "media-file-uploader", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("uploader", ctx_r5.uploader);
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = a0 => ({
  fileSelected: a0
});
const _c1 = (a0, a1) => ({
  component: a0,
  outputs: a1
});
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_ng_template_1_ng_container_0_Template, 1, 0, "ng-container", 9);
  }
  if (rf & 2) {
    const selectFromHostCmp_r11 = ctx.$implicit;
    const ctx_r10 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("dynamicComponent", i0.ɵɵpureFunction2(3, _c1, selectFromHostCmp_r11, i0.ɵɵpureFunction1(1, _c0, ctx_r10.onSelectedFileFromHost)));
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-card", 7);
    i0.ɵɵtemplate(1, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_ng_template_1_Template, 1, 6, "ng-template", 0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngLet", ctx_r6.selectFromHostComponent());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-input-autocomplete", 23);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_1_Template_ui_input_autocomplete_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r21 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r21.customUploadPath.set($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const suggestions_r20 = ctx.$implicit;
    const ctx_r17 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngModel", ctx_r17.customUploadPath())("allowCustomValue", true)("options", suggestions_r20)("minLengthSearchString", 1);
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r24 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-input-text", 15);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_3_Template_ui_input_text_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r24);
      const ctx_r23 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r23.customUploadPath.set($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngModel", ctx_r18.customUploadPath())("disabled", ctx_r18.disableInputs());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-field-wrapper", 20);
    i0.ɵɵtemplate(1, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_1_Template, 1, 4, "ng-template", 21);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵtemplate(3, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_ng_template_3_Template, 1, 2, "ng-template", null, 22, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r19 = i0.ɵɵreference(4);
    const ctx_r14 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 2, ctx_r14.uploadPathSuggestions$))("ngIfElse", _r19);
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r28 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 26);
    i0.ɵɵlistener("click", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r28);
      const ctx_r27 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r27.onClickStartUpload());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r25 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("icon", ctx_r25.faUpload);
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r30 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 27);
    i0.ɵɵlistener("click", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r30);
      const ctx_r29 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r29.onClickStartUpload(true));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r26 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("icon", ctx_r26.faUpload);
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_0_Template, 1, 1, "button", 24)(1, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_button_1_Template, 1, 1, "button", 25);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngIf", !ctx_r15.fileExistsConflict());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r15.fileExistsConflict());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_button_18_Template(rf, ctx) {
  if (rf & 1) {
    const _r32 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 28);
    i0.ɵɵlistener("click", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_button_18_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r32);
      const ctx_r31 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r31.onClickAbortUpload());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r16 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("loading", ctx_r16.isUploading())("icon", "times");
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r34 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ui-prop-chips-list", 10)(1, "ui-prop-chip", 11);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "bytes");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "ui-prop-chip", 12);
    i0.ɵɵelement(5, "fa-icon", 13);
    i0.ɵɵelementStart(6, "small");
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(8, "ui-card", 7)(9, "ui-field-wrapper", 14)(10, "ui-input-text", 15);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_Template_ui_input_text_ngModelChange_10_listener($event) {
      i0.ɵɵrestoreView(_r34);
      const ctx_r33 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r33.assetName.set($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "h3");
    i0.ɵɵtext(12, "Einstellungen");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ui_field_wrapper_13_Template, 5, 4, "ui-field-wrapper", 16);
    i0.ɵɵelementStart(14, "ui-field-wrapper", 17)(15, "ui-input-switch", 18);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_Template_ui_input_switch_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r34);
      const ctx_r35 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r35.applyTransformations.set($event));
    });
    i0.ɵɵelementEnd()()();
    i0.ɵɵelement(16, "app-messages");
    i0.ɵɵtemplate(17, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_ng_template_17_Template, 2, 2, "ng-template", 1)(18, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_button_18_Template, 1, 2, "button", 19);
  }
  if (rf & 2) {
    const fileDetails_r13 = ctx.$implicit;
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 10, fileDetails_r13.size), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("icon", ctx_r7.getAssetTypeGroupInfo(fileDetails_r13.fileTypeGroup).icon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r7.getAssetTypeGroupInfo(fileDetails_r13.fileTypeGroup).label);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngModel", ctx_r7.assetName())("disabled", ctx_r7.disableInputs());
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r7.showTargetPathInput());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngModel", ctx_r7.applyTransformations())("disabled", ctx_r7.disableInputs());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r7.isUploading());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r7.isUploading());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r37 = i0.ɵɵgetCurrentView();
    i0.ɵɵtemplate(0, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_field_wrapper_0_Template, 2, 3, "ui-field-wrapper", 2);
    i0.ɵɵelementStart(1, "ui-input-selectbutton", 3);
    i0.ɵɵlistener("ngModelChange", function MediaAssetCreatorComponent_ng_template_0_ng_template_0_Template_ui_input_selectbutton_ngModelChange_1_listener($event) {
      i0.ɵɵrestoreView(_r37);
      const ctx_r36 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r36.onSelectFileSource($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_2_Template, 2, 1, "ui-card", 4)(3, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ui_card_3_Template, 2, 1, "ui-card", 4)(4, MediaAssetCreatorComponent_ng_template_0_ng_template_0_ng_template_4_Template, 19, 12, "ng-template", 1);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !ctx_r2.hideProviderDropdown());
    i0.ɵɵadvance();
    i0.ɵɵproperty("options", ctx_r2.fileSourceOptions())("ngModel", ctx_r2.fileSource())("disabled", ctx_r2.disableInputs());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.fileSource() === "upload");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.fileSource() === "existing");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.selectedSourceFileDetails());
  }
}
function MediaAssetCreatorComponent_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Asset wurde erfolgreich erstellt.");
    i0.ɵɵelementEnd();
  }
}
function MediaAssetCreatorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MediaAssetCreatorComponent_ng_template_0_ng_template_0_Template, 5, 7, "ng-template", 1)(1, MediaAssetCreatorComponent_ng_template_0_ng_template_1_Template, 2, 0, "ng-template", 1);
  }
  if (rf & 2) {
    const result_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", !(result_r1 == null ? null : result_r1.success));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", result_r1 == null ? null : result_r1.success);
  }
}
let MediaAssetCreatorComponent = class MediaAssetCreatorComponent {
  constructor(uploader, api, messages, mediaProviders, config, dialogRef) {
    this.uploader = uploader;
    this.api = api;
    this.messages = messages;
    this.mediaProviders = mediaProviders;
    this.config = config;
    this.dialogRef = dialogRef;
    this.allowedFileTypes = input('any');
    this.uploadEndpoint = input(undefined);
    this.showTargetPathInput = input(true);
    this.uploadPath = input(null);
    this.customUploadPath = signal('');
    this.defaultUploadPath = '/';
    this.isAdmin = toSignal(this.isAdmin$);
    this.uploadPathSuggestions$ = new BehaviorSubject(null);
    this._uploadPathSuggestions = effect(() => {
      const providerId = this.selectedProvider();
      if (!providerId) this.uploadPathSuggestions$.next(null);
      const provider = this.mediaProviders.getProvider(providerId);
      if (provider.features.uploadPathSuggestions) {
        provider.getUploadPathSuggestions().then(suggestions => {
          this.uploadPathSuggestions$.next(suggestions.map(path => {
            return {
              label: path,
              value: path
            };
          }));
        });
      } else {
        this.uploadPathSuggestions$.next(null);
      }
    });
    this.forceProvider = input(false);
    this.defaultProviderId = signal(null);
    this.chosenProvider = signal(null);
    this.hideProviderDropdownWhenUnneeded = input(true);
    this.mediaProviderOptions = signal(null);
    this.selectedProvider = computed(() => {
      const defaultProvider = this.defaultProviderId();
      const forceProvider = this.forceProvider();
      if (typeof forceProvider === 'string') return forceProvider;
      if (forceProvider === true) return defaultProvider;
      return this.chosenProvider() || defaultProvider;
    });
    this.faUpload = faUpload;
    this.hideProviderDropdown = computed(() => {
      if (this.hideProviderDropdownWhenUnneeded()) {
        if (this.forceProvider() !== false) return true;
        if (this.mediaProviderOptions()?.length === 1) return true;
      }
      return false;
    });
    this.fileSourceOptions = computed(() => {
      const providerId = this.selectedProvider();
      const isAdmin = this.isAdmin();
      const provider = this.mediaProviders.getProvider(providerId);
      const options = [{
        label: 'Datei-Upload',
        value: 'upload'
      }];
      if (provider.features.selectFromHost && isAdmin) {
        options.push({
          label: 'Datei von Hoster wählen',
          value: 'existing'
        });
      }
      return options;
    });
    this.fileSource = signal('upload');
    this.selectFromHostComponent = computed(() => {
      const providerId = this.selectedProvider();
      const provider = this.mediaProviders.getProvider(providerId);
      return provider.selectFromHost_getComponent();
    });
    this._fileSourceChange = combineLatest({
      fileSource: toObservable(this.fileSource),
      providerId: toObservable(this.selectedProvider)
    }).pipe(takeWhileAlive(this)).subscribe(({
      fileSource,
      providerId
    }) => {
      const provider = this.mediaProviders.getProvider(providerId);
      if (this.fileSource() === 'upload') this.setupUploader(provider);
    });
    this.assetName = signal('');
    this.createdAsset = signal(null);
    this.fileExistsConflict = signal(false);
    this.applyTransformations = signal(true);
    this.selectedSourceFile = signal(null);
    this._proposeAssetName = effect(() => {
      const file = this.selectedSourceFile();
      if (!file) return '';
      const namePieces = file.name.split('.');
      namePieces.pop(); // remove extension
      const name = namePieces.join(' ');
      this.assetName.set(stringToReadableString(name));
    }, {
      allowSignalWrites: true
    });
    this.selectedSourceFileDetails = computed(() => {
      const file = this.selectedSourceFile();
      if (!file) return null;
      const fileTypeGroup = FileTypeDetectionHelper.detectFileTypeGroup({
        filename: file.name,
        mimetype: file.type
      });
      return {
        size: file.size,
        name: file.name,
        fileTypeGroup
      };
    });
    this.isUploading = signal(false);
    this.disableInputs = computed(() => {
      return this.isUploading();
    });
    this.uploadResult = signal(null);
    this._uploadResult = effect(() => {
      const result = this.uploadResult();
      if (result?.success) {
        this.assetCreated.emit(result.asset);
      }
    });
    this.assetCreated = new EventEmitter();
    this.MediaAssetType = MediaAssetType;
    this.getAssetTypeGroupInfo = getAssetTypeGroupInfo;
    this.destroyUploaderSubscriptions = new Subject();
    this.onSelectedFileFromHost = data => {
      const assetData = data.assetData;
      if (!IS_PRODUCTION) {
        if (!assetData.directUrl) throw new Error('SelectFromHostComponent emitted invalid assetData: directUrl is missing!');
        if (!assetData.type) throw new Error('SelectFromHostComponent emitted invalid assetData: type is missing!');
      }
      this.api.sendRequest(CreateMediaAssetRequest, {
        assetData: {
          provider: this.selectedProvider(),
          name: stringToReadableString(data.name),
          ownerId: null,
          directUrl: null,
          providerData: null,
          metadata: null,
          ready: false,
          ...assetData
        }
      }).subscribe({
        next: response => {
          this.createdAsset.set(response.asset);
          this.uploadResult.set({
            error: null,
            success: true,
            asset: response.asset
          });
        },
        error: error => this.handleUploadError(error)
      });
    };
    this.mediaProviderOptions.set(this.mediaProviders.getRegisteredProvidersOptionList());
    this.defaultProviderId.set(this.mediaProviders.getDefaultProviderId());
    if (dialogRef) {
      this.assetCreated.pipe(takeWhileAlive(this), take(1)).subscribe(asset => {
        dialogRef.close(asset);
      });
    }
    this.initializeUploader();
  }
  initializeUploader() {
    this.uploader.setHooks({
      getFileInfoFromResponse: response => {
        if (response instanceof AppMessage) {
          return response.data;
        } else if (response instanceof HttpErrorResponse) {
          return response.error['data']['data'];
        } else {
          return response.body['info'];
        }
      }
    });
  }
  setupUploader(provider) {
    if (this.uploader.isReady) {
      this.destroyUploaderSubscriptions.next(true);
      this.uploader.reset();
    }
    const allowedFileTypes = this.allowedFileTypes();
    const useChunks = provider.features.chunkedUpload && (allowedFileTypes === 'any' || allowedFileTypes === 'video');
    const endpoint = this.getUploadEndpoint(provider);
    const uploaderConfig = {
      path: endpoint,
      limit: 1,
      uploadStart: 'none',
      allowedFileTypes: SupportedFileTypes[this.allowedFileTypes()].extensions,
      allowedMimeTypes: SupportedFileTypes[this.allowedFileTypes()].mimes,
      chunks: useChunks,
      chunkingFileSizeThreshold: 1024 * 1000 * 5 // 5mb
    };
    if (provider.setupUploader) {
      provider.setupUploader(this.uploader, uploaderConfig);
    } else {
      this.uploader.setup(uploaderConfig);
    }
    this.uploader.events.pipe(takeWhileAlive(this), takeUntil(this.destroyUploaderSubscriptions)).subscribe(event => {
      if (event instanceof OnAfterAddItem) {
        this.selectedSourceFile.set(event.item.file);
        this.resetUpload();
      } else if (event instanceof OnItemCanceled || event instanceof OnItemRemoved) {
        this.selectedSourceFile.set(null);
        this.resetUpload();
      } else if (event instanceof OnItemError) {
        this.handleUploadError(event.response);
      } else if (event instanceof OnItemConflict) {
        this.fileExistsConflict.set(true);
        this.handleUploadError(this.messages.create(event.response).setMessage("Konflikt: Die Datei existiert bereits.").setSeverity('warn'));
      } else if (event instanceof OnItemSuccess) {
        this.uploadResult.set({
          error: null,
          success: true,
          asset: event.asset
        });
        this.isUploading.set(false);
      }
    });
  }
  getUploadEndpoint(provider) {
    if (provider.features.clientUpload) console.warn('MediaAssetCreator does not yet support providers that use clientUpload');
    let endpoint = false ? provider.getClientUploadEndpoint() : this.uploadEndpoint();
    if (!endpoint) {
      if (this.config.isPlatform('admin')) endpoint = 'admin/media/create';else throw new Error('No default upload endpoint exists for frontend platform. Please pass [uploadEndpoint] to MediaAssetCreatorComponent!');
    }
    return endpoint;
  }
  resetSource() {
    this.assetName.set('');
    this.selectedSourceFile.set(null);
    this.resetUpload();
  }
  resetUpload() {
    this.isUploading.set(false);
    this.uploadResult.set(null);
    this.createdAsset.set(null);
    this.fileExistsConflict.set(false);
    this.messages.clear();
  }
  onSelectFileSource(newValue) {
    this.fileSource.set(newValue);
    this.resetSource();
  }
  onClickStartUpload(overwrite = false) {
    this.isUploading.set(true);
    const type = this.selectedSourceFileDetails().fileTypeGroup || 'any';
    if (this.createdAsset()) {
      this.executeFileUpload(overwrite);
    } else {
      this.api.sendRequest(CreateMediaAssetRequest, {
        assetData: {
          provider: this.selectedProvider(),
          name: this.assetName(),
          type: supportedFileTypeToMediaAssetType(type),
          ownerId: null,
          directUrl: null,
          providerData: null,
          metadata: null,
          ready: false
        }
      }).subscribe({
        next: response => {
          this.createdAsset.set(response.asset);
          this.executeFileUpload(overwrite);
        },
        error: error => this.handleUploadError(error)
      });
    }
  }
  executeFileUpload(overwrite = false) {
    const asset = this.createdAsset();
    let uploadPath = this.getUploadPath();
    this.uploader.setCustomHeadersForCurrentFile([{
      name: HEADER_ASSET_ID,
      value: '' + asset.id
    }, {
      name: HEADER_ASSET_NAME,
      value: asset.name
    }, {
      name: HEADER_PROVIDER_ID,
      value: this.selectedProvider()
    }, {
      name: HEADER_PROVIDER_TARGETPATH,
      value: uploadPath
    }, {
      name: HEADER_ASSET_TRANSFORMATIONS,
      value: this.applyTransformations() ? "1" : "0"
    }]);
    this.uploader.upload(overwrite);
  }
  getUploadPath() {
    if (this.uploadPath()) {
      return this.uploadPath(); // upload path predefined from outside
    } else if (this.showTargetPathInput() && this.customUploadPath()?.length > 0) {
      return this.customUploadPath(); // custom path selected
    } else {
      return this.defaultUploadPath; // use default path
    }
  }
  onClickAbortUpload() {
    this.isUploading.set(false);
    this.uploader.cancel();
  }
  handleUploadError(error) {
    if (error instanceof AppMessage) {
      this.messages.add(error);
    } else {
      this.messages.createAndDisplay(error);
    }
    this.uploadResult.set({
      error: true,
      success: false
    });
    this.isUploading.set(false);
  }
  static {
    this.ɵfac = function MediaAssetCreatorComponent_Factory(t) {
      return new (t || MediaAssetCreatorComponent)(i0.ɵɵdirectiveInject(i1.MediaUploaderService), i0.ɵɵdirectiveInject(i2.AppRequestService), i0.ɵɵdirectiveInject(i3.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i4.MediaAssetProvidersFrontendService), i0.ɵɵdirectiveInject(i5.ConfigService), i0.ɵɵdirectiveInject(i6.DynamicDialogRef, 8));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetCreatorComponent,
      selectors: [["media-asset-creator"]],
      inputs: {
        allowedFileTypes: [i0.ɵɵInputFlags.SignalBased, "allowedFileTypes"],
        uploadEndpoint: [i0.ɵɵInputFlags.SignalBased, "uploadEndpoint"],
        showTargetPathInput: [i0.ɵɵInputFlags.SignalBased, "showTargetPathInput"],
        uploadPath: [i0.ɵɵInputFlags.SignalBased, "uploadPath"],
        forceProvider: [i0.ɵɵInputFlags.SignalBased, "forceProvider"],
        hideProviderDropdownWhenUnneeded: [i0.ɵɵInputFlags.SignalBased, "hideProviderDropdownWhenUnneeded"]
      },
      outputs: {
        assetCreated: "assetCreated"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([MediaUploaderService, LocalMessageHandlerService]), i0.ɵɵHostDirectivesFeature([i7.InputRefBlockerDirective]), i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "ngLet"], [3, "ngIf"], ["placeholder", "Provider/Host", 4, "ngIf"], [3, "options", "ngModel", "disabled", "ngModelChange"], ["class", "ui-margin", "theme", "content-soft", 4, "ngIf"], ["placeholder", "Provider/Host"], ["placeholder", "", 3, "options", "ngModel", "disabled", "ngModelChange"], ["theme", "content-soft", 1, "ui-margin"], [3, "uploader"], [4, "dynamicComponent"], ["align", "center"], ["label", "Upload-Gr\u00F6\u00DFe"], ["label", "Datei-Typ"], ["size", "lg", 3, "icon"], ["label", "Benennung", "orientation", "vertical"], [3, "ngModel", "disabled", "ngModelChange"], ["orientation", "vertical", "label", "Upload-Verzeichnis", 4, "ngIf"], ["orientation", "vertical"], ["labels", "Standard-Transformierungen erstellen", 3, "ngModel", "disabled", "ngModelChange"], ["uiButton", "", "label", "Upload abbrechen", "class", "upload-button ui-button-danger", 3, "loading", "icon", "click", 4, "ngIf"], ["orientation", "vertical", "label", "Upload-Verzeichnis"], [3, "ngIf", "ngIfElse"], ["withoutPathSuggestions", ""], [3, "ngModel", "allowCustomValue", "options", "minLengthSearchString", "ngModelChange"], ["uiButton", "", "label", "Upload starten", "class", "upload-button", 3, "icon", "click", 4, "ngIf"], ["uiButton", "", "label", "Datei \u00FCberschreiben", "class", "upload-button ui-button-danger", 3, "icon", "click", 4, "ngIf"], ["uiButton", "", "label", "Upload starten", 1, "upload-button", 3, "icon", "click"], ["uiButton", "", "label", "Datei \u00FCberschreiben", 1, "upload-button", "ui-button-danger", 3, "icon", "click"], ["uiButton", "", "label", "Upload abbrechen", 1, "upload-button", "ui-button-danger", 3, "loading", "icon", "click"]],
      template: function MediaAssetCreatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MediaAssetCreatorComponent_ng_template_0_Template, 2, 2, "ng-template", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngLet", ctx.uploadResult());
        }
      },
      dependencies: [CommonModule, i8.NgIf, i8.AsyncPipe, CoreDynamicComponentsModule, i9.DynamicComponentDirective, FormsModule, i10.NgControlStatus, i10.NgModel, UiPanelModule, UiCardModule, i11.Card, UiButtonModule, i12.UiButtonComponent, MediaIconsModule, i13.FaIconComponent, UiFieldWrapperModule, i14.FieldWrapperComponent, FileUploaderComponent, UiInputDropdownModule, i15.InputDropdown, UiInputSwitchModule, i16.InputSwitch, UiInputSelectButtonModule, i17.InputSelectButton, UiTabViewModule, UiGridModule, UiInputTextModule, i18.InputTextComponent, UiInputAutocompleteModule, i19.InputAutocomplete, UiPropChipModule, i20.UiPropChipComponent, i20.UiPropChipListComponent, NgBytesPipeModule, i21.BytesPipe, UtilsFrontendSharedModule, i22.NgLetDirective, MessagesFrontendSharedModule, i23.AppMessagesComponent, UiMessageModule],
      styles: ["[_nghost-%COMP%]     .p-selectbutton{display:flex;justify-content:center}[_nghost-%COMP%]     .p-tabview-panels{padding-left:0!important;padding-right:0!important}.upload-button[_ngcontent-%COMP%]{display:block;width:100%}\n\n/*# sourceMappingURL=media-asset-creator.component.ts-angular-inline--55.css.map*/"]
    });
  }
};
__decorate([Select(AccessState.isAdmin), __metadata("design:type", Observable)], MediaAssetCreatorComponent.prototype, "isAdmin$", void 0);
MediaAssetCreatorComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [MediaUploaderService, AppRequestService, LocalMessageHandlerService, MediaAssetProvidersFrontendService, ConfigService, DynamicDialogRef])], MediaAssetCreatorComponent);
export { MediaAssetCreatorComponent };