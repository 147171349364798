import { isConstructor } from '@core/shared';
import { ClassTransformService, Expose, ItemModelProp, customMetadataStorage } from '@core/shared/model';
import { SKILLSETS_ACCESSOR, SKILLSET_IDS_ACCESSOR } from './constants';
import { SkillsetItem } from '../models';
// not exported as considered private. Use provided functions to get metadata.
const SKILLSETS_PROP_TOKEN = '__SKILLSET_PROP_TOKEN__';
export function WithSkillsets(config = {}) {
  return target => {
    if (!IS_PRODUCTION && getSkillsetsMetadataForModel(config).length) {
      console.log('existing metadata:', getSkillsetsMetadataForModel(config));
      throw new Error('A model can only be decorated once with @WithSkillsets!');
    }
    customMetadataStorage.addMetadata({
      metaType: SKILLSETS_PROP_TOKEN,
      target: target,
      // propertyName: propertyKey,
      options: {
        ...config,
        targetProperty: SKILLSETS_ACCESSOR
      }
    });
    ClassTransformService.getMetadataStorage().addTypeMetadata({
      target: target,
      propertyName: SKILLSETS_ACCESSOR,
      reflectedType: Array,
      typeFunction: () => SkillsetItem,
      options: {}
    });
    ItemModelProp({
      defaultValueCreate: []
    })(target, SKILLSETS_ACCESSOR);
    // no typing here, will be added by MediaAssetRegistry as soon as it is constructed.
    // @Type is too generic, it does not know a map is expected in case the given data is empty.
    // TypedMap(SkillsetMappingItem)(target, SKILLSETS_ACCESSOR)
    Expose()(target, SKILLSETS_ACCESSOR);
    Expose()(target, SKILLSET_IDS_ACCESSOR);
  };
}
export function getModelsWithSkillsets() {
  return customMetadataStorage.findModelsByMetadataType(SKILLSETS_PROP_TOKEN);
}
export function getModelsWithSkillsets$() {
  return customMetadataStorage.findModelsByMetadataType$(SKILLSETS_PROP_TOKEN);
}
export function getSkillsetsMetadataForModel(model) {
  const ctor = isConstructor(model) ? model : model.constructor;
  return customMetadataStorage.findMetadataOfType(SKILLSETS_PROP_TOKEN, ctor);
}