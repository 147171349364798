import { __decorate } from "tslib";
import { SimpleFieldsetComponent } from '@common/forms/frontend-shared';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../core/frontend-shared/src/lib/dynamic-components/dynamic-component.directive";
import * as i4 from "../../../../../forms/frontend-shared/src/lib/instruction-outlet/instruction-outlet.component";
function CrudFieldsetComponent_div_0_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = (a0, a1, a2, a3, a4, a5) => ({
  formGroup: a0,
  formService: a1,
  model: a2,
  editMode: a3,
  restraints: a4,
  extraData: a5
});
function CrudFieldsetComponent_div_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, CrudFieldsetComponent_div_0_div_1_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.innerForm)("ngTemplateOutletContext", i0.ɵɵpureFunction6(2, _c0, ctx_r1.formService.formGroup, ctx_r1.formService, ctx_r1.formService.formGroup.getRawValue(), ctx_r1.formService.editMode, ctx_r1.formService.restraints, ctx_r1.innerFormData));
  }
}
function CrudFieldsetComponent_div_0_ng_template_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CrudFieldsetComponent_div_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrudFieldsetComponent_div_0_ng_template_2_ng_container_0_Template, 1, 0, "ng-container", 5);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("dynamicComponent", ctx_r2.innerFormComponentConfig);
  }
}
function CrudFieldsetComponent_div_0_ng_template_3_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "instruction-outlet", 8);
  }
  if (rf & 2) {
    const instruction_r8 = ctx.$implicit;
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("instruction", instruction_r8)("formService", ctx_r7.formService);
  }
}
function CrudFieldsetComponent_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, CrudFieldsetComponent_div_0_ng_template_3_ng_template_1_Template, 1, 2, "ng-template", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const instructions_r6 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formGroup", ctx_r3.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", instructions_r6);
  }
}
function CrudFieldsetComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("keyup.enter", function CrudFieldsetComponent_div_0_Template_div_keyup_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.onEnter($event));
    })("keyup.control.enter", function CrudFieldsetComponent_div_0_Template_div_keyup_control_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.onEnter($event));
    });
    i0.ɵɵtemplate(1, CrudFieldsetComponent_div_0_div_1_Template, 2, 9, "div", 2)(2, CrudFieldsetComponent_div_0_ng_template_2_Template, 1, 1, "ng-template", 3)(3, CrudFieldsetComponent_div_0_ng_template_3_Template, 2, 2, "ng-template", 3);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.innerForm);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.innerFormComponentConfig);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 3, ctx_r0.allInstructions$));
  }
}
let CrudFieldsetComponent = class CrudFieldsetComponent extends SimpleFieldsetComponent {
  constructor() {
    super(...arguments);
    // in CrudFieldset, instructions may change per ItemEditMode.
    // so incoming CrudInstructionsDefinition may be an Instruction[] or a map of ItemEditMode to Instruction[]!
    this.crudInstructions$ = new BehaviorSubject(null);
    this.editMode$ = new BehaviorSubject(null);
    this.nullProp = combineLatest({
      editMode: this.editMode$,
      crudInstructions: this.crudInstructions$
    }).pipe(takeWhileAlive(this)).subscribe(({
      editMode,
      crudInstructions
    }) => {
      if (!crudInstructions) {
        this.directInstructions$.next(null);
      } else {
        const instructions = !Array.isArray(crudInstructions) ? editMode ? crudInstructions[editMode] : null : crudInstructions;
        this.directInstructions$.next(instructions);
      }
    });
  }
  set _crudInstructions(instructions) {
    this.crudInstructions$.next(instructions);
  }
  get editMode() {
    return this.editMode$.getValue();
  }
  getPluginInstructions(ModelConstructor) {
    return this.pluginInstructionsService.getPluginInstructions(ModelConstructor, this.contextGroups || undefined, this.formService.getEditorDefinition(), this.editMode // TODO: should this be based on Observable? 
    );
  }
  _executeInitialization(options = {}) {
    // initialize editMode from fieldset -> service
    if (this.editMode$.getValue() !== null) this.formService.setEditMode(this.editMode);
    // initialize editMode from service -> fieldset
    else this.editMode$.next(this.formService.getEditMode());
    super._executeInitialization(options);
  }
  static {
    this.ɵfac = /*@__PURE__*/(() => {
      let ɵCrudFieldsetComponent_BaseFactory;
      return function CrudFieldsetComponent_Factory(t) {
        return (ɵCrudFieldsetComponent_BaseFactory || (ɵCrudFieldsetComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrudFieldsetComponent)))(t || CrudFieldsetComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudFieldsetComponent,
      selectors: [["crud-fieldset"]],
      inputs: {
        _crudInstructions: [i0.ɵɵInputFlags.None, "instructions", "_crudInstructions"]
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "keyup.enter", "keyup.control.enter", 4, "ngIf"], [3, "keyup.enter", "keyup.control.enter"], [4, "ngIf"], [3, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "dynamicComponent"], [1, "form-fields", 3, "formGroup"], ["ngFor", "", 3, "ngForOf"], [3, "instruction", "formService"]],
      template: function CrudFieldsetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrudFieldsetComponent_div_0_Template, 5, 5, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.initialized && ctx.formService.initialized);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i2.NgControlStatusGroup, i2.FormGroupDirective, i3.DynamicComponentDirective, i4.InstructionOutletComponent, i1.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:block;flex:1;height:auto}[_nghost-%COMP%] > div[_ngcontent-%COMP%]{flex:1;height:auto}.custom-form[_ngcontent-%COMP%]{flex:1;height:auto;display:flex;flex-direction:column}.form-fields[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{margin-top:10px;display:block}.custom-form[_ngcontent-%COMP%] + .form-fields[_ngcontent-%COMP%]{margin-top:10px}\n\n/*# sourceMappingURL=crud-fieldset.component.ts-angular-inline--46.css.map*/"]
    });
  }
};
CrudFieldsetComponent = __decorate([AutoUnsubscribe()], CrudFieldsetComponent);
export { CrudFieldsetComponent };