import { __decorate, __metadata } from "tslib";
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { ConfigService, LocalMessageHandlerService } from '@core/frontend-shared';
import { AuthenticateByMailRequest } from '@common/users/shared';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/forms/frontend-shared";
import * as i3 from "@angular/common";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "../../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i6 from "../../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i7 from "../../../../../../ui/frontend-shared/src/lib/card/card";
import * as i8 from "../../../../../../ui/frontend-shared/src/lib/shared/shared";
import * as i9 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i10 from "../../../../../../ui/frontend-shared/src/lib/grid/grid";
import * as i11 from "@angular/forms";
import * as i12 from "../../../../../../ui/frontend-shared/src/lib/input-text/input-text";
import * as i13 from "../../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i14 from "../../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
function PasswordResetComponent_ui_card_1_p_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Bitte geben Sie Ihre E-Mail-Adresse an. Wir senden dann eine E-Mail, mit der ein neues Passwort vergeben werden kann.");
    i0.ɵɵelementEnd();
  }
}
function PasswordResetComponent_ui_card_1_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Bitte geben Sie Benutzernamen und E-Mail-Adresse an. Wir senden eine Mail, mit der ein neues Passwort vergeben werden kann.");
    i0.ɵɵelementEnd();
  }
}
function PasswordResetComponent_ui_card_1_form_5_ui_field_wrapper_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-field-wrapper", 6);
    i0.ɵɵelement(1, "ui-input-text", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("placeholder", "E-Mail-Adresse");
  }
}
const _c0 = () => ({
  label: "Jetzt zur\u00FCcksetzen"
});
function PasswordResetComponent_ui_card_1_form_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "form", 5)(1, "ui-field-wrapper", 6);
    i0.ɵɵelement(2, "ui-input-text", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, PasswordResetComponent_ui_card_1_form_5_ui_field_wrapper_3_Template, 2, 1, "ui-field-wrapper", 8);
    i0.ɵɵelement(4, "app-messages");
    i0.ɵɵelementStart(5, "div", 9);
    i0.ɵɵelement(6, "button", 10);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formGroup", ctx_r4.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("placeholder", ctx_r4.usernameLabel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r4.usernameIsEmail);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("formSubmitButton", ctx_r4.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(5, _c0));
  }
}
function PasswordResetComponent_ui_card_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-card", 2)(1, "ui-header");
    i0.ɵɵtext(2, "Passwort zur\u00FCcksetzen");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, PasswordResetComponent_ui_card_1_p_3_Template, 2, 0, "p", 3)(4, PasswordResetComponent_ui_card_1_p_4_Template, 2, 0, "p", 3)(5, PasswordResetComponent_ui_card_1_form_5_Template, 7, 6, "form", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.usernameIsEmail);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.usernameIsEmail);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.formService.formGroup);
  }
}
function PasswordResetComponent_ui_card_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-card", 12)(1, "div", 13)(2, "div", 14);
    i0.ɵɵelement(3, "fa-icon", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 16)(5, "p");
    i0.ɵɵtext(6, "Wenn ein Account mit dieser E-Mail-Adresse existiert, haben Sie eine Mail mit einem Link erhalten, \u00FCber den ein neues Passwort vergeben werden kann.");
    i0.ɵɵelementEnd()()()();
  }
}
let PasswordResetComponent = class PasswordResetComponent {
  constructor(messages, config, formServiceFactory) {
    this.messages = messages;
    this.config = config;
    this.usernameIsEmail = this.config.get('users.usernameIsEmail', false);
    this.usernameLabel = this.usernameIsEmail ? 'E-Mail-Adresse / Benutzername' : 'Benutzername';
    this.formService = formServiceFactory.createModelForm(AuthenticateByMailRequest);
    this.formService.setImmutableValues({
      requireNewPassword: true
    });
    this.formService.onAfterSave.subscribe(({
      responseData,
      error
    }) => {
      if (error) {
        this.messages.push(error);
      } else {
        this.success = true;
      }
    });
    this.formService.initialize();
  }
  static {
    this.ɵfac = function PasswordResetComponent_Factory(t) {
      return new (t || PasswordResetComponent)(i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i1.ConfigService), i0.ɵɵdirectiveInject(i2.ModelFormServiceFactory));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: PasswordResetComponent,
      selectors: [["user-password-reset"]],
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 3,
      vars: 2,
      consts: [["size", "medium", 4, "ngIf"], ["size", "small", 4, "ngIf"], ["size", "medium"], [4, "ngIf"], [3, "formGroup", 4, "ngIf"], [3, "formGroup"], [3, "placeholder"], ["formControlName", "username", 1, "ui-width-stretch"], [3, "placeholder", 4, "ngIf"], [1, "ui-margin"], ["uiButton", "", 1, "ui-width-stretch", 3, "formSubmitButton", "formSubmitButtonOptions"], ["formControlName", "email", 1, "ui-width-stretch"], ["size", "small"], ["uiGrid", "", 1, "p-align-center"], [1, "p-col-3", 2, "text-align", "right"], ["icon", "check", "size", "4x", 1, "ui-float-right", "color-success"], [1, "p-col-9"]],
      template: function PasswordResetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ui-content-centered");
          i0.ɵɵtemplate(1, PasswordResetComponent_ui_card_1_Template, 6, 3, "ui-card", 0)(2, PasswordResetComponent_ui_card_2_Template, 7, 0, "ui-card", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.success);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.success);
        }
      },
      dependencies: [i3.NgIf, i4.FaIconComponent, i5.UiContentCenteredComponent, i6.FieldWrapperComponent, i7.Card, i8.Header, i9.UiButtonComponent, i10.UiGridComponent, i11.ɵNgNoValidate, i11.NgControlStatus, i11.NgControlStatusGroup, i11.FormGroupDirective, i11.FormControlName, i12.InputTextComponent, i13.FormSubmitButtonDirective, i14.AppMessagesComponent]
    });
  }
};
PasswordResetComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [LocalMessageHandlerService, ConfigService, ModelFormServiceFactory])], PasswordResetComponent);
export { PasswordResetComponent };