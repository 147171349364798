import { FileTypeDetectionHelper } from '@common/media/shared';
import { of } from 'rxjs';
import { cloudinaryItemToFileTypeInfo } from './cloudinary-item.interface';
import { MediaAssetType } from '@common/media/shared';
import { CloudinaryMediaTransformationBuilder } from './cloudinary-media-transformation.builder';
export class CloudinaryMediaAssetUrlBuilder {
  get sourceFileType() {
    return this.detectSourceFileType();
  }
  get isVector() {
    return this.itemIsVector();
  }
  constructor(item, clUrlParser, mediaTransformations) {
    this.item = item;
    this.clUrlParser = clUrlParser;
    this.mediaTransformations = mediaTransformations;
    this.transform = null;
    if (!this.item?.directUrl) throw new Error('CloudinaryMediaAssetUrlBuilder received invalid item.');
    const urlInfo = this.clUrlParser.parse(this.item.directUrl);
    this.itemUrlInfo = urlInfo;
    if (!this.item.providerData.publicId) this.item.providerData.publicId = urlInfo.publicId;
    this.explicitFileFormat = false;
  }
  setTransforms(transform) {
    this.transform = transform;
    return this;
  }
  setFileFormat(extension) {
    this.explicitFileFormat = extension;
    return this;
  }
  /**
   * If it is uncertain if a resource is ready for use, manually check before!
   */
  getUrl(inputTransforms) {
    const type = this.detectSourceFileType();
    if (type !== 'image' && type !== 'video') {
      // raw / document, skip all processing
      return this.item.directUrl;
    }
    if (typeof inputTransforms === 'string') {
      const preset = this.mediaTransformations.getTransformation(this.item.type, inputTransforms);
      this.setTransforms(preset.transformation);
    } else if (inputTransforms) {
      this.setTransforms(inputTransforms);
    }
    const customTransforms = typeof this.transform === 'object' && this.transform !== null ? this.transform : false;
    const applyDefaultTransformations = customTransforms === false;
    const cloudinaryUrl = 'https://res.cloudinary.com/' + this.itemUrlInfo.cloudName + '/' + type + '/upload/';
    const transformsString = customTransforms ? CloudinaryMediaTransformationBuilder.toUrlString(customTransforms, applyDefaultTransformations) + '/' : '';
    const ext = this.getDefaultFileExtension(type);
    this.transform = null;
    return cloudinaryUrl + transformsString + this.item.providerData.publicId + '.' + ext;
  }
  getAdaptiveUrl(inputTransforms) {
    return of(this.getUrl(inputTransforms));
  }
  detectSourceFileType() {
    if (this.itemUrlInfo.resourceType === 'video') return 'video';
    if (this.itemUrlInfo.resourceType === 'image') return 'image';
    if (this.itemUrlInfo.resourceType === 'document') return 'document';
    if (this.item.type === MediaAssetType.video) return 'video';
    if (this.item.type === MediaAssetType.image) return 'image';
    if (this.item.type === MediaAssetType.document) return 'document';
    const tmp = FileTypeDetectionHelper.detectFileTypeGroup(cloudinaryItemToFileTypeInfo(this.item));
    if (tmp === 'video') return 'video';
    if (tmp === 'image') return 'image';
    if (tmp === 'document') return 'document';
    return false;
  }
  itemIsVector() {
    if (this.detectSourceFileType() !== 'image') return false;
    const url = this.item.directUrl;
    const originalExt = url.substring(url.lastIndexOf('.') + 1);
    const isVector = ['ai', 'eps', 'svg'].indexOf(originalExt) > -1;
    return isVector;
  }
  getDefaultFileExtension(fileType) {
    if (this.explicitFileFormat) return this.explicitFileFormat;
    // let ext;
    if (fileType === 'video') {
      return 'mp4';
    } else {
      if (this.itemIsVector()) return 'svg';
      // if(['bmp','ico','pdf','tga','tif','tiff'].indexOf(originalExt)>-1) 
      return 'jpg';
    }
  }
}