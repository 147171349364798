import { __decorate } from "tslib";
import { AutoUnsubscribe } from '../../utils';
import { share, take } from 'rxjs';
import { BaseItemService } from './base-item-service';
import { ClientCacheRequestMode } from '../../client-cache';
/**
 * PagedItemService loads a page chunk of items
 */
// not injected, use ItemServiceFactory/CrudServiceFactory to create!
let PagedItemService = class PagedItemService extends BaseItemService {
  constructor() {
    super(...arguments);
    this._initialized = false;
  }
  // set offset to false to delay loading until manually calling setOffset()
  initialize(itemsPerPage, offset = 0) {
    this._initialized = true;
    this.configurate(itemsPerPage, offset);
  }
  configurate(itemsPerPage, offset = 0) {
    this.query.setLimit(itemsPerPage);
    if (typeof offset === 'number') this.query.setOffset(offset);else if (offset === false) this.query.setOffset(null);
  }
  refreshAllItems() {
    if (!this.hasReadItems) return;
    // just get items again
    const req = this.crud.readManyWithRangeInfo({
      cache: ClientCacheRequestMode.wipeCache
    }).pipe(take(1), share());
    req.subscribe(chunk => {
      this.updateItemListAndRange(chunk.items, chunk.rangeInfo);
    });
    return req;
  }
  _readItems() {
    this.assertInitialized();
    if (this.hasReadItems) return;
    this.hasReadItems = true;
    this.crud.readManyWithRangeInfo().subscribe(chunk => {
      this.updateItemListAndRange(chunk.items, chunk.rangeInfo);
    });
  }
  assertInitialized() {
    super.assertInitialized();
    if (!this._initialized) throw new Error('Tried to use PagedItemService before initialize has been called!');
  }
};
PagedItemService = __decorate([AutoUnsubscribe()], PagedItemService);
export { PagedItemService };