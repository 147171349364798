import { map } from 'rxjs';
import { convertToBehaviorSubject } from '../utils';
export class RouterLinkResolverService {
  constructor(registry) {
    this.registry = registry;
    this.allPlatformsResolversMap$ = this.createAllRouterLinkResolverMaps();
  }
  getRouterLinkResolvers(platform) {
    if (!['frontend', 'admin'].includes(platform)) {
      throw new Error('Unsupported RouterLinkResolver platform requested: ' + platform);
    }
    return this.allPlatformsResolversMap$.pipe(map(allResolvers => allResolvers[platform]));
  }
  getRouterLinkResolversForAllPlatforms() {
    return this.allPlatformsResolversMap$;
  }
  resolveRouterLink(platform, name, info, allowUndefined = false) {
    const resolvers = this.allPlatformsResolversMap$.getValue()?.[platform];
    if (resolvers === null) throw new Error('tried to resolve a routerLink before resolvers observable has been populated!');
    if (!resolvers.has(name)) {
      if (allowUndefined) return null;
      throw new Error("RouterLink resolver '" + platform + '::' + name + "' is not defined!");
    }
    const resolverDef = resolvers.get(name);
    const resolvedRoute = resolverDef.resolve(info || null);
    if (!Array.isArray(resolvedRoute)) return [resolvedRoute];else return resolvedRoute;
  }
  createAllRouterLinkResolverMaps() {
    const base = this.registry.getBaseRegistry().pipe(map(baseFeatures => {
      const mappedResolvers = this.generateResolversBaseMap();
      baseFeatures.forEach(feature => {
        const definition = feature.getSharedDefinitionCached();
        if (!definition.routerLinkResolvers) return;
        const platformIds = Object.keys(definition.routerLinkResolvers);
        platformIds.forEach(platform => {
          if (!['frontend', 'admin'].includes(platform)) {
            throw new Error('Unsupported RouterLinkResolver platform: ' + platform + ' found in ' + feature.resolveFeatureInfoFromStatic().context.featureId);
          }
          const resolversForPlatform = definition.routerLinkResolvers[platform];
          const resolverMapForPlatform = mappedResolvers[platform];
          this.addResolversToMap(resolversForPlatform, resolverMapForPlatform);
        });
      });
      return mappedResolvers;
    }));
    return convertToBehaviorSubject(null)(base);
  }
  generateResolversBaseMap() {
    const resolversBaseMap = {
      frontend: new Map(),
      admin: new Map()
    };
    return resolversBaseMap;
  }
  addResolversToMap(resolvers, targetPlatformResolverMap) {
    // eslint-disable-next-line guard-for-in
    for (const key in resolvers) {
      let resolverDef = resolvers[key];
      // convert functions to correct data structure
      if (typeof resolverDef === 'function') {
        resolverDef = {
          priority: 0,
          resolve: resolverDef
        };
      }
      if (targetPlatformResolverMap.has(key)) {
        // override existing resolvers by priority rank
        const existingResolver = targetPlatformResolverMap.get(key);
        if (resolverDef.priority > existingResolver.priority) {
          targetPlatformResolverMap.set(key, resolverDef);
        }
      } else {
        targetPlatformResolverMap.set(key, resolverDef);
      }
    }
  }
}