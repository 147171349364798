import { getBaseModelFromEditorModel, isConstructor, isEditorModel } from '@core/shared';
import { getSkillsetsMetadataForModel, getModelsWithSkillsets } from './skillsets.decorator';
export class SkillsetsHelper {
  static findModelsWithSkillsetsMetadata() {
    return getModelsWithSkillsets();
  }
  static getConfig(model) {
    if (!model) throw new Error('Data passed to SkillsetsHelper is falsy. Check before passing it in!');
    const meta = getSkillsetsMetadataForModel(model);
    if (!meta.length) throw new Error('Model ' + model + ' has no MediaAsset metadata assigned!');
    const config = meta[0].options;
    return config;
  }
  static getSkillsets(model) {
    const config = this.getConfig(model);
    return model[config.targetProperty] || {};
  }
  static setSkillsets(model, mappings) {
    const config = this.getConfig(model);
    model[config.targetProperty] = mappings;
  }
  // either Entity or Item may be passed.The registry works based on ITEM classes.
  // so if an Entity Ctor is passed, we need to extract the name of parent item class.
  static getModelClassName(input) {
    if (typeof input === 'string') return input;
    let modelCtor = isConstructor(input) ? input : input.constructor;
    if (isEditorModel(modelCtor)) modelCtor = getBaseModelFromEditorModel(modelCtor);
    let name = modelCtor.name || modelCtor.constructor.name;
    if (!name) throw new Error('cannot get modelClassName of ' + modelCtor);
    if (name.endsWith('Entity')) {
      const ParentItem = Object.getPrototypeOf(modelCtor);
      name = ParentItem.name;
    }
    return name;
  }
}