import { __decorate, __metadata } from "tslib";
import { AccessFacade, AccessState } from '@core/frontend-shared';
import { ConfigService } from '@core/shared';
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { LocalMessageHandlerService } from '@core/frontend-shared';
import { AuthenticateRequest } from '@common/users/shared';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@core/shared";
import * as i3 from "@common/forms/frontend-shared";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i8 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i9 from "@angular/router";
import * as i10 from "../../../../../../ui/frontend-shared/src/lib/input-text/input-text";
import * as i11 from "../../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
function LoginFormComponent_form_0_div_8_a_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵtext(1, "Registrieren");
    i0.ɵɵelementEnd();
  }
}
function LoginFormComponent_form_0_div_8_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, "|");
    i0.ɵɵelementEnd();
  }
}
function LoginFormComponent_form_0_div_8_a_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 14);
    i0.ɵɵtext(1, "Passwort vergessen?");
    i0.ɵɵelementEnd();
  }
}
function LoginFormComponent_form_0_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8)(1, "div", 9)(2, "small");
    i0.ɵɵtemplate(3, LoginFormComponent_form_0_div_8_a_3_Template, 2, 0, "a", 10)(4, LoginFormComponent_form_0_div_8_span_4_Template, 2, 0, "span", 11)(5, LoginFormComponent_form_0_div_8_a_5_Template, 2, 0, "a", 12);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.registrationEnabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.registrationEnabled && ctx_r1.resetPasswordEnabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.resetPasswordEnabled);
  }
}
const _c0 = () => ({
  label: "Login"
});
function LoginFormComponent_form_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "form", 1)(1, "ui-field-wrapper", 2);
    i0.ɵɵelement(2, "ui-input-text", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ui-field-wrapper", 2);
    i0.ɵɵelement(4, "ui-input-text", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵelement(6, "app-messages")(7, "button", 6);
    i0.ɵɵtemplate(8, LoginFormComponent_form_0_div_8_Template, 6, 3, "div", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formGroup", ctx_r0.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("label", ctx_r0.usernameLabel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("label", "Passwort");
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("formSubmitButton", ctx_r0.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(6, _c0));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.registrationEnabled || ctx_r0.resetPasswordEnabled);
  }
}
let LoginFormComponent = class LoginFormComponent {
  constructor(messages, accessFacade, config, formServiceFactory) {
    this.messages = messages;
    this.accessFacade = accessFacade;
    this.config = config;
    this.registrationEnabled = false;
    this.resetPasswordEnabled = true;
    this.usernameLabel = this.config.get('users.usernameIsEmail', false) ? 'E-Mail-Adresse' : 'Benutzername';
    this.registrationEnabled = config.get('users.registration', false);
    this.formService = formServiceFactory.createModelForm(AuthenticateRequest);
    this.formService.onAfterSave.subscribe(({
      responseData,
      error
    }) => {
      if (error) {
        this.messages.push(error);
      } else {
        const sessionId = responseData.sessionId;
        this.accessFacade.setSessionId(sessionId);
      }
    });
    this.formService.initialize();
  }
  static {
    this.ɵfac = function LoginFormComponent_Factory(t) {
      return new (t || LoginFormComponent)(i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i1.AccessFacade), i0.ɵɵdirectiveInject(i2.ConfigService), i0.ɵɵdirectiveInject(i3.ModelFormServiceFactory));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: LoginFormComponent,
      selectors: [["user-login-form"]],
      inputs: {
        resetPasswordEnabled: "resetPasswordEnabled"
      },
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 1,
      vars: 1,
      consts: [["e2e-login-form", "", 3, "formGroup", 4, "ngIf"], ["e2e-login-form", "", 3, "formGroup"], [3, "label"], ["formControlName", "username", 1, "ui-width-stretch"], ["type", "password", "formControlName", "password", 1, "ui-width-stretch"], [1, "ui-margin"], ["uiButton", "", "icon", "unlock", 1, "ui-width-stretch", 3, "formSubmitButton", "formSubmitButtonOptions"], ["class", "links", 4, "ngIf"], [1, "links"], [1, "ui-text-center"], ["routerLink", "/user/registration", 4, "ngIf"], [4, "ngIf"], ["routerLink", "/user/reset-password", 4, "ngIf"], ["routerLink", "/user/registration"], ["routerLink", "/user/reset-password"]],
      template: function LoginFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, LoginFormComponent_form_0_Template, 9, 7, "form", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.formService.initialized);
        }
      },
      dependencies: [i4.NgIf, i5.AppMessagesComponent, i6.ɵNgNoValidate, i6.NgControlStatus, i6.NgControlStatusGroup, i6.FormGroupDirective, i6.FormControlName, i7.FieldWrapperComponent, i8.UiButtonComponent, i9.RouterLink, i10.InputTextComponent, i11.FormSubmitButtonDirective],
      encapsulation: 2
    });
  }
};
__decorate([Select(AccessState.loggedIn), __metadata("design:type", Observable)], LoginFormComponent.prototype, "loggedIn$", void 0);
LoginFormComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [LocalMessageHandlerService, AccessFacade, ConfigService, ModelFormServiceFactory])], LoginFormComponent);
export { LoginFormComponent };