export function mimeTypeFilter(options, item) {
  return !(options.allowedMimeTypes && options.allowedMimeTypes.indexOf(item.type) === -1);
}
export function fileSizeFilter(options, item) {
  return !(options.maxFileSize && item.size > options.maxFileSize);
}
export function fileTypeFilter(options, item) {
  return isValidFileType(options, item);
}
export function queueLimitFilter(options, item, queue) {
  return options.queueLimit === undefined || queue.length < options.queueLimit;
}
// validate a file the same way input[type=file][accept=...] is working.
// modified version of isValidFile method from Dropzone.js.
// ############## IMPORTANT: while dragging, file.name is unknown!! ############################
export function isValidFileType(options, file) {
  const allowedFiles = options.allowedFileTypes;
  if (!Array.isArray(allowedFiles) || !allowedFiles.length) return true;
  const mimeType = file.type;
  const baseMimeType = mimeType.replace(/\/.*$/, ""); // convert mime to e.g. 'video'
  for (let validType of allowedFiles) {
    validType = validType.trim();
    if (validType.charAt(0) === ".") {
      // validate file extension
      if (file.name && file.name.toLowerCase().indexOf(validType.toLowerCase(), file.name.length - validType.length) !== -1) {
        return true;
      }
    } else if (/\/\*$/.test(validType)) {
      // wildcard mime type
      if (baseMimeType === validType.replace(/\/.*$/, "")) {
        return true;
      }
    } else {
      // exact mime match
      if (mimeType === validType) {
        return true;
      }
    }
  }
  return false;
}
;