import { __decorate, __metadata } from "tslib";
import { AccessState } from '../../access';
import { MemoizedMethod, arraySplitByCondition, arrayUnique } from '@core/shared/utils';
import { Select } from '@ngxs/store';
import * as deepmerge from 'deepmerge';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';
import { FeatureService } from '../feature-registry';
import { PermissionsHelper } from '@core/shared';
import { DevToolsRegistry } from '../../dev-tools';
import * as i0 from "@angular/core";
import * as i1 from "../feature-registry";
import * as i2 from "../../dev-tools";
;
export class FeatureMenuItemService {
  constructor(features, debug) {
    this.features = features;
    this.resolvers$ = new BehaviorSubject(null);
    this.allMenuItemTrees$ = this.features.getLoadedFeatures().pipe(map(activatedFeatures => {
      const featuresWithMenuItems = [];
      const allSpecifiedMenuNames = [];
      activatedFeatures.forEach(feature => {
        const definition = feature.getFeatureDefinitionCached();
        if (definition.menuItems) {
          featuresWithMenuItems.push(feature);
          allSpecifiedMenuNames.push(...Object.keys(definition.menuItems));
        }
      });
      const uniqueMenuNames = arrayUnique(allSpecifiedMenuNames);
      return {
        featuresWithMenuItems,
        uniqueMenuNames
      };
    }), map(({
      featuresWithMenuItems,
      uniqueMenuNames
    }) => {
      const menus = uniqueMenuNames.reduce((menuMap, menuName) => {
        const menuItems = [];
        featuresWithMenuItems.forEach(feature => {
          const definition = feature.getFeatureDefinitionCached().menuItems;
          if (definition[menuName]) {
            menuItems.push(...this.prepareMenuItemsOfFeature(feature, definition[menuName]));
          }
        }, []);
        const menuItemsGrouped = this.applyGroups(menuItems);
        this.sortByPriority(menuItemsGrouped);
        menuMap[menuName] = menuItemsGrouped;
        return menuMap;
      }, {});
      return menus;
    }), shareReplay());
    debug.exposeDebugFunction('inspectFeatureMenuItems', {
      displayName: 'Features: Menus'
    }, () => {
      return this.allMenuItemTrees$;
    });
  }
  getOrderedMenuItems(type, defaultMenuItems) {
    return this.getAccessibleMenuItems(type).pipe(map(featureMenuItems => {
      const all = [...defaultMenuItems, ...featureMenuItems];
      all.sort((a, b) => {
        const aPrio = typeof a.priority === 'number' ? a.priority : 0;
        const bPrio = typeof b.priority === 'number' ? b.priority : 0;
        return bPrio - aPrio;
      });
      return all;
    }));
  }
  getAccessibleMenuItems(type) {
    const menuItemTree$ = this.getMenuItemTree(type);
    return combineLatest([menuItemTree$, this.permissions$]).pipe(map(([menuItemTree, permissions]) => {
      const clonedMenuItemTree = deepmerge([], menuItemTree, {});
      const filteredTree = this.filterMenuItemsByPermissions(clonedMenuItemTree, permissions);
      return filteredTree;
    }), shareReplay(1));
  }
  hasAccessibleMenuItems(type) {
    return this.getAccessibleMenuItems(type).pipe(map(menuItems => {
      return menuItems.length > 0;
    }));
  }
  prepareMenuItemsOfFeature(feature, menu) {
    const menuItemsCloned = deepmerge([], Array.isArray(menu) ? menu : menu.items, {});
    // note: here we are processing the MenuTree of a SINGLE feature.
    // such a menuTree may specify menu.basePath which acts as a prefix for all specified items routerLinks.
    let basePath = null;
    if (!Array.isArray(menu) && menu.basePath) {
      if (!menu.basePath.startsWith('/')) basePath = '/' + menu.basePath;else basePath = menu.basePath;
    }
    const preparedMenu = iterateMenuTreeLayers(menuItemsCloned, (items, parents) => {
      return items.map((item, i) => {
        item['featureOrigin'] = feature.pluginName;
        if (typeof item.priority !== 'number') item.priority = 0;
        if (typeof item.routerLink !== 'undefined' && basePath) {
          item.routerLink = basePath + '/' + item.routerLink;
        }
        return item;
      });
    });
    return preparedMenu;
  }
  getMenuItemTree(menuItemType) {
    return this.allMenuItemTrees$.pipe(map(allMenuItemTrees => {
      return allMenuItemTrees[menuItemType] || [];
    }), shareReplay());
  }
  // check for menuItems specifying displayInGroup and add them as children of menuItem with same declareGroup info.
  applyGroups(menu) {
    // first filter out items that are marked with displayInGroup. We only need to check for these on first level.
    let [itemsToMoveToGroups, baseMenu] = arraySplitByCondition(menu, item => typeof item.displayInGroup === 'string');
    // scan remaining baseMenu items for group declarations
    const groupMenuItems = [];
    baseMenu = iterateMenuTreeLayers(baseMenu, (items, parents) => {
      // filtering is used to filter out duplicate group declarations only.
      return items.filter((item, index) => {
        if (!item.declareGroup) return true;
        const groupName = item.declareGroup;
        const isRedeclaration = groupMenuItems.find(i => i.declareGroup === groupName);
        if (!isRedeclaration) {
          groupMenuItems.push(item);
          return true;
        }
        // the item is a redeclaration. Group can only exist one time, so it must be merged.
        // To do this, we mark the child items of it to use the existing group and add them to itemsToMoveToGroups.
        item.items?.forEach(childItem => {
          childItem.displayInGroup = groupName;
          itemsToMoveToGroups.push(childItem);
        });
        // filter out the re-declaration
        return false;
      });
    });
    const leftovers = this.moveItemsToGroups(itemsToMoveToGroups, groupMenuItems);
    baseMenu.push(...leftovers);
    return baseMenu;
  }
  moveItemsToGroups(itemsToMoveToGroups, groupMenuItems) {
    const leftoverMenuItems = [];
    itemsToMoveToGroups.forEach(item => {
      const groupName = item.displayInGroup;
      const group = groupMenuItems.find(groupItem => groupItem.declareGroup === groupName);
      if (!group) {
        const action = item.onMissingGroup || 'error';
        if (action === 'error') {
          if (!IS_PRODUCTION) {
            console.log('available group menuItems: ', groupMenuItems);
            console.log('processed menuItem: ', item);
          }
          throw new Error('FeatureMenuItemService failed to move a menuItem into group. No group named ' + groupName + ' was found.');
        } else if (action === 'remove') {} else if (action === 'noop') {
          leftoverMenuItems.push(item);
        }
      } else {
        // add to found group
        if (!group.items) group.items = [];
        group.items.push(item);
      }
    });
    return leftoverMenuItems;
  }
  // move highest priority items to array start
  sortByPriority(menu) {
    iterateMenuTreeLayers(menu, layer => {
      // all menuItems without explicit prio will be assigned 0 in prepareMenuItem, don't need to do any type checking here.
      layer.sort((a, b) => {
        return b.priority - a.priority;
      });
    });
  }
  filterMenuItemsByPermissions(menuItems, permissions) {
    return menuItems.filter(menuItem => {
      if (!this.isMenuItemAllowed(menuItem, permissions)) return false;
      if (menuItem.items) {
        menuItem.items = this.filterMenuItemsByPermissions(menuItem.items, permissions);
      }
      return true;
    });
  }
  isMenuItemAllowed(menuItem, permissions) {
    if (menuItem.simplePermissions) {
      return PermissionsHelper.passesSimplePermissions(permissions, menuItem.simplePermissions);
    }
    return true;
  }
  static {
    this.ɵfac = function FeatureMenuItemService_Factory(t) {
      return new (t || FeatureMenuItemService)(i0.ɵɵinject(i1.FeatureService), i0.ɵɵinject(i2.DevToolsRegistry));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: FeatureMenuItemService,
      factory: FeatureMenuItemService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([Select(AccessState.permissions), __metadata("design:type", Observable)], FeatureMenuItemService.prototype, "permissions$", void 0);
__decorate([MemoizedMethod({
  observable: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [String]), __metadata("design:returntype", Observable)], FeatureMenuItemService.prototype, "getAccessibleMenuItems", null);
// can be used for iterating and/or replacing (to replace, handler should return a FeatureMenuItem array.)
function iterateMenuTreeLayers(tree, handler) {
  function iterateMenuTreeLayer(layer, parents) {
    const result = handler(layer, parents);
    if (typeof result !== 'undefined') {
      layer = result;
    }
    layer.forEach(item => {
      if (item.items && Array.isArray(item.items)) {
        item.items = iterateMenuTreeLayer(item.items, [...parents, item]);
      }
    });
    return layer;
  }
  return iterateMenuTreeLayer(tree, []);
}