import { __decorate, __metadata } from "tslib";
import { ComponentRef, EventEmitter, Injector, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { DynamicComponentHelper, DynamicComponentIOCheck } from '@core/frontend-shared';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
let InstructionOutletDirective = class InstructionOutletDirective {
  set inputData(data) {
    const {
      instruction,
      formControlName
    } = data;
    this.formGroup = data.formGroup;
    this.formService = data.formService;
    this.instruction = instruction;
    const inputs = {
      ...instruction.createComponentInputsMap(this.formGroup, this.formService)
    };
    if (formControlName) {
      // assign only if formControlName is available.
      // not all instructions use formControlName.
      inputs.formControlNameStandalone = formControlName;
    }
    this.config = {
      inputs,
      outputs: {},
      attributes: instruction.createComponentAttributesMap(this.formGroup, this.formService),
      id: null
    };
  }
  constructor(templateRef, injector, vcr) {
    this.templateRef = templateRef;
    this.injector = injector;
    this.vcr = vcr;
    this.defaultComponentContext = {};
    this.componentBindings = [];
    this.initialized = false;
  }
  ngOnInit() {
    this.loadComponent().subscribe(componentInfo => {
      this.componentInfo = componentInfo;
      this.createCustomComponent();
      this.instruction.applyDirectives(this.componentRef, this.injector, this.getFormValue());
      DynamicComponentHelper.updateComponentInputValues(this.componentRef, componentInfo, this.config.inputs);
      DynamicComponentHelper.updateComponentAttributes(this.componentRef, this.config.attributes);
      this.initialized = true;
    });
  }
  ngOnChanges(changes) {
    if (changes?.data?.currentValue?.inputs) {
      DynamicComponentHelper.updateComponentInputValues(this.componentRef, this.componentInfo, this.config.inputs);
    }
  }
  getFormValue() {
    if (this.formService) {
      return this.formService.getValueIncludingReadonly();
    } else {
      return this.formGroup.root.value;
    }
  }
  /*********************************
   * Handling of custom component
   *********************************/
  loadComponent() {
    const loader = this.instruction.load();
    return from(loader).pipe(map(data => {
      if (typeof data === 'function') {
        throw new Error("Passing component without a module is currently untested. Possibly it can work with standalone components in the future. ");
        // return {component:data,module:null} as ComponentInfo
      } else {
        return data;
      }
    }));
  }
  clearBindings() {
    this.componentBindings.forEach(binding => binding.next(true));
    this.componentBindings = [];
  }
  createCustomComponentConfig() {
    return {
      inputs: {},
      outputs: this.config.outputs,
      component: this.componentInfo,
      // TODO: disabled as its unknown how hostDirective i/o's work
      IOCheck: DynamicComponentIOCheck.None
    };
  }
  createCustomComponent() {
    this.clearBindings();
    const {
      componentRef,
      destroyBindings
    } = DynamicComponentHelper.makeCustomComponent(this.createCustomComponentConfig(), this.injector, this.vcr, this.componentRef);
    this.componentBindings.push(destroyBindings);
    this.componentRef = componentRef;
  }
  static {
    this.ɵfac = function InstructionOutletDirective_Factory(t) {
      return new (t || InstructionOutletDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: InstructionOutletDirective,
      selectors: [["", "instructionOutlet", ""]],
      inputs: {
        inputData: [i0.ɵɵInputFlags.None, "instructionOutlet", "inputData"]
      },
      features: [i0.ɵɵProvidersFeature([]), i0.ɵɵNgOnChangesFeature]
    });
  }
};
InstructionOutletDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [TemplateRef, Injector, ViewContainerRef])], InstructionOutletDirective);
export { InstructionOutletDirective };