import { arrayIncludesAll } from '../utils';
export var PermissionsHelper;
(function (PermissionsHelper) {
  function hasAnyPermission(granted, required, respectSuperuser = true) {
    // SU access
    if (respectSuperuser && granted.indexOf('SUPERUSER') > -1) return true;
    // check if actually any permissions are required
    if (!required || required.length === 0) return true;
    // permissions are required but none granted? stop here.
    if (granted.length === 0) return false;
    let foundValidPermission = false;
    for (const perm of required) {
      if (granted.includes(perm)) foundValidPermission = true;
    }
    return foundValidPermission;
  }
  PermissionsHelper.hasAnyPermission = hasAnyPermission;
  function hasAllPermissions(granted, required, respectSuperuser = true) {
    // SU access
    if (respectSuperuser && granted.indexOf('SUPERUSER') > -1) return true;
    // check if actually any permissions are required
    if (!required || required.length === 0) return true;
    // permissions are required but none granted? stop here.
    if (granted.length === 0) return false;
    // iterate required permissions and compare with granted permissions.
    const permissionRequirementsSatisfied = required.map(requiredPermission => {
      return granted.indexOf(requiredPermission) > -1;
    });
    const allSatisfied = permissionRequirementsSatisfied.every(check => check === true);
    return allSatisfied;
  }
  PermissionsHelper.hasAllPermissions = hasAllPermissions;
  function passesSimplePermissions(granted, simplePermissions, respectSuperuser = true) {
    // SU access
    if (respectSuperuser && granted.indexOf('SUPERUSER') > -1) return true;
    const matchesAllow = simplePermissions.allow ? fullfillsSimplePermissionStruct(granted, simplePermissions.allow) : true;
    const matchesExcept = simplePermissions.except ? fullfillsSimplePermissionStruct(granted, simplePermissions.except) : false;
    return matchesAllow && !matchesExcept;
  }
  PermissionsHelper.passesSimplePermissions = passesSimplePermissions;
  function fullfillsSimplePermissionStruct(granted, required, respectSuperuser = true) {
    // SU access
    if (respectSuperuser && granted.indexOf('SUPERUSER') > -1) return true;
    // check if actually any permissions are required
    if (!required || required.length === 0) return true;
    // permissions are required but none granted? stop here.
    if (granted.length === 0) return false;
    return required.some(matchingGroup => {
      if (Array.isArray(matchingGroup)) {
        return arrayIncludesAll(granted, matchingGroup);
      } else {
        return granted.includes(matchingGroup);
      }
    });
  }
  PermissionsHelper.fullfillsSimplePermissionStruct = fullfillsSimplePermissionStruct;
})(PermissionsHelper || (PermissionsHelper = {}));