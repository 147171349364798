import { buildMessage, matches, ValidateBy } from "class-validator";
const IS_VALID_USERNAME = 'IS_VALID_USERNAME';
export function IsValidUsername(validationOptions) {
  return ValidateBy({
    name: IS_VALID_USERNAME,
    constraints: [/^[\w\d-_@.]+$/, ''],
    validator: {
      validate: (value, args) => matches(value, args.constraints[0], args.constraints[0]),
      defaultMessage: buildMessage((eachPrefix, args) => 'Der Benutzername darf nur Buchstaben (a-z), Ziffern und die Zeichen - _ @ . enthalten!', validationOptions)
    }
  }, {
    message: 'Der Benutzername darf nur Buchstaben (a-z), Ziffern und die Zeichen - _ @ . enthalten!',
    ...validationOptions
  });
}