import { __decorate, __metadata } from "tslib";
import { AccessState, AutoUnsubscribe } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i3 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
function PageLoginOverlayComponent_ui_content_centered_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered")(1, "div");
    i0.ɵɵelement(2, "ui-spinner");
    i0.ɵɵtext(3, " Login wird durchgef\u00FChrt... ");
    i0.ɵɵelementEnd()();
  }
}
let PageLoginOverlayComponent = class PageLoginOverlayComponent {
  static {
    this.ɵfac = function PageLoginOverlayComponent_Factory(t) {
      return new (t || PageLoginOverlayComponent)();
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: PageLoginOverlayComponent,
      selectors: [["page-login-overlay"]],
      decls: 2,
      vars: 3,
      consts: [[4, "ngIf"]],
      template: function PageLoginOverlayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, PageLoginOverlayComponent_ui_content_centered_0_Template, 4, 0, "ui-content-centered", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !i0.ɵɵpipeBind1(1, 1, ctx.accessIsReady$));
        }
      },
      dependencies: [i1.NgIf, i2.UiContentCenteredComponent, i3.SpinnerComponent, i1.AsyncPipe],
      data: {
        animation: []
      }
    });
  }
};
__decorate([Select(AccessState.isReady), __metadata("design:type", Observable)], PageLoginOverlayComponent.prototype, "accessIsReady$", void 0);
PageLoginOverlayComponent = __decorate([AutoUnsubscribe()], PageLoginOverlayComponent);
export { PageLoginOverlayComponent };