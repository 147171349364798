import { FilterFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class FilterMultiselectInstruction extends FilterFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults,
      showHeader: false,
      display: 'comma'
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap,
      placeholder: 'placeholder',
      showHeader: 'showHeader',
      display: 'display'
    };
    this.attributeMap = {
      'e2e-filter': 'formControlName'
    };
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import('@common/ui/frontend-shared/input-multiselect').then(bundle => {
      return {
        component: bundle.InputMultiSelect,
        module: bundle.UiInputMultiSelectModule
      };
    });
  }
}