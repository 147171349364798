import { DynamicFieldInstruction } from './shared';
export class InputTextareaInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      height: 6,
      autoResize: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      autoResize: 'autoResize',
      rows: 'height'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-textarea').then(bundle => {
      return {
        component: bundle.InputTextareaComponent,
        module: bundle.UiInputTextareaModule
      };
    });
  }
}