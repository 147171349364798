import { DynamicFieldInstruction } from './shared';
export class InputCheckboxInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      infoText: null,
      large: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      label: 'infoText',
      large: 'large'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-checkbox').then(bundle => {
      return {
        component: bundle.InputCheckbox,
        module: bundle.UiInputCheckboxModule
      };
    });
  }
}