import { FilterDropdownInstruction, FilterTextInstruction } from '@common/instructions/frontend-shared';
export const crudManagerDefaults = {
  layout: {
    name: 'table'
  },
  editor: {
    instructions: [],
    autoSave: false,
    closeOnSave: false,
    submitShortcut: null,
    showButtons: true,
    showBackButton: true,
    showSaveButton: true
  },
  list: {
    useCrudEndpoint: undefined,
    loadAllItems: false,
    cols: [{
      field: 'id',
      header: 'ID',
      width: '70px',
      align: 'center'
    }, {
      field: 'enabled',
      header: 'Aktiv',
      width: '88px',
      align: 'center'
    }, {
      field: 'name',
      header: 'Titel',
      width: ''
    }],
    reordering: false,
    clickRowToEdit: true,
    // see https://github.com/nestjsx/crud/wiki/Requests#filter-conditions for possible filter operators
    filters: [new FilterTextInstruction({
      formControlName: 'name',
      placeholder: 'Suche Name...',
      filters: [{
        operator: '$cont',
        field: 'name'
      }]
    }), new FilterDropdownInstruction({
      formControlName: 'enabled',
      placeholder: '[Status]',
      options: [{
        value: '0',
        label: 'deaktiviert'
      }, {
        value: '1',
        label: 'aktiviert'
      }],
      filters: [{
        operator: '$eq',
        field: 'enabled'
      }]
    })],
    toggleable: true,
    createable: true,
    editable: true,
    deleteable: true,
    itemsPerPage: 10,
    itemsPerPageOptions: [3, 10, 20, 50, 100],
    toolbarButtons: []
  },
  itemActions: null
};