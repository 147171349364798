import { getItemModelText } from '@core/shared/model';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
export class ItemController2 {
  get ItemFactory() {
    return this.service.ItemFactory;
  }
  get Model() {
    return this.service.ItemFactory.Model;
  }
  constructor(confirmationService, modelFactoryProvider, itemServiceFactory) {
    this.confirmationService = confirmationService;
    this.modelFactoryProvider = modelFactoryProvider;
    this.itemServiceFactory = itemServiceFactory;
    this.initialized = false;
    this.canEdit = false;
    this.canDelete = false;
    this.canToggle = false;
    this.hasTriedToSubmitForm$ = new BehaviorSubject(false);
    this.formValueChanges$ = new BehaviorSubject({});
    this.subscribeValueChanges = false;
  }
  initialize(service, restraints) {
    this.service = service;
    this.restraints = restraints;
    this.initialized = true;
  }
  getModel() {
    return this.Model;
  }
  loadItem(id) {
    return this.service.crud.readOne(id);
  }
  newItem() {
    return this.service.crud.createNew();
  }
  deleteItem(item, event) {
    if (event?.stopPropagation) event.stopPropagation();
    const canExecuteAction = this.restraints.canRemoveItem(item);
    if (canExecuteAction !== true) throw Error(typeof canExecuteAction === 'string' ? canExecuteAction : 'Aktion nicht erlaubt');
    const executeDeletion = () => {
      const del = this.service.crud.delete(item).pipe(catchError(err => {
        if (this.messages) this.messages.push(err);
        throw err;
      }));
      return del;
    };
    const obs = new Observable(observer => {
      const confirmMsg = getItemModelText('confirmDeleteItem', item);
      this.confirmationService.confirm({
        message: confirmMsg,
        accept: () => {
          executeDeletion().subscribe({
            next: result => {
              observer.next(result);
              observer.complete();
            },
            error: error => {
              observer.error(error);
            }
          });
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        }
      });
    }).pipe(share());
    obs.subscribe();
    return obs;
  }
  toggleItem(item, event) {
    if (event?.stopPropagation) event.stopPropagation();
    const canExecuteAction = this.restraints.canToggleItem(item);
    if (canExecuteAction !== true) throw Error(typeof canExecuteAction === 'string' ? canExecuteAction : 'Aktion nicht erlaubt');
    const req = this.service.crud.toggleState(item);
    req.subscribe({
      error: err => {
        if (this.messages) this.messages.push(err);
      }
    });
    return req;
  }
  saveItem(item) {
    const canExecuteAction = this.restraints.canUpdateItem(item);
    if (canExecuteAction !== true) throw Error(typeof canExecuteAction === 'string' ? canExecuteAction : 'Aktion nicht erlaubt');
    const req = this.service.crud.save(item);
    req.subscribe({
      next: result => {
        const savedItem = this.ItemFactory.fromData(result);
        this.onSuccessfulSave(savedItem);
      },
      error: err => {
        if (this.messages) this.messages.push(err);
      }
    });
    return req;
  }
  onSuccessfulSave(item) {}
}