import { __decorate, __metadata } from "tslib";
import { Default, EditorDefinition, Expose, Model } from '@core/shared/model';
let StatisticsQueryParams = class StatisticsQueryParams extends Model {};
__decorate([Expose(), Default('history'), __metadata("design:type", String)], StatisticsQueryParams.prototype, "type", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", String)], StatisticsQueryParams.prototype, "segments", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", String)], StatisticsQueryParams.prototype, "mode", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", String)], StatisticsQueryParams.prototype, "groupBy", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", Object)], StatisticsQueryParams.prototype, "from", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", Object)], StatisticsQueryParams.prototype, "until", void 0);
__decorate([Expose(), Default({}), __metadata("design:type", Object)], StatisticsQueryParams.prototype, "filters", void 0);
StatisticsQueryParams = __decorate([EditorDefinition({}, {
  allowAllExposed: true
})], StatisticsQueryParams);
export { StatisticsQueryParams };