import { __decorate } from "tslib";
import { CommonModule } from '@angular/common';
import { InputCheckboxInstruction, InputDropdownInstruction, InputTextInstruction } from '@common/instructions/frontend-shared';
import { DynamicFieldsetModule } from '@common/forms/frontend-shared';
import { MessagesFrontendSharedModule } from '@common/messages/frontend-shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiIconsModule } from '@common/ui/frontend-shared/icons';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { CoreFrontendSharedModule, FormInstructionInterface, LocalMessageHandlerService } from '@core/frontend-shared';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { MembershipDisplayMode, MembershipRegistrationPluginFormComponent } from '@common/membership/frontend-shared';
import { MembershipRegistrationDataInsuranceSimpleModel, insurances } from '@pflegetheke/membership-plugins-insurance/shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../common/forms/frontend-shared/src/lib/simple-fieldset/simple-fieldset.component";
import * as i3 from "../../../../../../../common/forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i4 from "../../../../../../../common/ui/frontend-shared/src/lib/button/button";
import * as i5 from "../../../../../../../common/ui/frontend-shared/src/lib/input-group/input-group";
import * as i6 from "../../../../../../../common/messages/frontend-shared/src/lib/components/app-messages.component";
function MembershipRegistrationFormInsuranceSimpleComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.pluginTitle);
  }
}
function MembershipRegistrationFormInsuranceSimpleComponent_p_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Die Nutzung der Pflegetheke ist f\u00FCr Sie kostenfrei.");
    i0.ɵɵelementEnd();
  }
}
function MembershipRegistrationFormInsuranceSimpleComponent_p_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Wir ben\u00F6tigen von Ihnen noch einige Angaben, danach k\u00F6nnen Sie direkt loslegen!");
    i0.ɵɵelementEnd();
  }
}
function MembershipRegistrationFormInsuranceSimpleComponent_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function MembershipRegistrationFormInsuranceSimpleComponent_button_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onReset());
    });
    i0.ɵɵelementEnd();
  }
}
const _c0 = a0 => ({
  label: a0
});
let MembershipRegistrationFormInsuranceSimpleComponent = class MembershipRegistrationFormInsuranceSimpleComponent extends MembershipRegistrationPluginFormComponent {
  getInstructions() {
    const compactLayout = this.displayMode === MembershipDisplayMode.compact;
    const labelProp = compactLayout ? 'placeholder' : 'label';
    const insuranceMembershipIdHelp = `
			<p>Für die Abrechnung mit der Krankenkasse benötigen wir Ihre Versichertennummer. Sie finden diese auf Ihrer Krankenkassenkarte.</p>
			<div class="ui-margin ui-align-center">
				<img 
					src="https://res.cloudinary.com/pflegetheke/image/upload/v1696854921/pflegetheke/general/krankenkassenkarte-versichertennummer.png"
					alt="Hier finden Sie Ihre Versichertennummer auf der Krankenkassenkarte" width="360"
				>
			</div>`;
    const instructions = [new InputDropdownInstruction({
      formControlName: 'insuranceId',
      [compactLayout ? 'dropdownPlaceholder' : 'label']: this.plugin.getLabelForField('insuranceId'),
      options: this.getInsuranceOptions(),
      filter: true
    }), new InputTextInstruction({
      formControlName: 'insuranceMembershipId',
      [labelProp]: this.plugin.getLabelForField('insuranceMembershipId'),
      help: insuranceMembershipIdHelp
    })];
    if (this.requireAccountData) {
      const checkboxInfoText = "Ich bin damit einverstanden, dass meine personenbezogenen Daten gemäß den Datenschutzbestimmungen an Drittanbieter weitergegeben werden dürfen, um weitere Informationen zu Versorgungsthemen zu erhalten.";
      instructions.push(new InputTextInstruction({
        formControlName: 'firstName',
        [labelProp]: this.plugin.getLabelForField('firstName')
      }), new InputTextInstruction({
        formControlName: 'lastName',
        [labelProp]: this.plugin.getLabelForField('lastName')
      }), new InputTextInstruction({
        formControlName: 'email',
        [labelProp]: this.plugin.getLabelForField('email')
      }), new InputTextInstruction({
        formControlName: 'phone',
        [labelProp]: this.plugin.getLabelForField('phone')
      }), new InputCheckboxInstruction({
        formControlName: 'allow3rdPartyContact',
        [labelProp]: compactLayout ? undefined : this.plugin.getLabelForField('allow3rdPartyContact'),
        infoText: checkboxInfoText
      }));
    }
    return instructions;
  }
  getEditorDefinition() {
    return this.requireAccountData ? 'with-account-fields' : 'default';
  }
  getInsuranceOptions() {
    return insurances.map(insurance => {
      return {
        value: insurance.id,
        label: insurance.name
      };
    });
  }
  getFormModel() {
    return MembershipRegistrationDataInsuranceSimpleModel;
  }
  static {
    this.ɵfac = /*@__PURE__*/(() => {
      let ɵMembershipRegistrationFormInsuranceSimpleComponent_BaseFactory;
      return function MembershipRegistrationFormInsuranceSimpleComponent_Factory(t) {
        return (ɵMembershipRegistrationFormInsuranceSimpleComponent_BaseFactory || (ɵMembershipRegistrationFormInsuranceSimpleComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MembershipRegistrationFormInsuranceSimpleComponent)))(t || MembershipRegistrationFormInsuranceSimpleComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MembershipRegistrationFormInsuranceSimpleComponent,
      selectors: [["membership-registration-form-insurance-simple"]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 10,
      vars: 10,
      consts: [[3, "ngIf"], [4, "ngIf"], [3, "formService", "instructions"], [1, "ui-margin", "ui-align-center"], ["uiButton", "", "label", "Zur\u00FCcksetzen", "icon", "times", "class", "ui-button-secondary small", 3, "click", 4, "ngIf"], ["uiButton", "", 1, "ui-button-primary", 3, "formSubmitButton", "formSubmitButtonOptions"], [1, "reset-wrapper"], ["uiButton", "", "label", "Zur\u00FCcksetzen", "icon", "times", 1, "ui-button-secondary", "small", 3, "click"]],
      template: function MembershipRegistrationFormInsuranceSimpleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MembershipRegistrationFormInsuranceSimpleComponent_ng_template_0_Template, 2, 1, "ng-template", 0)(1, MembershipRegistrationFormInsuranceSimpleComponent_p_1_Template, 2, 0, "p", 1)(2, MembershipRegistrationFormInsuranceSimpleComponent_p_2_Template, 2, 0, "p", 1);
          i0.ɵɵelement(3, "simple-fieldset", 2)(4, "app-messages");
          i0.ɵɵelementStart(5, "div", 3)(6, "ui-input-group");
          i0.ɵɵtemplate(7, MembershipRegistrationFormInsuranceSimpleComponent_button_7_Template, 1, 0, "button", 4);
          i0.ɵɵelement(8, "button", 5);
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(9, "div", 6);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.displayMode === "full");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.platform === "frontend");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.platform === "frontend");
          i0.ɵɵadvance();
          i0.ɵɵproperty("formService", ctx.formService)("instructions", ctx.instructions);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", ctx.platform === "frontend");
          i0.ɵɵadvance();
          i0.ɵɵproperty("formSubmitButton", ctx.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction1(8, _c0, !ctx.submitsImmediately ? "weiter" : "senden"));
        }
      },
      dependencies: [CommonModule, i1.NgIf, CoreFrontendSharedModule, UiTabViewModule, UtilsFrontendSharedModule, DynamicFieldsetModule, i2.SimpleFieldsetComponent, i3.FormSubmitButtonDirective, UiButtonModule, i4.UiButtonComponent, UiInputGroupModule, i5.InputGroup, MessagesFrontendSharedModule, i6.AppMessagesComponent, UiIconsModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
MembershipRegistrationFormInsuranceSimpleComponent = __decorate([AutoUnsubscribe()], MembershipRegistrationFormInsuranceSimpleComponent);
export { MembershipRegistrationFormInsuranceSimpleComponent };