import { AbilitySubjectType } from './ability.interface';
import { DefaultAbilityActionsMap } from './default-ability-actions';
// injectable in both platforms
export class AbilitySubjectsRegistry {
  constructor(features, models) {
    this.features = features;
    this.models = models;
    this.registry = {
      [AbilitySubjectType.Feature]: new Map(),
      [AbilitySubjectType.Item]: new Map()
    };
    this.features.getBaseFeatures().forEach(base => {
      this.registerSubjectsFromFeature(base);
    });
  }
  getActionDefinitionsForSubject(subjectType, subject) {
    const subjectConfig = this.registry[subjectType].get(subject);
    if (!subjectConfig) return [];
    return subjectConfig.actionDefinitions;
  }
  getActionsForSubject(subjectType, subject) {
    return this.getActionDefinitionsForSubject(subjectType, subject).map(def => def.action);
  }
  getSubjectsOfType(subjectType) {
    return Array.from(this.registry[subjectType].keys());
  }
  getSubjectIds() {
    const types = Object.keys(this.registry);
    const list = [];
    types.forEach(type => {
      const map = this.registry[type];
      const subjects = Array.from(map.keys());
      subjects.forEach(subject => {
        list.push({
          type: type,
          subject
        });
      });
    });
    return list;
  }
  registerSubjectsFromFeature(base) {
    const info = base.resolveFeatureInfoFromStatic();
    if (Array.isArray(info.abilityActions) && info.abilityActions?.length) {
      this.registerSubject(AbilitySubjectType.Feature, info.context.featureId, info.abilityActions);
    }
    const itemNames = base.resolveFeatureInfoFromStatic().bundledItemModels;
    if (itemNames) {
      itemNames.forEach(name => {
        this.registerSubject(AbilitySubjectType.Item, name, DefaultAbilityActionsMap.Item);
      });
    }
  }
  registerSubject(subjectType, subject, actions = []) {
    const actionDefinitions = actions.map(a => {
      if (typeof a === 'object') return a;
      return {
        action: a,
        defaultBias: -1
      };
    });
    this.registry[subjectType].set(subject, {
      actionDefinitions
    });
  }
}