import { __decorate, __metadata } from "tslib";
import { EditorDefinition, Expose, IsEmail, IsMatching, IsSafePassword, IsSimpleText, IsValidUsername, ItemModelProp, MaxLength, MinLength, Model, ToLowerCase, Transform, Trim, ValidateIf } from '@core/shared/model';
// part of registration request to be sent to server, data will be used to create UserItem
let RegistrationModelAccount = class RegistrationModelAccount extends Model {};
__decorate([MinLength(3), MaxLength(64), IsValidUsername(), Trim(), Expose(), ItemModelProp({
  defaultValueCreate: ''
}), __metadata("design:type", String)], RegistrationModelAccount.prototype, "username", void 0);
__decorate([IsSimpleText(), MinLength(2), Trim(), Expose(), ItemModelProp({
  defaultValueCreate: ''
}), __metadata("design:type", String)], RegistrationModelAccount.prototype, "firstName", void 0);
__decorate([IsSimpleText(), MinLength(2), Trim(), Expose(), ItemModelProp({
  defaultValueCreate: ''
}), __metadata("design:type", String)], RegistrationModelAccount.prototype, "lastName", void 0);
__decorate([ItemModelProp({
  defaultValueCreate: '',
  allowEmptyString: true
}), IsSafePassword(), Expose(), __metadata("design:type", String)], RegistrationModelAccount.prototype, "password", void 0);
__decorate([ValidateIf(e => {
  return typeof e.password === 'string';
}), IsMatching('password', {
  message: 'Beide Passwörter müssen identisch sein!'
}), ItemModelProp({
  defaultValueCreate: '',
  isOptionalUpdate: false
}) // isOptionalUpdate defaults to true which would not run validation at all if field is undefined!
, Expose(), __metadata("design:type", String)], RegistrationModelAccount.prototype, "password2", void 0);
__decorate([IsEmail({}), Transform(({
  value
}) => {
  if (typeof value === 'string' && value.trim() === '') return null;else return value;
}) // required to make it work with unique index
, Trim(), ToLowerCase(), Expose(), ItemModelProp({
  defaultValueCreate: null
}), __metadata("design:type", String)], RegistrationModelAccount.prototype, "email", void 0);
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: null
}), __metadata("design:type", String)], RegistrationModelAccount.prototype, "phone", void 0);
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: false
}), __metadata("design:type", Boolean)], RegistrationModelAccount.prototype, "allow3rdPartyContact", void 0);
RegistrationModelAccount = __decorate([EditorDefinition(['username', 'password', 'password2', 'email', 'firstName', 'lastName', 'phone', 'allow3rdPartyContact']), EditorDefinition('noUsername', ['password', 'password2', 'email', 'firstName', 'lastName', 'phone', 'allow3rdPartyContact'])], RegistrationModelAccount);
export { RegistrationModelAccount };