import 'reflect-metadata';
export const APP_EVENT_KEY = '__APP_EVENT__';
export function AppEvent(moduleId, name) {
  return function SetAppEventMeta(model) {
    Reflect.defineMetadata(APP_EVENT_KEY, {
      moduleId,
      name,
      type: 'event'
    }, model);
    return model;
  };
}