import { LayoutInstruction } from './shared';
export class LayoutGridInstruction extends LayoutInstruction {
  getDefaults() {
    return {
      title: null
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      instructions: 'instructions',
      title: 'title'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/forms/frontend-shared/layout-instruction-components/grid').then(bundle => {
      return {
        component: bundle.InstructionLayoutColumnsComponent,
        module: null
      };
    });
  }
}
export class LayoutGridColumnInstruction extends LayoutInstruction {
  getDefaults() {
    return {
      title: null,
      colClasses: null,
      panel: false,
      toggleable: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      instructions: 'instructions',
      title: 'title',
      toggleable: 'toggleable'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/forms/frontend-shared/layout-instruction-components/grid').then(bundle => {
      return {
        component: bundle.InstructionLayoutColumnComponent,
        module: null
      };
    });
  }
}