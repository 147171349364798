import { __decorate, __metadata } from "tslib";
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MaintenanceFacade, MaintenanceState } from "../state/maintenance.state";
import * as i0 from "@angular/core";
import * as i1 from "../state/maintenance.state";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../ui/frontend-shared/src/lib/button/button";
function MaintenanceAnnouncementComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "button", 2);
    i0.ɵɵlistener("click", function MaintenanceAnnouncementComponent_div_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.dismiss());
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const announcement_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", announcement_r1, " ");
  }
}
export class MaintenanceAnnouncementComponent {
  constructor(facade) {
    this.facade = facade;
  }
  dismiss() {
    this.facade.dismiss();
  }
  static {
    this.ɵfac = function MaintenanceAnnouncementComponent_Factory(t) {
      return new (t || MaintenanceAnnouncementComponent)(i0.ɵɵdirectiveInject(i1.MaintenanceFacade));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MaintenanceAnnouncementComponent,
      selectors: [["maintenance-announcement"]],
      decls: 2,
      vars: 3,
      consts: [["class", "infobar", 4, "ngIf"], [1, "infobar"], ["uiButton", "", "label", "alles klar!", 1, "ui-button-outline", 3, "click"]],
      template: function MaintenanceAnnouncementComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MaintenanceAnnouncementComponent_div_0_Template, 3, 1, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.announcement$));
        }
      },
      dependencies: [i2.NgIf, i3.UiButtonComponent, i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{position:fixed;top:0;left:0;right:0;z-index:9998;background:#0000;transition:background-color .3s}div[_ngcontent-%COMP%]{background:var(--primaryColor);color:var(--primaryTextColor);padding:10px 20px;display:flex;justify-content:center;align-items:center}.ui-button-outline[_ngcontent-%COMP%]{border-color:var(--primaryTextColor);border-width:2px}div[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{margin:0 10px}div[_ngcontent-%COMP%]{transform:translateY(0);transition:transform .3s}"]
    });
  }
}
__decorate([Select(MaintenanceState.announcement), __metadata("design:type", Observable)], MaintenanceAnnouncementComponent.prototype, "announcement$", void 0);