import { __decorate, __metadata } from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { AppStateActions } from './app.actions';
import * as i0 from "@angular/core";
let AppState = class AppState {
  static connectionError(state) {
    return state.connectionError;
  }
  // isBrowser = isPlatformBrowser(this.platformId)
  constructor() {}
  ngxsOnInit(ctx) {}
  setIsInitialRoute({
    patchState
  }, action) {
    patchState({
      connectionError: action.isError
    });
  }
  static {
    this.ɵfac = function AppState_Factory(t) {
      return new (t || AppState)();
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: AppState,
      factory: AppState.ɵfac
    });
  }
};
__decorate([Action(AppStateActions.SetConnectionError), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, AppStateActions.SetConnectionError]), __metadata("design:returntype", void 0)], AppState.prototype, "setIsInitialRoute", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AppState, "connectionError", null);
AppState = __decorate([State({
  name: 'app',
  defaults: {
    connectionError: false
  }
}), __metadata("design:paramtypes", [])], AppState);
export { AppState };