import { DynamicFieldInstruction, applyOptionsDynamicDirectives } from './shared';
export class InputPermissionsInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      disabledPermissions: [],
      displayInline: false,
      excludeProgrammaticPermissions: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      disabledPermissions: 'disabledPermissions',
      excludeProgrammaticPermissions: 'excludeProgrammaticPermissions',
      dropdown: config => {
        return !config.displayInline;
      }
    };
    this.attributeMap = {};
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import('@common/ui/frontend-shared/input-permissions').then(bundle => {
      return {
        component: bundle.UiInputPermissionsComponent,
        module: bundle.UiInputPermissionsModule
      };
    });
  }
}