import { __decorate, __metadata } from "tslib";
import { ComponentRef, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribe, takeWhileAlive } from '../../../utils';
import { filter } from 'rxjs/operators';
import { FeatureDynamicComponentsService } from '../feature-dynamic-components.service';
import { DynamicComponentConfig, ComponentInfo, DynamicComponentHelper } from '../../../dynamic-components';
import * as i0 from "@angular/core";
import * as i1 from "../feature-dynamic-components.service";
/**
 * ReplaceableComponentDirective is a structural directive which should be used on ng-container.
 * <ng-container *replaceableComponent="{id:'selector',inputs,outputs}"></ng-container>
 * Based on the passed id, it will check registry if a replacement component for that ID is available.
 * Then it will either render the found replacement or fall back to the default component (specified by @ReplaceableComponent).
 */
let ReplaceableComponentDirective = class ReplaceableComponentDirective {
  set _config(config) {
    this.config = {
      inputs: {},
      outputs: {},
      ...config
    };
  }
  constructor(templateRef, injector, vcr, dynamicComponentsService) {
    this.templateRef = templateRef;
    this.injector = injector;
    this.vcr = vcr;
    this.dynamicComponentsService = dynamicComponentsService;
    this.initialized = false;
    this.defaultComponentContext = {};
    this.componentBindings = [];
  }
  ngOnInit() {
    this.dynamicComponentsService.resolveComponent(this.config.id).pipe(filter(componentInfo => {
      if (!componentInfo.component) return false;
      if (!this.initialized) return true;
      // TODO: implement correct comparison
      if (componentInfo.component !== this.componentInfo.component) return true;
      return false;
    }),
    // distinctUntilChanged(),
    takeWhileAlive(this)).subscribe(componentInfo => {
      this.componentInfo = componentInfo;
      this.setupCustomComponent(componentInfo);
    });
  }
  clearBindings() {
    this.componentBindings.forEach(binding => binding.next(true));
    this.componentBindings = [];
  }
  setupCustomComponent(componentInfo) {
    const config = {
      component: componentInfo,
      ...this.config
    };
    this.clearBindings();
    const {
      componentRef,
      destroyBindings
    } = DynamicComponentHelper.makeCustomComponent(config, this.injector, this.vcr, this.componentRef);
    this.componentBindings.push(destroyBindings);
    this.componentRef = componentRef;
    this.initialized = true;
  }
  static {
    this.ɵfac = function ReplaceableComponentDirective_Factory(t) {
      return new (t || ReplaceableComponentDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i1.FeatureDynamicComponentsService));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: ReplaceableComponentDirective,
      selectors: [["", "replaceableComponent", ""]],
      inputs: {
        _config: [i0.ɵɵInputFlags.None, "replaceableComponent", "_config"]
      },
      features: [i0.ɵɵProvidersFeature([])]
    });
  }
};
ReplaceableComponentDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [TemplateRef, Injector, ViewContainerRef, FeatureDynamicComponentsService])], ReplaceableComponentDirective);
export { ReplaceableComponentDirective };