import { __decorate, __metadata } from "tslib";
import { Store } from '@ngxs/store';
import { AccessState } from '../access';
import { CLIENT_CACHE_PROTOCOL, CLIENT_CACHE_TAG_USERCHANGE } from './client-cache';
import { ClientCacheService } from './client-cache.service';
import { distinctUntilChanged } from 'rxjs';
import { AutoUnsubscribe, takeWhileAlive } from '../utils';
import { ConfigService } from '@core/shared/config';
import { ProtocolDistributor } from '@core/shared';
import * as i0 from "@angular/core";
import * as i1 from "./client-cache.service";
import * as i2 from "@ngxs/store";
import * as i3 from "@core/shared/config";
import * as i4 from "@core/shared";
let ClientCacheAccessConnector = class ClientCacheAccessConnector {
  constructor(clientCache, store, config, protocol) {
    const isAdmin = config.isPlatform('admin');
    store.select(AccessState.permissions).pipe(distinctUntilChanged(), takeWhileAlive(this)).subscribe(permissions => {
      if (isAdmin) {
        clientCache.clearCache();
        protocol.write('permissions changed to ' + permissions.join(',') + '. cleared all cache entries of [platform:admin]');
      } else {
        const count = clientCache.clearCacheByTag(CLIENT_CACHE_TAG_USERCHANGE);
        protocol.write('permissions changed to ' + permissions.join(',') + '. cleared ' + count + ' cache entries of [platform:frontend]');
      }
    });
  }
  static {
    this.ɵfac = function ClientCacheAccessConnector_Factory(t) {
      return new (t || ClientCacheAccessConnector)(i0.ɵɵinject(i1.ClientCacheService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(CLIENT_CACHE_PROTOCOL));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: ClientCacheAccessConnector,
      factory: ClientCacheAccessConnector.ɵfac,
      providedIn: 'root'
    });
  }
};
ClientCacheAccessConnector = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ClientCacheService, Store, ConfigService, ProtocolDistributor])], ClientCacheAccessConnector);
export { ClientCacheAccessConnector };