import { combineLatest, map } from 'rxjs';
export var ModelActionHelper;
(function (ModelActionHelper) {
  function createMenuItemsFromItemModelActions$(contextMenuSelection$, contextMenuActionsAll$) {
    return combineLatest({
      selection: contextMenuSelection$,
      itemActions: contextMenuActionsAll$
    }).pipe(map(({
      selection,
      itemActions
    }) => {
      if (!itemActions?.length) return [];
      if (!selection) return [];
      const menuItems = ModelActionHelper.createMenuItemsFromItemModelActions(itemActions, () => selection);
      return menuItems;
    }));
  }
  ModelActionHelper.createMenuItemsFromItemModelActions$ = createMenuItemsFromItemModelActions$;
  function createMenuItemsFromItemModelActions(itemActions, getSelectedItem) {
    if (!itemActions) return [];
    const transformedItems = itemActions.map(action => {
      const item = getSelectedItem();
      return {
        ...processItemActionProperties(action, item),
        automationId: action.id || undefined,
        disabled: action.enableIf && !action.enableIf(item),
        command: () => {
          const currentItem = getSelectedItem();
          if (!currentItem) throw new Error('ItemAction failed: No selected item available!');
          action.action(currentItem);
        }
      };
    });
    return transformedItems;
  }
  ModelActionHelper.createMenuItemsFromItemModelActions = createMenuItemsFromItemModelActions;
  // several ModelAction properties support static value or a function.
  // this method checks for functions and executes all of them to receive plain data.
  // eslint-disable-next-line no-inner-declarations
  function processItemActionProperties(action, item) {
    return ['label', 'title', 'icon', 'styleClass'].reduce((processed, property) => {
      if (action[property]) {
        if (typeof action[property] === 'function') processed[property] = action[property](item);else if (typeof action[property] === 'string') processed[property] = action[property];
      }
      return processed;
    }, {});
  }
})(ModelActionHelper || (ModelActionHelper = {}));