import { __decorate, __metadata } from "tslib";
import { InjectionToken, PLATFORM_ID } from '@angular/core';
import { ConfigService } from '@core/shared';
import { FeatureRegistry, FeatureStatusService, FeatureType, OptInFeatureManager, OptInFeatureScope } from '@core/shared/feature';
import { Select } from '@ngxs/store';
import { Observable, combineLatest, map, take } from 'rxjs';
import { AccessState } from '../../access/access/access.state';
import { DevToolsRegistry } from '../../dev-tools';
import { ScopeState } from '../../scope/scope.state';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@core/shared";
import * as i2 from "../../dev-tools";
// located in own file which is not exported from index, so it is "internal"
// must not import AccessService! would cause circular dependency!
export const BASE_FEATURES_PROVIDER = new InjectionToken("Base Features Provider");
export class FrontendFeatureRegistry extends FeatureRegistry {
  constructor(config, debug, baseFeatures, platformId) {
    super(config);
    this.config = config;
    this.platformId = platformId;
    this.featureStatus = new FeatureStatusService();
    this.setup(baseFeatures);
    debug.exposeDebugFunction('inspectRegisteredFeatures', {
      displayName: 'Features: Inspect'
    }, () => {
      return this.getBaseRegistry().pipe(take(1), map(bases => {
        return bases.map(base => {
          return {
            ...base.resolveFeatureInfoFromStatic(),
            definition: base.getSharedDefinitionCached()
          };
        });
      }));
    });
    debug.exposeDebugFunction('inspectRegisteredPlugins', {
      displayName: 'Features: Inspect Plugins'
    }, () => {
      return this.getPluginRegistry().pipe(take(1), map(featurePlugins => {
        return featurePlugins.map(feature => {
          const status = this.featureStatus.get(feature);
          return {
            instance: feature,
            context: feature.getFeatureContext(),
            featureInfo: feature.__info,
            definition: feature.__def,
            loaded: status.loaded,
            status
          };
        });
      }));
    });
  }
  setupPluginStatusCalculation() {
    combineLatest({
      registeredFeatures: this.getPluginRegistry(),
      permissions: this.permissions$,
      scope: this.scope$,
      activatedOptInFeatures: this.activatedFeatures$
    }).subscribe(({
      registeredFeatures,
      permissions,
      scope,
      activatedOptInFeatures
    }) => {
      registeredFeatures.forEach(feature => {
        const status = this.featureStatus.setNewStatus(feature, permissions, scope, activatedOptInFeatures);
        this.updateFeatureStatus(feature, status);
      });
    });
  }
  validatePlugin(plugin) {
    if (isPlatformBrowser(this.platformId)) {
      const registeredPlugins = this.pluginRegistry$.value;
      if (registeredPlugins.find(f => f.__info.name === plugin.__info.name)) {
        throw new Error('Feature registration error: A feature named "' + plugin.__info.name + '" is already registered!');
      }
    }
    const info = plugin.__info;
    if (info.requiredPermissions) {
      if (info.type !== FeatureType.Dynamic && info.type !== FeatureType.OptIn) {
        throw new Error('Invalid Feature setup: requiredPermissions can only be used with FeatureTypes Dynamic and OptIn!');
      }
    }
    if (info.type === FeatureType.Dynamic) {
      if (!info.requiredPermissions?.length && !info.requiredScopes?.length) {
        throw new Error('Invalid Feature setup: FeatureType.Dynamic requires at least either requiredPermissions or requiredScope to be set!');
      }
    }
    if (info.type === FeatureType.OptIn) {
      if (info.scope === OptInFeatureScope.Company && info.configurableBy === OptInFeatureManager.Self) {
        throw new Error('Invalid Feature setup: Company OptIn features can only be controlled by admins!');
      }
    }
  }
  static {
    this.ɵfac = function FrontendFeatureRegistry_Factory(t) {
      return new (t || FrontendFeatureRegistry)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.DevToolsRegistry), i0.ɵɵinject(BASE_FEATURES_PROVIDER), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: FrontendFeatureRegistry,
      factory: FrontendFeatureRegistry.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([Select(AccessState.permissions), __metadata("design:type", Observable)], FrontendFeatureRegistry.prototype, "permissions$", void 0);
__decorate([Select(AccessState.activatedFeatures), __metadata("design:type", Observable)], FrontendFeatureRegistry.prototype, "activatedFeatures$", void 0);
__decorate([Select(ScopeState.scope), __metadata("design:type", Observable)], FrontendFeatureRegistry.prototype, "scope$", void 0);