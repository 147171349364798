// partially inspired by dot-prop
const disallowedKeys = ['__proto__', 'prototype', 'constructor'];
const isValidPath = pathSegments => !pathSegments.some(segment => disallowedKeys.includes(segment));
function isObj(obj) {
  return typeof obj === 'object' && !Array.isArray(obj);
}
function getPathSegments(path) {
  const pathArray = path.split('.');
  const parts = [];
  for (let i = 0; i < pathArray.length; i++) {
    let p = pathArray[i];
    while (p[p.length - 1] === '\\' && pathArray[i + 1] !== undefined) {
      p = p.slice(0, -1) + '.';
      p += pathArray[++i];
    }
    parts.push(p);
  }
  if (!isValidPath(parts)) return [];
  return parts;
}
export function getDotProp(object, path) {
  let match;
  if (object[path]) {
    // exact string match
    match = object[path];
  } else {
    // deep property search
    const crumbs = path.split('.');
    match = crumbs.reduce((subobject, crumb) => {
      return subobject && typeof subobject[crumb] !== 'undefined' ? subobject[crumb] : null;
    }, object);
  }
  if (typeof match !== 'undefined') return match;
  return null;
}
;
export function setDotProp(object, path, value) {
  if (typeof object !== 'object' || typeof path !== 'string') return object;
  const root = object;
  const pathArray = getPathSegments(path);
  for (let i = 0; i < pathArray.length; i++) {
    const p = pathArray[i];
    if (typeof object[p] !== 'object') object[p] = {};
    if (i === pathArray.length - 1) object[p] = value;
    object = object[p];
  }
  return root;
}
;
export function deleteDotProp(object, path) {
  if (!isObj(object) || typeof path !== 'string') return;
  const pathArray = getPathSegments(path);
  for (let i = 0; i < pathArray.length; i++) {
    const p = pathArray[i];
    if (i === pathArray.length - 1) {
      delete object[p];
      return;
    }
    object = object[p];
    if (!isObj(object)) return;
  }
}
;
export function hasDotProp(object, path) {
  if (!isObj(object) || typeof path !== 'string') return false;
  const pathArray = getPathSegments(path);
  if (pathArray.length === 0) return false;
  for (let i = 0; i < pathArray.length; i++) {
    if (isObj(object)) {
      if (!(pathArray[i] in object)) return false;
      object = object[pathArray[i]];
    } else {
      return false;
    }
  }
  return true;
}
;