import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, Renderer2, SimpleChanges, ViewContainerRef } from "@angular/core";
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared';
import { getAllParentElements, getScrollParent } from '@core/frontend-shared/utils/dom';
import { ActionQueue, ActionQueueBehavior, ActionQueueHandlerCompleteOn, SubscriptionManager } from '@core/shared';
import { arrow, autoPlacement, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { BehaviorSubject, Subject, combineLatest, concat, debounceTime, defer, from, last, map, of, switchMap, take, timer } from 'rxjs';
import { TooltipPlacements } from './interfaces';
import { DOCUMENT } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
const _c0 = ["contentRef"];
const _c1 = ["arrow"];
function TooltipContent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.htmlContent, i0.ɵɵsanitizeHtml);
  }
}
function TooltipContent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
function TooltipContent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 7, 8);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r3.options.arrowClasses || "");
  }
}
const _c2 = ["*"];
const baseOptions = {
  ariaRole: "uiTooltip",
  ariaDescribe: "",
  hideOnClickOutside: true,
  hideOnMouseLeave: false,
  hideOnScroll: false,
  disableAnimation: false,
  showArrow: true,
  enableFlip: true,
  showDelay: 0,
  hideDelay: 0,
  appendTo: undefined,
  offset: 6,
  placement: TooltipPlacements.AUTO,
  middlewares: []
};
class HideAction {
  constructor(config) {
    this.config = config;
  }
}
class ShowAction {
  constructor(config) {
    this.config = config;
  }
}
class UpdateAction {}
let TooltipContent = class TooltipContent {
  set enabled(enabled) {
    this.updateStatus({
      disabled: !enabled
    });
  }
  get status() {
    return this.status$.getValue();
  }
  constructor(elRef, renderer, vcr, cd, document) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.vcr = vcr;
    this.cd = cd;
    this.document = document;
    // custom bindings
    this.theme = 'default';
    this.size = 'default';
    this.options = {
      ...baseOptions
    };
    this.onHide = new EventEmitter();
    this.onShow = new EventEmitter();
    // @Output("onUpdateTooltip") onUpdate: EventEmitter<any> = new EventEmitter<any>();
    this.status$ = new BehaviorSubject({
      disabled: false,
      visible: false
    });
    this.debouncedUpdate$ = new Subject();
    this.actions = new ActionQueue({
      behavior: ActionQueueBehavior.abortPrevious,
      handlerCompleteOn: ActionQueueHandlerCompleteOn.completedObservable
    });
    this.state$ = combineLatest({
      status: this.status$,
      executingAction: this.actions.currentAction$
    }).pipe(map(data => {
      return {
        ...data.status,
        executingAction: data.executingAction
      };
    }));
    this.isMouseOver = false;
    this.temporaryListeners = new SubscriptionManager();
    this.actions.registerHandler(HideAction, this.action_hide.bind(this));
    this.actions.registerHandler(ShowAction, this.action_show.bind(this));
    this.actions.registerHandler(UpdateAction, this.action_update.bind(this));
    this.debouncedUpdate$.pipe(debounceTime(50), takeWhileAlive(this)).subscribe(() => {
      console.log('exec debounced update');
      this._update();
    });
  }
  ngOnInit() {
    this.options = {
      ...baseOptions,
      ...this._options
    };
  }
  ngAfterViewInit() {
    this.floatingUiConfig = this.createFloatingUiConfig(); // execute again now that ViewRefs are available
    if (this.options.autoShow) this.show({
      trigger: 'auto'
    });
  }
  ngOnChanges(changes) {
    if (changes['_options']) {
      this.options = {
        ...baseOptions,
        ...changes['_options'].currentValue
      };
      if (!changes['_options'].isFirstChange) {
        // depends on elelemtRefs, so it must not execute on initial change!
        this.floatingUiConfig = this.createFloatingUiConfig();
      }
    }
  }
  // ========== STATE + SETUP ==============================================================
  updateStatus(patch) {
    const currStatus = this.status$.getValue();
    const newStatus = {
      ...currStatus,
      ...patch
    };
    this.status$.next(newStatus);
  }
  // ========== HIDE+SHOW ==============================================================
  hide(config = {}) {
    this.actions.dispatch(new HideAction(config));
  }
  show(config = {}) {
    this.actions.dispatch(new ShowAction(config));
  }
  toggle(config = {}) {
    this.state$.pipe(take(1)).subscribe(state => {
      const isShown = state.visible || state.executingAction instanceof ShowAction;
      isShown ? this.hide(config) : this.show(config);
    });
  }
  // ========== STATE ACTIONS ==============================================================
  action_hide(action, abort$) {
    return this.state$.pipe(take(1), switchMap(state => {
      if (state.disabled) return of(null);
      if (!state.visible) return of(null);
      const initialDelay = action.config.immediate ? 0 : this.options.hideDelay;
      // TODO: concat is not the correct choice. we need a "chain" operator that is stoppable and passes data between steps.
      // there still is a conceptual issue with aborting right in the middle of an animation cause these are not controlled by observable...
      return concat(timer(initialDelay), defer(() => new Promise((res, rej) => {
        /**
         * hideOnClickOutsideHandler listens to document click event.
         * So if something bubbles up from inside the tooltip, this event will execute.
         * Means, we need to check if the click target element was inside the popup or not.
         * Issue: Things like ui-input-dropdown will open itself a popup that is appended to body...
         * ... so it "looks" as if we clicked inside but in DOM it is a totally separate element.
         */
        const target = this.vcr.element.nativeElement;
        const targetIsInsideTooltip = this.checkIfElementIsInsideTooltip(target);
        const targetIsInsidePrimeOverlay = this.checkIfRenderedInsidePrimeOverlay();
        const mouseIsOverContent = this.checkIfMouseIsOverContent();
        const abortHiding = targetIsInsideTooltip || mouseIsOverContent || targetIsInsidePrimeOverlay;
        if (!abortHiding) {
          this.updateStatus({
            visible: false
          });
          this.onHide.emit(this);
          const el = this.contentRef.nativeElement;
          this.renderer.setStyle(el, 'display', 'none');
          if (this.options.appendTo) {
            this.elRef.nativeElement.parentNode?.removeChild(this.elRef.nativeElement);
            if (this.originalParentEl) {
              this.originalParentEl.appendChild(this.elRef.nativeElement);
              this.originalParentEl = null;
            }
          }
          this.temporaryListeners.clear();
        }
        res(void 0);
      }))).pipe(last());
    }));
  }
  action_show(action, abort$) {
    return this.state$.pipe(take(1), switchMap(state => {
      if (state.disabled) return of(null);
      if (state.visible) return of(null);
      const initialDelay = action.config.immediate ? 0 : this.options.showDelay;
      return concat(timer(initialDelay), defer(() => new Promise((res, rej) => {
        const el = this.contentRef.nativeElement;
        const appendToEl = this.options.appendTo && this.document.querySelector(this.options.appendTo);
        if (appendToEl && this.elRef.nativeElement.parentNode !== appendToEl) {
          this.originalParentEl = this.elRef.nativeElement.parentNode;
          if (this.originalParentEl) {
            this.originalParentEl.removeChild(this.elRef.nativeElement);
          }
          appendToEl.appendChild(this.elRef.nativeElement);
        }
        this.renderer.setStyle(el, 'display', 'block');
        this._update();
        this.bindTemporaryListeners();
        this.updateStatus({
          visible: true
        });
        this.onShow.emit(this);
        res(void 0);
      }))).pipe(last());
    }));
  }
  action_update(config, abort$) {
    return from(this._update());
  }
  // ========== DOM LISTENERS SETUP ==============================================================
  bindTemporaryListeners() {
    this.temporaryListeners.add(this.renderer.listen("window", "resize", () => {
      this.update(true);
    }));
    if (this.options.hideOnClickOutside) {
      this.temporaryListeners.add(this.renderer.listen("document", "click", () => this.hide({
        trigger: 'click-outside'
      })));
    }
    if (this.options.hideOnScroll) {
      this.temporaryListeners.add(this.renderer.listen(getScrollParent(this.vcr.element.nativeElement), "scroll", () => this.hide({
        trigger: 'scroll'
      })));
    }
    if (this.options.hideOnMouseLeave) {
      ["touchend", "touchcancel", "mouseleave"].forEach(eventName => {
        this.createHostElementListener(eventName, () => this.hide({
          trigger: 'mouse-leave'
        }));
      });
    }
  }
  createHostElementListener(name, handler) {
    const subscr = this.renderer.listen(this.contentRef.nativeElement, name, event => {
      event.stopPropagation();
      handler();
    });
    this.temporaryListeners.add(subscr);
  }
  // ========== UTIL ==============================================================
  checkIfElementIsInsideTooltip(el) {
    const contentEl = this.contentRef.nativeElement;
    if (!contentEl) return null;
    if (contentEl === el) return true;
    if (contentEl.contains(el)) return true;
    return false;
  }
  /**
   * check if the tooltip is located within a primeng overlay.
   * if that is true, behavior for clicking outside must be changed
   */
  checkIfRenderedInsidePrimeOverlay() {
    const parents = getAllParentElements(this.vcr.element.nativeElement);
    const overlay = parents.find(parentEl => {
      return parentEl.classList.contains('p-overlay');
    });
    return overlay ? true : false;
  }
  checkIfMouseIsOverContent() {
    // content.isMouseOver can only work if device has a mouse...
    // check for touch device and always return false for these
    const isTouchDevice = matchMedia('(hover: none)').matches;
    if (isTouchDevice) return false;
    return this.isMouseOver;
  }
  // called whenever options object changes via ngOnChanges
  createFloatingUiConfig() {
    const {
      placement,
      middlewares: extraMiddlewares,
      offset: offsetConfig,
      showArrow,
      enableFlip
    } = this.options;
    const middlewares = [];
    if (placement === TooltipPlacements.AUTO) middlewares.push(autoPlacement());else if (enableFlip) middlewares.push(flip());
    if (offsetConfig) {
      middlewares.push(offset(offsetConfig));
    }
    middlewares.push(shift({
      padding: 6
    }));
    if (showArrow && this.arrowRef?.nativeElement) {
      middlewares.push(arrow({
        element: this.arrowRef.nativeElement,
        padding: 5 // stop 5px from the edges of the floating element
      }));
    }
    if (extraMiddlewares?.length) {
      middlewares.push(...extraMiddlewares);
    }
    return {
      placement: placement === TooltipPlacements.AUTO ? undefined : placement,
      middleware: middlewares
    };
  }
  update(debounced = true) {
    if (debounced) {
      this.debouncedUpdate$.next(true);
    } else {
      return this._update();
    }
  }
  // must not be inside action_update because it must be called form inside other actions - which is not supported by ActionQueue.
  _update() {
    const tooltip = this.contentRef.nativeElement;
    const reference = this.referenceObject instanceof ElementRef ? this.referenceObject.nativeElement : this.referenceObject;
    if (!reference) throw new Error('ui-tooltip-content error: No reference element found!');
    const arrowEl = this.arrowRef?.nativeElement;
    return computePosition(reference, tooltip, this.floatingUiConfig).then(({
      x,
      y,
      placement,
      middlewareData
    }) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`
      });
      if (middlewareData.arrow) {
        // Accessing arrow data
        const {
          x: arrowX,
          y: arrowY
        } = middlewareData.arrow;
        const invertedSide = this.getInvertedSide(placement);
        this.renderer.removeClass(arrowEl, 'position-left');
        this.renderer.removeClass(arrowEl, 'position-top');
        this.renderer.removeClass(arrowEl, 'position-right');
        this.renderer.removeClass(arrowEl, 'position-bottom');
        this.renderer.addClass(arrowEl, 'position-' + invertedSide);
        Object.assign(arrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : ''
        });
      }
    });
  }
  getInvertedSide(placement) {
    const primarySide = placement.split('-')[0]; // may be like "right" or like "top-start", get first part
    const invertedSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right'
    }[primarySide];
    return invertedSide;
  }
  onMouseOver() {
    this.isMouseOver = true;
  }
  onMouseLeave() {
    this.isMouseOver = false;
  }
  ngOnDestroy() {
    this.hide();
    this.temporaryListeners.clear();
    this.vcr?.detach();
    if (this.elRef?.nativeElement?.parentNode) {
      this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
    }
  }
  static {
    this.ɵfac = function TooltipContent_Factory(t) {
      return new (t || TooltipContent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: TooltipContent,
      selectors: [["ui-tooltip-content"]],
      viewQuery: function TooltipContent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentRef = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.arrowRef = _t.first);
        }
      },
      hostBindings: function TooltipContent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseover", function TooltipContent_mouseover_HostBindingHandler() {
            return ctx.onMouseOver();
          })("mouseleave", function TooltipContent_mouseleave_HostBindingHandler() {
            return ctx.onMouseLeave();
          });
        }
      },
      inputs: {
        theme: "theme",
        size: "size",
        enabled: "enabled",
        referenceObject: "referenceObject",
        htmlContent: "htmlContent",
        _options: [i0.ɵɵInputFlags.None, "uiTooltipOptions", "_options"]
      },
      outputs: {
        onHide: "onHideTooltip",
        onShow: "onShowTooltip"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c2,
      decls: 5,
      vars: 8,
      consts: [[3, "ngClass"], ["contentRef", ""], ["class", "tooltip-inner", 3, "innerHTML", 4, "ngIf"], ["class", "tooltip-inner", 4, "ngIf"], ["class", "arrow", 3, "ngClass", 4, "ngIf"], [1, "tooltip-inner", 3, "innerHTML"], [1, "tooltip-inner"], [1, "arrow", 3, "ngClass"], ["arrow", ""]],
      template: function TooltipContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0, 1);
          i0.ɵɵtemplate(2, TooltipContent_div_2_Template, 1, 1, "div", 2)(3, TooltipContent_div_3_Template, 2, 0, "div", 3)(4, TooltipContent_div_4_Template, 2, 1, "div", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap("tooltip theme-" + ctx.theme + " size-" + ctx.size);
          i0.ɵɵclassProp("animation", !ctx.options.disableAnimation);
          i0.ɵɵproperty("ngClass", ctx.options.contentClasses || "");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.htmlContent);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.htmlContent);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.options.showArrow);
        }
      },
      dependencies: [i1.NgClass, i1.NgIf],
      styles: [".tooltip{--tooltip-bg: var(--softGreyColor);--tooltip-text: var(--textColor);--tooltip-border: var(--tooltip-bg);--tooltip-padding:8px 10px;--tooltip-fontSize:1rem;--tooltip-maxWidth:350px}.tooltip.theme-alert{--tooltip-bg: rgb(117, 23, 36);--tooltip-text: rgb(255, 255, 255)}.tooltip.theme-contrast{--tooltip-bg: var(--contrastColor);--tooltip-text: var(--contrastTextColor)}.tooltip.theme-primary{--tooltip-bg: var(--primaryColor);--tooltip-text: var(--primaryTextColor)}.tooltip.size-small{--tooltip-padding:3px 8px;--tooltip-fontSize:.8rem;--tooltip-maxWidth:220px}.tooltip.size-mini{--tooltip-padding:3px 8px;--tooltip-fontSize:.8rem;--tooltip-maxWidth:150px}.tooltip{display:none;position:absolute;border-radius:3px}.tooltip{background:var(--tooltip-bg);color:var(--tooltip-text);border:1px solid var(--tooltip-border);max-width:var(--tooltip-maxWidth);box-shadow:var(--inputOverlayShadow);padding:var(--tooltip-padding);font-size:var(--tooltip-fontSize);z-index:100}.tooltip.animation{animation:tooltip-fadeIn .15s ease-out}.tooltip>.arrow{width:0;height:0;position:absolute;margin:0;border:8px solid transparent}.tooltip>.arrow.position-top{border-bottom-color:var(--tooltip-border);top:-16px}.tooltip>.arrow.position-bottom{border-top-color:var(--tooltip-border);bottom:-16px}.tooltip>.arrow.position-left{border-right-color:var(--tooltip-border);left:-16px}.tooltip>.arrow.position-right{border-left-color:var(--tooltip-border);right:-16px}@media (max-width: 479px){.tooltip{max-width:90vw;max-width:calc(100vw - 56px)}}@keyframes tooltip-fadeIn{0%{display:block;opacity:0}1%{display:block;opacity:0}to{display:block;opacity:1}}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TooltipContent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ElementRef, Renderer2, ViewContainerRef, ChangeDetectorRef, Document])], TooltipContent);
export { TooltipContent };