import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, EventEmitter, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { MediaUploaderService, OnItemConflict, OnItemProgress, OnItemSuccess } from '../../uploader';
import { CommonModule } from '@angular/common';
import { FileDropZoneComponent } from '../drop-zone/drop-zone.component';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiIconsModule } from '@common/ui/frontend-shared/icons';
import { UiSpinnerModule } from '@common/ui/frontend-shared/spinner';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
function FileUploaderComponent_div_1_div_2_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Ziehe Dateien hier hin, um sie hochzuladen.");
    i0.ɵɵelement(2, "br");
    i0.ɵɵelementStart(3, "small");
    i0.ɵɵtext(4, "Klicke, um einen Auswahldialog zu \u00F6ffnen.");
    i0.ɵɵelementEnd()();
  }
}
function FileUploaderComponent_div_1_div_2_div_2_p_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p")(1, "button", 9);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_2_p_5_Template_button_click_1_listener($event) {
      i0.ɵɵrestoreView(_r17);
      const ctrl_r3 = i0.ɵɵnextContext(3).$implicit;
      ctrl_r3.upload();
      return i0.ɵɵresetView($event.stopPropagation());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 10);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_2_p_5_Template_button_click_2_listener($event) {
      i0.ɵɵrestoreView(_r17);
      const ctrl_r3 = i0.ɵɵnextContext(3).$implicit;
      ctrl_r3.clear();
      return i0.ɵɵresetView($event.stopPropagation());
    });
    i0.ɵɵelementEnd()();
  }
}
function FileUploaderComponent_div_1_div_2_div_2_button_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_2_button_6_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctrl_r3 = i0.ɵɵnextContext(3).$implicit;
      ctrl_r3.clear();
      return i0.ɵɵresetView($event.stopPropagation());
    });
    i0.ɵɵelementEnd();
  }
}
function FileUploaderComponent_div_1_div_2_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "p");
    i0.ɵɵtext(2, " Gew\u00E4hlte Datei:");
    i0.ɵɵelement(3, "br");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, FileUploaderComponent_div_1_div_2_div_2_p_5_Template, 3, 0, "p", 4)(6, FileUploaderComponent_div_1_div_2_div_2_button_6_Template, 1, 0, "button", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctrl_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctrl_r3.item.file.name, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctrl_r3.options.uploadStart !== "none");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctrl_r3.options.uploadStart === "none");
  }
}
function FileUploaderComponent_div_1_div_2_div_3_p_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r27 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p")(1, "button", 10);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_3_p_2_Template_button_click_1_listener($event) {
      i0.ɵɵrestoreView(_r27);
      const ctrl_r3 = i0.ɵɵnextContext(3).$implicit;
      ctrl_r3.clear();
      return i0.ɵɵresetView($event.stopPropagation());
    });
    i0.ɵɵelementEnd()();
  }
}
function FileUploaderComponent_div_1_div_2_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "ui-spinner", 12);
    i0.ɵɵtemplate(2, FileUploaderComponent_div_1_div_2_div_3_p_2_Template, 2, 0, "p", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctrl_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("inline", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctrl_r3.options.uploadStart !== "none");
  }
}
function FileUploaderComponent_div_1_div_2_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Upload erfolgreich. ");
    i0.ɵɵelementEnd();
  }
}
const _c0 = a0 => ({
  "width": a0
});
function FileUploaderComponent_div_1_div_2_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelement(1, "div", 14);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c0, i0.ɵɵpipeBind1(2, 1, ctx_r10.uploadProgress$) + "%"));
  }
}
function FileUploaderComponent_div_1_div_2_div_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r31 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15)(1, "p");
    i0.ɵɵelement(2, "fa-icon", 16);
    i0.ɵɵtext(3, " Die Datei konnte nicht hochgeladen werden. ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "p")(5, "button", 17);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_6_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r31);
      const ctrl_r3 = i0.ɵɵnextContext(2).$implicit;
      return i0.ɵɵresetView(ctrl_r3.clear());
    });
    i0.ɵɵelementEnd()()();
  }
}
function FileUploaderComponent_div_1_div_2_div_7_p_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r34 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p", 20)(1, "button", 21);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_7_p_6_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r34);
      const ctx_r33 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r33.handleConflict("existing"));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 22);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_7_p_6_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r34);
      const ctx_r35 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r35.handleConflict("overwrite"));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 23);
    i0.ɵɵlistener("click", function FileUploaderComponent_div_1_div_2_div_7_p_6_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r34);
      const ctrl_r3 = i0.ɵɵnextContext(3).$implicit;
      return i0.ɵɵresetView(ctrl_r3.clear());
    });
    i0.ɵɵelementEnd()();
  }
}
function FileUploaderComponent_div_1_div_2_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18)(1, "p");
    i0.ɵɵelement(2, "fa-icon", 16);
    i0.ɵɵtext(3, " Die Datei existiert bereits:");
    i0.ɵɵelement(4, "br");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, FileUploaderComponent_div_1_div_2_div_7_p_6_Template, 4, 0, "p", 19);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctrl_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", ctrl_r3.item.file.name, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctrl_r3.options.uploadStart !== "none");
  }
}
function FileUploaderComponent_div_1_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, FileUploaderComponent_div_1_div_2_div_1_Template, 5, 0, "div", 4)(2, FileUploaderComponent_div_1_div_2_div_2_Template, 7, 3, "div", 4)(3, FileUploaderComponent_div_1_div_2_div_3_Template, 3, 2, "div", 4)(4, FileUploaderComponent_div_1_div_2_div_4_Template, 2, 0, "div", 4)(5, FileUploaderComponent_div_1_div_2_div_5_Template, 3, 5, "div", 5)(6, FileUploaderComponent_div_1_div_2_div_6_Template, 6, 0, "div", 6)(7, FileUploaderComponent_div_1_div_2_div_7_Template, 7, 2, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const state_r5 = ctx.ngIf;
    const ctrl_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "idle" && !ctrl_r3.item);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "idle" && ctrl_r3.item);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "uploading");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "completed");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "uploading");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "error");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r5 === "conflict");
  }
}
const _c1 = (a0, a1) => ({
  "nv-file-over": a0,
  "nv-file-reject": a1
});
function FileUploaderComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r41 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "div", 3);
    i0.ɵɵlistener("fileOver", function FileUploaderComponent_div_1_Template_div_fileOver_1_listener($event) {
      i0.ɵɵrestoreView(_r41);
      const ctx_r40 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r40.fileOverBase($event));
    })("fileValid", function FileUploaderComponent_div_1_Template_div_fileValid_1_listener($event) {
      i0.ɵɵrestoreView(_r41);
      const ctx_r42 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r42.fileOverBaseValid($event));
    });
    i0.ɵɵtemplate(2, FileUploaderComponent_div_1_div_2_Template, 8, 7, "div", 4);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctrl_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c1, ctx_r0.draggingFileOverDropzone, ctx_r0.draggingFileOverDropzone && !ctx_r0.draggingValidFileType))("controller", ctrl_r3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 3, ctrl_r3.state$));
  }
}
function FileUploaderComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ui-spinner", 24);
  }
}
let FileUploaderComponent = class FileUploaderComponent {
  set uploader(u) {
    this.uploaderService.set(u);
    u.ready$.pipe(takeWhileAlive(this)).subscribe(ready => {
      this.bindToUploaderService(u, ready);
    });
  }
  constructor(cd) {
    this.cd = cd;
    // @Input() uploaderLabel = 'Ziehe Dateien hier hin, um sie hochzuladen.';
    this.abortButton = true;
    this.fileInfo = new EventEmitter();
    this.draggingFileOverDropzone = false;
    this.draggingValidFileType = false;
    this.uploadProgress$ = new BehaviorSubject(0);
    this.controller = signal(null);
    this.uploaderService = signal(null);
    this.fileInfo.subscribe(response => {
      this.reset();
    });
  }
  bindToUploaderService(service, readyState) {
    if (readyState) {
      this.controller.set(service.controller);
      if (this.controllerSubscription) this.controllerSubscription.unsubscribe();
      this.controllerSubscription = service.controller.events.pipe(takeWhileAlive(this)).subscribe(event => {
        if (event instanceof OnItemProgress) {
          this.uploadProgress$.next(event.progress);
        }
        if (event instanceof OnItemSuccess) {
          this.fileInfo.emit(event.asset);
        } else if (event instanceof OnItemConflict) {
          this.conflictingFileInfo = event.fileInfo;
        }
        this.cd.markForCheck();
      });
    } else {
      if (this.controllerSubscription) this.controllerSubscription.unsubscribe();
      this.controller.set(null);
    }
  }
  fileOverBase(status) {
    this.draggingFileOverDropzone = status;
  }
  fileOverBaseValid(status) {
    this.draggingValidFileType = status;
  }
  fileDropped(files) {
    this.fileInfo.emit(files[0]);
  }
  // @ViewChild('fileInput',{static:true}) public fileInput:ElementRef;
  handleConflict(action) {
    if (action === 'existing') {
      this.fileInfo.emit(this.conflictingFileInfo);
    } else {
      this.controller()?.upload(true);
    }
  }
  reset() {
    this.conflictingFileInfo = false;
    this.controller()?.clear();
  }
  upload(e) {
    e.stopPropagation();
    this.controller()?.upload();
  }
  static {
    this.ɵfac = function FileUploaderComponent_Factory(t) {
      return new (t || FileUploaderComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: FileUploaderComponent,
      selectors: [["media-file-uploader"]],
      inputs: {
        abortButton: "abortButton",
        uploader: "uploader"
      },
      outputs: {
        fileInfo: "fileInfo"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 2,
      consts: [[1, "wrapper"], [4, "ngIf", "ngIfElse"], ["loading", ""], ["fileDropZone", "", 1, "drop-zone", 3, "ngClass", "controller", "fileOver", "fileValid"], [4, "ngIf"], ["class", "progress", 4, "ngIf"], ["class", "error", 4, "ngIf"], ["class", "conflict", 4, "ngIf"], ["uiButton", "", "title", "Abbruch", "icon", "times", "class", "button-top-right ui-button-secondary small interactive", 3, "click", 4, "ngIf"], ["uiButton", "", "label", "Hochladen", 1, "ui-button-primary", "interactive", 3, "click"], ["uiButton", "", "label", "Abbruch", 1, "ui-button-secondary", "interactive", 3, "click"], ["uiButton", "", "title", "Abbruch", "icon", "times", 1, "button-top-right", "ui-button-secondary", "small", "interactive", 3, "click"], ["label", "Datei wird hochgeladen...", 3, "inline"], [1, "progress"], ["role", "progressbar", 1, "progress-bar", 3, "ngStyle"], [1, "error"], ["icon", "exclamation-triangle"], ["uiButton", "", "label", "Andere Datei w\u00E4hlen", 1, "ui-button-primary", "interactive", 3, "click"], [1, "conflict"], ["class", "interactive", 4, "ngIf"], [1, "interactive"], ["uiButton", "", "label", "Bestehende verwenden", "item", "check", 1, "ui-button-primary", 3, "click"], ["uiButton", "", "label", "\u00DCberschreiben", 1, "ui-button-danger", 3, "click"], ["uiButton", "", "label", "Abbrechen", 1, "ui-button-secondary", 3, "click"], ["label", "Warte auf Upload-Controller"]],
      template: function FileUploaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, FileUploaderComponent_div_1_Template, 4, 8, "div", 1)(2, FileUploaderComponent_ng_template_2_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const _r2 = i0.ɵɵreference(3);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.controller())("ngIfElse", _r2);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgStyle, i1.AsyncPipe, FileDropZoneComponent, UiButtonModule, i2.UiButtonComponent, UiIconsModule, i3.FaIconComponent, UiSpinnerModule, i4.SpinnerComponent],
      styles: [".wrapper[_ngcontent-%COMP%]{position:relative}.drop-zone[_ngcontent-%COMP%]{border:dotted 3px lightgray;min-height:110px;position:relative;z-index:2;display:flex;align-items:center;justify-content:center;text-align:center;transition:border .2s}.drop-zone[_ngcontent-%COMP%]:hover{cursor:pointer;border-color:#aaa}.drop-zone.nv-file-over[_ngcontent-%COMP%]{border:solid 3px #2b8eeb}.drop-zone.nv-file-reject[_ngcontent-%COMP%]{border:solid 3px hsl(337,70%,42%)!important;background:#bdbdbd;cursor:no-drop!important}.drop-zone[_ngcontent-%COMP%]   .button-top-right[_ngcontent-%COMP%]{position:absolute;top:5px;right:5px;z-index:10}.drop-zone[_ngcontent-%COMP%]   p[_ngcontent-%COMP%], .overlay[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin:0}.drop-zone[_ngcontent-%COMP%]   p[_ngcontent-%COMP%] + p[_ngcontent-%COMP%], .overlay[_ngcontent-%COMP%]   p[_ngcontent-%COMP%] + p[_ngcontent-%COMP%]{margin-top:12px}.progress[_ngcontent-%COMP%]{background:transparent;margin:0;position:absolute;inset:4px;opacity:.2}.progress[_ngcontent-%COMP%]   .progress-bar[_ngcontent-%COMP%]{width:0;height:100%;background:#1e4966;transition:width 1s}.overlay[_ngcontent-%COMP%]{margin:0;position:absolute;inset:0;opacity:1;z-index:5;border:2px solid --inputErrorBorderColor;display:flex;align-items:center;justify-content:center;flex-direction:column;background-color:var(--panelContentBgColor)}"]
    });
  }
};
FileUploaderComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef])], FileUploaderComponent);
export { FileUploaderComponent };