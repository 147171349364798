import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, computed, effect, input, signal } from '@angular/core';
import { MediaAssetErrorCode, MediaAssetType, getMediaAssetErrorCodeDescription } from '@common/media/shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiContentCenteredModule } from '@common/ui/frontend-shared/content-centered';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { UiSpinnerModule } from '@common/ui/frontend-shared/spinner';
import { UiVideoPlayerModule } from '@common/ui/frontend-shared/video-player';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared';
import { MediaAssetProvidersFrontendService } from '../provider';
import { MediaIconsModule } from '../media-icons.module';
import * as i0 from "@angular/core";
import * as i1 from "../provider";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../ui/frontend-shared/src/lib/video-player/vime-player";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i8 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
function MediaAssetPreviewComponent_ng_template_1_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered", 4);
    i0.ɵɵelement(1, "ui-spinner");
    i0.ɵɵelementEnd();
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementStart(2, "p", 9)(3, "small");
    i0.ɵɵtext(4, "In Verarbeitung");
    i0.ɵɵelementEnd()()();
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_2_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_2_ng_template_5_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r9.clickForceLoad());
    });
    i0.ɵɵelementEnd();
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "fa-icon", 10);
    i0.ɵɵelementStart(2, "p", 9)(3, "small");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(5, MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_2_ng_template_5_Template, 1, 0, "ng-template", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctx_r6.getMediaAssetErrorCodeDescription(state_r1.error), " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.error === ctx_r6.MediaAssetErrorCode.ForeignCloud);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered", 4);
    i0.ɵɵtemplate(1, MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_1_Template, 5, 0, "ng-template", 5)(2, MediaAssetPreviewComponent_ng_template_1_ng_template_1_ng_template_2_Template, 6, 2, "ng-template", null, 6, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r7 = i0.ɵɵreference(3);
    const state_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.error === ctx_r3.MediaAssetErrorCode.NotReady)("ngIfElse", _r7);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ui-video-player", 12);
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("url", state_r1.preview.url)("stretch", false);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 13);
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("src", state_r1.preview.url, i0.ɵɵsanitizeUrl);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_2_fa_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 16);
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("icon", state_r1.preview.icon);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered")(1, "div", 14);
    i0.ɵɵtemplate(2, MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_2_fa_icon_2_Template, 1, 1, "fa-icon", 15);
    i0.ɵɵtext(3, " Keine Vorschau m\u00F6glich. ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", state_r1.preview.icon);
  }
}
function MediaAssetPreviewComponent_ng_template_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_0_Template, 1, 2, "ng-template", 3)(1, MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_1_Template, 1, 1, "ng-template", 3)(2, MediaAssetPreviewComponent_ng_template_1_ng_template_2_ng_template_2_Template, 4, 1, "ng-template", 3);
  }
  if (rf & 2) {
    const state_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", state_r1.preview.type === ctx_r4.MediaAssetType.video);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.preview.type === ctx_r4.MediaAssetType.image);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.preview.type === ctx_r4.MediaAssetType.document || state_r1.preview.type === ctx_r4.MediaAssetType.raw);
  }
}
function MediaAssetPreviewComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MediaAssetPreviewComponent_ng_template_1_ng_template_0_Template, 2, 0, "ng-template", 2)(1, MediaAssetPreviewComponent_ng_template_1_ng_template_1_Template, 4, 2, "ng-template", 3)(2, MediaAssetPreviewComponent_ng_template_1_ng_template_2_Template, 3, 3, "ng-template", 3);
  }
  if (rf & 2) {
    const state_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", state_r1.loading);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.error);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", state_r1.preview);
  }
}
let MediaAssetPreviewComponent = class MediaAssetPreviewComponent {
  constructor(cd, mediaProviders) {
    this.cd = cd;
    this.mediaProviders = mediaProviders;
    this.previewSize = input('thumb');
    this.layout = input({
      type: "fit"
    });
    this.layoutStyle = input({});
    this.layoutStyleCssVars = computed(() => {
      let out = '';
      const style = this.layoutStyle();
      function asDimension(input) {
        if (typeof input === 'string') return input;else return input + 'px';
      }
      if (style.height) out += ' --height:' + asDimension(style.height) + '; ';
      if (style.minHeight) out += ' --minHeight:' + asDimension(style.minHeight) + '; ';
      if (style.maxHeight) out += ' --maxHeight:' + asDimension(style.maxHeight) + '; ';
      return out;
    });
    this.layoutClasses = computed(() => {
      const config = this.layout();
      let classes = 'layout-' + config.type;
      if (config.type === 'ratio') {
        classes += 'ratio-' + config.ratio;
      }
      return classes;
    });
    this.enableVideoPlayer = input(false);
    this.asset = input(null);
    this.provider = computed(() => {
      const asset = this.asset();
      if (!asset) return null;
      return this.mediaProviders.getProvider(asset);
    });
    this.MediaAssetType = MediaAssetType;
    this.MediaAssetErrorCode = MediaAssetErrorCode;
    this.getMediaAssetErrorCodeDescription = getMediaAssetErrorCodeDescription;
    this.state = signal({
      loading: true
    });
    this._stateUpdates = effect(() => {
      const p = this.provider();
      const asset = this.asset();
      // if(asset.directUrl===null) console.log('Got NULL directUrl for assetId '+asset.id)
      if (!p || !asset) return;
      if (!asset.directUrl) {
        this.state.set({
          loading: false,
          error: MediaAssetErrorCode.Invalid
        });
      } else if (!asset.ready) {
        this.state.set({
          loading: false,
          error: MediaAssetErrorCode.NotReady
        });
      } else {
        const preview = p.getPreviewInfo(asset, this.enableVideoPlayer(), this.previewSize());
        this.state.set({
          loading: false,
          preview
        });
      }
    }, {
      allowSignalWrites: true
    });
  }
  resetState() {
    this.state.set({
      loading: true
    });
  }
  static {
    this.ɵfac = function MediaAssetPreviewComponent_Factory(t) {
      return new (t || MediaAssetPreviewComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.MediaAssetProvidersFrontendService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetPreviewComponent,
      selectors: [["media-asset-preview"]],
      inputs: {
        previewSize: [i0.ɵɵInputFlags.SignalBased, "previewSize"],
        layout: [i0.ɵɵInputFlags.SignalBased, "layout"],
        layoutStyle: [i0.ɵɵInputFlags.SignalBased, "layoutStyle"],
        enableVideoPlayer: [i0.ɵɵInputFlags.SignalBased, "enableVideoPlayer"],
        asset: [i0.ɵɵInputFlags.SignalBased, "asset"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([]), i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 4,
      consts: [[1, "wrapper", 3, "ngClass"], [3, "ngLet"], ["class", "loading", 3, "ngIf"], [3, "ngIf"], [1, "other-content"], [3, "ngIf", "ngIfElse"], ["displayError", ""], [1, "ui-text-center"], ["icon", "hourglass-half", "size", "2x"], [1, "ui-margin-remove"], ["icon", "exclamation-triangle", "size", "2x"], ["uiButton", "", "label", "Trotzdem laden", 1, "ui-button-outline", 3, "click"], [3, "url", "stretch"], [3, "src"], [1, "preview-other"], ["size", "2x", 3, "icon", 4, "ngIf"], ["size", "2x", 3, "icon"]],
      template: function MediaAssetPreviewComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, MediaAssetPreviewComponent_ng_template_1_Template, 3, 3, "ng-template", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleMap(ctx.layoutStyleCssVars());
          i0.ɵɵproperty("ngClass", ctx.layoutClasses());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngLet", ctx.state());
        }
      },
      dependencies: [CommonModule, i2.NgClass, i2.NgIf, UiVideoPlayerModule, i3.UiVideoPlayerComponent, MediaIconsModule, i4.FaIconComponent, UiSpinnerModule, i5.SpinnerComponent, UiContentCenteredModule, i6.UiContentCenteredComponent, UiMessageModule, UiButtonModule, i7.UiButtonComponent, UtilsFrontendSharedModule, i8.NgLetDirective],
      styles: [".wrapper[_ngcontent-%COMP%]{overflow:hidden;position:relative;background:var(--backgroundSoftColor)}.wrapper[_ngcontent-%COMP%], .wrapper[_ngcontent-%COMP%]   .other-content[_ngcontent-%COMP%]{height:var(--height);max-height:var(--maxHeight);min-height:var(--minHeight)}.wrapper[_ngcontent-%COMP%]   ui-content-centered[_ngcontent-%COMP%]{position:absolute}.wrapper.layout-fit[_ngcontent-%COMP%], .wrapper.layout-fit[_ngcontent-%COMP%]   .other-content[_ngcontent-%COMP%]{position:absolute;inset:0}.wrapper.layout-fit[_ngcontent-%COMP%]   ui-video-player[_ngcontent-%COMP%]{position:absolute;inset:0;object-fit:contain;object-position:top left}.wrapper.layout-fit[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{position:absolute;inset:0;object-fit:cover;object-position:center top;height:100%;width:100%}.wrapper.layout-block[_ngcontent-%COMP%]{--min-height:100px }.wrapper.layout-block[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{min-width:80px;width:100%;margin:0;display:block;object-fit:contain;object-position:center center}.wrapper.layout-block[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{height:var(--height);max-height:var(--maxHeight);min-height:var(--minHeight)}.wrapper.layout-block[_ngcontent-%COMP%]   ui-video-player[_ngcontent-%COMP%]{height:var(--height);width:calc(var(--height) * 16 / 9);margin:0 auto}.unsupported[_ngcontent-%COMP%]{position:absolute;inset:10px;background:var(--backgroundSoftColor);display:flex;align-items:center;justify-content:center;text-align:left;flex-direction:row}.unsupported[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{margin:0 10px}"],
      changeDetection: 0
    });
  }
};
MediaAssetPreviewComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, MediaAssetProvidersFrontendService])], MediaAssetPreviewComponent);
export { MediaAssetPreviewComponent };