import { __decorate } from "tslib";
import { EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { BaseFormService } from "../form-service/base-form.service";
/**
 * InstructionFormService does not use a typed Model, instead it auto-creates FormControls for passed instructions.
 * This means it is a bit of a dirty solution because TModel is not closely related to the form data.
 * However a hybrid approach is still  possible: Model / ModelFactory can be set, and then,
 * instead of relying on an EditorDefinition, the formGroup can be based on Instructions.
 */
let InstructionFormService = class InstructionFormService extends BaseFormService {
  constructor() {
    super(...arguments);
    this.onUpdateFormControlReadonlyState = new EventEmitter();
    // fields
    this.useFieldsToCreateFormGroup = false;
  }
  setupUsingInstructions(instructions) {
    if (!Array.isArray(instructions) || !instructions.length) {
      console.log(instructions);
      throw new Error('setupUsingInstructions: invalid value specified, must be an array with length >= 1!');
    }
    this.useFieldsToCreateFormGroup = true;
    this.instructions = instructions;
    this.buildFormGroupByInstructions();
  }
  getDefaultValue() {
    if (this.modelFactory) return this.modelFactory.newInstance();else return {};
  }
  getInitialValue() {
    if (this.initialValue) {
      if (this.modelFactory) return this.modelFactory.fromData(this.initialValue);else return this.initialValue;
    } else if (this.modelFactory) {
      return this.modelFactory.newInstance();
    } else {
      return {};
    }
  }
  buildFormGroupByInstructions() {
    const formControls = {};
    const defaults = {};
    this.instructions.forEach(instr => {
      formControls[instr.config.formControlName] = new UntypedFormControl(instr.config['value'] || null);
      defaults[instr.config.formControlName] = instr.config['value'] || null;
    });
    const form = new UntypedFormGroup(formControls);
    const fg = this.enhanceFormGroup(form);
    this.formGroup$.next(fg);
    this.initialValue = defaults;
    const value = {
      ...defaults
    };
    this.resetForm(true);
    this.reloadFormValue(value);
    this.bindFormGroupListeners();
  }
  dataIsArray() {
    return false;
  }
};
InstructionFormService = __decorate([AutoUnsubscribe()], InstructionFormService);
export { InstructionFormService };