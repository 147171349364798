import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, effect, input, signal } from '@angular/core';
import { AutoUnsubscribe } from '@core/frontend-shared';
import * as i0 from "@angular/core";
import * as i1 from "./media-grid-sort-button.component";
let MediaAssetGridSortingComponent = class MediaAssetGridSortingComponent {
  constructor(
  // protected modelFactoryProvider:ModelFactoryProvider,
  // protected itemServiceFactory:ItemServiceFactory,
  // protected mediaProviders:MediaAssetProvidersFrontendService,
  // protected http:HttpClient, 
  cd) {
    this.cd = cd;
    this.listController = input(null);
    this.ordering = signal({
      field: 'creationDate',
      order: -1
    });
    this._ordering = effect(() => {
      const ordering = this.ordering() || {
        field: null
      };
      const listController = this.listController();
      listController.updateListState({
        ordering: [ordering]
      });
      // listController.reloadItems()
    });
  }
  static {
    this.ɵfac = function MediaAssetGridSortingComponent_Factory(t) {
      return new (t || MediaAssetGridSortingComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetGridSortingComponent,
      selectors: [["media-asset-grid-sorting"]],
      inputs: {
        listController: [i0.ɵɵInputFlags.SignalBased, "listController"]
      },
      decls: 3,
      vars: 2,
      consts: [["field", "creationDate", "label", "Erstelldatum", 3, "ordering", "updateOrdering"], ["field", "name", "label", "Name", 3, "ordering", "updateOrdering"]],
      template: function MediaAssetGridSortingComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0, "Sortierung: \u00A0\n");
          i0.ɵɵelementStart(1, "media-grid-sort-button", 0);
          i0.ɵɵlistener("updateOrdering", function MediaAssetGridSortingComponent_Template_media_grid_sort_button_updateOrdering_1_listener($event) {
            return ctx.ordering.set($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "media-grid-sort-button", 1);
          i0.ɵɵlistener("updateOrdering", function MediaAssetGridSortingComponent_Template_media_grid_sort_button_updateOrdering_2_listener($event) {
            return ctx.ordering.set($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ordering", ctx.ordering());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ordering", ctx.ordering());
        }
      },
      dependencies: [i1.MediaGridSortButton],
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
MediaAssetGridSortingComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef])], MediaAssetGridSortingComponent);
export { MediaAssetGridSortingComponent };