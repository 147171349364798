import { ItemRestraints } from '@core/shared';
export class SkillsetMappingRestraints extends ItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return this.isExecutedBySystem() || this.requirePermission('ADMINISTRATOR');
  }
  canToggleItem(item) {
    return false;
  }
  canUpdateItem(item, oldItem) {
    return false; // asset mappings will always be replaced instead of updated!
  }
  canRemoveItem(item) {
    return this.isExecutedBySystem() || this.requirePermission('ADMINISTRATOR');
  }
}