import { getBaseModelFromEditorModel, isConstructor, isEditorModel } from '@core/shared';
import { getMediaAssetsMetadataForModel, getModelsWithMediaAssetsMetadata } from './media-assets.decorator';
export class MediaAssetsHelper {
  static findModelsWithMediaAssetsMetadata() {
    return getModelsWithMediaAssetsMetadata();
  }
  static getConfig(model) {
    if (!model) throw new Error('Data passed to MediaAssetsHelper is falsy. Check before passing it in!');
    const meta = getMediaAssetsMetadataForModel(model);
    if (!meta.length) throw new Error('Model ' + model + ' has no MediaAsset metadata assigned!');
    const config = meta[0].options;
    return config;
  }
  /**
   * it is allowed to access an item's MediaAssetSlotsMap directly.
   * If it is unknown where the map is stored, this API will find the map via metadata.
   */
  static getAllAssetMappings(model) {
    const config = this.getConfig(model);
    return model[config.targetProperty] || {};
  }
  static slotMapToMappingList(map) {
    const list = [];
    Object.entries(map).forEach(([slotName, mapping]) => {
      if (!mapping) return;
      mapping.consumerMediaSlot = slotName;
      list.push(mapping);
    });
    return list;
  }
  static setNewAssetMappings(model, mappings) {
    const config = this.getConfig(model);
    model[config.targetProperty] = mappings;
  }
  static getAssetBySlot(model, slotName) {
    return this.getAssetMappingBySlot(model, slotName)?.asset || null;
  }
  static getAssetMappingBySlot(model, slotName) {
    const assets = this.getAllAssetMappings(model);
    return assets[slotName] || null;
  }
  static removeMappings(model, slotName) {
    if (!slotName) {
      this.setNewAssetMappings(model, {});
    } else {
      const mappings = {
        ...this.getAllAssetMappings(model)
      };
      delete mappings[slotName];
      this.setNewAssetMappings(model, mappings);
    }
  }
  // either Entity or Item may be passed.The registry works based on ITEM classes.
  // so if an Entity Ctor is passed, we need to extract the name of parent item class.
  static getModelClassName(input) {
    if (typeof input === 'string') return input;
    let modelCtor = isConstructor(input) ? input : input.constructor;
    if (isEditorModel(modelCtor)) modelCtor = getBaseModelFromEditorModel(modelCtor);
    let name = modelCtor.name || modelCtor.constructor.name;
    if (!name) throw new Error('cannot get modelClassName of ' + modelCtor);
    if (name.endsWith('Entity')) {
      const ParentItem = Object.getPrototypeOf(modelCtor);
      name = ParentItem.name;
    }
    return name;
  }
}