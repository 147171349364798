import { EventEmitter } from '@angular/core';
import { FileLikeObject } from "../file-like-object/file-like-object.class";
import { FileItem } from '../item/file-item.class';
import { defaultUploadControllerOptions } from './controller.options';
import { OnAfterAddItem, OnAddItemFailed, OnAllItemsComplete, OnItemUpload, OnItemSuccess, OnItemError, OnItemConflict, OnItemProgress, OnItemRemoved } from '../events/events';
import { queueLimitFilter, fileSizeFilter, fileTypeFilter, mimeTypeFilter, isValidFileType } from '../filters/filters';
import { ChunkedFileItem } from '../item/chunked-file-item.class';
import { Uploader } from '../uploader/uploader.class';
import { BehaviorSubject } from 'rxjs';
export class UploadController {
  constructor(options, uploaderOptions, http) {
    this.http = http;
    this.events = new EventEmitter();
    this._state = new BehaviorSubject('idle');
    this.state$ = this._state.asObservable();
    this.setOptions(options);
    this.uploader = new Uploader(http);
    this.uploader.configurate(uploaderOptions);
  }
  setOptions(options) {
    this.options = {
      ...defaultUploadControllerOptions,
      ...options
    };
    this.options.filters.unshift({
      name: 'queueLimit',
      fn: queueLimitFilter
    });
    if (this.options.maxFileSize) this.options.filters.unshift({
      name: 'fileSize',
      fn: fileSizeFilter
    });
    if (this.options.allowedFileTypes) this.options.filters.unshift({
      name: 'fileType',
      fn: fileTypeFilter
    });
    if (this.options.allowedMimeTypes) this.options.filters.unshift({
      name: 'mimeType',
      fn: mimeTypeFilter
    });
  }
  add(file, filters) {
    if (file instanceof FileList) file = file[0];
    const fileItem = this._createFileItem(file, filters);
    if (fileItem) {
      this.item = fileItem;
      this.item.state$.subscribe(state => this._state.next(state));
      this.events.emit(new OnAfterAddItem(fileItem));
      if (this.options.uploadStart === 'immediate') {
        this.upload();
      }
    }
  }
  _createFileItem(file, filters) {
    const arrayOfFilters = this._getFilters(filters);
    const options = this.options;
    const temp = new FileLikeObject(file);
    if (this._isValidFile(temp, arrayOfFilters, options)) {
      let fileItem;
      if (options.chunkSize > 0 && temp.size > (options.chunkingFileSizeThreshold || 0)) {
        fileItem = new ChunkedFileItem(file, options, this.uploader);
      } else {
        fileItem = new FileItem(file, options, this.uploader);
      }
      fileItem.events.subscribe(event => {
        this._handleItemEvent(event);
      });
      return fileItem;
    } else {
      const filter = arrayOfFilters[this._failFilterIndex];
      this.events.emit(new OnAddItemFailed(temp, filter, options));
      return false;
    }
  }
  upload(overwrite = false) {
    this.uploader.upload(this.item, this.options, overwrite);
  }
  setCustomHeadersForCurrentFile(headers) {
    this.item.headers = headers;
  }
  cancel() {
    this.uploader.stop();
    this._state.next('idle');
    if (this.options.autoUpload) this.clear();
  }
  clear() {
    this.uploader.stop();
    if (this.item) {
      this.events.emit(new OnItemRemoved(this.item));
      this.item._onRemove();
      this.item = null;
    }
    this._state.next('idle');
  }
  destroy() {
    this.clear();
  }
  isValidFileType(file) {
    return isValidFileType(this.options, file);
  }
  _handleItemEvent(event) {
    if (event instanceof OnItemUpload) {
      this.events.emit(event);
    } else if (event instanceof OnItemProgress) {
      this.events.emit(event);
    } else if (event instanceof OnItemSuccess) {
      this.events.emit(event);
      this.events.emit(new OnAllItemsComplete());
    } else if (event instanceof OnItemError || event instanceof OnItemConflict) {
      this.events.emit(event);
    }
  }
  _getFilters(filters) {
    if (!filters) {
      return this.options.filters;
    }
    if (Array.isArray(filters)) {
      return filters;
    }
    if (typeof filters === 'string') {
      const names = filters.match(/[^\s,]+/g);
      return this.options.filters.filter(filter => names.indexOf(filter.name) !== -1);
    }
    return this.options.filters;
  }
  _isValidFile(file, filters, options) {
    this._failFilterIndex = -1;
    return !filters.length ? true : filters.every(filter => {
      this._failFilterIndex++;
      return filter.fn.call(this, file, options);
    });
  }
}