import { __decorate, __metadata } from "tslib";
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { Store } from '@ngxs/store';
import { MembershipPluginsService, RegistrationFormType } from '@common/membership/shared';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { map, take } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFieldsetModule } from '@common/forms/frontend-shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiFieldWrapperModule } from '@common/ui/frontend-shared/field-wrapper';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { UiInputTextModule } from '@common/ui/frontend-shared/input-text';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { CommonModule } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@common/forms/frontend-shared";
import * as i2 from "@ngxs/store";
import * as i3 from "@common/membership/shared";
/**
 * when this component is displayed, users should already have completed membership-type-selection questions at /login.
 * in case a user manages to go to full registration without filling in these details first,
 * we do a second check for that here and allow membership-type-selection to be re-displayed if details are missing.
 */
// TODO: deprecated Component! Only left here for later analysis as its the base class of all legacy forms!
export const RegistrationFormBaseComponentImports = [CommonModule, CoreFrontendSharedModule, UtilsFrontendSharedModule,
// all these imports can be removed when switching to instructions.
DynamicFieldsetModule, FormsModule, ReactiveFormsModule, UiButtonModule, UiInputTextModule, UiInputGroupModule, UiFieldWrapperModule, UiMessageModule];
let RegistrationFormBaseComponent = class RegistrationFormBaseComponent {
  constructor(formServiceFactory, store, pluginsService) {
    this.formServiceFactory = formServiceFactory;
    this.store = store;
    this.pluginsService = pluginsService;
    this.instructions = [];
  }
  patchFormValue() {
    if (this.formValue) this.formService.setInitialValue(this.formValue);
  }
  // if observable resolves to true this indicates form is complete and parent can continue navigation
  tryCompleteForm() {
    return this.formService.getValidityAndValue().pipe(take(1), map(info => {
      return {
        ...info,
        formType: this.formType
      };
    }));
  }
  getRegistrationFieldTitle(fieldId) {
    return fieldId;
  }
  static {
    this.ɵfac = function RegistrationFormBaseComponent_Factory(t) {
      return new (t || RegistrationFormBaseComponent)(i0.ɵɵdirectiveInject(i1.ModelFormServiceFactory), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.MembershipPluginsService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationFormBaseComponent,
      selectors: [["ng-component"]],
      inputs: {
        formValue: "formValue"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function RegistrationFormBaseComponent_Template(rf, ctx) {},
      dependencies: [CommonModule, CoreFrontendSharedModule, UtilsFrontendSharedModule,
      // all these imports can be removed when switching to instructions.
      DynamicFieldsetModule, FormsModule, ReactiveFormsModule, UiButtonModule, UiInputTextModule, UiInputGroupModule, UiFieldWrapperModule, UiMessageModule],
      encapsulation: 2
    });
  }
};
RegistrationFormBaseComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ModelFormServiceFactory, Store, MembershipPluginsService])], RegistrationFormBaseComponent);
export { RegistrationFormBaseComponent };