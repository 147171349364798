
<layout-contentbars-wrapper *ngIf="isSSR || (accessIsReady$|async)">
	<layout-contentbar-top >
		<a routerLink="" class="logo" >
			<ui-pflegetheke-logo [scaling]="64" [background]="true" openConsole></ui-pflegetheke-logo>
		</a>
		<div class="left">
			<h2 class="page-title" [innerHTML]="pageTitle$|async"></h2>
		</div>

		<div class="right">
			<feedback-button></feedback-button>
		</div>
	</layout-contentbar-top>

	<router-outlet-fullscreen></router-outlet-fullscreen>

	<layout-contentbar-bottom [fixed]="true" >
		<pflegetheke-toolbar></pflegetheke-toolbar>
	</layout-contentbar-bottom>
	
</layout-contentbars-wrapper>

<page-connection-error>
	<ui-pflegetheke-logo background="true" [scaling]="100" id="logo"></ui-pflegetheke-logo>
</page-connection-error>
<page-login-overlay></page-login-overlay>
<app-message-toast position="bottom-right" *ssrExclude></app-message-toast>
<ui-confirmDialog header="Bestätigung erforderlich" icon="exclamation-triangle"></ui-confirmDialog>
<versioncheck *ssrExclude></versioncheck>
<maintenance-announcement *ssrExclude></maintenance-announcement>
<maintenance-mode>
	<ui-pflegetheke-logo background="true" [scaling]="100" id="logo"></ui-pflegetheke-logo>
</maintenance-mode>
<tracking-consent *ssrExclude position="overlay"></tracking-consent>
<user-force-new-password *ssrExclude ></user-force-new-password>
<console-toggle></console-toggle>