import { __decorate, __metadata } from "tslib";
import { IdItemModel, Trim, Expose, ItemModelProp, EditorDefinition, CrudEndpoints, ItemModelDefinition, ModelId } from '@core/shared/model';
import { UseRestraints } from '@core/shared';
import { SkillsetRestraints } from "./skillset.restraints";
let SkillsetItem = class SkillsetItem extends IdItemModel {
  toString() {
    return "Skillset " + this.name;
  }
};
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: true
}), __metadata("design:type", Boolean)], SkillsetItem.prototype, "enabled", void 0);
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: false
}), __metadata("design:type", Boolean)], SkillsetItem.prototype, "isSystem", void 0);
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: ''
}), Trim(), __metadata("design:type", String)], SkillsetItem.prototype, "name", void 0);
__decorate([Expose(), ItemModelProp({
  defaultValueCreate: []
}), __metadata("design:type", Array)], SkillsetItem.prototype, "abilities", void 0);
SkillsetItem = __decorate([EditorDefinition({}, {
  allowAllExposed: true,
  useCrudEndpoint: 'admin'
}), CrudEndpoints({
  admin: '/admin/abilities/skillsets'
}), ItemModelDefinition({
  texts: {
    item: 'Skillset',
    items: 'Skillsets'
  }
}), UseRestraints(SkillsetRestraints), ModelId("SkillsetItem") // autogenerated by generate-model-ids
], SkillsetItem);
export { SkillsetItem };