import { LayoutInstruction } from './shared';
export class LayoutPanelInstruction extends LayoutInstruction {
  getDefaults() {
    return {
      title: null,
      toggleable: false
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      title: 'title',
      toggleable: 'toggleable',
      collapsed: 'toggleable'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/forms/frontend-shared/layout-instruction-components/panel').then(bundle => {
      return {
        component: bundle.InstructionLayoutPanelComponent,
        module: null
      };
    });
  }
}