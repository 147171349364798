import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, ElementRef, forwardRef, TemplateRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicComponentIOCheck, ModelFieldInstructionService } from '@core/frontend-shared';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { BehaviorSubject, combineLatest, map, take } from 'rxjs';
import { ModelFormServiceFactory } from '../form-service';
import { ModelBasedControlValueAccessor } from '../model-based-control-value-accessor';
import { isConstructor } from '@core/shared';
import * as i0 from "@angular/core";
import * as i1 from "../form-service";
import * as i2 from "@core/frontend-shared";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../core/frontend-shared/src/lib/dynamic-components/dynamic-component.directive";
import * as i6 from "../instruction-outlet/instruction-outlet.component";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i8 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
function SimpleFieldsetComponent_div_0_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = (a0, a1, a2, a3, a4) => ({
  formGroup: a0,
  formService: a1,
  parentFormService: a2,
  model: a3,
  data: a4
});
function SimpleFieldsetComponent_div_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, SimpleFieldsetComponent_div_0_div_1_ng_container_1_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formGroup", ctx_r3.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.innerForm)("ngTemplateOutletContext", i0.ɵɵpureFunction5(3, _c0, ctx_r3.formService.formGroup, ctx_r3.formService, ctx_r3.parentFormService, ctx_r3.formService.formGroup.getRawValue(), ctx_r3.innerFormData));
  }
}
function SimpleFieldsetComponent_div_0_ng_template_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SimpleFieldsetComponent_div_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SimpleFieldsetComponent_div_0_ng_template_2_ng_container_0_Template, 1, 0, "ng-container", 7);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("dynamicComponent", ctx_r4.innerFormComponentConfig);
  }
}
function SimpleFieldsetComponent_div_0_ng_template_3_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "instruction-outlet", 10);
  }
  if (rf & 2) {
    const instruction_r10 = ctx.$implicit;
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("instruction", instruction_r10)("formService", ctx_r9.formService);
  }
}
function SimpleFieldsetComponent_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, SimpleFieldsetComponent_div_0_ng_template_3_ng_template_1_Template, 1, 2, "ng-template", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const instructions_r8 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formGroup", ctx_r5.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", instructions_r8);
  }
}
function SimpleFieldsetComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵlistener("keyup.enter", function SimpleFieldsetComponent_div_0_Template_div_keyup_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.onEnter($event));
    })("keyup.control.enter", function SimpleFieldsetComponent_div_0_Template_div_keyup_control_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.onEnter($event));
    });
    i0.ɵɵtemplate(1, SimpleFieldsetComponent_div_0_div_1_Template, 2, 9, "div", 3)(2, SimpleFieldsetComponent_div_0_ng_template_2_Template, 1, 1, "ng-template", 4)(3, SimpleFieldsetComponent_div_0_ng_template_3_Template, 2, 2, "ng-template", 4);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.innerForm);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.innerFormComponentConfig);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 3, ctx_r0.allInstructions$));
  }
}
function SimpleFieldsetComponent_ng_template_2_small_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "small", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelement(2, "br");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" fieldset initialized: ", ctx_r14.initialized, "");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2(" formService initialized - local: ", ctx_r14.formServiceInitialized, " / origin: ", ctx_r14.formService ? ctx_r14.formService.initialized : "unknown", " ");
  }
}
function SimpleFieldsetComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered")(1, "div");
    i0.ɵɵelement(2, "ui-spinner");
    i0.ɵɵtext(3, " Editor wird initialisiert... ");
    i0.ɵɵtemplate(4, SimpleFieldsetComponent_ng_template_2_small_4_Template, 4, 3, "small", 11);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r1.isDev);
  }
}
export const SIMPLE_FIELDSET_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SimpleFieldsetComponent),
  multi: true
};
export const SIMPLE_FIELDSET_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SimpleFieldsetComponent),
  multi: true
};
/**
 * SimpleFieldset continues to support both the new ModelFormService and the fast "classic" way to create the form group by itself.
 *
 * It provides two ways to build a form: Either provide [instructions] list, or pass a custom form to [innerForm].
 */
let SimpleFieldsetComponent = class SimpleFieldsetComponent extends ModelBasedControlValueAccessor {
  set instructions(instructions) {
    this.directInstructions$.next(instructions);
  }
  get instructions() {
    console.warn('synchronous access to form instructions is deprecated. Use allInstructions$ isntead!');
    return this.directInstructions$.getValue();
  }
  constructor(cd, formServiceFactory, pluginInstructionsService) {
    super(cd, formServiceFactory);
    this.cd = cd;
    this.formServiceFactory = formServiceFactory;
    this.pluginInstructionsService = pluginInstructionsService;
    // instructions input is still used, but only FilterFieldset supports use of instructions WITHOUT Model or custom FormGroup now!
    this.directInstructions$ = new BehaviorSubject([]);
    this.pluginInstructions$ = new BehaviorSubject([]);
    this.allInstructions$ = combineLatest([this.directInstructions$, this.pluginInstructions$]).pipe(map(([staticInstructions, pluginInstructions]) => {
      return this.pluginInstructionsService.addPluginInstructionsToStaticInstructions(staticInstructions, pluginInstructions);
    }), takeWhileAlive(this));
    this.innerFormComponent = null;
    this.innerFormComponentConfig = null;
    this.innerFormData = {};
    this.submitShortcut = 'ctrl+enter';
    // if configurated, the fieldset can check for dynamic addon fields to show.
    this.contextGroups = false;
    combineLatest({
      formService: this.formService$,
      initialized: this.formServiceInitialized$
    }).pipe(takeWhileAlive(this)).subscribe(({
      initialized,
      formService
    }) => {
      if (initialized && formService) {
        if (pluginInstructionsService) {
          this.setupPluginInstructions(formService);
        }
        if (this.innerFormComponent) {
          this.setupInnerFormComponent(formService);
        }
      }
    });
  }
  setupPluginInstructions(formService) {
    const ModelConstructor = formService.modelFactory.getOriginalModel();
    this.getPluginInstructions(ModelConstructor).pipe(take(1) // if not limited take, we'd need unsubscribe/resubscribe management as formService$ could emit multiple times
    ).subscribe(pluginInstructions => {
      // if(instructions.length && this.innerForm) {
      // 	console.warn('Fieldset uses custom innerForm but received pluginInstructions as well. This is unsupported currently. pluginInstructions will not show.')
      // }
      this.pluginInstructions$.next(pluginInstructions);
    });
  }
  getPluginInstructions(ModelConstructor) {
    return this.pluginInstructionsService.getPluginInstructions(ModelConstructor, this.contextGroups || undefined, this.formService.getEditorDefinition(), null // simple fieldset does not know about ItemEditModes
    );
  }
  submit() {
    if (this.formService) this.formService.save();
  }
  setupInnerFormComponent(formService) {
    if (!this.innerFormComponent) return;
    if (!isConstructor(this.innerFormComponent)) {
      this.innerFormComponentConfig = this.innerFormComponent;
    } else {
      this.innerFormComponentConfig = {
        component: this.innerFormComponent,
        inputs: {
          formGroup: formService.formGroup,
          formService: formService,
          parentFormService: this.parentFormService,
          item: formService.getValueIncludingReadonly(),
          data: this.innerFormData
        },
        // template component has only the inputs that it needs, disable erroring in case of missing IOs
        IOCheck: DynamicComponentIOCheck.FilterUnknown
      };
    }
  }
  onEnter(event) {
    const isCtrl = event.ctrlKey;
    if (this.submitShortcut === 'ctrl+enter') {
      if (isCtrl) this.submit();
    } else {
      this.submit();
    }
  }
  static {
    this.ɵfac = function SimpleFieldsetComponent_Factory(t) {
      return new (t || SimpleFieldsetComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.ModelFormServiceFactory), i0.ɵɵdirectiveInject(i2.ModelFieldInstructionService, 8));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: SimpleFieldsetComponent,
      selectors: [["simple-fieldset"]],
      inputs: {
        instructions: "instructions",
        title: "title",
        innerForm: "innerForm",
        innerFormComponent: "innerFormComponent",
        innerFormData: "innerFormData",
        submitShortcut: "submitShortcut",
        contextGroups: "contextGroups"
      },
      features: [i0.ɵɵProvidersFeature([SIMPLE_FIELDSET_VALUE_ACCESSOR, SIMPLE_FIELDSET_VALIDATOR]), i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 4,
      consts: [[3, "keyup.enter", "keyup.control.enter", 4, "ngIf", "ngIfElse"], ["initializing", ""], [3, "keyup.enter", "keyup.control.enter"], ["class", "custom-form", 3, "formGroup", 4, "ngIf"], [3, "ngIf"], [1, "custom-form", 3, "formGroup"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "dynamicComponent"], [1, "form-fields", 3, "formGroup"], ["ngFor", "", 3, "ngForOf"], [3, "instruction", "formService"], ["style", "display:block; opacity:.5", 4, "ngIf"], [2, "display", "block", "opacity", ".5"]],
      template: function SimpleFieldsetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, SimpleFieldsetComponent_div_0_Template, 5, 5, "div", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵtemplate(2, SimpleFieldsetComponent_ng_template_2_Template, 5, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const _r2 = i0.ɵɵreference(3);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.allInitialized$))("ngIfElse", _r2);
        }
      },
      dependencies: [i3.NgForOf, i3.NgIf, i3.NgTemplateOutlet, i4.NgControlStatusGroup, i4.FormGroupDirective, i5.DynamicComponentDirective, i6.InstructionOutletComponent, i7.UiContentCenteredComponent, i8.SpinnerComponent, i3.AsyncPipe],
      styles: [".form-fields[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{margin-top:10px;display:block}.custom-form[_ngcontent-%COMP%] + .form-fields[_ngcontent-%COMP%]{margin-top:10px}\n\n/*# sourceMappingURL=simple-fieldset.component.ts-angular-inline--30.css.map*/"],
      changeDetection: 0
    });
  }
};
SimpleFieldsetComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, ModelFormServiceFactory, ModelFieldInstructionService])], SimpleFieldsetComponent);
export { SimpleFieldsetComponent };