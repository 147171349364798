import { __decorate, __metadata } from "tslib";
import { StatisticRequest, StatisticsResponse } from '@common/statistics/shared';
import { AppRequest, AppRequestMethod } from '@core/shared';
import { Expose, Type } from '@core/shared/model';
import { MembershipStatisticsQueryParams } from '../models';
let MembershipCreationStatisticsRequest = class MembershipCreationStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => MembershipStatisticsQueryParams), __metadata("design:type", MembershipStatisticsQueryParams)], MembershipCreationStatisticsRequest.prototype, "params", void 0);
MembershipCreationStatisticsRequest = __decorate([AppRequest('admin/membership', 'statistics/creation', {
  httpMethod: AppRequestMethod.POST
})], MembershipCreationStatisticsRequest);
export { MembershipCreationStatisticsRequest };
let MembershipGrowthStatisticsRequest = class MembershipGrowthStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => MembershipStatisticsQueryParams), __metadata("design:type", MembershipStatisticsQueryParams)], MembershipGrowthStatisticsRequest.prototype, "params", void 0);
MembershipGrowthStatisticsRequest = __decorate([AppRequest('admin/membership', 'statistics/growth', {
  httpMethod: AppRequestMethod.POST
})], MembershipGrowthStatisticsRequest);
export { MembershipGrowthStatisticsRequest };
let MembershipsByTypeStatisticsRequest = class MembershipsByTypeStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => MembershipStatisticsQueryParams), __metadata("design:type", MembershipStatisticsQueryParams)], MembershipsByTypeStatisticsRequest.prototype, "params", void 0);
MembershipsByTypeStatisticsRequest = __decorate([AppRequest('admin/membership', 'statistics/by-type', {
  httpMethod: AppRequestMethod.POST
})], MembershipsByTypeStatisticsRequest);
export { MembershipsByTypeStatisticsRequest };
let MembershipsByCategorizationStatisticsRequest = class MembershipsByCategorizationStatisticsRequest extends StatisticRequest {
  static {
    this.Response = StatisticsResponse;
  }
};
__decorate([Expose(), Type(() => MembershipStatisticsQueryParams), __metadata("design:type", MembershipStatisticsQueryParams)], MembershipsByCategorizationStatisticsRequest.prototype, "params", void 0);
MembershipsByCategorizationStatisticsRequest = __decorate([AppRequest('admin/membership', 'statistics/by-insurance', {
  httpMethod: AppRequestMethod.POST
})], MembershipsByCategorizationStatisticsRequest);
export { MembershipsByCategorizationStatisticsRequest };