const defaultOpts = {
  preserveCase: true,
  stripNumbers: true,
  convertUmlauts: false
};
// aims to extract human-readable words from a more technical source like URLs, file paths/names etc
export function stringToReadableString(input, options = {}) {
  const opts = {
    ...defaultOpts,
    ...options
  };
  let lowerCaseSpecialChars = 'àáâæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôòóœøōõőṕŕřßśšşșťțûùúūǘůűųẃẍÿýžźż';
  let lowerCaseReplacements = 'aaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuwxyyzzz';
  if (opts.replaceUmlauts) {
    lowerCaseSpecialChars += 'äöü';
    lowerCaseReplacements += 'aou';
  }
  const lowerCaseRegex = new RegExp(lowerCaseSpecialChars.split('').join('|'), 'g');
  let processed = input.toString();
  if (opts.preserveCase) {
    const upperCaseSpecialChars = lowerCaseSpecialChars.toUpperCase();
    const upperCaseReplacements = lowerCaseReplacements.toUpperCase();
    const upperCaseRegex = new RegExp(upperCaseSpecialChars.split('').join('|'), 'g');
    processed = processed.replace(lowerCaseRegex, c => lowerCaseReplacements.charAt(lowerCaseSpecialChars.indexOf(c))) // Replace language specific char variatioons with simple ones
    .replace(upperCaseRegex, c => upperCaseReplacements.charAt(upperCaseSpecialChars.indexOf(c))); // Replace language specific char variatioons with simple ones
  } else {
    processed = processed.toLowerCase().replace(lowerCaseRegex, c => lowerCaseReplacements.charAt(lowerCaseSpecialChars.indexOf(c))); // Replace language specific char variatioons with simple ones
  }
  processed = processed.replace(/&/g, ' and '); // Replace & with 'and'
  processed = processed.replace(/[^a-zA-Z0-9äöüÄÖÜ]+/g, ' '); // Remove all non-word characters
  if (opts.stripNumbers) {
    processed = processed.replace(/[0-9]+/g, ' '); // Remove all non-word characters
  }
  processed = processed.replace(/\s\s+/g, ' '); // replace multiple whitespaces by single whitespace
  return processed.trim();
}