import { extendsConstructor } from '../../utils';
import { customMetadataStorage } from '../custom-metadata';
import { Model } from '../model/model.class';
const MODEL_ID = 'MODEL_ID';
export function ModelId(id) {
  return function ModelIdDecorator(ItemClass) {
    if (!extendsConstructor(ItemClass, Model)) throw Error('All data models must extend Model base class!');
    // customMetadataStorage.registerItemModel(ItemClass);
    customMetadataStorage.addMetadata({
      metaType: MODEL_ID,
      target: ItemClass,
      propertyName: null,
      options: id
    });
  };
}
export function getModelId(Ctor) {
  const metas = customMetadataStorage.findMetadataOfType(MODEL_ID, Ctor);
  if (metas.length) return metas[0].options;else throw new Error('No ModelId metadata found for model class ' + Ctor.name);
}