import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalMessageHandlerService } from "@core/frontend-shared";
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { ConfigService } from '@core/shared';
import { map } from 'rxjs/operators';
import { CrudControllerFactory } from '../controller/controller-factory';
import { CrudManagerService } from './crud-manager.service';
import { DynamicDialogService } from '@common/ui/frontend-shared/dynamic-dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@core/shared";
import * as i3 from "./crud-manager.service";
import * as i4 from "@core/frontend-shared";
function CrudManager2Component_crud_manager_layout_table_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crud-manager-layout-table", 1);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("componentDisplay", i0.ɵɵpipeBind1(1, 2, ctx_r0.componentDisplay$))("infoMessage", ctx_r0.infoMessage);
  }
}
function CrudManager2Component_crud_manager_layout_list_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crud-manager-layout-list", 1);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("componentDisplay", i0.ɵɵpipeBind1(1, 2, ctx_r1.componentDisplay$))("infoMessage", ctx_r1.infoMessage);
  }
}
export const CrudManager2Template = {
  providers: [LocalMessageHandlerService, CrudManagerService, CrudControllerFactory, DynamicDialogService],
  styles: [``],
  template: `
		<crud-manager-layout-table *ngIf="config.layout.name==='table'" [componentDisplay]="componentDisplay$|async" [infoMessage]="infoMessage"></crud-manager-layout-table>
		<crud-manager-layout-list *ngIf="config.layout.name==='list'" [componentDisplay]="componentDisplay$|async" [infoMessage]="infoMessage"></crud-manager-layout-list>
	`
};
let CrudManager2Component = class CrudManager2Component {
  // DO NOT OVERRIDE. override injectExtraDependencies instead.
  constructor(cd, route, router, configService, crudManager, inject, messages) {
    this.cd = cd;
    this.route = route;
    this.router = router;
    this.configService = configService;
    this.crudManager = crudManager;
    this.inject = inject;
    this.messages = messages;
    this.displayList = true;
    this.displayEditor = false;
    this.displayBatchEditor = false;
    this.routeParamKeyForItemId = 'id';
    this.modeAndItem$ = this.crudManager.modeAndItem$;
    this.componentDisplay$ = this.modeAndItem$.pipe(map(({
      mode
    }) => {
      const list = mode === 'list';
      const batchEditor = mode === 'batch';
      return {
        list,
        batchEditor,
        editor: !list && !batchEditor
      };
    }));
    this.injectExtraDependencies(inject);
    this.setupCrudManager(); // must be executed immediately so that child components are being created with configurated CrudManagerService!
  }
  injectExtraDependencies(injector) {}
  // override to pass a number of custom template components for use in list/editor crud components
  getTemplates() {
    return {};
  }
  setupCrudManager() {
    this.crudManager.configurate(this.getConfig(), this.getModel(), this.getCrudService(), this.getTemplates(), this.router, this.route);
    this.crudManager.setFormServiceCustomizer((fs, item) => {
      if (this.customizeFormService) fs = this.customizeFormService(fs, item);
      return fs;
    });
    this.config = this.crudManager.config;
    this.service = this.crudManager.service;
    this.restraints = this.crudManager.restraints;
    this.initializeCrudManager();
  }
  getCrudService() {
    return false;
  }
  // called by CrudManagerService during FormService setup BEFORE service initialization
  customizeFormService(formService, item) {
    return formService;
  }
  // called after setup is completed and CrudManager is ready for use
  initializeCrudManager() {}
  setTitle(title) {
    this.crudManager.setPageTitle(title);
  }
  onCloseEditor($event) {
    this.crudManager.configurateModeAndItem(null);
    this.router.navigate(['./'], {
      relativeTo: this.route.parent
    });
  }
  getItemActions() {
    return this.config.itemActions;
  }
  static {
    this.ɵfac = function CrudManager2Component_Factory(t) {
      return new (t || CrudManager2Component)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.ConfigService), i0.ɵɵdirectiveInject(i3.CrudManagerService), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i4.LocalMessageHandlerService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudManager2Component,
      selectors: [["ng-component"]],
      features: [i0.ɵɵProvidersFeature(CrudManager2Template.providers)],
      decls: 2,
      vars: 2,
      consts: [[3, "componentDisplay", "infoMessage", 4, "ngIf"], [3, "componentDisplay", "infoMessage"]],
      template: function CrudManager2Component_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrudManager2Component_crud_manager_layout_table_0_Template, 2, 4, "crud-manager-layout-table", 0)(1, CrudManager2Component_crud_manager_layout_list_1_Template, 2, 4, "crud-manager-layout-list", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.config.layout.name === "table");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.config.layout.name === "list");
        }
      },
      encapsulation: 2
    });
  }
};
CrudManager2Component = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, ActivatedRoute, Router, ConfigService, CrudManagerService, Injector, LocalMessageHandlerService])], CrudManager2Component);
export { CrudManager2Component };