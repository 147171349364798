import { __decorate, __metadata } from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { AccessFacade, AccessState, AppMessageFactory, FeatureRouterLinkResolverService } from '@core/frontend-shared';
import { Store } from '@ngxs/store';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@core/frontend-shared";
import * as i3 from "@ngxs/store";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "../../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i6 from "../../../../../../ui/frontend-shared/src/lib/card/card";
import * as i7 from "../../../../../../ui/frontend-shared/src/lib/grid/grid";
/**
 * Important: We cannot rely on Observables in here.
 * When login status changes, this component will be recreated.
 * This will create a code loop.
 * So we need to do a redirect to another route to break the loop.
 */
let LoginByMailComponent = class LoginByMailComponent {
  // @Select(AccessState.loggedIn) loggedIn$!:Observable<boolean>;
  constructor(route, router, facade, store, messages, routerLinkResolver) {
    this.route = route;
    this.router = router;
    this.facade = facade;
    this.store = store;
    this.messages = messages;
    this.routerLinkResolver = routerLinkResolver;
  }
  ngOnInit() {
    this.tryLogin();
  }
  tryLogin() {
    const alreadyLoggedIn = this.store.selectSnapshot(AccessState.loggedIn);
    // this.loggedIn$.pipe(take(1),takeWhileAlive(this)).subscribe((alreadyLoggedIn:boolean)=> {
    if (alreadyLoggedIn) {
      // if user is currently logged in, we need to logout and do a page reload.
      // this is to make sure no data of the other account is still cached/displayed.
      this.facade.logout();
    }
    const token = this.route.snapshot.queryParams['token'];
    if (!token) {
      this.messages.create().setSeverity('warn').setMessage('Dieser Link ist ungültig. Bitte klicken Sie erneut den Link in der Mail oder lassen Sie sich eine neue Mail zustellen.');
    }
    this.facade.setSessionId(token).pipe(take(1)).subscribe(() => {
      let link;
      link = ['/'];
      this.router.navigate(link);
    });
    // })
    // http://localhost:8010/user/login-by-mail?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InBlcm1pc3Npb25zIjpbIlJFR0lTVEVSRUQiXSwidXNlcklkIjo0MzE2LCJyZXF1aXJlTmV3UGFzc3dvcmQiOnRydWV9LCJpYXQiOjE2ODM4MjE3NzgsImV4cCI6MTY4NjQxMzc3OCwiYXVkIjoicGZsZWdldGhla2UtZnJvbnRlbmQiLCJpc3MiOiJsb2NhbGhvc3Q6ODAxMi9hcGkiLCJzdWIiOiI0MzE2In0.PMowEy2JB54_yq5LesuUJlgXg-592ya2y4A2il_mOP_x4sgQleh3948nUjL0x739N5-mrkJpa54H2n1w30MNV7prcwIyaxVqLFcLTtJtNW1Jt-b4QE-HYFYo4rdm3neFW0gMJXPqffO-2EjYi8CLgaPF-vHphiq2cvGF2IcZSw0
  }
  static {
    this.ɵfac = function LoginByMailComponent_Factory(t) {
      return new (t || LoginByMailComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.AccessFacade), i0.ɵɵdirectiveInject(i3.Store), i0.ɵɵdirectiveInject(i2.AppMessageFactory), i0.ɵɵdirectiveInject(i2.FeatureRouterLinkResolverService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: LoginByMailComponent,
      selectors: [["user-login-by-mail"]],
      decls: 8,
      vars: 1,
      consts: [["size", "small"], ["uiGrid", "", 1, "p-align-center"], [1, "p-col-3", 2, "text-align", "right"], ["icon", "circle-notch", "size", "4x", 1, "ui-float-right", "color-primary", 3, "spin"], [1, "p-col-9"]],
      template: function LoginByMailComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ui-content-centered")(1, "ui-card", 0)(2, "div", 1)(3, "div", 2);
          i0.ɵɵelement(4, "fa-icon", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 4)(6, "h2");
          i0.ɵɵtext(7, "Login wird durchgef\u00FChrt...");
          i0.ɵɵelementEnd()()()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("spin", true);
        }
      },
      dependencies: [i4.FaIconComponent, i5.UiContentCenteredComponent, i6.Card, i7.UiGridComponent]
    });
  }
};
LoginByMailComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ActivatedRoute, Router, AccessFacade, Store, AppMessageFactory, FeatureRouterLinkResolverService])], LoginByMailComponent);
export { LoginByMailComponent };