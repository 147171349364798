import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, effect, input, signal } from '@angular/core';
import { AutoUnsubscribe } from '@core/frontend-shared';
import { MediaGridSettingsService, defaultMediaGridSettings } from './media-grid-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "./media-grid-settings.service";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i4 from "../../../../../../ui/frontend-shared/src/lib/input-switch/input-switch";
import * as i5 from "../../../../../../ui/frontend-shared/src/lib/input-group/input-group";
import * as i6 from "@angular/forms";
const _c0 = a0 => ({
  "ui-button-secondary": a0
});
let MediaAssetGridSettingsComponent = class MediaAssetGridSettingsComponent {
  constructor(
  // protected modelFactoryProvider:ModelFactoryProvider,
  // protected itemServiceFactory:ItemServiceFactory,
  // protected mediaProviders:MediaAssetProvidersFrontendService,
  // protected http:HttpClient, 
  cd, settingsService) {
    this.cd = cd;
    this.listController = input(null);
    this.size = signal(defaultMediaGridSettings.size);
    this.displayName = signal(defaultMediaGridSettings.displayNames);
    this.displayStatus = signal(defaultMediaGridSettings.displayStatus);
    this.displayUsage = signal(defaultMediaGridSettings.displayUsage);
    if (!settingsService) throw new Error("MediaAssetGridSettingsComponent received no MediaGridSettingsService. please inject one in host component of media grid and its settings component.");
    effect(() => {
      settingsService.setSize(this.size());
    });
    effect(() => {
      settingsService.setDisplayNames(this.displayName());
    });
    effect(() => {
      settingsService.setDisplayStatus(this.displayStatus());
    });
    effect(() => {
      settingsService.setDisplayUsage(this.displayUsage());
    });
  }
  static {
    this.ɵfac = function MediaAssetGridSettingsComponent_Factory(t) {
      return new (t || MediaAssetGridSettingsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.MediaGridSettingsService, 8));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetGridSettingsComponent,
      selectors: [["media-asset-grid-settings"]],
      inputs: {
        listController: [i0.ɵɵInputFlags.SignalBased, "listController"]
      },
      decls: 8,
      vars: 12,
      consts: [[1, "select-size", 3, "rounded"], ["uiButton", "", "title", "Kleinere Vorschau", "icon", "search-minus", 3, "ngClass", "click"], ["uiButton", "", "title", "Normale Vorschau", "icon", "image", 3, "ngClass", "click"], ["uiButton", "", "title", "Gro\u00DFe Vorschau", "icon", "search-plus", 3, "ngClass", "click"], ["label", "Namen anzeigen", 3, "ngModel", "ngModelChange"], ["label", "Nutzung anzeigen", 3, "ngModel", "ngModelChange"]],
      template: function MediaAssetGridSettingsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "span");
          i0.ɵɵtext(1, "Ansicht: \u00A0");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "ui-input-group", 0)(3, "button", 1);
          i0.ɵɵlistener("click", function MediaAssetGridSettingsComponent_Template_button_click_3_listener() {
            return ctx.size.set("small");
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "button", 2);
          i0.ɵɵlistener("click", function MediaAssetGridSettingsComponent_Template_button_click_4_listener() {
            return ctx.size.set("normal");
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "button", 3);
          i0.ɵɵlistener("click", function MediaAssetGridSettingsComponent_Template_button_click_5_listener() {
            return ctx.size.set("large");
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "ui-input-switch", 4);
          i0.ɵɵlistener("ngModelChange", function MediaAssetGridSettingsComponent_Template_ui_input_switch_ngModelChange_6_listener($event) {
            return ctx.displayName.set($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "ui-input-switch", 5);
          i0.ɵɵlistener("ngModelChange", function MediaAssetGridSettingsComponent_Template_ui_input_switch_ngModelChange_7_listener($event) {
            return ctx.displayUsage.set($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("rounded", true);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c0, ctx.size() !== "small"));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, ctx.size() !== "normal"));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(10, _c0, ctx.size() !== "large"));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngModel", ctx.displayName());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngModel", ctx.displayUsage());
        }
      },
      dependencies: [i2.NgClass, i3.UiButtonComponent, i4.InputSwitch, i5.InputGroup, i6.NgControlStatus, i6.NgModel],
      styles: ["[_nghost-%COMP%]{display:flex;align-items:center}[_nghost-%COMP%] > *[_ngcontent-%COMP%]{margin:0 5px}.select-size[_ngcontent-%COMP%]{--inputGroupElementHeight:26px }.select-size[_ngcontent-%COMP%]     .ui-button{padding:4px}"],
      changeDetection: 0
    });
  }
};
MediaAssetGridSettingsComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, MediaGridSettingsService])], MediaAssetGridSettingsComponent);
export { MediaAssetGridSettingsComponent };