import { __decorate, __metadata } from "tslib";
import { ElementRef, ExistingProvider, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppRequestService } from '@core/frontend-shared/api';
import { AppMessageFactory } from '@core/frontend-shared/messages';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { ConfirmDialogService } from '@common/ui/frontend-shared/confirmdialog';
import { FORM_CONTROL_NAME_HOST } from '@common/ui/frontend-shared/input-ref';
import { getAssetTypeGroupInfo, MediaAssetItem, MediaAssetMappingItem, SupportedFileTypeGroups } from '@common/media/shared';
import { CommonModule } from '@angular/common';
import { MediaAssetPreviewComponent } from '../media-preview';
import { BehaviorSubject, take } from 'rxjs';
import { isObjectEmpty } from '@core/shared';
import { MediaInputFieldContextmenuComponent } from './media-contextmenu';
import { MediaAssetCreatorComponent } from '../media-asset-creator';
import { DynamicDialogService } from '@common/ui/frontend-shared/dynamic-dialog';
import { MediaAssetSelectorComponent } from '../media-asset-selector';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { MediaIconsModule } from '../media-icons.module';
import * as i0 from "@angular/core";
import * as i1 from "@common/ui/frontend-shared/confirmdialog";
import * as i2 from "@common/ui/frontend-shared/dynamic-dialog";
import * as i3 from "@core/frontend-shared/api";
import * as i4 from "@core/frontend-shared/messages";
import * as i5 from "@common/ui/frontend-shared/input-ref";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/input-ref/form-control-name";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i9 from "../../../../../ui/frontend-shared/src/lib/input-group/input-group";
import * as i10 from "@fortawesome/angular-fontawesome";
const _c0 = () => ({
  type: "block"
});
const _c1 = () => ({
  height: 200
});
function MediaInputMediaFieldComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "media-asset-preview", 2);
    i0.ɵɵelementStart(1, "media-input-field-contextmenu", 3);
    i0.ɵɵlistener("removeAsset", function MediaInputMediaFieldComponent_ng_template_0_Template_media_input_field_contextmenu_removeAsset_1_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.unsetAsset());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const mapping_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("asset", mapping_r3.asset)("enableVideoPlayer", true)("layout", i0.ɵɵpureFunction0(6, _c0))("layoutStyle", i0.ɵɵpureFunction0(7, _c1));
    i0.ɵɵadvance();
    i0.ɵɵproperty("mapping", mapping_r3)("allowedFileTypes", ctx_r0.allowedFileTypes);
  }
}
function MediaInputMediaFieldComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "ui-input-group", 5)(2, "button", 6);
    i0.ɵɵlistener("click", function MediaInputMediaFieldComponent_ng_template_2_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onClickUpload());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 7);
    i0.ɵɵlistener("click", function MediaInputMediaFieldComponent_ng_template_2_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.onClickSelectExisting());
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelement(4, "fa-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("rounded", true);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("icon", ctx_r1.getAssetTypeGroupInfo(ctx_r1.allowedFileTypes).icon)("title", ctx_r1.getAssetTypeGroupInfo(ctx_r1.allowedFileTypes).label);
  }
}
const MEDIA_FIELD_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MediaInputMediaFieldComponent),
  multi: true
};
let MediaInputMediaFieldComponent = class MediaInputMediaFieldComponent {
  constructor(confirm, dialogService, apiService, messages, el) {
    this.confirm = confirm;
    this.dialogService = dialogService;
    this.apiService = apiService;
    this.messages = messages;
    this.el = el;
    this.onChange = newValue => {};
    this.onTouched = () => {};
    this.value$ = new BehaviorSubject(null);
    this.formControlName = null;
    this.uploadPath = null;
    this.allowedFileTypes = 'image';
    this.getAssetTypeGroupInfo = getAssetTypeGroupInfo;
    this.value$.pipe(takeWhileAlive(this)).subscribe(value => {
      if (this.onChange) this.onChange(value);
    });
  }
  // called by FormControlNameStandalone
  setFormControlName(name) {
    this.formControlName = name;
  }
  getSlotName() {
    const slotName = this.slotName || this.formControlName;
    if (!slotName) throw new Error('MediaInputMediaField cannot continue: to bind an asset, the component must know its slotName. Either set slotName input binding or make sure a formControlName binding is present, allowing formControlNameStandalone to pass the name to the component.');
    return slotName;
  }
  unsetAsset() {
    this.value$.next(null);
  }
  onClickUpload() {
    const ref = this.dialogService.open(MediaAssetCreatorComponent, {
      data: {
        allowedFileTypes: this.allowedFileTypes,
        uploadPath: this.uploadPath || undefined,
        showTargetPathInput: false,
        uploadEndpoint: undefined
      },
      width: '700px',
      height: '85%',
      header: 'Asset erstellen',
      dismissableMask: true,
      padded: true,
      contentStyle: {
        "overflow-y": "auto"
      },
      baseZIndex: 10000
    });
    ref.onClose.pipe(take(1)).subscribe(result => {
      if (result) this.onAssetSelected(result);
    });
  }
  onClickSelectExisting() {
    const ref = this.dialogService.open(MediaAssetSelectorComponent, {
      data: {
        allowedFileTypes: this.allowedFileTypes
      },
      width: '1000px',
      height: '85%',
      header: 'Asset wählen',
      dismissableMask: true,
      padded: true,
      contentStyle: {
        "overflow-y": "auto"
      },
      baseZIndex: 10000
    });
    ref.onClose.pipe(take(1)).subscribe(result => {
      if (result) this.onAssetSelected(result);
    });
  }
  onAssetSelected(asset) {
    this.value$.next({
      id: null,
      enabled: true,
      assetId: asset.id,
      asset,
      consumerName: null,
      consumerId: null,
      consumerMediaSlot: this.getSlotName()
    });
  }
  // -------- FORM CONTROL BINDINGS -----------------------------------------------
  writeValue(value) {
    if (isObjectEmpty(value)) value = null;
    this.value$.next(value);
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  static {
    this.ɵfac = function MediaInputMediaFieldComponent_Factory(t) {
      return new (t || MediaInputMediaFieldComponent)(i0.ɵɵdirectiveInject(i1.ConfirmDialogService), i0.ɵɵdirectiveInject(i2.DynamicDialogService), i0.ɵɵdirectiveInject(i3.AppRequestService), i0.ɵɵdirectiveInject(i4.AppMessageFactory), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaInputMediaFieldComponent,
      selectors: [["media-input-media-field"]],
      inputs: {
        uploadPath: "uploadPath",
        allowedFileTypes: "allowedFileTypes",
        slotName: "slotName"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([MEDIA_FIELD_VALUE_ACCESSOR, DynamicDialogService, {
        provide: FORM_CONTROL_NAME_HOST,
        useExisting: forwardRef(() => MediaInputMediaFieldComponent)
      }]), i0.ɵɵHostDirectivesFeature([i5.InputRefDirective, {
        directive: i6.FormControlNameStandalone,
        inputs: ["formControlName", "formControlNameStandalone"]
      }]), i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [[3, "ngIf", "ngIfElse"], ["noFileSelected", ""], [3, "asset", "enableVideoPlayer", "layout", "layoutStyle"], [3, "mapping", "allowedFileTypes", "removeAsset"], [1, "startbuttons"], [3, "rounded"], ["uiButton", "", "label", "Asset erstellen", 3, "click"], ["uiButton", "", "label", "Bestehendes Asset w\u00E4hlen", 1, "ui-button-contrast", 3, "click"], ["size", "lg", 1, "asset-type-icon", 3, "icon", "title"]],
      template: function MediaInputMediaFieldComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, MediaInputMediaFieldComponent_ng_template_0_Template, 2, 8, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵtemplate(2, MediaInputMediaFieldComponent_ng_template_2_Template, 5, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const _r2 = i0.ɵɵreference(3);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.value$))("ngIfElse", _r2);
        }
      },
      dependencies: [CommonModule, i7.NgIf, i7.AsyncPipe, MediaAssetPreviewComponent, MediaInputFieldContextmenuComponent, UiButtonModule, i8.UiButtonComponent, UiInputGroupModule, i9.InputGroup, MediaIconsModule, i10.FaIconComponent],
      styles: ["[_nghost-%COMP%]{display:block;margin-bottom:10px;position:relative}button.remove[_ngcontent-%COMP%], media-input-field-contextmenu[_ngcontent-%COMP%]{position:absolute;right:5px;top:5px;z-index:5}button.remove[_ngcontent-%COMP%]{right:40px}ui-media-preview[_ngcontent-%COMP%]{display:block;height:150px;background:var(--inputBgColor);border:1px solid #a6a6a6;border-color:var(--inputBorderColor)}.startbuttons[_ngcontent-%COMP%]{display:flex;align-items:center}.startbuttons[_ngcontent-%COMP%]   fa-icon[_ngcontent-%COMP%]{margin-left:10px}"]
    });
  }
};
MediaInputMediaFieldComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ConfirmDialogService, DynamicDialogService, AppRequestService, AppMessageFactory, ElementRef])], MediaInputMediaFieldComponent);
export { MediaInputMediaFieldComponent };