import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { EventEmitter, TemplateRef, computed, input, signal } from '@angular/core';
import { FormSubmitButtonDirective } from '@common/forms/frontend-shared';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiMenuModule } from '@common/ui/frontend-shared/menu';
import { ModelActionHelper } from '@core/frontend-shared';
import { AutoUnsubscribe, UtilsFrontendSharedModule, takeWhileAlive } from '@core/frontend-shared/utils';
import { CrudManagerService } from '../crud-manager';
import * as i0 from "@angular/core";
import * as i1 from "../crud-manager";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i4 from "../../../../../ui/frontend-shared/src/lib/menu/menu";
import * as i5 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
function CrudItemToolbarComponent_ng_template_1_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "button", 8);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formSubmitButton", ctx_r2.formService());
  }
}
function CrudItemToolbarComponent_ng_template_1_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function CrudItemToolbarComponent_ng_template_1_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.onClickCloseEditor());
    });
    i0.ɵɵelementEnd();
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_3_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrudItemToolbarComponent_ng_template_1_ng_template_3_ng_container_0_Template, 1, 0, "ng-container", 10);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.templateLeft);
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_7_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrudItemToolbarComponent_ng_template_1_ng_template_7_ng_container_0_Template, 1, 0, "ng-container", 10);
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r5.templateRight);
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_8_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function CrudItemToolbarComponent_ng_template_1_ng_template_8_ng_template_0_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const _r13 = i0.ɵɵreference(2);
      return i0.ɵɵresetView(_r13.toggle($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelement(1, "ui-menu", 12, 13);
  }
  if (rf & 2) {
    const actions_r11 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("label", "Aktionen...");
    i0.ɵɵadvance();
    i0.ɵɵproperty("popup", true)("model", actions_r11);
  }
}
function CrudItemToolbarComponent_ng_template_1_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrudItemToolbarComponent_ng_template_1_ng_template_8_ng_template_0_Template, 3, 3, "ng-template", 5);
  }
  if (rf & 2) {
    const actions_r11 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", actions_r11.length);
  }
}
function CrudItemToolbarComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, CrudItemToolbarComponent_ng_template_1_button_1_Template, 1, 1, "button", 3)(2, CrudItemToolbarComponent_ng_template_1_button_2_Template, 1, 0, "button", 4)(3, CrudItemToolbarComponent_ng_template_1_ng_template_3_Template, 1, 1, "ng-template", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "div", 6);
    i0.ɵɵelementStart(5, "div", 7);
    i0.ɵɵprojection(6);
    i0.ɵɵtemplate(7, CrudItemToolbarComponent_ng_template_1_ng_template_7_Template, 1, 1, "ng-template", 5)(8, CrudItemToolbarComponent_ng_template_1_ng_template_8_Template, 1, 1, "ng-template", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const c_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", c_r1.showSaveButton && ctx_r0.formService());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", c_r1.showBackButton);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.templateLeft);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r0.templateRight);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngLet", ctx_r0.contextMenuActions());
  }
}
const _c0 = [[["", 8, "buttonbar-right"]]];
const _c1 = [".buttonbar-right"];
let CrudItemToolbarComponent = class CrudItemToolbarComponent {
  constructor(crudManager) {
    this.crudManager = crudManager;
    this.closeEditor = new EventEmitter();
    this.configInput = input({}, {
      alias: 'config'
    });
    this.config = computed(() => {
      return {
        showSaveButton: true,
        showBackButton: true,
        showActionsButton: true,
        ...this.configInput()
      };
    });
    this.item = input();
    this.formService = input();
    this.actions = signal([]);
    this.contextMenuActions = computed(() => {
      const item = this.item();
      const actions = this.actions();
      if (!actions?.length) return [];
      if (!this.config().showActionsButton) return [];
      if (!item) return [];
      return ModelActionHelper.createMenuItemsFromItemModelActions(actions, () => item);
    });
    if (crudManager) crudManager.getItemActionsForCurrentMode$().pipe(takeWhileAlive(this)).subscribe(actions => this.actions.set(actions));
  }
  onClickCloseEditor() {
    if (this.crudManager) {
      this.crudManager.navigateTo('list');
    } else {
      this.closeEditor.emit(true);
    }
  }
  static {
    this.ɵfac = function CrudItemToolbarComponent_Factory(t) {
      return new (t || CrudItemToolbarComponent)(i0.ɵɵdirectiveInject(i1.CrudManagerService, 8));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudItemToolbarComponent,
      selectors: [["crud-item-toolbar"]],
      inputs: {
        templateLeft: "templateLeft",
        templateRight: "templateRight",
        configInput: [i0.ɵɵInputFlags.SignalBased, "config", "configInput"],
        item: [i0.ɵɵInputFlags.SignalBased, "item"],
        formService: [i0.ɵɵInputFlags.SignalBased, "formService"]
      },
      outputs: {
        closeEditor: "closeEditor"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 2,
      vars: 1,
      consts: [[1, "toolbar"], [3, "ngLet"], [1, "left"], ["e2e-crud-editor-submit-button", "", "type", "submit", "uiButton", "", 3, "formSubmitButton", 4, "ngIf"], ["uiButton", "", "icon", "times", "label", "Zur\u00FCck zur \u00DCbersicht", "class", "ui-button-secondary", 3, "click", 4, "ngIf"], [3, "ngIf"], [1, "center"], [1, "right"], ["e2e-crud-editor-submit-button", "", "type", "submit", "uiButton", "", 3, "formSubmitButton"], ["uiButton", "", "icon", "times", "label", "Zur\u00FCck zur \u00DCbersicht", 1, "ui-button-secondary", 3, "click"], [4, "ngTemplateOutlet"], ["uiButton", "", "icon", "bars", 1, "ui-button-secondary", 3, "label", "click"], ["appendTo", "body", 3, "popup", "model"], ["menu", ""]],
      template: function CrudItemToolbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CrudItemToolbarComponent_ng_template_1_Template, 9, 5, "ng-template", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngLet", ctx.config());
        }
      },
      dependencies: [CommonModule, i2.NgIf, i2.NgTemplateOutlet, UiButtonModule, i3.UiButtonComponent, UiMenuModule, i4.Menu, UtilsFrontendSharedModule, i5.NgLetDirective, FormSubmitButtonDirective],
      styles: [".toolbar[_ngcontent-%COMP%]{display:flex}\n\n/*# sourceMappingURL=crud-item-toolbar.component.ts-angular-inline--42.css.map*/", ".toolbar[_ngcontent-%COMP%] > .center[_ngcontent-%COMP%]{flex:1}\n\n/*# sourceMappingURL=crud-item-toolbar.component.ts-angular-inline--43.css.map*/", ".toolbar[_ngcontent-%COMP%] >*:not(:first-child){margin-left:10px}\n\n/*# sourceMappingURL=crud-item-toolbar.component.ts-angular-inline--45.css.map*/"]
    });
  }
};
CrudItemToolbarComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [CrudManagerService])], CrudItemToolbarComponent);
export { CrudItemToolbarComponent };