import { __decorate, __metadata } from "tslib";
import { PLATFORM_ID } from '@angular/core';
import { shareAsReplaySubject } from '@core/shared/utils';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared';
import * as i0 from "@angular/core";
let ScreenService = class ScreenService {
  constructor(config, platformId) {
    this.config = config;
    this.platformId = platformId;
    if (isPlatformBrowser(this.platformId)) {
      this._windowWidth$ = new BehaviorSubject(window.innerWidth);
      this.windowWidth$ = this._windowWidth$.asObservable();
      const resizeWindowWidth$ = fromEvent(window, 'resize').pipe(debounceTime(50), map(e => e.target['innerWidth']), shareAsReplaySubject(1), takeWhileAlive(this));
      resizeWindowWidth$.subscribe(width => {
        this._windowWidth$.next(width);
      });
    } else {
      this._windowWidth$ = new BehaviorSubject(960);
      this.windowWidth$ = this._windowWidth$.asObservable();
    }
    this.isMobile$ = this.windowWidth$.pipe(map(width => {
      return width < config.breakpointMobile;
    }));
    this.isTablet$ = this.windowWidth$.pipe(map(width => {
      return width >= config.breakpointMobile && width < config.breakpointTablet;
    }));
    this.isMobileOrTablet$ = this.windowWidth$.pipe(map(width => {
      return width < config.breakpointTablet;
    }));
    this.isDesktop$ = this.windowWidth$.pipe(map(width => {
      return width >= config.breakpointTablet;
    }));
    this.isLargeDesktop$ = this.windowWidth$.pipe(map(width => {
      return width >= config.breakpointLargeDesktop;
    }));
  }
  static {
    this.ɵfac = function ScreenService_Factory(t) {
      return new (t || ScreenService)(i0.ɵɵinject('SCREEN_SERVICE_CONFIG_PROVIDER'), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: ScreenService,
      factory: ScreenService.ɵfac
    });
  }
};
ScreenService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Object, Object])], ScreenService);
export { ScreenService };