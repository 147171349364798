import { __decorate, __metadata } from "tslib";
import { NavigationEnd } from '@angular/router';
import { CRUD_ROUTE_STATE_ITEM_DEFAULTS, CrudFormServiceFactory, makeFormServiceSaveHandlerApiCrudBatchCreate } from '@common/crud/frontend-shared';
import { PageFacade } from '@common/page/frontend-shared';
import { AutoUnsubscribe, ItemServiceFactory, ModelActionService, RestraintsFactory, getCurrentRouteState, takeWhileAlive } from '@core/frontend-shared';
import { DEFAULT_EDITOR_DEFINITION_ID, convertToBehaviorSubject, getItemModelText, isConstructor } from '@core/shared';
import { BehaviorSubject, Observable, filter, map, switchMap } from 'rxjs';
import { crudManagerDefaults } from '../crud.config';
import { isCrudModeWithRouteParam } from './crud-manager.utils';
import { CrudListController } from '../controller';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/page/frontend-shared";
import * as i3 from "@common/crud/frontend-shared";
// injected in CrudManager
let CrudManagerService = class CrudManagerService {
  get Model() {
    return this.Model$.getValue();
  }
  get restraints() {
    return this._restraints || this.createRestraints();
  }
  get crudService() {
    return this.service.crud;
  }
  get enableEdit() {
    return this.config.list.editable;
  }
  get enableDelete() {
    return this.config.list.deleteable;
  }
  get enableToggle() {
    return this.config.list.toggleable;
  }
  get enableCreate() {
    return this.config.list.createable;
  }
  get itemEditorDetails() {
    return this.itemEditorDetails$.getValue();
  }
  constructor(modelActionService, restraintsFactory, itemServiceFactory, page, formServiceFactory) {
    this.modelActionService = modelActionService;
    this.restraintsFactory = restraintsFactory;
    this.itemServiceFactory = itemServiceFactory;
    this.page = page;
    this.formServiceFactory = formServiceFactory;
    this.Model$ = new BehaviorSubject(null);
    this.templates = {};
    // updated by CrudEditor/CrudItemView 
    this.modeAndItem$ = new BehaviorSubject({
      mode: null,
      item: null
    });
    this.crudRouteState$ = new BehaviorSubject(null);
    this.contextGroups$ = this.modeAndItem$.pipe(takeWhileAlive(this), map(({
      mode
    }) => {
      return this.getContextGroups(mode);
    }));
    this.itemActions$ = convertToBehaviorSubject([])(this.modeAndItem$.pipe(takeWhileAlive(this), switchMap(({
      mode
    }) => {
      return this._getItemActionsForMode(mode);
    })));
    this.itemEditorDetails$ = convertToBehaviorSubject(null)(this.modeAndItem$.pipe(takeWhileAlive(this), map(({
      mode,
      item
    }) => {
      return this._getItemEditorDetails(mode, item);
    })));
    this.modifiers = {
      formService: null
    };
  }
  configurate(config, Model, crudService, templates, router, baseRoute) {
    this.config = {
      layout: config.layout || crudManagerDefaults.layout,
      list: {
        ...crudManagerDefaults.list,
        ...config.list
      },
      editor: {
        ...crudManagerDefaults.editor,
        ...config.editor
      },
      batch: config.batch || null,
      dependencies: config.dependencies,
      itemActions: config.itemActions
    };
    if (this.config.list.loadAllItems) this.config.list.itemsPerPage = false;
    if (this.config.layout.name === 'list') {
      this.config.editor.showBackButton = false;
    }
    this.router = router;
    this.baseRoute = baseRoute;
    this.templates = templates;
    this.Model$.next(Model);
    this.subscribeToRouteChanges(router, baseRoute);
    this.subscribeToRouteStateChanges();
    this.setupCrudAndItemService(crudService);
    this.createRestraints();
  }
  // #######  List Controller  ###########################################################
  /**
   * ListControllers are used in different scenarios.
   * When used in a CRUD UI system, it is easiest to let CrudManagerService construct one.
   * But some components may be used outside of CRUD UI.
   * It is fine to create a ListController instance manually then.
   * Depending on situation, the CrudManagerService can construct a ListController or store an existing one.
   */
  getListController(createIfNotExisting = true) {
    if (!this.listController && createIfNotExisting) {
      this.listController = new CrudListController(this.config.list, this.service, getCurrentRouteState(), this.config.dependencies);
    }
    return this.listController || null;
  }
  setListController(controller) {
    this.listController = controller;
  }
  // #######  Route management  ###########################################################
  subscribeToRouteChanges(router, route) {
    router.events.pipe(filter(e => e instanceof NavigationEnd), takeWhileAlive(this)).subscribe(e => {
      // this check indicated the child route is in a temporary state.
      // another NavigationEnd event will fire right after this.
      // if(route.firstChild?.component === null) return;
      this.handleRouteChange(route);
    });
    this.handleRouteChange(route);
  }
  handleRouteChange(route) {
    if (route.component === null) {
      // firstChild route has no component assigned, this can happen when using an "empty" route for resolvers
      route = route.firstChild;
    }
    const hasChildRoute = !!route?.firstChild;
    if (!hasChildRoute) {
      this.crudRouteState$.next({
        type: 'list',
        url: '',
        id: null
      });
    } else {
      const child = route.firstChild;
      // console.log('chzild route',child)
      const snapshot = child.snapshot || child._futureSnapshot;
      const url = snapshot.url[0].path;
      const mode = snapshot.data.mode;
      if (!mode) {
        // can happen when there are custom child routes
        this.crudRouteState$.next({
          type: 'custom',
          url,
          id: null
        });
      } else {
        const isIdBasedEditMode = m => {
          return ['edit', 'clone', 'view'].includes(m);
        };
        // TODO: ID may be stored in other parameter than :id!
        const idParamName = snapshot.data.routeIdParamName || 'id';
        const id = isIdBasedEditMode(mode) ? parseInt(snapshot.params[idParamName], 10) : null;
        this.crudRouteState$.next({
          type: mode,
          url,
          id
        });
      }
    }
  }
  subscribeToRouteStateChanges() {
    this.crudRouteState$.pipe(takeWhileAlive(this), filter(state => !!state)).subscribe(state => {
      if (state.type === 'list') {
        this.setPageTitle(this.getItemText('items') + ' verwalten');
      }
    });
  }
  configurateModeAndItem(patch) {
    if (patch.mode === 'list') throw new Error('CrudManagerService.configurateModeAndItem should not be called explicitly for list, only for ItemEditModes!');
    if (patch.mode === null) {
      this.modeAndItem$.next({
        mode: null,
        item: null
      });
    } else {
      if (!IS_PRODUCTION) {
        const modeChanged = !!patch.mode;
        if (patch.item && !(patch.item instanceof this.Model)) {
          throw new Error('invalid data passed to configurateModeAndItem: Item is no instance of configurated Model!');
        }
        if (modeChanged && typeof patch.item === 'undefined') {
          throw new Error('invalid data passed to configurateModeAndItem: when setting a mode, an explicit value for item (either data or null) must be passed!');
        }
      }
      const prev = this.modeAndItem$.getValue();
      const next = {
        ...prev,
        ...patch
      };
      this.modeAndItem$.next(next);
    }
  }
  navigate(commands) {
    this.router.navigate(commands, {
      relativeTo: this.baseRoute
    });
  }
  // TODO: this could replace manual navigation inside CrudEditor.subscribeToOnAfterSave
  navigateTo(requestedView, param) {
    const currentRoute = this.crudRouteState$.getValue();
    const requestedViewUsesParams = isCrudModeWithRouteParam(requestedView);
    // nothing to do, we are already in correct view!
    if (!requestedViewUsesParams && currentRoute.type === requestedView) {
      return;
    }
    switch (requestedView) {
      case 'list':
        this.router.navigate(['./'], {
          relativeTo: this.baseRoute
        });
        break;
      case 'new':
      case 'batch':
        this.router.navigate(['./', requestedView], {
          relativeTo: this.baseRoute
        });
        break;
      case 'edit':
      case 'view':
        if (!param) throw new Error('navigateTo ' + requestedView + ' requires route param to be passed (item ID)!');
        this.router.navigate(['./', param], {
          relativeTo: this.baseRoute
        });
        break;
      case 'clone':
        if (!param) throw new Error('navigateTo ' + requestedView + ' requires route param to be passed (item ID)!');
        this.router.navigate(['./clone', param], {
          relativeTo: this.baseRoute
        });
        break;
    }
  }
  executeCustomButtonAction(event, btn) {
    if (btn.command) {
      btn.command(event);
    } else if (btn.routerLink) {
      this.navigate(btn.routerLink);
    } else {
      throw new Error('dont know how to handle clicking custom button');
    }
  }
  // #######  Editor View setup  ###########################################################
  setupCrudAndItemService(customCrudService) {
    let crudServiceConstructorInfo;
    if (customCrudService) {
      if (isConstructor(customCrudService)) {
        const crudService = this.itemServiceFactory.crud.create(customCrudService);
        crudService.setModel(this.getModel());
        crudServiceConstructorInfo = crudService;
      } else {
        crudServiceConstructorInfo = customCrudService;
      }
    } else {
      crudServiceConstructorInfo = this.getModel();
    }
    const usePagedItemService = this.config.list.loadAllItems === false && this.config.list.itemsPerPage !== false;
    if (!usePagedItemService) {
      this.service = this.itemServiceFactory.createSimpleItemService(crudServiceConstructorInfo);
    } else {
      this.service = this.itemServiceFactory.createPagedItemService(crudServiceConstructorInfo);
      this.service.initialize(this.config.list.itemsPerPage || 20);
    }
    if (!this.config.list.useCrudEndpoint) throw new Error('CrudManager error: Please set config.list.useCrudEndpoint correctly.');
    const useCrudEndpoint = this.config.list.useCrudEndpoint;
    this.service.useCrudEndpoint(useCrudEndpoint);
    this.bindToCrudServiceEvents();
  }
  bindToCrudServiceEvents() {
    /**
     * subscribing to CrudService events manually for reloading items is not required anymore.
     * CrudService will emit events to CrudRequestState, ItemService will listen to these and trigger reloads where needed.
     */
  }
  _getItemEditorDetails(mode, initialItem) {
    if (mode === 'list') return null;
    if (mode === 'view') {
      return {
        formService: null,
        instructions: null,
        mode,
        initialItem
      };
    }
    if (mode === 'batch') {
      // if(this.batchFormService) {
      // 	this.batchFormService.resetForm();
      // } else {
      // 	this.batchFormService = this.buildBatchFormService();;
      // }
      // this.setPageTitle('Stapelverarbeitung');
      const crudInstructions = this.config.batch.instructions;
      const instructions = Array.isArray(crudInstructions) ? crudInstructions : crudInstructions[mode];
      return {
        formService: this.buildBatchFormService(),
        instructions,
        mode,
        initialItem
      };
    } else {
      // for all item-based modes clone, edit, new
      if (!initialItem) return null;
      const defaultItemData = this.createDefaultItemData(mode, initialItem);
      const crudInstructions = this.config.editor.instructions;
      const instructions = Array.isArray(crudInstructions) ? crudInstructions : crudInstructions[mode];
      return {
        formService: this.buildFormService(defaultItemData, mode),
        instructions,
        mode,
        initialItem
      };
    }
  }
  createDefaultItemData(mode, item) {
    const state = getCurrentRouteState();
    if (state[CRUD_ROUTE_STATE_ITEM_DEFAULTS]) {
      if (mode === 'edit' || mode === 'batch') {
        throw new Error('Passing default item values is not supported for ItemEditMode ' + mode);
      }
      item = {
        ...item,
        ...state[CRUD_ROUTE_STATE_ITEM_DEFAULTS]
      };
    }
    return item;
  }
  buildFormService(item = null, mode = 'edit') {
    let formService = this.formServiceFactory.createCrudFormService(this.service.ItemFactory.Model);
    formService.setEditMode(mode);
    const editorDefinition = this.config.editor.editorDefinition;
    const def = typeof editorDefinition === 'object' ? editorDefinition[mode] : editorDefinition;
    formService.setEditorDefinition(def || DEFAULT_EDITOR_DEFINITION_ID);
    formService.setApiService(this.service.crud);
    formService.setModel(this.service.ItemFactory.Model);
    if (item) formService.setInitialValue(item);
    formService.enableAutoSave(this.config.editor.autoSave);
    if (this.modifiers.formService) {
      formService = this.modifiers.formService(formService, item);
    }
    formService.initialize();
    return formService;
  }
  buildBatchFormService() {
    if (!this.config.batch) throw new Error('Batch mode is not configurated!');
    if (!this.config.batch.batchModel) throw new Error('Batch configuration misses Model to use');
    if (!this.config.batch.transformFunction) throw new Error('Batch configuration misses transformFunction');
    if (!this.config.batch.instructions) throw new Error('Batch configuration misses instructions');
    if (!this.config.batch.targetCrudEndpoint) throw new Error('Batch configuration misses targetCrudEndpoint');
    const BatchModel = this.config.batch.batchModel;
    const batchFormService = this.formServiceFactory.createModelForm(BatchModel);
    batchFormService.setEditMode('batch');
    batchFormService.setEditorDefinition(this.config.batch.editorDefinition || 'default');
    const Handler = makeFormServiceSaveHandlerApiCrudBatchCreate(this.config.batch.transformFunction, this.getModel(), this.config.batch.targetCrudEndpoint, this.service.crud);
    batchFormService.setSaveHandler(Handler);
    batchFormService.initialize();
    return batchFormService;
  }
  // #######  Restraints  ###########################################################
  createRestraints() {
    this._restraints = this.restraintsFactory.createForModel(this.Model);
    return this._restraints;
  }
  getItemRestraintsInfo(item) {
    if (item) {
      const r = this.restraints;
      return {
        canEdit: r.canUpdateItem(item),
        canDelete: r.canRemoveItem(item),
        canToggle: r.canToggleItem(item)
      };
    } else {
      return {
        canEdit: null,
        canDelete: null,
        canToggle: null
      };
    }
  }
  // #######  Item Actions  ###########################################################
  _getItemActionsForMode(mode) {
    return this.Model$.pipe(filter(Model => !!Model), switchMap(Model => {
      return this.modelActionService.getModelActions(Model, mode, this.getContextGroups(mode), this.config?.itemActions || []);
    }));
  }
  getItemActions$(mode) {
    if (mode) {
      return this._getItemActionsForMode(mode);
    } else {
      return this.itemActions$;
    }
  }
  getItemActionsForCurrentMode$() {
    return this.modeAndItem$.pipe(switchMap(modeAndItem => {
      return this._getItemActionsForMode(modeAndItem.mode);
    }));
  }
  getAllItemActions() {
    return this.itemActions$.getValue();
  }
  // #######  Utils  ###########################################################
  getModel() {
    return this.Model;
  }
  getCurrentModeAndItem() {
    return this.modeAndItem$.getValue();
  }
  setPageTitle(title) {
    this.page.setTitle(title);
  }
  getBaseRoute() {
    return this.baseRoute;
  }
  setFormServiceCustomizer(func) {
    this.modifiers.formService = func;
  }
  getContextGroups(mode) {
    const groups = ['crud', mode];
    if (mode !== 'list') groups.push('editor');
    return groups;
  }
  getItemText(id) {
    const item = this.Model || null;
    if (!item) return id;
    return getItemModelText(id, item);
  }
  static {
    this.ɵfac = function CrudManagerService_Factory(t) {
      return new (t || CrudManagerService)(i0.ɵɵinject(i1.ModelActionService), i0.ɵɵinject(i1.RestraintsFactory), i0.ɵɵinject(i1.ItemServiceFactory), i0.ɵɵinject(i2.PageFacade), i0.ɵɵinject(i3.CrudFormServiceFactory));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: CrudManagerService,
      factory: CrudManagerService.ɵfac
    });
  }
};
CrudManagerService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ModelActionService, RestraintsFactory, ItemServiceFactory, PageFacade, CrudFormServiceFactory])], CrudManagerService);
export { CrudManagerService };