import { __decorate, __metadata } from "tslib";
import { Select, Store } from '@ngxs/store';
import { VersioncheckActions, VersioncheckState } from './state/versioncheck.state';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/common";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../../../ui/frontend-shared/src/lib/button/button";
function VersioncheckComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3);
    i0.ɵɵtext(3, "Neue Version verf\u00FCgbar. Bitte aktualisieren Sie die Seite!");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 4)(5, "button", 5);
    i0.ɵɵlistener("click", function VersioncheckComponent_div_0_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClickReload());
    });
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(6, "div", 6)(7, "a", 7);
    i0.ɵɵlistener("click", function VersioncheckComponent_div_0_Template_a_click_7_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.postpone());
    });
    i0.ɵɵelement(8, "fa-icon", 8);
    i0.ɵɵelementEnd()()();
  }
}
export class VersioncheckComponent {
  constructor(store) {
    this.store = store;
    this.enableOverlay = false;
    this.newVersionAvailable$.subscribe(avail => {
      this.enableOverlay = avail;
    });
  }
  onClickReload() {
    window.location.reload();
  }
  postpone() {
    this.store.dispatch(new VersioncheckActions.PostponeUpdate());
  }
  static {
    this.ɵfac = function VersioncheckComponent_Factory(t) {
      return new (t || VersioncheckComponent)(i0.ɵɵdirectiveInject(i1.Store));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: VersioncheckComponent,
      selectors: [["versioncheck"]],
      hostVars: 2,
      hostBindings: function VersioncheckComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("overlay", ctx.enableOverlay);
        }
      },
      decls: 2,
      vars: 3,
      consts: [["class", "infobar", 4, "ngIf"], [1, "infobar"], [1, "center"], [1, "infotext"], [1, "mobile-block"], ["uiButton", "", "label", "Aktualisieren", 1, "ui-button-outline", 3, "click"], [1, "right"], [3, "click"], ["icon", "times", "size", "lg"]],
      template: function VersioncheckComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, VersioncheckComponent_div_0_Template, 9, 0, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.newVersionAvailable$));
        }
      },
      dependencies: [i2.NgIf, i3.FaIconComponent, i4.UiButtonComponent, i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{position:fixed;top:0;left:0;right:0;z-index:9999;background:#0000;transition:background-color .3s}.infobar[_ngcontent-%COMP%]{background:var(--primaryColor);color:var(--primaryTextColor);padding:10px 20px;display:flex;justify-content:center;align-items:center}.infobar[_ngcontent-%COMP%]{transform:translateY(-60px);transition:transform .3s}.infobar[_ngcontent-%COMP%]   .right[_ngcontent-%COMP%]{position:absolute;right:10px;top:16px}.infobar[_ngcontent-%COMP%]   a[_ngcontent-%COMP%], .infobar[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{color:var(--primaryTextColor)}.infobar[_ngcontent-%COMP%]   .ui-button-outline[_ngcontent-%COMP%]{border-color:var(--primaryTextColor);border-width:2px}@media (max-width: 767px){.infobar[_ngcontent-%COMP%]   .infotext[_ngcontent-%COMP%]{display:block;margin:0 10px 5px 0}.infobar[_ngcontent-%COMP%]   .mobile-block[_ngcontent-%COMP%]{display:block;text-align:center}.infobar[_ngcontent-%COMP%]   .right[_ngcontent-%COMP%]{top:10px}}@media (min-width: 768px){.infobar[_ngcontent-%COMP%]   .infotext[_ngcontent-%COMP%], .infobar[_ngcontent-%COMP%]   .mobile-block[_ngcontent-%COMP%]{display:inline}.infobar[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin:0 10px}}.overlay[_nghost-%COMP%]{bottom:0;background:#0000004d}.overlay[_nghost-%COMP%]   .infobar[_ngcontent-%COMP%]{transform:translateY(0)}@media (prefers-color-scheme: dark){.overlay[_nghost-%COMP%]{background:#0009}}"]
    });
  }
}
__decorate([Select(VersioncheckState.newVersionAvailable), __metadata("design:type", Observable)], VersioncheckComponent.prototype, "newVersionAvailable$", void 0);