import { Transform } from 'class-transformer-global-storage';
import { isConstructor } from '../../utils';
import { hasClassTransformerMetadata } from '../metadata-utils';
export function DefaultDecorator_applyValue(defaultValue, value, executor) {
  if (value !== null && typeof value !== 'undefined') return value;
  // function or class constructor
  if (typeof defaultValue === 'function') {
    if (isConstructor(defaultValue)) {
      if (hasClassTransformerMetadata(defaultValue)) {
        // TODO: #861myyp40 - needs reimplementation to not use ClassTransformService statically.
        const ChildClass = defaultValue;
        const plainData = {};
        return executor.transform(undefined, plainData, ChildClass, undefined, undefined, undefined);
      } else {
        return new defaultValue();
      }
    } else {
      return defaultValue();
    }
  }
  if (Array.isArray(defaultValue)) return [...defaultValue];
  // plain object
  if (typeof defaultValue === 'object') {
    return defaultValue === null ? null : {
      ...defaultValue
    };
  }
  return defaultValue;
}
/**
 * Will be used if given property is null or undefined.
 * provides multiple ways to define a default value.
 * defaultValue can be a simple value, array of simple values or a plain object.
 * Also supports passing a Model Constructor to be built by class-transformer.
 * If a non-constructor function is being passed, its return value will be used.
 */
export function Default(defaultValue) {
  return Transform(({
    value,
    options,
    executor
  }) => {
    return DefaultDecorator_applyValue(defaultValue, value, executor);
  });
}