import { __decorate, __metadata } from "tslib";
import { Default, EditorDefinition, Expose, Model } from "@core/shared/model";
import { Transform } from 'class-transformer-global-storage';
let MaintenanceSettings = class MaintenanceSettings extends Model {};
__decorate([Expose(), Default(false), Transform(({
  value
}) => {
  return typeof value === 'string' ? value === '1' ? true : false : value;
}, {
  toClassOnly: true
}), __metadata("design:type", Boolean)], MaintenanceSettings.prototype, "announcementEnable", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", String)], MaintenanceSettings.prototype, "announcementText", void 0);
__decorate([Expose(), __metadata("design:type", Date)], MaintenanceSettings.prototype, "announcementTime", void 0);
__decorate([Expose(), Default(false), Transform(({
  value
}) => {
  return typeof value === 'string' ? value === '1' ? true : false : value;
}, {
  toClassOnly: true
}), __metadata("design:type", Boolean)], MaintenanceSettings.prototype, "maintenanceMode", void 0);
__decorate([Expose(), Default(null), __metadata("design:type", String)], MaintenanceSettings.prototype, "maintenanceModeText", void 0);
MaintenanceSettings = __decorate([EditorDefinition('admin', {}, {
  allowAllExposed: true
})], MaintenanceSettings);
export { MaintenanceSettings };
export const MaintenanceSettingsKeys = ['announcementEnable', 'announcementText', 'maintenanceMode', 'maintenanceModeText'];