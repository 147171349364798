import { __decorate, __metadata } from "tslib";
import { ElementRef } from "@angular/core";
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { UiButtonComponent } from '@common/ui/frontend-shared/button';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseFormService, ModelFormService } from '../form-service';
import * as i0 from "@angular/core";
import * as i1 from "@common/ui/frontend-shared/button";
let FormSubmitButtonDirective = class FormSubmitButtonDirective {
  set formSubmitButton(main) {
    if (main instanceof BaseFormService) this.ModelFormService = main;else throw new Error('class passed to formSubmitButton must be an instance of BaseFormService!');
  }
  set _options(options) {
    this.customLabel = options.label || undefined;
    this.customLabelSent = options.labelSent || undefined;
    this.customIcon = options.icon || undefined;
    this.state$.next(this.state$.value);
  }
  set ModelFormService(service) {
    this.service = service;
    this.formType = service.formType;
    service.state$.pipe(takeWhileAlive(this)).subscribe(state => this.state$.next(state));
  }
  constructor(el, comp) {
    this.el = el;
    this.comp = comp;
    this.state$ = new BehaviorSubject(null);
    this.formType = 'editor';
    this.e2eStateAttr = "valid";
    this.isSubmitBtn = true;
    this.state$.pipe(filter(state => state !== null)).subscribe(state => {
      let buttonLabel;
      if (state.busy || state.saveState === 'saving') buttonLabel = 'Bitte warten...';else if (!state.valid && state.hasTriedToSubmitForm) buttonLabel = 'Bitte Eingaben prüfen.';else if (this.formType === 'editor') {
        if (this.service.getEditMode() === 'edit') {
          if (state.saveState === 'idle' && state.changeState === 'changed') buttonLabel = this.customLabel || 'Speichern';else buttonLabel = 'Gespeichert';
        } else {
          if (state.saveState === 'idle') buttonLabel = this.customLabel || 'Erstellen';else buttonLabel = 'Gespeichert';
        }
      } else {
        if (state.saveState === 'idle') buttonLabel = this.customLabel || 'Senden';else buttonLabel = this.customLabelSent || 'Erfolgreich gesendet';
      }
      let buttonClass;
      if (state.busy) buttonClass = 'ui-button-secondary';else if (!state.valid && state.hasTriedToSubmitForm) buttonClass = 'ui-button-danger';else if (this.formType === 'editor') {
        if (this.service.getEditMode() === 'edit') {
          buttonClass = state.changeState === 'unchanged' ? 'ui-button-secondary' : 'ui-button-primary';
        } else {
          buttonClass = 'ui-button-primary';
        }
      } else if (this.formType === 'form' && state.hasTriedToSubmitForm) buttonClass = 'ui-button-secondary';else buttonClass = '';
      let buttonIcon;
      if (!state.valid && state.hasTriedToSubmitForm) buttonIcon = 'exclamation-triangle';else if (this.formType === 'editor') {
        if (this.service.getEditMode() === 'edit') {
          if (state.saveState === 'saved' || state.changeState === 'unchanged') buttonIcon = 'check';else buttonIcon = this.customIcon || 'save';
        } else {
          if (state.saveState === 'saved') buttonIcon = 'check';else buttonIcon = this.customIcon || 'save';
        }
      } else {
        if (state.saveState === 'saved') buttonIcon = 'check';else buttonIcon = this.customIcon || 'chevron-right';
      }
      // const buttonDisabled = state.hasTriedToSubmitForm && !state.valid
      this.e2eStateAttr = !state.valid && state.hasTriedToSubmitForm ? "invalid" : "valid";
      this.comp.loading = state.busy || state.saveState === 'saving';
      this.comp.label = buttonLabel;
      this.comp.styleClass = buttonClass;
      this.comp.icon = buttonIcon;
      this.comp.cd.detectChanges();
      this.buttonDisabled = undefined;
    });
  }
  onClick() {
    this.service.save();
  }
  static {
    this.ɵfac = function FormSubmitButtonDirective_Factory(t) {
      return new (t || FormSubmitButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.UiButtonComponent));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: FormSubmitButtonDirective,
      selectors: [["", "uiButton", "", "formSubmitButton", ""]],
      hostVars: 4,
      hostBindings: function FormSubmitButtonDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function FormSubmitButtonDirective_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("disabled", ctx.buttonDisabled)("e2e-form-submit-button", ctx.e2eStateAttr);
          i0.ɵɵclassProp("form-submit-button", ctx.isSubmitBtn);
        }
      },
      inputs: {
        formSubmitButton: "formSubmitButton",
        _options: [i0.ɵɵInputFlags.None, "formSubmitButtonOptions", "_options"]
      },
      standalone: true
    });
  }
};
FormSubmitButtonDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ElementRef, UiButtonComponent])], FormSubmitButtonDirective);
export { FormSubmitButtonDirective };