import { __decorate, __metadata } from "tslib";
import { PLATFORM_ID } from '@angular/core';
import { ConfigService } from '@core/shared';
import { FeatureType, RouterLinkResolverService } from '@core/shared/feature';
import { BehaviorSubject, take } from 'rxjs';
import { DevToolsRegistry } from '../../dev-tools';
import { FrontendFeatureRegistry } from './feature.registry';
import { AutoUnsubscribe, takeWhileAlive } from '../../utils';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "./feature.registry";
import * as i2 from "@core/shared/feature";
import * as i3 from "@core/shared";
import * as i4 from "../../dev-tools";
let FeatureService = class FeatureService {
  // list of all installed FeaturePlugins, independent of currently active user/company and its settings.
  get allOptInFeaturePluginInfos$() {
    return this.registry.allOptInFeaturePluginInfos$;
  }
  constructor(registry, resolverService, config, debug, platformId) {
    this.registry = registry;
    this.resolverService = resolverService;
    this.platformId = platformId;
    // list of all Features that are enabled + loaded
    // loadedPlugins$:Observable<TFeature[]>;
    this.loadedPlugins$ = new BehaviorSubject([]);
    this.resolvers$ = new BehaviorSubject(null);
    this.registry.observeEachPlugin().pipe(takeWhileAlive(this)).subscribe(plugin => {
      // listen to incoming status changes for each registered plugin.
      // react to changes by loading/unloading the plugins.
      const status$ = this.registry.featureStatus.get$(plugin);
      status$.pipe(takeWhileAlive(this)).subscribe(status => {
        const {
          isEnabled
        } = this.handleStatusUpdate(plugin, status);
        this.updateLoadedPlugins(plugin, isEnabled);
      });
    });
    // cache routerLinkResolvers of current platform 
    this.resolverService.getRouterLinkResolvers(config.getPlatform()).pipe(takeWhileAlive(this)).subscribe(allResolvers => this.resolvers$.next(allResolvers));
    const platform = config.getPlatform();
    debug.exposeDebugFunction('inspectRouterLinkResolvers', {
      displayName: 'Features: RouterLinkResolvers (' + platform + ')'
    }, () => {
      return this.resolvers$.pipe(take(1));
    });
  }
  updateLoadedPlugins(plugin, isEnabled) {
    const currentLoaded = this.loadedPlugins$.getValue();
    if (isEnabled) {
      if (!currentLoaded.find(p => p === plugin)) {
        const update = currentLoaded.concat(plugin);
        this.loadedPlugins$.next(update);
      }
    } else {
      const index = currentLoaded.findIndex(p => p === plugin);
      if (index >= 0) {
        const update = [...currentLoaded];
        update.splice(index, 1);
        this.loadedPlugins$.next(update);
      }
    }
  }
  handleStatusUpdate(feature, status) {
    let shouldLoad = false;
    if (!isPlatformBrowser(this.platformId)) {
      // in SSR context, only features of type Permanent are allowed.
      // All dynamic / optIn features are user specific and must be disabled.
      shouldLoad = status.enabled && feature.__info.type === FeatureType.Permanent;
    } else {
      shouldLoad = status.enabled;
    }
    if (shouldLoad && !status.loaded) {
      this.registry.updateFeatureStatus(feature, {
        loaded: true
      });
      feature.onFeatureInit();
    } else if (!shouldLoad && status.loaded) {
      this.registry.updateFeatureStatus(feature, {
        loaded: false
      });
      feature.onFeatureDestroy();
    }
    return {
      isEnabled: shouldLoad
    };
  }
  getLoadedFeatures() {
    return this.loadedPlugins$;
  }
  // TODO: does not handle features registered lately
  getRequiredIcons() {
    const icons = this.registry.getBaseFeatures().reduce((mergedIcons, baseFeature) => {
      return [...mergedIcons, ...(baseFeature.resolveFeatureInfoFromStatic().icons || [])];
    }, []);
    return icons;
  }
  static {
    this.ɵfac = function FeatureService_Factory(t) {
      return new (t || FeatureService)(i0.ɵɵinject(i1.FrontendFeatureRegistry), i0.ɵɵinject(i2.RouterLinkResolverService), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.DevToolsRegistry), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: FeatureService,
      factory: FeatureService.ɵfac,
      providedIn: 'root'
    });
  }
};
FeatureService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [FrontendFeatureRegistry, RouterLinkResolverService, ConfigService, DevToolsRegistry, Object])], FeatureService);
export { FeatureService };