import { __decorate, __metadata } from "tslib";
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigService } from '@core/shared/config';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PageState } from './page.state';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@core/shared/config";
/**
 * PageService is responsible for applying Meta/Title changes to DOM header tags.
 * Note:initialize must be called as APP_INITIALIZER!
 **/
export class PageService {
  constructor(title, meta, config, document) {
    this.title = title;
    this.meta = meta;
    this.config = config;
    this.document = document;
    // required for removing unwanted custom metadata again
    this.previousEntries = [];
    this.metaConfig = {
      keywords: this.config.get('page.meta.keywords', ''),
      description: this.config.get('page.meta.description', ''),
      author: this.config.get('page.meta.author', ''),
      robots: this.config.get('page.meta.robots', 'noindex, nofollow'),
      title: this.config.get('page.meta.title', this.config.get('id', '')),
      titlePrefix: this.config.get('page.meta.titlePrefix', ''),
      titleSuffix: this.config.get('page.meta.titleSuffix', '')
    };
  }
  initialize() {
    this.clearStaticMeta();
    this.applyMetadata([]);
    this.setTitle();
    this.bindObservables();
  }
  /**
   * index.html has static metadata tags assigned.
   * These will not be overriden, so we should manually remove these before assigning other metadata dynamically.
   */
  clearStaticMeta() {
    // works fine in SSR too!
    const staticMetaTags = Array.from(this.document.head.getElementsByTagName('meta'));
    const filteredTags = staticMetaTags.filter(el => {
      return ['description', 'keywords'].includes(el.getAttribute('name'));
    });
    filteredTags.forEach(el => {
      this.document.head.removeChild(el);
    });
  }
  /**
   * @param title - if omitted, default title will be set.
   */
  setTitle(title = null) {
    if (title === null) {
      this.title.setTitle(this.metaConfig.title);
    } else {
      const pieces = [];
      if (this.metaConfig.titlePrefix) pieces.push(this.metaConfig.titlePrefix);
      if (title) pieces.push(title);
      if (this.metaConfig.titleSuffix) pieces.push(this.metaConfig.titleSuffix);
      const fullTitle = pieces.join(' - ');
      this.title.setTitle(fullTitle);
    }
  }
  getDefaultMeta() {
    return [{
      name: 'keywords',
      content: this.metaConfig.keywords
    }, {
      name: 'description',
      content: this.metaConfig.description
    }, {
      name: 'author',
      content: this.metaConfig.author
    }, {
      name: 'robots',
      content: this.metaConfig.robots
    }];
  }
  /**
   * metadata handling is kinda tricky. Some meta tags are pretty much static, e.g. viewport.
   * So these should not ever be touched or modified at all.
   * Then there are our default meta entries (see getDefaultMeta), which may be overridden but should never be removed from DOM.
   * Third, there are optional meta items which could be passed in, but may need to be removed from DOM.
   * To handle all these situations, applyMetadata...
   * - will never receive PARTIAL list of Meta
   * - keeps track of previously set metadata using this.previousEntries
   * - uses difference of new entries vs this.previousEntries to remove outdated meta from DOM
   */
  applyMetadata(customEntries) {
    const allEntries = this.addDefaultMetadata(customEntries);
    // find and remove meta tags that will not be assigned a new value
    const metaEntriesToRemove = this.previousEntries.filter(entry => {
      const matchInNewEntries = allEntries.find(e => e.name === entry.name);
      return !matchInNewEntries;
    });
    metaEntriesToRemove.forEach(entry => {
      this.meta.removeTag("name=" + entry.name);
    });
    /**
     * API of angular's Meta Service is pretty weird.
     * updateTag is the way to go, it will create meta tag if non-existing!
     */
    allEntries.forEach(entry => {
      this.meta.updateTag(entry);
    });
    this.previousEntries = allEntries;
  }
  addDefaultMetadata(customMeta) {
    // as meta info is contained in an array, it may contain multiple instructions for the same meta name.
    // so we first join it and then need to make sure that each name is only included once (with latest entry having highest priority).
    const joined = [...this.getDefaultMeta(), ...customMeta];
    const definedNames = [];
    const filtered = joined.reverse().filter(meta => {
      if (definedNames.includes(meta.name)) return false;
      definedNames.push(meta.name);
      return true;
    });
    return filtered.reverse();
  }
  bindObservables() {
    this.title$.subscribe(title => {
      this.setTitle(title);
    });
    this.meta$.subscribe(metas => {
      this.applyMetadata(metas);
    });
  }
  static {
    this.ɵfac = function PageService_Factory(t) {
      return new (t || PageService)(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: PageService,
      factory: PageService.ɵfac
    });
  }
}
__decorate([Select(PageState.title), __metadata("design:type", Observable)], PageService.prototype, "title$", void 0);
__decorate([Select(PageState.meta), __metadata("design:type", Observable)], PageService.prototype, "meta$", void 0);