import { EventEmitter, IterableDiffers } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Model } from '@core/shared/model';
import * as merge from 'deepmerge';
import { BehaviorSubject } from 'rxjs';
import { BaseFormService, createformArrayChildFormGroup, updateDynamicFormArray } from '../form-service';
function enhanceFormArrayWithControlsObservable(plainFormGroup, diffFactory) {
  const enhancedFormGroup = plainFormGroup;
  enhancedFormGroup.controls$ = new BehaviorSubject([]);
  const formControlDiff = diffFactory.find([]).create(null);
  const originalMethod = enhancedFormGroup.updateValueAndValidity;
  enhancedFormGroup.updateValueAndValidity = opts => {
    originalMethod.call(enhancedFormGroup, opts);
    // using IterableDiffers to detect if anything changed actually.
    const changes = formControlDiff.diff(enhancedFormGroup.controls);
    if (changes) {
      // this log can show the unusual behavior spotted on page load, described in #338775b
      // console.log(">>>>>>> changes",changes);
      enhancedFormGroup.controls$.next(
      // this is extremely important! A NEW array must be emitted!
      // otherwise, regular change detection will not realize something has changed!
      [...enhancedFormGroup.controls]);
    }
  };
  return enhancedFormGroup;
}
export class ArrayFormService extends BaseFormService {
  constructor() {
    super(...arguments);
    // TODO: should there really be service.value AND formGroup.value?
    this.value = [];
    this.onUpdateFormControlReadonlyState = new EventEmitter();
    // will emit if the structure of the FormArray controls changes, useful for fieldset rendering
    this.onFormArrayUpdated$ = new BehaviorSubject(null);
  }
  // ###########################################################################################################
  // ####  SETUP  ##############################################################################################
  // ###########################################################################################################
  initialize() {
    return super.initialize().then(() => {
      this.onFormArrayUpdated$.next(this.formGroup);
    });
  }
  // ###########################################################################################################
  // ####  VALUE HANDLING  #####################################################################################
  // ###########################################################################################################
  // TODO: why build new controls if used as sub-model? Parent formGroup should already contain controls?
  reloadFormValue(value) {
    if (!this.formGroup) return;
    if (this._debug) console.log(this.debugInfo() + '.reloadFormValue', this.formGroup.value, value);
    updateDynamicFormArray(this.formGroup, this.modelFactory, value);
    this.refreshFormState();
  }
  getValue() {
    if (!this.formGroup) return null;
    let value = this.formGroup.getRawValue();
    if (!Array.isArray(value)) throw new Error('ArrayFormService.getValue can only work with arrays!');
    if (this.immutableValues) {
      value = value.map(item => {
        return {
          ...item,
          ...this.immutableValues
        };
      });
    }
    if (this.modelFactory?.Model) {
      value = value.map(item => this.modelFactory.fromData(item));
    }
    return value;
  }
  _setValue(inputValue) {
    if (this._debug) console.log(this.debugInfo() + '._setValue', inputValue);
    if (!Array.isArray(inputValue)) throw new Error('ArrayFormService._setValue can only work with arrays!');
    if (!this.modelFactory) throw new Error('Cannot create valid internal value because modelFactory is missing!');
    const value = inputValue.map(item => {
      if (!(item instanceof Model) && !this.modelFactory) {
        throw new Error('ArrayFormService._setValue cannot be used with non-models if no modelFactory is set!');
      }
      // try to convert values to correct model
      if (!(inputValue instanceof this.modelFactory.Model)) {
        return this.modelFactory.fromData({
          ...inputValue,
          ...this.immutableValues
        });
      } else {
        return this.modelFactory.patch(inputValue, this.immutableValues);
      }
    });
    this.reloadFormValue(value);
  }
  setValue(value) {
    if (!Array.isArray(value)) throw new Error('ArrayFormService: trying to set non-array value');
    value = merge([], value);
    this.reloadFormValue(value);
  }
  getDefaultValue() {
    return [];
  }
  getInitialValue() {
    return [];
  }
  dataIsArray() {
    return true;
  }
  // ###########################################################################################################
  // ####  FORM ARRAY  #########################################################################################
  // ###########################################################################################################
  buildFormGroup() {
    if (this._debug) console.log(this.debugInfo() + '.buildFormGroup', this.formGroup, this.modelFactory);
    if (!this.modelFactory) return Promise.reject('buildFormGroup error: exposedModelFactory not available');
    if (!this.formGroup) {
      const fg = this.enhanceFormGroup(new UntypedFormArray([]));
      this.formGroup$.next(fg);
    }
    return this.resolveFormDependencies().then(() => {
      // ngxDynamicFormBuilder requires root form to have dynamicFormBuilderOptions, else setObject will fail.
      if (!this.formGroup.root.dynamicFormBuilderOptions) {
        const dummyForm = this.createDynamicFormGroup(this.modelFactory.Model, {});
        this.formGroup.root.dynamicFormBuilderOptions = dummyForm.dynamicFormBuilderOptions;
      }
      // this.formGroup.reset(); // would reset state but value too!
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      this.bindFormGroupListeners();
      // now that formGroup is ready, this will trigger controls$ emission
      // which is required to make UI show the actual form data.
      this.formGroup.updateValueAndValidity();
    });
  }
  enhanceFormGroup(fg) {
    const fgWithService = super.enhanceFormGroup(fg);
    return enhanceFormArrayWithControlsObservable(fgWithService, this.injector.get(IterableDiffers));
  }
  updateFormControlDisabledState() {
    if (!this.formGroup) return;
    this.onUpdateFormControlReadonlyState.emit(this.formGroup);
  }
  addNewItem() {
    const data = this.modelFactory.newInstance();
    const newItem = createformArrayChildFormGroup(data, this.formGroup);
    this.formGroup.push(newItem);
    // newItem.markAsPristine();
    // newItem.markAsUntouched();
    // attach validation logic + current status for the new formGroup.
    this.refreshFormState();
    this.onFormArrayUpdated$.next(this.formGroup);
  }
  removeItem(input) {
    const index = typeof input === 'number' ? input : this.formGroup.controls.findIndex(i2 => i2 === input);
    this.formGroup.removeAt(index);
    this.onFormArrayUpdated$.next(this.formGroup);
  }
  reorderItems(actions) {
    let tmp;
    actions.forEach(action => {
      tmp = this.formGroup.at(action.from);
      this.formGroup.removeAt(action.from);
      this.formGroup.insert(action.to, tmp);
    });
    this.onFormArrayUpdated$.next(this.formGroup);
  }
}