var ConsoleState_1;
import { __decorate, __metadata } from "tslib";
import { Action, ActionStatus, Actions, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { ConsoleStateActions } from './console.actions';
import { AutoUnsubscribe, PersistStateFactory, takeWhileAlive } from '@core/frontend-shared';
import { filter, skipUntil, timer } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@core/frontend-shared";
const STORAGE_KEY_DEVCONSOLE_STATE = 'dev-console-state';
let ConsoleState = class ConsoleState {
  static {
    ConsoleState_1 = this;
  }
  static currentView(state) {
    return state.view;
  }
  static selectViewState(viewId) {
    return createSelector([ConsoleState_1], state => {
      return state.viewStates[viewId] || null;
    });
  }
  constructor(actions$, persistenceFactory) {
    this.actions$ = actions$;
    this.persistenceFactory = persistenceFactory;
    this.persistedState = this.persistenceFactory.create(STORAGE_KEY_DEVCONSOLE_STATE, true, 'session');
  }
  ngxsOnInit(ctx) {
    ctx.dispatch(new ConsoleStateActions.RestorePersistedState());
    this.actions$.pipe(skipUntil(timer(500)), filter(e => {
      return e.status === "SUCCESSFUL" /* ActionStatus.Successful */;
    }), takeWhileAlive(this)).subscribe(() => {
      const state = ctx.getState();
      const persist = {
        view: state.view,
        viewStates: state.viewStates
      };
      this.persistedState.set(persist);
    });
  }
  setIsInitialRoute({
    patchState
  }, {
    view
  }) {
    patchState({
      view
    });
  }
  setViewState({
    patchState,
    getState
  }, {
    viewId,
    data
  }) {
    const viewStates = getState().viewStates;
    const newViewStates = {
      ...viewStates,
      [viewId]: data
    };
    patchState({
      viewStates: newViewStates
    });
  }
  restorePersistedState({
    patchState,
    getState
  }) {
    const persisted = this.persistedState.get();
    if (persisted) patchState(persisted);
  }
  static {
    this.ɵfac = function ConsoleState_Factory(t) {
      return new (t || ConsoleState)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.PersistStateFactory));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: ConsoleState,
      factory: ConsoleState.ɵfac
    });
  }
};
__decorate([Action(ConsoleStateActions.SetCurrentView), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, ConsoleStateActions.SetCurrentView]), __metadata("design:returntype", void 0)], ConsoleState.prototype, "setIsInitialRoute", null);
__decorate([Action(ConsoleStateActions.SetViewState), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, ConsoleStateActions.SetViewState]), __metadata("design:returntype", void 0)], ConsoleState.prototype, "setViewState", null);
__decorate([Action(ConsoleStateActions.RestorePersistedState), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], ConsoleState.prototype, "restorePersistedState", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], ConsoleState, "currentView", null);
ConsoleState = ConsoleState_1 = __decorate([State({
  name: 'devconsole',
  defaults: {
    view: 0,
    viewStates: {}
  }
}), AutoUnsubscribe(), __metadata("design:paramtypes", [Actions, PersistStateFactory])], ConsoleState);
export { ConsoleState };