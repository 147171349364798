import { OnItemChunkError, OnItemError, OnItemProgress } from '../events/events';
import { FileChunk } from './file-chunk.class';
import { FileItem } from './file-item.class';
import { filter, interval, takeUntil } from 'rxjs';
import { clamp } from '@core/shared';
export class ChunkedFileItem extends FileItem {
  constructor(some, options, uploader) {
    super(some, options, uploader);
    this.chunkSize = null;
    this.chunkTotalRetries = 3;
    this.chunkRetries = 0;
    // start with expecting 5s per chunk. Will be updated when chunks finish.
    this.avgMsPerChunk = 5000;
  }
  createFileChunk() {
    this.fileChunks = new FileChunk(this._file, {
      byteStepSize: this.options.chunkSize
    });
  }
  getCurrentChunkFile() {
    return this.fileChunks.getCurrentRawFileChunk();
  }
  prepareNextChunk() {
    this.fileChunks.prepareNextChunk();
  }
  getCurrentChunk() {
    return this.fileChunks.getCurrentChunk();
  }
  getTotalChunks() {
    return this.fileChunks.getTotalChunks();
  }
  set fileChunks(val) {
    this._fileChunks = val;
  }
  get fileChunks() {
    return this._fileChunks;
  }
  _onCompleteChunk(event) {
    this._onUploadProgress(null);
    this.chunkRetries = 0;
    this.prepareNextChunk();
  }
  _onRetrySendChunk() {
    this.chunkRetries++;
    this.fileChunks.retrocedeChunk();
    this.prepareNextChunk();
  }
  _onErrorChunk(error) {
    this.events.emit(new OnItemChunkError(this, error.message, error.status, error.headers));
  }
  _onUploadStart() {
    super._onUploadStart();
    this.startProgressUpdating();
    if (this.fileChunks) {
      this.fileChunks.setUploading(true);
    }
  }
  _onUploadProgress(event) {
    this.lastChunkCompletionTimeStamp = Date.now();
    const timeSinceUploadStart = this.lastChunkCompletionTimeStamp - this.uploadStartTimeStamp;
    const avgTimePerChunk = timeSinceUploadStart / (this.getCurrentChunk() + 1);
    this.avgMsPerChunk = avgTimePerChunk;
    // console.log('updated avgTimePerChunk to '+(avgTimePerChunk/1000)+' based on current chunk being '
    // 	+(this.getCurrentChunk()+1)+', time since start is '+(timeSinceUploadStart/1000))
  }
  startProgressUpdating() {
    this.uploadStartTimeStamp = Date.now();
    this.lastChunkCompletionTimeStamp = Date.now();
    const endProgressUpdates$ = this.state$.pipe(filter(state => state !== 'uploading'));
    const refreshRate = 1000;
    interval(refreshRate).pipe(takeUntil(endProgressUpdates$)).subscribe({
      next: counter => this.emitProgressUpdate(refreshRate * counter)
      // complete:()=>{console.log('ended progress updating.')} 
    });
  }
  emitProgressUpdate(msSinceStart) {
    const currentChunkTimeMs = Date.now() - this.lastChunkCompletionTimeStamp;
    const currentChunkProgress = currentChunkTimeMs / this.avgMsPerChunk;
    const completedChunksProgress = this.getCurrentChunk() / this.getTotalChunks();
    const progress = completedChunksProgress + currentChunkProgress / this.getTotalChunks();
    // console.log(`currentChunk uploading for ${currentChunkTimeMs/1000}s, progress ${currentChunkProgress}. completed chunks progress: ${completedChunksProgress} `)
    this.progress = clamp(Math.round(progress * 100), 0, 100);
    this.events.emit(new OnItemProgress(this, this.progress));
  }
  hasChunksLeft() {
    return this.getCurrentChunk() + 1 < this.getTotalChunks();
  }
  _onError(error) {
    this._state.next('error');
    if (this.fileChunks) {
      this.fileChunks.setUploading(false);
    }
    this.events.emit(new OnItemError(this, error));
  }
}