import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FeatureMenuItem, FeatureMenuItemService, FeatureService } from '@core/frontend-shared/feature';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';


@Component({
	selector:'app-management',
	templateUrl:'./management.component.html',
	styleUrls:['./management.component.scss'],
	changeDetection:ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class AppManagementComponent {

	menuModel:FeatureMenuItem[];

	constructor(
		featureService:FeatureService,
		featureMenuItemService:FeatureMenuItemService,
		icons:FaIconLibrary,
		cd:ChangeDetectorRef,
	) {
		icons.addIcons(...featureService.getRequiredIcons())
		featureMenuItemService.getAccessibleMenuItems('managementToolbar')
			.pipe(takeWhileAlive(this))
			.subscribe(menuItems=>{
				this.menuModel = [];
				menuItems.forEach((menuItem:FeatureMenuItem)=>{
					this.menuModel.push(menuItem)
				});
				cd.markForCheck()
			})
	}
	
}