import { __decorate, __metadata } from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { AccessState } from '@core/frontend-shared';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import { Store } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngxs/store";
import * as i3 from "../login/login.component";
let UserLandingpageComponent = class UserLandingpageComponent {
  constructor(
  // private authService: AuthService, 
  router, route, store) {
    this.router = router;
    this.route = route;
    this.store = store;
    const loggedIn = this.store.selectSnapshot(AccessState.loggedIn);
    if (loggedIn) {
      this.router.navigate(['./profile'], {
        relativeTo: this.route
      });
    }
  }
  static {
    this.ɵfac = function UserLandingpageComponent_Factory(t) {
      return new (t || UserLandingpageComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.Store));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: UserLandingpageComponent,
      selectors: [["user-landingpage"]],
      decls: 1,
      vars: 0,
      template: function UserLandingpageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "user-login");
        }
      },
      dependencies: [i3.LoginComponent],
      styles: ["[_nghost-%COMP%]{display:flex;align-items:center;justify-content:center}\n\n/*# sourceMappingURL=user-landingpage.component.ts-angular-inline--75.css.map*/", "[_nghost-%COMP%] > *[_ngcontent-%COMP%]{max-width:100%}\n\n/*# sourceMappingURL=user-landingpage.component.ts-angular-inline--76.css.map*/"]
    });
  }
};
UserLandingpageComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [Router, ActivatedRoute, Store])], UserLandingpageComponent);
export { UserLandingpageComponent };