import { DynamicFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class InputSelectOrderlistInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap
    };
    this.attributeMap = {};
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import('@common/ui/frontend-shared/input-select-orderlist').then(bundle => {
      return {
        component: bundle.InputSelectOrderlist,
        module: bundle.UiInputSelectOrderlistModule
      };
    });
  }
}