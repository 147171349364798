import { DynamicFieldInstruction } from './shared';
export class InputPasswordSimpleInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      usePlaceholders: false,
      orientation: 'horizontal',
      showToggleVisibilityButton: true,
      showPasswordGenerateButton: true
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      usePlaceholders: 'usePlaceholders',
      orientation: 'orientation',
      showToggleVisibilityButton: 'showToggleVisibilityButton',
      showPasswordGenerateButton: 'showPasswordGenerateButton'
    };
    this.attributeMap = {};
    this.isInput = true;
    this.useFieldWrapper = true;
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-password').then(bundle => {
      return {
        component: bundle.InputPasswordComponent,
        module: bundle.UiInputPasswordModule
      };
    });
  }
}