import { DynamicFieldInstruction } from './shared';
export class InputMediaInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      fileType: 'any',
      uploadPath: null
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      uploadPath: 'uploadPath',
      allowedFileTypes: 'fileType'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    return import('@common/media/frontend-shared/input-media-field/input-media-field.component').then(bundle => {
      return {
        component: bundle.MediaInputMediaFieldComponent,
        module: null
      };
    });
  }
}