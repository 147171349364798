import { HttpErrorResponse } from '@angular/common/http';
export const defaultUploaderHooks = {
  beforeUpload: (item, options, uploaderOptions) => Promise.resolve(),
  getRequestHeaders: (headers, item, options, uploaderOptions) => headers,
  modifyRequest: (request, item, options, uploaderOptions, overwrite) => {
    if (overwrite) {
      request = request.clone({
        setParams: {
          overwrite: '1'
        }
      });
    }
    return request;
  },
  setChunkHeaders: (headers, chunk, item, options, uploaderOptions) => headers,
  getFileInfoFromResponse: response => {
    if (response instanceof HttpErrorResponse) {
      return response.error['data'];
    } else {
      return response.body['data'];
    }
  }
};