import { __decorate, __metadata } from "tslib";
import { NgxPermissionsService, NgxPermissionsStore, USE_PERMISSIONS_STORE } from 'ngx-permissions';
import { firstValueFrom, skip, take } from 'rxjs';
import { AutoUnsubscribe } from '../../utils';
import * as i0 from "@angular/core";
import * as i1 from "ngx-permissions";
/**
 * Modification of NgxPermissionsService:
 * - include an "initialized" flag
 * - delay permission check until initial permissions have been set
 */
let PermissionsService = class PermissionsService extends NgxPermissionsService {
  constructor(isolate = false, permissionsStore) {
    super(isolate, permissionsStore);
    this.initialized = false;
  }
  hasPermission(permission) {
    if (!permission || Array.isArray(permission) && permission.length === 0) {
      return Promise.resolve(true);
    }
    if (this.initialized) return super.hasPermission(permission);
    return firstValueFrom(this.permissions$.pipe(skip(1),
    // BehaviorSubject always emits, so skip 1
    take(1))).then(() => super.hasPermission(permission));
  }
  loadPermissions(permissions, validationFunction) {
    super.loadPermissions(permissions, validationFunction);
    this.initialized = true;
  }
  isInitialized() {
    return this.initialized;
  }
  static {
    this.ɵfac = function PermissionsService_Factory(t) {
      return new (t || PermissionsService)(i0.ɵɵinject(USE_PERMISSIONS_STORE), i0.ɵɵinject(i1.NgxPermissionsStore));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: PermissionsService,
      factory: PermissionsService.ɵfac,
      providedIn: 'root'
    });
  }
};
PermissionsService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Boolean, NgxPermissionsStore])], PermissionsService);
export { PermissionsService };