import { arraySplitByCondition, arrayMapMergeOne } from '../utils';
export var AbilityUtils;
(function (AbilityUtils) {
  function getActionId(ability) {
    return `${ability.subjectType}.${ability.subject}.${ability.action}`;
  }
  AbilityUtils.getActionId = getActionId;
  function buildActionId(subjectId, action) {
    return `${subjectId.type}.${subjectId.subject}.${action}`;
  }
  AbilityUtils.buildActionId = buildActionId;
  function matchSubjectIds(s1, s2) {
    return s1.type === s2.type && s1.subject === s2.subject;
  }
  AbilityUtils.matchSubjectIds = matchSubjectIds;
  function findSubjectInList(id, list) {
    return list.find(id2 => matchSubjectIds(id, id2));
  }
  AbilityUtils.findSubjectInList = findSubjectInList;
  function mergeSkillsetsToAbilities(skillsets) {
    const abilities = skillsets.reduce((a, skillset) => {
      if (skillset.enabled) a.push(...skillset.abilities);
      return a;
    }, []);
    const cleaned = cleanupRedundantAbilities(abilities);
    return cleaned;
  }
  AbilityUtils.mergeSkillsetsToAbilities = mergeSkillsetsToAbilities;
  function cleanupRedundantAbilities(abilities) {
    const abilitiesMap = new Map();
    abilities.forEach(ability => {
      // if(!ability.enabled) return;
      const actionId = getActionId(ability);
      arrayMapMergeOne(abilitiesMap, actionId, ability);
    });
    const mergedAbilities = [];
    // filter available abilities:
    // - all conditional abilities must be included
    // - unconditional abilities can be compared, only include the most relevant one (strongest bias/priority)
    for (const [actionId, actionIdAbilities] of abilitiesMap) {
      // Using the default iterator (could be `map.entries()` instead)
      const [unconditional, conditional] = arraySplitByCondition(actionIdAbilities, ability => {
        return !ability.conditions;
      });
      unconditional.sort((a, b) => {
        const val = Math.abs(b.bias) - Math.abs(a.bias);
        if (val === 0) return a.bias - b.bias; // if absolute values are equal, negative value should be sorted before positive
        return val;
      });
      // console.log('unconditionals for '+actionId, unconditional);
      mergedAbilities.push(unconditional[0], ...conditional);
    }
    return mergedAbilities;
  }
  AbilityUtils.cleanupRedundantAbilities = cleanupRedundantAbilities;
})(AbilityUtils || (AbilityUtils = {}));