import { __decorate, __metadata } from "tslib";
import { PLATFORM_ID } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { shareAsReplaySubject } from '@core/shared/utils';
import { map, startWith } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./error-message.component";
function PageRouterErrorComponent_page_error_message_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "page-error-message", 1);
  }
  if (rf & 2) {
    const errorInfo_r1 = ctx.$implicit;
    i0.ɵɵproperty("error", errorInfo_r1);
  }
}
/**
 * used by Router in case of error during navigation.
 * most likely happens if target route is not found (404).
 */
let PageRouterErrorComponent = class PageRouterErrorComponent {
  constructor(activatedRoute, platformId) {
    this.activatedRoute = activatedRoute;
    this.platformId = platformId;
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.errorInfo$ = this.activatedRoute.paramMap.pipe(takeWhileAlive(this), map(() => {
      return this.isBrowser ? {
        ...window.history.state
      } : {};
    }), startWith({}), map(state => {
      return {
        summary: 'Fehler',
        status: 404,
        message: 'Die Seite konnte nicht geladen werden werden.',
        ...state
      };
    }), shareAsReplaySubject(1));
  }
  static {
    this.ɵfac = function PageRouterErrorComponent_Factory(t) {
      return new (t || PageRouterErrorComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: PageRouterErrorComponent,
      selectors: [["page-error-navigation"]],
      decls: 2,
      vars: 3,
      consts: [[3, "error", 4, "ngIf"], [3, "error"]],
      template: function PageRouterErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, PageRouterErrorComponent_page_error_message_0_Template, 1, 1, "page-error-message", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.errorInfo$));
        }
      },
      dependencies: [i2.NgIf, i3.PageErrorMessageComponent, i2.AsyncPipe],
      encapsulation: 2
    });
  }
};
PageRouterErrorComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ActivatedRoute, Object])], PageRouterErrorComponent);
export { PageRouterErrorComponent };