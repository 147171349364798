import { FilterDropdownInstruction, FilterTextInstruction } from '@common/instructions/frontend-shared';
import { getMediaAssetProviderOptionsList, getMediaAssetTypeOptionsList } from '@common/media/shared';
export function createMediaGridFilters() {
  return [
  // new FilterDropdownInstruction({ 
  // 	formControlName:'tag', placeholder:'[Tag]', filters:[{ operator:'$eq', field:'tags.id' }],
  // 	optionsDynamic:{model:MembershipTagItem,useCrudEndpoint:'admin'}, filter:true, optionValue:'id'
  // }),
  new FilterTextInstruction({
    formControlName: 'name',
    placeholder: 'Suche Name...',
    filters: [{
      operator: '$cont',
      field: 'name'
    }, {
      operator: '$starts',
      field: 'id'
    }]
  }), new FilterDropdownInstruction({
    formControlName: 'type',
    placeholder: 'Typ...',
    filters: [{
      operator: '$eq',
      field: 'type'
    }],
    options: getMediaAssetTypeOptionsList()
  }), new FilterDropdownInstruction({
    formControlName: 'provider',
    placeholder: 'Host...',
    filters: [{
      operator: '$eq',
      field: 'provider'
    }],
    options: getMediaAssetProviderOptionsList()
  }), new FilterDropdownInstruction({
    formControlName: 'ready',
    placeholder: 'Status...',
    filters: [{
      operator: '$eq',
      field: 'ready'
    }],
    options: [{
      value: '0',
      label: 'wird verarbeitet'
    }, {
      value: '1',
      label: 'bereit'
    }]
  })
  // TODO: difficult to implement due to filter operation limits
  // new FilterDropdownInstruction({ 
  // 	formControlName:'usage', placeholder:'Verwendung...', filters:[{ operator:'$eq', field:'ready' }],
  // 	options:[{value:'0',label:'ungenutzt'},{value:'1',label:'in Verwendung'}]
  // }),
  // new FilterAutocompleteInstruction({ 
  // 	formControlName:'ownerId', placeholder:'Besitzer...',
  // 	multiple:false, optionValue:'id',
  // 	crudEndpointConfig:{model:UserItem,useCrudEndpoint:'admin'} ,
  // 	searchQueryBuilder:userSearchQueryBuilder,
  // 	renderOptionLabel:getUserOptionLabel,
  // 	filters:[{ operator:'$eq', field:'ownerId' }]
  // }),
  ];
}