import { DynamicFieldInstruction } from './shared';
export class InputEditorInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      height: 400
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      height: 'height'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-editor').then(bundle => {
      return {
        component: bundle.InputEditorComponent,
        module: bundle.UiInputEditorModule
      };
    });
  }
}