export class FileUploaderEvent {}
// Item Events
export class OnItemUpload extends FileUploaderEvent {
  constructor(item) {
    super();
    this.item = item;
  }
}
export class OnItemProgress extends FileUploaderEvent {
  constructor(item, progress) {
    super();
    this.item = item;
    this.progress = progress;
  }
}
export class OnItemConflict extends FileUploaderEvent {
  constructor(item, fileInfo, response) {
    super();
    this.item = item;
    this.fileInfo = fileInfo;
    this.response = response;
  }
}
export class OnItemSuccess extends FileUploaderEvent {
  constructor(item, asset, response) {
    super();
    this.item = item;
    this.asset = asset;
    this.response = response;
  }
}
export class OnItemError extends FileUploaderEvent {
  constructor(item, response) {
    super();
    this.item = item;
    this.response = response;
  }
}
export class OnItemCanceled extends FileUploaderEvent {
  constructor(item) {
    super();
    this.item = item;
  }
}
export class OnItemRemoved extends FileUploaderEvent {
  constructor(item) {
    super();
    this.item = item;
  }
}
// Item Chunk Events
export class OnItemChunkComplete extends FileUploaderEvent {
  constructor(item, response, status, headers) {
    super();
    this.item = item;
    this.response = response;
    this.status = status;
    this.headers = headers;
  }
}
export class OnItemChunkError extends FileUploaderEvent {
  constructor(item, response, status, headers) {
    super();
    this.item = item;
    this.response = response;
    this.status = status;
    this.headers = headers;
  }
}
// Controller Events
export class OnAfterAddItem extends FileUploaderEvent {
  constructor(item) {
    super();
    this.item = item;
  }
}
export class OnAddItemFailed extends FileUploaderEvent {
  constructor(file, filter, options) {
    super();
    this.file = file;
    this.filter = filter;
    this.options = options;
  }
}
// export class OnQueueAddedAll extends FileUploaderEvent {
// 	constructor(public items:FileItemInterface[]) {super()}
// }
export class OnStartUpload extends FileUploaderEvent {
  constructor(item) {
    super();
    this.item = item;
  }
}
export class OnAllItemsComplete extends FileUploaderEvent {
  constructor() {
    super();
  }
}