import { BaseFeature, FeatureType } from '@core/shared/feature';
import { MEDIA_ASSET_PROVIDER_TOKEN } from '@common/media/shared';
export class CloudinaryBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-common-cloudinary-feature","featureId":"common-cloudinary"},
      icons: [],
      abilityActions: false,
      plugins: [
      // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'CloudinaryFrontendFeature'},
      // {forPlatform:'admin', type:FeatureType.Permanent, pluginClassName:'CloudinaryFrontendAdminFeature'},
      {
        forPlatform: 'backend',
        type: FeatureType.Permanent,
        pluginClassName: 'CloudinaryBackendFeature'
      }, {
        forPlatform: 'backend-admin',
        type: FeatureType.Permanent,
        pluginClassName: 'CloudinaryBackendAdminFeature'
      }],
      providers: [{
        forPlatform: 'frontend',
        token: MEDIA_ASSET_PROVIDER_TOKEN,
        providerName: 'MediaAssetProviderCloudinaryFrontend',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEDIA_ASSET_PROVIDER_TOKEN,
        providerName: 'MediaAssetProviderCloudinaryFrontend',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'backend',
        token: MEDIA_ASSET_PROVIDER_TOKEN,
        providerName: 'MediaAssetProviderCloudinaryBackend',
        importFrom: 'backend-shared'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {}
      }
    };
  }
}