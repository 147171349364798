import { __decorate, __metadata } from "tslib";
import { ComponentRef, Injector, SimpleChanges, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribe } from '../utils';
import { DynamicComponentHelper } from './dynamic-component.helper';
import * as i0 from "@angular/core";
/**
 * DynamicComponentDirective is a structural directive which should be used on ng-container.
 * <ng-container *dynamicComponent="{id:'selector',inputs,outputs}"></ng-container>
 * Based on the passed id, it will check registry if a replacement component for that ID is available.
 * Then it will either render the found replacement or fall back to the default component (specified by @ReplaceableComponent).
 */
let DynamicComponentDirective = class DynamicComponentDirective {
  set _config(config) {
    this.config = {
      inputs: {},
      outputs: {},
      ...config
    };
  }
  constructor(injector, vcr) {
    this.injector = injector;
    this.vcr = vcr;
    this.defaultComponentContext = {};
    this.componentBindings = [];
  }
  ngOnInit() {
    if (!this.config.component) throw new Error('DynamicComponentdirective failed: no component info was passed!');
  }
  ngOnChanges(changes) {
    if (!changes?._config) return;
    if (changes._config.currentValue.component && changes._config.currentValue.component !== changes._config.previousValue?.component) {
      setTimeout(() => {
        this.componentInfo = DynamicComponentHelper.getComponentInfoFromInput(this.config);
        this.setupCustomComponent(this.componentInfo);
      });
    } else if (changes._config.currentValue.inputs) {
      // TODO: without deep diff, the if check will always result in true
      DynamicComponentHelper.updateComponentInputValues(this.componentRef, this.componentInfo, this.config.inputs);
    }
  }
  /*********************************
   * Handling of custom component
   *********************************/
  clearBindings() {
    this.componentBindings.forEach(binding => binding.next(true));
    this.componentBindings = [];
  }
  setupCustomComponent(componentInfo) {
    this.clearBindings();
    // this.removeComponent();
    const {
      componentRef,
      destroyBindings
    } = DynamicComponentHelper.makeCustomComponent(this.config, this.injector, this.vcr, this.componentRef);
    this.componentBindings.push(destroyBindings);
    this.componentRef = componentRef;
  }
  static {
    this.ɵfac = function DynamicComponentDirective_Factory(t) {
      return new (t || DynamicComponentDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: DynamicComponentDirective,
      selectors: [["", "dynamicComponent", ""]],
      inputs: {
        _config: [i0.ɵɵInputFlags.None, "dynamicComponent", "_config"]
      },
      features: [i0.ɵɵProvidersFeature([]), i0.ɵɵNgOnChangesFeature]
    });
  }
};
DynamicComponentDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [Injector, ViewContainerRef])], DynamicComponentDirective);
export { DynamicComponentDirective };