import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, effect, input } from '@angular/core';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i3 from "../../../../../../ui/frontend-shared/src/lib/toolbar/toolbar";
import * as i4 from "../media-grid-settings/media-grid-settings.component";
import * as i5 from "../media-grid-sorting/media-grid-sorting.component";
function MediaAssetGridToolbarComponent_p_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p", 4)(1, "span");
    i0.ɵɵtext(2, "Suchergebnisse");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 5);
    i0.ɵɵlistener("click", function MediaAssetGridToolbarComponent_p_5_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClickResetSearch());
    });
    i0.ɵɵelementEnd()();
  }
}
let MediaAssetGridToolbarComponent = class MediaAssetGridToolbarComponent {
  constructor(
  // protected modelFactoryProvider:ModelFactoryProvider,
  // protected itemServiceFactory:ItemServiceFactory,
  // protected mediaProviders:MediaAssetProvidersFrontendService,
  // protected http:HttpClient, 
  cd) {
    this.cd = cd;
    // @Input() itemsPerPage:number = -1;
    this.service = input(null);
    this._service = effect(() => {});
    this.listController = input(null);
    this._listController = effect(() => {
      const listController = this.listController();
      if (!listController) return;
      const obs = listController.getSubscriptions();
      this.hasActiveFilters$ = obs.hasActiveFilters$.pipe(takeWhileAlive(this));
    });
    this.updateFilters = data => {
      // to allow users to type without every char triggering reload, add 300ms of debounce
      this.listController().updateListState({
        filters: data
      }, 300);
      this.cd.markForCheck();
    };
  }
  bindFilterFieldsetFormService(service) {
    this.listController().bindFilterFieldsetFormService(service);
  }
  onClickResetSearch() {
    this.listController().updateListState({
      filters: {}
    });
  }
  static {
    this.ɵfac = function MediaAssetGridToolbarComponent_Factory(t) {
      return new (t || MediaAssetGridToolbarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MediaAssetGridToolbarComponent,
      selectors: [["media-asset-grid-toolbar"]],
      inputs: {
        service: [i0.ɵɵInputFlags.SignalBased, "service"],
        listController: [i0.ɵɵInputFlags.SignalBased, "listController"]
      },
      decls: 7,
      vars: 5,
      consts: [[1, "ui-toolbar-group-left"], [3, "listController"], [1, "ui-toolbar-group-right"], ["class", "active-filters-notification", 4, "ngIf"], [1, "active-filters-notification"], ["uiButton", "", "title", "Suche beenden", "icon", "times", 1, "small", "ui-button-invisible", 3, "click"]],
      template: function MediaAssetGridToolbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ui-toolbar")(1, "div", 0);
          i0.ɵɵelement(2, "media-asset-grid-settings", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2);
          i0.ɵɵelement(4, "media-asset-grid-sorting", 1);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(5, MediaAssetGridToolbarComponent_p_5_Template, 4, 0, "p", 3);
          i0.ɵɵpipe(6, "async");
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("listController", ctx.listController());
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("listController", ctx.listController());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(6, 3, ctx.hasActiveFilters$));
        }
      },
      dependencies: [i1.NgIf, i2.UiButtonComponent, i3.Toolbar, i4.MediaAssetGridSettingsComponent, i5.MediaAssetGridSortingComponent, i1.AsyncPipe],
      styles: ["filter-fieldset.has-active-filters[_ngcontent-%COMP%]     ui-input-group{outline:2px solid var(--contrastColor)}.active-filters-notification[_ngcontent-%COMP%]{background:var(--softGreyColor);padding:5px;text-align:center;font-weight:700;width:300px;margin:10px auto 20px;border-radius:18px;position:relative}.active-filters-notification[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{position:absolute;right:3px;top:2px}"],
      changeDetection: 0
    });
  }
};
MediaAssetGridToolbarComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef])], MediaAssetGridToolbarComponent);
export { MediaAssetGridToolbarComponent };