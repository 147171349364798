import { isEqualDeep } from "@core/shared/utils";
import { Model } from "@core/shared/model";
import { createFormControls, DynamicFormBuilder } from "ngx-dynamic-form-builder";
export function updateDynamicFormArray(formArray, factory, value) {
  if (formArray.length !== value.length) {
    formArray.clear();
    value.forEach(val => {
      formArray.push(createformArrayChildFormGroup(val, formArray));
    });
    // this.bindFormGroupListeners()
    formArray.setValue(value, {
      emitEvent: false,
      onlySelf: true
    });
  } else {
    // FormArray object comparision has issues.
    // compare old and new value and patch form only when a difference has been found.
    const oldValue = formArray.value;
    const newValue = value.map(item => {
      if (item instanceof Model) {
        return factory.classTransformService.instanceToPlain(item);
      }
      return item;
    });
    const valueHasChanged = isEqualDeep(oldValue, newValue);
    if (!valueHasChanged) {
      formArray.patchValue(newValue, {
        emitEvent: false,
        onlySelf: true
      });
    }
  }
}
export function createformArrayChildFormGroup(value, formArray) {
  // cannot use DFB.childFormGroup because it would fetch metadata without the correct configuration context only known within rootFormGroup configuration.
  // so instead we call createFormControls directly, providing the already available metadata from parent formGroup.
  const formBuilder = new DynamicFormBuilder();
  const metadata = getMetadataForFormArrayItem(formArray);
  // if(this._debug) console.log(this.debugInfo()+'.createChildFormGroup',value,metadata,this.formGroup);
  const form = createFormControls({
    classType: value.prototype,
    form: formBuilder.group({}),
    formBuilder,
    defaultValue: value,
    rootFormGroup: formArray.root,
    metadata
  });
  return form;
}
export function getMetadataForFormArrayItem(formArray) {
  const ownMetadata = formArray.classTransformMetadata;
  if (!ownMetadata) throw Error('own formgroup has no metadata set!');
  if (!ownMetadata.isArray) throw Error('ownMetadata is no array item!');
  return ownMetadata;
}
export function getMetadataForFormGroupProperty(formGroup, propertyName) {
  const ownMetadata = formGroup.classTransformMetadata;
  if (!ownMetadata) throw Error('formgroup has no metadata set!');
  // if(!ownMetadata.isArray) throw Error('ownMetadata is no array item!');
  const match = ownMetadata.properties.find(entry => entry.propertyName === propertyName);
  if (!match) throw new Error('own metadata has no entry for propertyName ' + propertyName);
  return match;
}