import { FilterFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class FilterSelectbuttonInstruction extends FilterFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap,
      placeholder: 'placeholder'
    };
    this.attributeMap = {
      'e2e-filter': 'formControlName'
    };
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import('@common/ui/frontend-shared/input-selectbutton').then(bundle => {
      return {
        component: bundle.InputSelectButton,
        module: bundle.UiInputSelectButtonModule
      };
    });
  }
}