import { __decorate, __metadata } from "tslib";
import { EditorDefinition, Expose, IsEmail, IsOptional, IsString, IsValidInsuranceMembershipId, MinLength, Model } from '@core/shared/model';
// part of registration request to be sent to server, data will be used to create UserItem
let MembershipRegistrationDataInsuranceSimpleModel = class MembershipRegistrationDataInsuranceSimpleModel extends Model {};
__decorate([Expose(), IsString({
  message: 'Bitte wählen Sie Ihre Krankenkasse aus.'
}), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "insuranceId", void 0);
__decorate([Expose(), IsValidInsuranceMembershipId(), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "insuranceMembershipId", void 0);
__decorate([Expose(), MinLength(2, {
  message: 'Bitte geben Sie den Vornamen an.'
}), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "firstName", void 0);
__decorate([Expose(), MinLength(2, {
  message: 'Bitte geben Sie den Nachnamen an.'
}), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "lastName", void 0);
__decorate([Expose(), IsEmail(undefined, {
  message: 'Bitte geben Sie Ihre E-Mail-Adresse an.'
}), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "email", void 0);
__decorate([Expose(), IsOptional(), __metadata("design:type", String)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "phone", void 0);
__decorate([Expose(), __metadata("design:type", Boolean)], MembershipRegistrationDataInsuranceSimpleModel.prototype, "allow3rdPartyContact", void 0);
MembershipRegistrationDataInsuranceSimpleModel = __decorate([EditorDefinition(['insuranceId', 'insuranceMembershipId']), EditorDefinition('with-account-fields', ['insuranceId', 'insuranceMembershipId', 'firstName', 'lastName', 'email', 'phone', 'allow3rdPartyContact'])
// @EditorDefinition(['topic','reason','careDegree','insuranceMembershipId','firstName','lastName','birthdate','address','zipcode','city'])
], MembershipRegistrationDataInsuranceSimpleModel);
export { MembershipRegistrationDataInsuranceSimpleModel };