export function checkForRequiredPermissions(info, grantedPermissions) {
  let allowed = true;
  if (!info.requiredPermissions) {
    return true;
  }
  info.requiredPermissions.forEach(perm => {
    if (!grantedPermissions.includes(perm)) allowed = false;
  });
  return allowed;
}
export function checkForRequiredScope(info, currentScope) {
  if (!info.requiredScopes?.length) {
    return true;
  }
  return info.requiredScopes.includes(currentScope);
}