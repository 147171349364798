import { __decorate, __metadata } from "tslib";
import { HttpErrorResponse, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { AccessState } from '@core/frontend-shared';
import { MAINTENANCE_MODE_HEADER } from '@common/maintenance/shared';
import { AppMessage } from "@core/frontend-shared";
import { Select } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { MaintenanceFacade, MaintenanceState } from '../state/maintenance.state';
import * as i0 from "@angular/core";
import * as i1 from "../state/maintenance.state";
export class MaintenanceModeInterceptor {
  // abortRequests$ = new BehaviorSubject(false);
  constructor(facade) {
    this.facade = facade;
    this.mmEnabled = false;
    this.SUMMEnabled = false;
    this.isSU$.subscribe(isSU => {
      this.isSU = isSU;
    });
    this.SUMMEnabled$.subscribe(SUMMEnabled => {
      this.SUMMEnabled = SUMMEnabled;
    });
    this.mmEnabled$.subscribe(mmEnabled => {
      this.mmEnabled = mmEnabled;
    });
  }
  intercept(request, next) {
    if (this.isSU) {
      return next.handle(request).pipe(
      // Skip `sent` event
      filter(e => e instanceof HttpResponse), map(res => {
        const suMaintenanceModeEnable = res.headers.get(MAINTENANCE_MODE_HEADER) === '1';
        if (suMaintenanceModeEnable) this.facade.enableSUMaintenanceMode();
        return res;
      }));
    } else {
      // if MM is already enabled, reject immediately
      if (this.mmEnabled) {
        return throwError(() => new Error('Maintenance Mode enabled, aborting API request!'));
      }
      // send API request
      return next.handle(request).pipe(catchError(err => {
        const maintenanceModeEnabled = err.status === 503 || err.status === '503' || this.mmEnabled;
        if (maintenanceModeEnabled) {
          let httpError;
          if (err instanceof AppMessage) {
            err.discard();
            httpError = err.originalError;
          } else {
            httpError = err;
          }
          const message = httpError.error.message;
          this.facade.enableMaintenanceMode(message);
          return throwError(() => new Error('Maintenance Mode enabled'));
        } else {
          return throwError(() => err);
        }
      }));
    }
  }
  static {
    this.ɵfac = function MaintenanceModeInterceptor_Factory(t) {
      return new (t || MaintenanceModeInterceptor)(i0.ɵɵinject(i1.MaintenanceFacade));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: MaintenanceModeInterceptor,
      factory: MaintenanceModeInterceptor.ɵfac
    });
  }
}
__decorate([Select(AccessState.isSuperUser), __metadata("design:type", Observable)], MaintenanceModeInterceptor.prototype, "isSU$", void 0);
__decorate([Select(MaintenanceState.SUMaintenanceModeEnabled), __metadata("design:type", Observable)], MaintenanceModeInterceptor.prototype, "SUMMEnabled$", void 0);
__decorate([Select(MaintenanceState.maintenanceModeEnabled), __metadata("design:type", Observable)], MaintenanceModeInterceptor.prototype, "mmEnabled$", void 0);