import { ItemRestraints } from '@core/shared';
export class SkillsetRestraints extends ItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return this.isExecutedBySystem() || this.requirePermission('SUPERUSER');
  }
  canToggleItem(item) {
    if (item.isSystem) return false;
    return this.isExecutedBySystem() || this.requirePermission('SUPERUSER');
  }
  canUpdateItem(item, oldItem) {
    return this.isExecutedBySystem() || this.requirePermission('SUPERUSER');
  }
  canRemoveItem(item) {
    if (item.isSystem) return false;
    return this.isExecutedBySystem() || this.requirePermission('SUPERUSER');
  }
}