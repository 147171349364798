import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
export { Dayjs } from "dayjs";
// TODO: better to export a factory function instead of doing this immediately?
// caused some errors when trying to add this to utils index file.
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('de');
export { dayjs };