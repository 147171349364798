import { __decorate, __metadata } from "tslib";
import { ElementRef } from '@angular/core';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import * as i0 from "@angular/core";
let InputRefBlockerDirective = class InputRefBlockerDirective {
  constructor(host) {
    this.host = host;
  }
  static {
    this.ɵfac = function InputRefBlockerDirective_Factory(t) {
      return new (t || InputRefBlockerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: InputRefBlockerDirective,
      selectors: [["", "inputRefBlocker", ""]],
      standalone: true
    });
  }
};
InputRefBlockerDirective = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ElementRef])], InputRefBlockerDirective);
export { InputRefBlockerDirective };