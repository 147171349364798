// headers must be lowercase!
export const HEADER_UNIQUE_UPLOAD_ID = "x-unique-upload-id";
export const HEADER_PROVIDER_ID = "x-provider-id";
export const HEADER_PROVIDER_TARGETPATH = "x-provider-target-path";
export const HEADER_ASSET_NAME = "x-asset-name";
export const HEADER_ASSET_ID = "x-asset-id";
export const HEADER_ASSET_TRANSFORMATIONS = "x-asset-transformations";
export const HEADER_CONTENT_RANGE = 'content-range';
export function getRequestedHeaderTransformations(headers) {
  return headers[HEADER_ASSET_TRANSFORMATIONS] === '1';
}