import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, ElementRef, forwardRef, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModelFormServiceFactory } from '../form-service';
import { ModelBasedControlValueAccessor } from '../model-based-control-value-accessor';
import { FilterFormService } from './filter-form.service';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../form-service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../instruction-outlet/instruction-outlet.directive";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/input-group/input-group";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/button/button";
function FilterFieldsetComponent_div_0_ui_input_group_3_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = (a0, a1, a2) => ({
  instruction: a0,
  formGroup: a1,
  formControlName: a2
});
function FilterFieldsetComponent_div_0_ui_input_group_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 2);
    i0.ɵɵtemplate(1, FilterFieldsetComponent_div_0_ui_input_group_3_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const instr_r6 = ctx.$implicit;
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("formGroup", ctx_r4.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("instructionOutlet", i0.ɵɵpureFunction3(2, _c0, instr_r6, ctx_r4.formService.formGroup, instr_r6.config.formControlName));
  }
}
function FilterFieldsetComponent_div_0_ui_input_group_3_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function FilterFieldsetComponent_div_0_ui_input_group_3_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r9);
      i0.ɵɵnextContext(2);
      const _r1 = i0.ɵɵreference(2);
      return i0.ɵɵresetView(_r1.reset());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("label", ctx_r5.showResetLabel ? ctx_r5.resetLabel : undefined);
  }
}
function FilterFieldsetComponent_div_0_ui_input_group_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-input-group", 6);
    i0.ɵɵtemplate(1, FilterFieldsetComponent_div_0_ui_input_group_3_ng_container_1_Template, 2, 6, "ng-container", 7)(2, FilterFieldsetComponent_div_0_ui_input_group_3_button_2_Template, 1, 1, "button", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("breakpoint", ctx_r2.inputGroupBreakpoint)("rounded", ctx_r2.rounded);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.instructions);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.showResetButton && ctx_r2.canReset);
  }
}
function FilterFieldsetComponent_div_0_div_4_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function FilterFieldsetComponent_div_0_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 2);
    i0.ɵɵtemplate(1, FilterFieldsetComponent_div_0_div_4_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const instr_r12 = ctx.$implicit;
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("formGroup", ctx_r10.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("instructionOutlet", i0.ɵɵpureFunction3(2, _c0, instr_r12, ctx_r10.formService.formGroup, instr_r12.config.formControlName));
  }
}
function FilterFieldsetComponent_div_0_div_4_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function FilterFieldsetComponent_div_0_div_4_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r15);
      i0.ɵɵnextContext(2);
      const _r1 = i0.ɵɵreference(2);
      return i0.ɵɵresetView(_r1.reset());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("label", ctx_r11.showResetLabel ? ctx_r11.resetLabel : undefined);
  }
}
function FilterFieldsetComponent_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtemplate(1, FilterFieldsetComponent_div_0_div_4_ng_container_1_Template, 2, 6, "ng-container", 7)(2, FilterFieldsetComponent_div_0_div_4_button_2_Template, 1, 1, "button", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r3.instructions);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.showResetButton && ctx_r3.canReset);
  }
}
function FilterFieldsetComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "form", 2, 3);
    i0.ɵɵtemplate(3, FilterFieldsetComponent_div_0_ui_input_group_3_Template, 3, 4, "ui-input-group", 4)(4, FilterFieldsetComponent_div_0_div_4_Template, 3, 2, "div", 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("formGroup", ctx_r0.formService.formGroup);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.grouped);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.grouped);
  }
}
export const FILTER_FIELDSET_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterFieldsetComponent),
  multi: true
};
let FilterFieldsetComponent = class FilterFieldsetComponent extends ModelBasedControlValueAccessor {
  set _value(value) {
    this.formService?.setValue(value);
    this.value = value;
    this.calculateCanReset(value);
  }
  set instructions(instructions) {
    this.directInstructions$.next(instructions.map(f => {
      // formService will be initialized using setupUsingInstructions.
      // that setup routine already sets default values based on a "value" property within each field.
      // so we just have to map the definable defaultValue prop to "value" prop.
      const enhanced = f;
      enhanced.value = f.config.defaultValue || null;
      return enhanced;
    }));
  }
  get instructions() {
    return this.directInstructions$.getValue();
  }
  constructor(cd, formServiceFactory) {
    super(cd, formServiceFactory);
    this.formServiceFactory = formServiceFactory;
    this._model = {};
    this.defaultModel = {};
    this.rounded = true;
    this.grouped = true;
    this.showResetButton = true;
    this.inputGroupBreakpoint = -1;
    this.showResetLabel = false;
    this.resetLabel = 'Löschen';
    this.canReset = false;
    // Note that FilterFieldset does NOT inherit SimpleFieldset!
    this.directInstructions$ = new BehaviorSubject(null);
    this.innerFormData = {};
    this.submitShortcut = 'ctrl+enter';
  }
  _tryToInitialize() {
    if (this.initialized) return;
    // configurate formService to work without Model/Factory/Service
    this.createDefaultFormService();
    this.formService.setupUsingInstructions(this.instructions);
    this._executeInitialization({
      initializeFormService: true
    });
  }
  bindFormServiceObservables() {
    super.bindFormServiceObservables();
    this.formService.valueChanges$.pipe(takeWhileAlive(this)).subscribe(value => this.calculateCanReset(value));
  }
  createDefaultFormService() {
    this.formService$.next(this.formServiceFactory.createUninitialized(FilterFormService));
    if (this.debug) this.formService.debug();
  }
  submit() {
    if (this.formService) this.formService.save();
  }
  onEnter(event) {
    const isCtrl = event.ctrlKey;
    if (this.submitShortcut === 'ctrl+enter') {
      if (isCtrl) this.submit();
    } else {
      this.submit();
    }
  }
  calculateCanReset(value) {
    const keys = Object.keys(value);
    this.cd.markForCheck();
    for (let i = 0; i < keys.length; i++) {
      if (typeof value[keys[i]] !== 'undefined' && value[keys[i]] !== null) {
        this.canReset = true;
        return;
      }
    }
    this.canReset = false;
  }
  static {
    this.ɵfac = function FilterFieldsetComponent_Factory(t) {
      return new (t || FilterFieldsetComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.ModelFormServiceFactory));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: FilterFieldsetComponent,
      selectors: [["filter-fieldset"]],
      hostVars: 2,
      hostBindings: function FilterFieldsetComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("has-active-filters", ctx.canReset);
        }
      },
      inputs: {
        rounded: "rounded",
        grouped: "grouped",
        showResetButton: "showResetButton",
        inputGroupBreakpoint: "inputGroupBreakpoint",
        showResetLabel: "showResetLabel",
        resetLabel: "resetLabel",
        _value: [i0.ɵɵInputFlags.None, "value", "_value"],
        instructions: "instructions",
        title: "title",
        innerForm: "innerForm",
        innerFormData: "innerFormData",
        submitShortcut: "submitShortcut"
      },
      features: [i0.ɵɵProvidersFeature([FILTER_FIELDSET_VALUE_ACCESSOR]), i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 3,
      consts: [["class", "ui-toolbar-group-right", 4, "ngIf"], [1, "ui-toolbar-group-right"], [3, "formGroup"], ["formControl", "ngForm"], ["class", "filter-fieldset-fields", 3, "breakpoint", "rounded", 4, "ngIf"], ["class", "filter-fieldset-fields", 4, "ngIf"], [1, "filter-fieldset-fields", 3, "breakpoint", "rounded"], [3, "formGroup", 4, "ngFor", "ngForOf"], ["e2e-filter-bar-reset-button", "", "uiButton", "", "icon", "times", "title", "Filter zur\u00FCcksetzen", "class", "button-reset ui-button-contrast", 3, "label", "click", 4, "ngIf"], [4, "instructionOutlet"], ["e2e-filter-bar-reset-button", "", "uiButton", "", "icon", "times", "title", "Filter zur\u00FCcksetzen", 1, "button-reset", "ui-button-contrast", 3, "label", "click"], [1, "filter-fieldset-fields"], ["e2e-filter-bar-reset-button", "", "uiButton", "", "icon", "times", "title", "Filter zur\u00FCcksetzen", "class", "button-reset", 3, "label", "click", 4, "ngIf"], ["e2e-filter-bar-reset-button", "", "uiButton", "", "icon", "times", "title", "Filter zur\u00FCcksetzen", 1, "button-reset", 3, "label", "click"]],
      template: function FilterFieldsetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, FilterFieldsetComponent_div_0_Template, 5, 3, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.initialized$) && (ctx.instructions == null ? null : ctx.instructions.length) > 0);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, i3.ɵNgNoValidate, i3.NgControlStatusGroup, i3.FormGroupDirective, i4.InstructionOutletDirective, i5.InputGroup, i6.UiButtonComponent, i2.AsyncPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
FilterFieldsetComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, ModelFormServiceFactory])], FilterFieldsetComponent);
export { FilterFieldsetComponent };