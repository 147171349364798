import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudRequestEventType, LocalMessageHandlerService } from '@core/frontend-shared';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { CrudRequestType, getItemModelText } from '@core/shared';
import { BehaviorSubject, combineLatest, delay, distinctUntilChanged, filter, skip, take } from 'rxjs';
import { CrudManagerService } from '../crud-manager/crud-manager.service';
import { CrudControllerFactory } from '../controller/controller-factory';
import * as i0 from "@angular/core";
import * as i1 from "../crud-manager/crud-manager.service";
import * as i2 from "@angular/router";
import * as i3 from "../controller/controller-factory";
import * as i4 from "@core/frontend-shared";
/**
 * base class handling loading and preparing of a CrudItem.
 * subscribe to crudManager.modeAndItem$ to get the loaded Item.
 */
let CrudItemViewComponent = class CrudItemViewComponent {
  constructor(cd, crudManager, route, router, controllerFactory, messages) {
    this.cd = cd;
    this.crudManager = crudManager;
    this.route = route;
    this.router = router;
    this.controllerFactory = controllerFactory;
    this.messages = messages;
    // keep in mind that this will only be updated when actively triggering a refresh / subscribeToNextConfigurationUpdate!
    this.editorConfiguration$ = new BehaviorSubject(null);
    this.routeItemId$ = new BehaviorSubject(null);
    if (!this.crudManager) throw new Error('Missing dependency: CrudManagerService was not found.');
    this.itemController = controllerFactory.createItemController(crudManager.service, crudManager.restraints);
    // allow child constructor to execute before bindObservables
    setTimeout(() => this.bindObservables(), 1);
  }
  bindObservables() {
    // listen to route changes to trigger configuration refresh
    combineLatest({
      params: this.route.params,
      data: this.route.data
    }).pipe(
    // delay so that it executes AFTER child constructor has been executed.
    delay(1), takeWhileAlive(this)).subscribe(({
      params,
      data
    }) => {
      this.subscribeToNextConfigurationUpdate();
      this.handleRouteChange(params, data);
    });
    // on configuration update, call handler
    this.editorConfiguration$.pipe(filter(config => !!config), takeWhileAlive(this)).subscribe(config => {
      this.handleConfigurationUpdate(config);
    });
    this.routeItemId$.pipe(filter(itemId => !!itemId), distinctUntilChanged(), takeWhileAlive(this)).subscribe(itemId => {
      this.loadItem(itemId);
    });
    this.crudManager.crudService.listenToCrudStateEvents$().pipe(takeWhileAlive(this)).subscribe(event => {
      if (event.eventType === CrudRequestEventType.RequestCompleted) {
        if (event.crudAction === CrudRequestType.Update) {
          this.refresh();
        }
      }
    });
  }
  refresh() {
    const itemId = this.routeItemId$.getValue();
    this.itemController.loadItem(itemId).subscribe({
      next: item => {
        this.subscribeToNextConfigurationUpdate();
        this.crudManager.configurateModeAndItem({
          mode: 'view',
          item
        });
        this.cd.markForCheck();
      }
    });
  }
  loadItem(itemId) {
    if (typeof itemId !== 'number') throw new Error('CrudItemView.loadItem received non-numeric value for itemId');
    this.itemController.loadItem(itemId).subscribe({
      next: item => {
        this.crudManager.configurateModeAndItem({
          mode: 'view',
          item
        });
        this.cd.markForCheck();
      },
      error: () => {
        this.messages.create(getItemModelText('itemNotExists', {
          id: itemId
        })).setSeverity('warn');
        this.router.navigate(['../'], {
          relativeTo: this.route
        });
      }
    });
  }
  handleRouteChange(params, data) {
    if (params.id) {
      const itemId = typeof params.id === 'number' ? params.id : parseInt(params.id, 10);
      this.routeItemId$.next(itemId);
    } else {
      throw new Error('CrudEditorComponent was unable to understand Route Configuration!');
    }
  }
  subscribeToNextConfigurationUpdate() {
    this.crudManager.itemEditorDetails$.pipe(takeWhileAlive(this),
    // as observable is hot it will emit immediately with current data, BEFORE routeChanges called configurateModeAndItem.
    // we dont want that emission to be processed, so it is being skipped here.
    skip(1),
    // config may be null, skip those emissions
    filter(config => !!config),
    // only take 1 to make it cleaner. When route data changes, a new configuration subscription will be created.
    take(1)).subscribe(config => {
      this.editorConfiguration$.next(config);
      this.cd.markForCheck();
    });
  }
  closeView() {
    this.router.navigate(['../'], {
      relativeTo: this.route
    });
  }
  static {
    this.ɵfac = function CrudItemViewComponent_Factory(t) {
      return new (t || CrudItemViewComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.CrudManagerService), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.CrudControllerFactory), i0.ɵɵdirectiveInject(i4.LocalMessageHandlerService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudItemViewComponent,
      selectors: [["ng-component"]],
      decls: 0,
      vars: 0,
      template: function CrudItemViewComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
};
CrudItemViewComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, CrudManagerService, ActivatedRoute, Router, CrudControllerFactory, LocalMessageHandlerService])], CrudItemViewComponent);
export { CrudItemViewComponent };