import { __decorate, __metadata } from "tslib";
import { AppEvent, Expose, Model } from '@core/shared';
import { UserItem } from '../models/user.item';
import { UserConversion } from '../constants';
/**
 * Fired when a user is converted to a new role/assignment. (e.g. from team member to manager)
 */
let UserConvertedEvent = class UserConvertedEvent extends Model {};
__decorate([Expose(), __metadata("design:type", String)], UserConvertedEvent.prototype, "conversionType", void 0);
__decorate([Expose(), __metadata("design:type", UserItem)], UserConvertedEvent.prototype, "userBefore", void 0);
__decorate([Expose(), __metadata("design:type", UserItem)], UserConvertedEvent.prototype, "userAfter", void 0);
UserConvertedEvent = __decorate([AppEvent('users', 'user-converted')], UserConvertedEvent);
export { UserConvertedEvent };