import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { EventEmitter, QueryList } from '@angular/core';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiIconsModule } from '@common/ui/frontend-shared/icons';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { Store } from '@ngxs/store';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { BehaviorSubject, combineLatest, map, of, switchMap } from 'rxjs';
import { MembershipRegistrationFacade, MembershipRegistrationState } from '../../state';
import * as i0 from "@angular/core";
import * as i1 from "../../state";
import * as i2 from "@ngxs/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i5 from "../../../../../../ui/frontend-shared/src/lib/input-group/input-group";
import * as i6 from "@fortawesome/angular-fontawesome";
const _c0 = ["subform"];
function MembershipSteppedFormComponent_ng_template_3_fa_icon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 11);
  }
}
function MembershipSteppedFormComponent_ng_template_3_li_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 12);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 13);
    i0.ɵɵelement(2, "path", 14);
    i0.ɵɵelementEnd()();
  }
}
const _c1 = (a0, a1) => ({
  "current": a0,
  "completed": a1
});
function MembershipSteppedFormComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 6)(1, "b", 7);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 8);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, MembershipSteppedFormComponent_ng_template_3_fa_icon_5_Template, 1, 0, "fa-icon", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, MembershipSteppedFormComponent_ng_template_3_li_6_Template, 3, 0, "li", 10);
  }
  if (rf & 2) {
    const formInfo_r2 = ctx.$implicit;
    const index_r3 = ctx.index;
    const isLast_r4 = ctx.last;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c1, index_r3 === ctx_r0.info.currentIndex, index_r3 < ctx_r0.info.currentIndex));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", index_r3 + 1, ".");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(formInfo_r2.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", index_r3 < ctx_r0.info.currentIndex);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !isLast_r4);
  }
}
function MembershipSteppedFormComponent_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 15);
    i0.ɵɵlistener("click", function MembershipSteppedFormComponent_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.prev());
    });
    i0.ɵɵelementEnd();
  }
}
const _c2 = ["*"];
/**
 * Stepped form was a hardcoded component of membership 1.0.
 * It has been rewritten (but untested) as ui component, to be used by membership 2.0 plugins.
 */
// just for development purposes!
const IGNORE_VALIDATION_ERRORS = false;
let MembershipSteppedFormComponent = class MembershipSteppedFormComponent {
  set requiredForms(forms) {
    this.requiredForms$.next(forms);
    this.currentFormIndex$.next(0);
  }
  constructor(registration, store) {
    this.registration = registration;
    this.store = store;
    this.requiredForms$ = new BehaviorSubject([]);
    this.currentFormIndex$ = new BehaviorSubject(0);
    this.onChangeStep = new EventEmitter();
    this.onComplete = new EventEmitter();
    this.currentFormAndIndex$ = combineLatest([this.currentFormIndex$, this.requiredForms$]).pipe(map(([currentIndex, forms]) => {
      const formType = forms[currentIndex].id;
      const allFormData = this.store.selectSnapshot(MembershipRegistrationState.allFormData);
      return {
        currentIndex,
        formType,
        initialFormValue: allFormData[formType]
      };
    }));
    this.info$ = combineLatest([this.requiredForms$]).pipe(map(([requiredForms]) => {
      return {
        requiredForms
      };
    }));
  }
  next() {
    if (this.forms.length !== 1) throw new Error('RegistrationFormsComponent Error: There should always be exactly ONE child form in view!');
    this.validateCurrentForm().pipe(switchMap(isValid => {
      return this.storeCurrentFormData();
    })).subscribe({
      error: console.error,
      next: success => {
        // if(IGNORE_VALIDATION_ERRORS || success) {	
        this.setFormIndex('next');
        // }
      }
    });
  }
  prev() {
    this.setFormIndex('prev');
  }
  setFormIndex(index) {
    const requiredForms = this.requiredForms$.getValue();
    const currentFormIndex = this.currentFormIndex$.getValue();
    let requestedIndex;
    if (index === 'prev') {
      requestedIndex = currentFormIndex - 1;
    } else if (index === 'next') {
      requestedIndex = currentFormIndex + 1;
    } else if (typeof index === 'string') {
      // must be a RegistrationFormType
      requestedIndex = requiredForms.findIndex(info => info.id === index);
    } else {
      requestedIndex = index;
    }
    if (isNaN(requestedIndex)) {
      throw new Error('Cannot complete setFormIndex. Incoming data could not be resolved to a form index. (' + index + ' resolved to ' + requestedIndex + ')');
    }
    if (requestedIndex < 0) requestedIndex = 0;
    if (requestedIndex > requiredForms.length - 1) {
      // if "next" formIndex is requested but we already are at last, 
      // this indicates the entire form step should be completed.
      this.onComplete.emit({});
    } else {
      this.currentFormIndex$.next(requestedIndex);
      this.onChangeStep.emit({
        from: currentFormIndex,
        to: requestedIndex
      });
    }
  }
  validateCurrentForm() {
    return of(true);
  }
  storeCurrentFormData() {
    return this.forms.first.tryCompleteForm();
  }
  static {
    this.ɵfac = function MembershipSteppedFormComponent_Factory(t) {
      return new (t || MembershipSteppedFormComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.Store));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MembershipSteppedFormComponent,
      selectors: [["membership-stepped-form"]],
      contentQueries: function MembershipSteppedFormComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.forms = _t);
        }
      },
      inputs: {
        requiredForms: "requiredForms",
        title: "title",
        info: "info"
      },
      outputs: {
        onChangeStep: "onChangeStep",
        onComplete: "onComplete"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 12,
      vars: 7,
      consts: [[1, "steps"], ["ngFor", "", 3, "ngForOf"], [1, "forms"], [1, "ui-margin", "bottom-bar"], ["uiButton", "", "label", "Zur\u00FCck", "class", "ui-button-secondary", 3, "click", 4, "ngIf"], ["uiButton", "", "label", "Weiter", 3, "click"], [1, "step", 3, "ngClass"], [1, "number"], [1, "caption"], ["icon", "check", "size", "2x", 4, "ngIf"], ["class", "spacer", 4, "ngIf"], ["icon", "check", "size", "2x"], [1, "spacer"], ["viewBox", "0 0 100 100", 0, "xml", "space", "preserve"], ["d", "M 0 0 L 100 50 L 0 100 L 15 50 z"], ["uiButton", "", "label", "Zur\u00FCck", 1, "ui-button-secondary", 3, "click"]],
      template: function MembershipSteppedFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "h2");
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "ul", 0);
          i0.ɵɵtemplate(3, MembershipSteppedFormComponent_ng_template_3_Template, 7, 8, "ng-template", 1);
          i0.ɵɵpipe(4, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 2);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 3)(8, "ui-input-group");
          i0.ɵɵtemplate(9, MembershipSteppedFormComponent_button_9_Template, 1, 0, "button", 4);
          i0.ɵɵpipe(10, "async");
          i0.ɵɵelementStart(11, "button", 5);
          i0.ɵɵlistener("click", function MembershipSteppedFormComponent_Template_button_click_11_listener() {
            return ctx.next();
          });
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.title);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(4, 3, ctx.requiredForms$));
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(10, 5, ctx.currentFormIndex$) > 0);
        }
      },
      dependencies: [CommonModule, i3.NgClass, i3.NgForOf, i3.NgIf, i3.AsyncPipe, CoreFrontendSharedModule, UiTabViewModule, UtilsFrontendSharedModule, UiButtonModule, i4.UiButtonComponent, UiInputGroupModule, i5.InputGroup, UiIconsModule, i6.FaIconComponent],
      styles: [".bottom-bar[_ngcontent-%COMP%]{display:flex;justify-content:center;--inputBorderRadius:20px}.steps[_ngcontent-%COMP%]{padding:0;margin:40px 0 0}@media (max-width: 767px){.steps[_ngcontent-%COMP%]{display:none}}@media (min-width: 768px){.steps[_ngcontent-%COMP%]{list-style:none;display:flex;justify-content:center;align-items:center}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{text-align:center;position:relative}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{background:var(--contentSoftColor);border:2px solid var(--contentSoftColor);padding:12px;border-radius:15px}.steps[_ngcontent-%COMP%]   .step.completed[_ngcontent-%COMP%]{color:var(--disabledTextColor)}.steps[_ngcontent-%COMP%]   .step.current[_ngcontent-%COMP%]{color:var(--primaryColor);border-color:var(--primaryColor)}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%] > fa-icon[_ngcontent-%COMP%]{position:absolute;right:10px;top:10px;color:var(--primaryColor)}.steps[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{font-weight:700;font-size:30px}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]{margin:0 10px;height:2px;display:block;background:var(--disabledTextColor);position:relative;flex:1;opacity:.5;max-width:30%}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]{width:20px;position:absolute;right:-2px;top:-9px;fill:currentColor}}@media (min-width: 960px){.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{padding:20px;border-radius:20px}.steps[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{font-size:40px}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]{margin:0 20px}}.forms[_ngcontent-%COMP%]{margin:50px 0 30px}"],
      changeDetection: 0
    });
  }
};
MembershipSteppedFormComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [MembershipRegistrationFacade, Store])], MembershipSteppedFormComponent);
export { MembershipSteppedFormComponent };