import { ItemRestraints } from '@core/shared';
/**
 * use this Restraints subtype for easier checking against info provided by User-based session
 */
export class UserBasedItemRestraints extends ItemRestraints {
  get access() {
    try {
      const tmp = this.accessProvider.get();
      return tmp;
    } catch (err) {
      throw new Error('UserBasedItemRestraints: Tried to get reference to AccessService outside of a request scope/context. Likely you are executing system-level operations which the Restraints class does not yet expect and check.');
    }
  }
  constructor(accessProvider, executorProvider) {
    super(accessProvider, executorProvider);
    this.accessProvider = accessProvider;
    this.executorProvider = executorProvider;
  }
  // ########  HELPER METHODS  ################################################################
  userManagesCompany(companyId) {
    const managedCompany = this.access.getAccessData('managedCompany', null, 'throw');
    return managedCompany?.id === companyId;
  }
  userBelongsToCompany(companyId) {
    const company = this.access.getAccessData('company', null, 'throw');
    return company?.id === companyId;
  }
  currentUserIsManagerOfOwner(item) {
    if (!this.access) throw new Error("Prüfung des Besitzers fehlgeschlagen: AccessService nicht verfügbar");
    // ownerId===null is allowed, so check against undefined!
    if (typeof item.ownerId === 'undefined') throw new Error("Prüfung des Besitzers fehlgeschlagen: Item hat keine Angabe für ownerId");
    const managesUserId = this.access.getAccessData('managesUserId', item.ownerId, 'throw');
    return managesUserId;
  }
  currentUserIsManagedByOwner(item) {
    if (!this.access) throw new Error("Prüfung des Besitzers fehlgeschlagen: AccessService nicht verfügbar");
    // ownerId===null is allowed, so check against undefined!
    if (typeof item.ownerId === 'undefined') throw new Error("Prüfung des Besitzers fehlgeschlagen: Item hat keine Angabe für ownerId");
    const managers = this.access.getAccessData('companyManagers', null, 'throw');
    if (!managers) return false;
    const managerIds = managers.map(m => m.id);
    return managerIds.includes(item.ownerId);
  }
}
export class UserBasedAdminOnlyItemRestraints extends UserBasedItemRestraints {
  canCreateItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canViewItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canToggleItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canUpdateItem(item, oldItem) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
}
export class UserBasedReadonlyItemRestraints extends UserBasedItemRestraints {
  canToggleItem(item) {
    return 'Not toggleable';
  }
  canUpdateItem(item, oldItem) {
    return 'Not updateable';
  }
  canRemoveItem(item) {
    return 'Not removable';
  }
}