import { buildMessage, ValidateBy } from "class-validator";
import { isValidPermission } from '../../access/is-valid-permission';
const IS_VALID_PERMISSION = 'IS_VALID_PERMISSION';
export function IsValidPermission(validationOptions) {
  return ValidateBy({
    name: IS_VALID_PERMISSION,
    // constraints: [/^[\w\d-_@.]+$/, ''],
    validator: {
      validate: (value, args) => isValidPermission(value),
      defaultMessage: buildMessage((eachPrefix, args) => 'Berechtigungen dürfen nur Großbuchstaben, Ziffern und Unterstriche enthalten!', validationOptions)
    }
  }, {
    message: 'Berechtigungen dürfen nur Großbuchstaben, Ziffern und Unterstriche enthalten!',
    ...validationOptions
  });
}