import { FormServiceSaveHandlerApiItem } from '@common/forms/frontend-shared/form-service';
import { CrudServiceFactory } from '@core/frontend-shared';
import { ModelFactoryProvider } from '@core/shared';
export class FormServiceSaveHandlerApiCrudItem extends FormServiceSaveHandlerApiItem {
  constructor() {
    super(...arguments);
    this.returnsUpdatedFormData = true;
  }
  setService(service) {
    this.service = service;
  }
  execute(value) {
    if (Array.isArray(value)) throw new Error(this.constructor.name + ' does not support saving arrays yet!');
    const mode = this.fs.getEditMode();
    // some safety checks to find buggy setups early
    if (mode === 'new' && typeof value['id'] !== 'undefined') throw new Error('CrudItemFormService detected misconfiguration: Trying to CREATE a item with existing id');
    if (mode === 'edit' && typeof value['id'] !== 'number') throw new Error('CrudItemFormService detected misconfiguration: Trying to UPDATE a item without a id');
    const service = this.resolveService();
    const req = service.save(value);
    return req;
  }
  resolveService() {
    if (this.service) {
      return this.service;
    }
    const definitionOptions = this.fs.getEditorModelInfo().definition.options;
    if (!definitionOptions.useCrudEndpoint) {
      throw Error(this.constructor.name + ' error: formService Model is not configurated for submission by CrudService!');
    }
    const factory = this.injector.get(CrudServiceFactory);
    const service = factory.createCrudService(this.fs.modelFactory.Model);
    service.useCrudEndpoint(definitionOptions.useCrudEndpoint);
    return service;
  }
}
export class FormServiceSaveHandlerApiCrudBatchCreate extends FormServiceSaveHandlerApiCrudItem {
  constructor() {
    super(...arguments);
    this.returnsUpdatedFormData = false;
  }
  execute(value) {
    if (Array.isArray(value)) throw new Error(this.constructor.name + ' does not support saving arrays yet!');
    if (!this.transformFn) throw new Error(this.constructor.name + '.execute called before setTransformFunction! Aborting execution.');
    const service = this.resolveService();
    const items = this.transformFn(value, this.targetModelFactory);
    const req = service.createMany(items);
    return req;
  }
  resolveService() {
    const factory = this.injector.get(CrudServiceFactory);
    const service = factory.createCrudService(this.targetModelFactory.Model);
    service.useCrudEndpoint(this.targetCrudEndpoint);
    return service;
  }
}
export function makeFormServiceSaveHandlerApiCrudBatchCreate(transformFn, targetModel, targetCrudEndpoint, crudService) {
  const className = 'FormServiceSaveHandlerApiCrudBatchCreate__' + targetModel.name;
  const classFactory = {
    [className]: class extends FormServiceSaveHandlerApiCrudBatchCreate {
      constructor(fs, injector) {
        super(fs, injector);
        this.fs = fs;
        this.injector = injector;
        this.transformFn = transformFn;
        this.targetCrudEndpoint = targetCrudEndpoint;
        this.targetModelFactory = this.injector.get(ModelFactoryProvider).createModelFactory(targetModel);
      }
      resolveService() {
        return crudService;
      }
    }
  };
  return classFactory[className];
}