import { __decorate, __metadata } from "tslib";
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { LayoutContentbarBottomComponent } from './bottombar.component';
import { LayoutContentbarsService } from './contentbars.service';
import { LayoutContentbarTopComponent } from './topbar.component';
import * as i0 from "@angular/core";
import * as i1 from "./contentbars.service";
import * as i2 from "@angular/common";
function LayoutContentbarWrapperComponent_ng_template_8_Template(rf, ctx) {}
function LayoutContentbarWrapperComponent_ng_template_10_Template(rf, ctx) {}
const _c0 = [[["layout-contentbar-top"]], "*", [["layout-contentbar-bottom"]]];
const _c1 = (a0, a1) => ({
  "has-bottombar": a0,
  "has-topbar": a1
});
const _c2 = ["layout-contentbar-top", "*", "layout-contentbar-bottom"];
let LayoutContentbarWrapperComponent = class LayoutContentbarWrapperComponent {
  set _bottombar(bar) {
    this.hasBottomBarContent$.next(!!bar);
  }
  set _topbar(bar) {
    this.hasTopBarContent$.next(!!bar);
  }
  constructor(contentbars) {
    this.contentbars = contentbars;
    this.hasBottomBarContent$ = new BehaviorSubject(false);
    this.hasTopBarContent$ = new BehaviorSubject(false);
    this.hasBottomBar$ = combineLatest([this.hasBottomBarContent$, contentbars.showBottomBar$]).pipe(map(([hasContent, show]) => {
      return hasContent && show;
    }));
    this.hasTopBar$ = combineLatest([this.hasTopBarContent$, contentbars.showTopBar$]).pipe(map(([hasContent, show]) => {
      return hasContent && show;
    }));
  }
  static {
    this.ɵfac = function LayoutContentbarWrapperComponent_Factory(t) {
      return new (t || LayoutContentbarWrapperComponent)(i0.ɵɵdirectiveInject(i1.LayoutContentbarsService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: LayoutContentbarWrapperComponent,
      selectors: [["layout-contentbars-wrapper"]],
      contentQueries: function LayoutContentbarWrapperComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, LayoutContentbarBottomComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, LayoutContentbarTopComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._bottombar = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._topbar = _t.first);
        }
      },
      ngContentSelectors: _c2,
      decls: 12,
      vars: 8,
      consts: [[1, "layout-contentbars-wrapper"], [1, "layout-content-wrapper", 3, "ngClass"], [1, "layout-content-inner"], ["topbar", ""], ["bottombar", ""]],
      template: function LayoutContentbarWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵpipe(3, "async");
          i0.ɵɵpipe(4, "async");
          i0.ɵɵelementStart(5, "div", 2);
          i0.ɵɵprojection(6, 1);
          i0.ɵɵelementEnd()();
          i0.ɵɵprojection(7, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(8, LayoutContentbarWrapperComponent_ng_template_8_Template, 0, 0, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor)(10, LayoutContentbarWrapperComponent_ng_template_10_Template, 0, 0, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c1, i0.ɵɵpipeBind1(3, 1, ctx.hasBottomBar$), i0.ɵɵpipeBind1(4, 3, ctx.hasTopBar$)));
        }
      },
      dependencies: [i2.NgClass, i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:block;min-height:100%;min-height:100vh;background:var(--backgroundSoftColor);position:relative}access-login[_ngcontent-%COMP%]{display:block;height:100%;height:100vh}.layout-contentbars-wrapper[_ngcontent-%COMP%]{position:relative;height:100%;height:100vh}.layout-content-wrapper[_ngcontent-%COMP%]{height:100%;height:100vh;overflow:auto;position:relative;display:flex;flex-direction:column;justify-content:stretch}.layout-content-inner[_ngcontent-%COMP%]{position:relative;display:flex;flex:1}  .layout-content-inner>*{flex:1}@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){.layout-content-wrapper[_ngcontent-%COMP%]{height:100vh}}.layout-content-wrapper[_ngcontent-%COMP%]{min-height:100vh}.layout-content-wrapper[_ngcontent-%COMP%]:not(.has-bottombar).has-topbar{padding-top:var(--topbarHeight)}.layout-content-wrapper[_ngcontent-%COMP%]:not(.has-bottombar).has-topbar{height:calc(100vh - var(--topbarHeight))}.layout-content-wrapper.has-bottombar[_ngcontent-%COMP%]:not(.has-topbar){height:calc(100vh - var(--bottombarHeight));padding-bottom:var(--bottombarHeight)}.layout-content-wrapper.has-bottombar.has-topbar[_ngcontent-%COMP%]{padding-top:var(--topbarHeight);padding-bottom:var(--bottombarHeight)}.layout-content-wrapper.has-bottombar.has-topbar[_ngcontent-%COMP%]{height:calc(100vh - var(--topbarHeight) - var(--bottombarHeight))}"],
      changeDetection: 0
    });
  }
};
LayoutContentbarWrapperComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [LayoutContentbarsService])], LayoutContentbarWrapperComponent);
export { LayoutContentbarWrapperComponent };