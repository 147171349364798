var PageState_1;
import { __decorate, __metadata } from "tslib";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivationStart, NavigationStart, Router } from '@angular/router';
import { Action, createSelector, Selector, State, Store } from '@ngxs/store';
import { filter, skip, take } from 'rxjs';
import { PageActions } from './page.actions';
import { PersistStateFactory } from '@core/frontend-shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngxs/store";
import * as i3 from "@core/frontend-shared";
const STORAGE_KEY_PAGE_STATE = 'page-state';
let PageState = class PageState {
  static {
    PageState_1 = this;
  }
  static title(state) {
    return state.title;
  }
  static themeConfiguration(state) {
    return state.theme;
  }
  static theme(state) {
    return state.theme !== 'auto' ? state.theme : state.browserTheme;
  }
  static meta(state) {
    return state.meta;
  }
  static isInitialRoute(state) {
    return state.isInitialRoute;
  }
  static get(key) {
    return createSelector([PageState_1], state => {
      return typeof state[key] !== 'undefined' ? state[key] : null;
    });
  }
  constructor(router, store, persistenceFactory, doc, rendererFactory, platformId) {
    this.router = router;
    this.store = store;
    this.persistenceFactory = persistenceFactory;
    this.doc = doc;
    this.platformId = platformId;
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.persistedState = this.persistenceFactory.create(STORAGE_KEY_PAGE_STATE, true, 'local');
    this.renderer = rendererFactory.createRenderer(null, null);
    router.events.subscribe(event => {
      /**
       * Note: the event may emit multiple times during navigation.
       * We cannot work around that because that is how lazy loaded routes work.
       */
      if (event instanceof ActivationStart) {
        const routeData = event.snapshot.data;
        const title = routeData['pageTitle'] || '';
        this.store.dispatch(new PageActions.SetTitle(title));
        const meta = [];
        if (routeData['metaDescription']) {
          meta.push({
            name: 'description',
            content: routeData['metaDescription']
          });
        }
        if (routeData['metaKeywords']) {
          meta.push({
            name: 'keywords',
            content: routeData['metaKeywords']
          });
        }
        if (routeData['metaRobots']) {
          meta.push({
            name: 'robots',
            content: routeData['metaRobots']
          });
        }
        this.store.dispatch(new PageActions.SetMeta(meta, true));
      }
    });
    router.events.pipe(filter(event => event instanceof NavigationStart), skip(1), take(1)).subscribe(event => {
      this.store.dispatch(new PageActions.SetIsInitialRoute(false));
    });
  }
  ngxsOnInit(ctx) {
    if (this.isBrowser) {
      if (window?.matchMedia) {
        const preferDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");
        ctx.dispatch(new PageActions.SetBrowserTheme(preferDarkTheme.matches ? 'dark' : 'light'));
        preferDarkTheme.addEventListener("change", e => {
          ctx.dispatch(new PageActions.SetBrowserTheme(e.matches ? 'dark' : 'light'));
        });
      } else {
        this.applyTheme(ctx.getState());
      }
    }
    this.restorePersistedState(ctx);
  }
  setIsInitialRoute({
    patchState
  }, action) {
    patchState({
      isInitialRoute: action.isInitialRoute
    });
  }
  setTitle({
    patchState
  }, action) {
    patchState({
      title: action.title
    });
  }
  setBrowserTheme({
    getState,
    patchState
  }, {
    mode
  }) {
    patchState({
      browserTheme: mode
    });
    this.applyTheme(getState());
  }
  setTheme({
    getState,
    patchState
  }, {
    mode
  }) {
    patchState({
      theme: mode
    });
    this.applyTheme(getState());
    this.persistedState.update({
      theme: mode
    });
  }
  setMeta({
    getState,
    patchState
  }, {
    data,
    resetMeta
  }) {
    // reset meta will be triggered on route changes to make sure custom meta will not be persisted.
    let newMeta = resetMeta ? [] : [...getState().meta];
    data.forEach(metaInfo => {
      const existingIndex = newMeta.findIndex(exist => exist.name === metaInfo.name);
      if (metaInfo.content === null) {
        // reset entry by removing from custom meta set
        if (existingIndex) {
          newMeta.splice(existingIndex, 1);
        }
      } else if (existingIndex > -1) {
        // update entry
        newMeta[existingIndex] = metaInfo;
      } else {
        // create entry
        newMeta.push(metaInfo);
      }
    });
    patchState({
      meta: newMeta
    });
  }
  applyTheme(state) {
    this.renderer.setAttribute(this.doc.body, 'ui-theme', state.theme);
  }
  restorePersistedState(ctx) {
    const persisted = this.persistedState.get();
    if (persisted?.theme) ctx.dispatch(new PageActions.SetTheme(persisted.theme));
  }
  static {
    this.ɵfac = function PageState_Factory(t) {
      return new (t || PageState)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.PersistStateFactory), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: PageState,
      factory: PageState.ɵfac
    });
  }
};
__decorate([Action(PageActions.SetIsInitialRoute), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, PageActions.SetIsInitialRoute]), __metadata("design:returntype", void 0)], PageState.prototype, "setIsInitialRoute", null);
__decorate([Action(PageActions.SetTitle), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, PageActions.SetTitle]), __metadata("design:returntype", void 0)], PageState.prototype, "setTitle", null);
__decorate([Action(PageActions.SetBrowserTheme), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, PageActions.SetBrowserTheme]), __metadata("design:returntype", void 0)], PageState.prototype, "setBrowserTheme", null);
__decorate([Action(PageActions.SetTheme), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, PageActions.SetTheme]), __metadata("design:returntype", void 0)], PageState.prototype, "setTheme", null);
__decorate([Action(PageActions.SetMeta), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, PageActions.SetMeta]), __metadata("design:returntype", void 0)], PageState.prototype, "setMeta", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], PageState, "title", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", String)], PageState, "themeConfiguration", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", String)], PageState, "theme", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], PageState, "meta", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], PageState, "isInitialRoute", null);
PageState = PageState_1 = __decorate([State({
  name: 'page',
  defaults: {
    title: 'Home',
    meta: [],
    browserTheme: null,
    theme: 'auto',
    isInitialRoute: true
  }
}), __metadata("design:paramtypes", [Router, Store, PersistStateFactory, Object, RendererFactory2, Object])], PageState);
export { PageState };