import { __decorate, __metadata } from "tslib";
import { ActivatedRoute } from '@angular/router';
import { FeatureMenuItemService, ReplaceableComponent } from '@core/frontend-shared/feature';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@core/frontend-shared/feature";
import * as i3 from "../../../../../../ui/frontend-shared/src/lib/menu/menu";
import * as i4 from "../../../../../../ui/frontend-shared/src/lib/panel/panel";
import * as i5 from "../../../../../../../core/frontend-shared/src/lib/router/router-outlet/custom-router-outlet";
import * as i6 from "@angular/common";
let ProfileWrapperComponent = class ProfileWrapperComponent {
  constructor(route, featureMenuItemService) {
    this.route = route;
    this.defaultMenuItems = [{
      label: 'Mein Benutzerkonto',
      priority: 10,
      routerLink: './common'
    }, {
      label: 'Einstellungen',
      priority: 3,
      routerLink: './settings'
    }];
    this.activeItem = 0;
    const profileInfo = route.snapshot.data['profileData'];
    if (!profileInfo) throw new Error('ProfileData not found at route data');
    const isCompanyMember = !!profileInfo.company;
    const canDeleteAccount = !isCompanyMember;
    this.defaultMenuItems.push({
      label: 'Account löschen',
      priority: -1,
      routerLink: './delete',
      visible: canDeleteAccount
    });
    this.menu$ = featureMenuItemService.getOrderedMenuItems('profile', this.defaultMenuItems);
  }
  static {
    this.ɵfac = function ProfileWrapperComponent_Factory(t) {
      return new (t || ProfileWrapperComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.FeatureMenuItemService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: ProfileWrapperComponent,
      selectors: [["user-profile"]],
      features: [i0.ɵɵProvidersFeature([])],
      decls: 9,
      vars: 4,
      consts: [[3, "fillHeight"], [1, "wrapper"], [1, "sidebar"], ["e2e-profile-menu", "", 3, "model"], [1, "content"]],
      template: function ProfileWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ui-panel", 0)(1, "h1");
          i0.ɵɵtext(2, "Mein Profil");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 1)(4, "div", 2);
          i0.ɵɵelement(5, "ui-menu-with-mobile-transition", 3);
          i0.ɵɵpipe(6, "async");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 4);
          i0.ɵɵelement(8, "custom-router-outlet");
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("fillHeight", true);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("model", i0.ɵɵpipeBind1(6, 2, ctx.menu$));
        }
      },
      dependencies: [i3.MenuWithMobileTransition, i4.Panel, i5.CustomRouterOutlet, i6.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:block;max-width:100%;flex:1}.container[_ngcontent-%COMP%]{height:100%}.wrapper[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{position:relative}@media (min-width: 960px){ui-panel[_ngcontent-%COMP%]     .p-panel-content{display:flex;flex-direction:column;overflow:hidden!important}.wrapper[_ngcontent-%COMP%]{display:flex;flex:1;overflow:hidden}.wrapper[_ngcontent-%COMP%]   .sidebar[_ngcontent-%COMP%]{width:300px;max-width:40%;min-width:100px}.wrapper[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{flex:1;position:relative;margin-left:2%;overflow:auto}}.btn-logout[_ngcontent-%COMP%]{display:inline-block;margin:-2px 0 0 10px;vertical-align:bottom}"]
    });
  }
};
ProfileWrapperComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [ActivatedRoute, FeatureMenuItemService])], ProfileWrapperComponent);
export { ProfileWrapperComponent };