export function cloudinaryItemToFileTypeInfo(data) {
  const converted = convertMediaAssetToLegacyCloudinaryItem(data);
  const fileNameAndPath = converted.type === 'raw' ? converted.publicId : converted.publicId + '.' + converted.originalExtension;
  const filename = fileNameAndPath.substring(fileNameAndPath.lastIndexOf('/') + 1);
  return {
    extension: converted.originalExtension,
    filename
  };
}
export function convertMediaAssetToLegacyCloudinaryItem(data) {
  if (!data) return null;
  if (data.originalExtension) {
    // passed data already is a CloudinaryItemInterface!
    return data;
  }
  const asset = typeof data['consumerId'] === 'number' ? data.asset : data;
  return {
    url: asset.directUrl,
    type: asset.type,
    publicId: asset.providerData?.publicId || null,
    completed: asset.ready,
    originalExtension: asset.meta?.format,
    meta: {
      duration: asset.meta?.duration,
      format: asset.meta?.format
    }
  };
}