import { KnownRole, VirtualPropertyBaseDefinition } from "@core/shared";
export class UserVirtualPropertyBaseDefinition extends VirtualPropertyBaseDefinition {
  getValidAccessGroups() {
    return ['self', 'manager', 'managerOfUser', 'admin', 'public'];
  }
  getGrantedAccessGroupsForUserAndItem(item, access) {
    const accessGroups = [];
    accessGroups.push('public');
    if (access.isAdmin) accessGroups.push('admin');
    const userId = access.getAccessData('userId', null, 'null');
    const editsSelf = userId === item.id;
    if (editsSelf) accessGroups.push('self');
    const isManager = access.getAccessData('isManager', null, 'null');
    if (isManager) {
      accessGroups.push('manager');
      if (this.userIsInManagedCompany(item, access) && this.userIsSimpleUser(item)) {
        accessGroups.push('managerOfUser');
      }
    }
    return accessGroups;
  }
  userIsInManagedCompany(user, access) {
    const companyId = user.companyId || user.company?.id;
    const managedCompany = access.getAccessData('managedCompany', null, 'throw');
    if (!companyId) return false;
    if (!managedCompany?.id) return false;
    return companyId === managedCompany.id;
  }
  userIsSimpleUser(user) {
    if (user.role) {
      // only rely on RoleItem if it is available
      for (let p of user.role.permissions) {
        if (['MANAGER', 'ADMINISTRATOR', 'SUPERUSER'].includes(p)) return false; // restricted permissions, reject
      }
      return true;
    } else {
      if (user.roleId === KnownRole.USER) return true;else return false;
    }
  }
}