/**
 * based on the functioning of class-transformer, this class uses the same approach
 * to scan an object for props and execute some custom modification on it.
 * It is basically used to compensate the missing plugin support of class-transformer.
 * It should be used AFTER object has been transformed to a Model class instance.
 */
import { customMetadataStorage } from "../custom-metadata/storage";
import { extendsConstructor } from '../../utils';
import 'reflect-metadata';
import { ClassTransformService } from '../class-transform';
const ENABLE_TYPE_REFLECTION = true;
export const PrimitiveTypes = ['boolean', 'number', 'string', 'undefined', 'bigint', 'symbol'];
export const NonPrimitiveTypes = ['array', 'object', 'function', 'map'];
/**
 * CustomModelProcessor iterates a Model structure (deeply) and calls a handler method for each of the found properties.
 */
export class CustomModelProcessor {
  constructor() {
    // handler will always be called for array ITEMS and object PROPERTIES.
    // these flags control if the handler will also be called with the complete array / object.
    // handler will never be called for the root object/array.
    this.handlerCalledForArrays = true;
    this.handlerCalledForObjects = true;
    this.handlerCalledForMaps = true;
    this.guessConstructorFromValue = true;
    this.skipUntypedObjectsAndArrays = false;
    this.ignoredProperties = ['__proto__', 'constructor'];
    this.dependencies = null;
    return this;
  }
  setOptions(opts) {
    if (typeof opts.handlerCalledForArrays === 'boolean') this.handlerCalledForArrays = opts.handlerCalledForArrays;
    if (typeof opts.handlerCalledForObjects === 'boolean') this.handlerCalledForObjects = opts.handlerCalledForObjects;
    if (typeof opts.handlerCalledForMaps === 'boolean') this.handlerCalledForMaps = opts.handlerCalledForMaps;
    if (typeof opts.guessConstructorFromValue === 'boolean') this.guessConstructorFromValue = opts.guessConstructorFromValue;
    if (typeof opts.skipUntypedObjectsAndArrays === 'boolean') this.skipUntypedObjectsAndArrays = opts.skipUntypedObjectsAndArrays;
    if (opts.filterPropertiesByType) {
      if (!ENABLE_TYPE_REFLECTION) {
        if (PrimitiveTypes.includes(opts.filterPropertiesByType)) {
          throw new Error('CustomModelProcessir option filterPropertiesByType does not support filtering by primitive type anymore!');
        }
      }
      this.filterPropertiesByType = opts.filterPropertiesByType;
    }
    // validate configuration
    if (!this.handlerCalledForArrays) {
      if (this.filterPropertiesByType === 'array') throw new Error('CustomModelProcessor is misconfigurated: Filtering for arrays conflicts with handlerCalledForArrays=false!');
    }
    if (!this.handlerCalledForObjects) {
      if (this.filterPropertiesByType === 'object' || this.filterPropertiesByType === 'instances') throw new Error('CustomModelProcessor is misconfigurated: Filtering for objects/instances conflicts with handlerCalledForObjects=false!');
      if (typeof this.filterPropertiesByType === 'function') throw new Error('CustomModelProcessor is misconfigurated: Filtering for instances of a class conflicts with handlerCalledForObjects=false!');
    }
    return this;
  }
  // may be required for custom Transform and Type decorators
  setDependencies(dependencies) {
    this.dependencies = dependencies;
    return this;
  }
  execute(
  // source: Record<string, any> | Record<string, any>[] | any,
  value, targetType, _key) {
    if (Array.isArray(value) || value instanceof Set) {
      // handler will be called for sub-arrays, but as this is root level, we only iterate the array directly.
      this.executeOnArray(value, targetType, _key);
    } else if (value instanceof Map) {
      for (const key of this.getKeys(value)) {
        if (this.ignoredProperties.includes(key)) continue;
        const propertyName = key;
        const subValue = value.get(key);
        const metadataType = this.getMetaType(targetType, propertyName, value);
        const metadataCustom = customMetadataStorage.findMetadata(targetType, propertyName) || [];
        this.executeOnValue(subValue, value, metadataType, propertyName, metadataCustom);
      }
    } else if (typeof value === 'object' && value !== null) {
      if (!targetType) targetType = this.guessConstructorType(value);
      for (const key of this.getKeys(value)) {
        if (this.ignoredProperties.includes(key)) continue;
        const propertyName = key;
        const subValue = value[propertyName];
        const metadataType = this.getMetaType(targetType, propertyName, value);
        const metadataCustom = customMetadataStorage.findMetadata(targetType, propertyName) || [];
        this.executeOnValue(subValue, value, metadataType, propertyName, metadataCustom);
      }
    } else {
      throw new TypeError(`Prop Transformer can only work on arrays and objects! (execute failed at: value ${value}, key ${_key}, type: ${targetType})`);
    }
  }
  executeOnArray(
  // source: Record<string, any> | Record<string, any>[] | any,
  value, itemType, propKey, metadataCustom = []) {
    // console.log('>>>> executeOnArray',itemType);
    value.forEach((subValue, index) => {
      this.executeOnValue(subValue, value, itemType, index, metadataCustom);
    });
  }
  executeOnMap(
  // source: Record<string, any> | Record<string, any>[] | any,
  value, itemType, propKey, metadataCustom = []) {
    // console.log('>>>> executeOnMap',itemType);
    Array.from(value.entries()).forEach(([key, subValue]) => {
      this.executeOnValue(subValue, value, itemType, key, metadataCustom);
    });
  }
  executeOnValue(
  // source: Record<string, any> | Record<string, any>[] | any,
  value, parent, targetType, propKey, metadataCustom) {
    // if(value===null || value===undefined) return;
    // console.log('>>>executeOnValue',value,targetType,propKey)
    // console.log('executeOnValue - metadata for',propKey,metadataCustom,targetType)
    const updateValueFn = val => {
      if (parent instanceof Map) parent.set(propKey, val);else parent[propKey] = val;
    };
    // used to re-load a prop value from parent as it may have been updated through updateValueFn!
    const getValueFromParentFn = () => {
      if (parent instanceof Map) return parent.get(propKey);else return parent[propKey];
    };
    // execute on array items / child objects
    if (Array.isArray(value)) {
      // if value is an array try to get its custom array type
      // if parent is an array too this cannot work as we have no property that can store metadata.
      let itemType;
      if (typeof targetType === 'function') itemType = targetType;else if (typeof targetType === 'object') itemType = targetType.target;else if (typeof propKey === 'string') itemType = this.getMetaType(targetType, propKey, value);
      if (this.handlerCalledForArrays) {
        this.handleProp(value, {
          type: 'array',
          constructor: itemType
        }, propKey, metadataCustom, updateValueFn);
      }
      this.executeOnArray(getValueFromParentFn(), itemType, propKey, metadataCustom);
    } else if (value instanceof Map) {
      if (this.handlerCalledForMaps) {
        this.handleProp(value, {
          type: 'map',
          constructor: targetType
        }, propKey, metadataCustom, updateValueFn);
      }
      this.executeOnMap(getValueFromParentFn(), targetType, propKey);
    } else if (typeof value === 'object' && value !== null) {
      if (this.handlerCalledForObjects) {
        this.handleProp(value, {
          type: 'object',
          constructor: targetType
        }, propKey, metadataCustom, updateValueFn);
      }
      this.execute(getValueFromParentFn(), targetType, propKey);
    } else {
      // primitive value
      // let reflectedType = this.getReflectedTypeAsString(parent as Func, propKey as string)
      const typeInfo = {
        constructor: targetType
      };
      if (ENABLE_TYPE_REFLECTION) {
        typeInfo.type = this.getReflectedTypeAsString(parent, propKey);
      } else {
        Object.defineProperty(typeInfo, 'type', {
          get: () => {
            console.warn('Type Reflection of primitives is not possible anymore due to compatibility issues with modern angular and ES2015+. See #86bxftymx');
            return 'primitive';
          }
        });
      }
      this.handleProp(value, typeInfo, propKey, metadataCustom, val => {
        parent[propKey] = val;
      });
    }
  }
  registerHandler(handler) {
    this.handler = handler;
    return this;
  }
  guessConstructorType(value) {
    if (value?.constructor) return value.constructor;
    return null;
  }
  handleProp(value, typeInfo, key, metadata, replaceHandler) {
    if (this.guessConstructorFromValue) {
      if (!typeInfo.constructor && typeInfo.type === 'object') {
        typeInfo.constructor = this.guessConstructorType(value);
      }
    }
    if (this.filterPropertiesByType) {
      if (this.matchesFilterType(value, typeInfo)) {
        this.callHandler(value, typeInfo, key, metadata, replaceHandler);
      }
    } else {
      this.callHandler(value, typeInfo, key, metadata, replaceHandler);
    }
  }
  matchesFilterType(value, typeInfo) {
    const type = this.filterPropertiesByType;
    if (typeof type === 'function') {
      // filter by a certain constructor
      if (typeof typeInfo.constructor !== 'function') return false;
      return extendsConstructor(typeInfo.constructor, type);
    }
    if (type === 'null') {
      return value === null;
    }
    if (type === 'instances') {
      return typeInfo.type === 'object' && !!typeInfo.constructor;
    }
    if (type === 'array') {
      return typeInfo.type === 'array';
    }
    if (type === 'object') {
      return typeInfo.type === 'object';
    }
    if (type === 'primitive') {
      if (ENABLE_TYPE_REFLECTION) {
        return PrimitiveTypes.includes(typeInfo.type);
      } else {
        return typeInfo.type === 'primitive';
      }
    }
    if (ENABLE_TYPE_REFLECTION) {
      return typeInfo.type === type;
    } else {
      throw new Error('Unsupported filter: ' + type);
    }
  }
  callHandler(value, typeInfo, key, metadata, replaceHandler) {
    this.handler?.(value, typeInfo, key, metadata, {
      replace: replaceHandler
    });
  }
  getKeys(object) {
    // we'll ignore all expose, grouping, versioning etc.
    // it is assumed this has been applied before.
    let keys;
    if (object instanceof Map) {
      keys = Array.from(object.keys());
    } else {
      keys = Object.keys(object);
    }
    return keys;
  }
  getReflectedTypeAsString(target, propertyName) {
    if (ENABLE_TYPE_REFLECTION) {
      const type = this.getReflectedType(target, propertyName);
      if (type === undefined) return 'undefined';
      return type.name.toLowerCase();
    }
  }
  // if it returns string "undefined", the given property is of type undefined.
  // if it returns undefined, the given property has no type defined.
  getReflectedType(target, propertyName) {
    if (ENABLE_TYPE_REFLECTION) {
      if (!target) return "undefined";
      const reflectedType = Reflect.getMetadata('design:type', target, propertyName);
      return reflectedType;
    }
  }
  getMetaType(target, propertyName, value) {
    if (!target) return undefined;
    const metadataType = ClassTransformService.getMetadataStorage().findTypeMetadata(target, propertyName);
    // if metadataType is available, try to extract the configurated Type
    if (metadataType) {
      if (metadataType.typeFunction) {
        const options = {
          newObject: value,
          object: value,
          property: propertyName,
          executor: null,
          dependencies: this.dependencies || {}
        };
        return metadataType.typeFunction(options);
      } else {
        return metadataType.reflectedType;
      }
    } else {
      return undefined;
    }
  }
}