import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
var AccessState_1;
import { __decorate, __metadata } from "tslib";
import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Action, createSelector, NgxsSimpleChange, Selector, State, StateContext } from '@ngxs/store';
import { filter, from, Observable, of } from 'rxjs';
import { AUTHCODE_ID_KEY, LOCALSTORAGE_SESSIONTOKEN_KEY, STORAGE_KEY_REFERRER_CODE, URL_PARAM_REFERRER_CODE } from '../constants';
import { AccessActions } from './access.actions';
import { AccessService } from './access.service';
import { OnSessionStart, OnSessionEnd, OnSessionReloaded } from '@core/shared';
import { AutoUnsubscribe, PersistStateFactory, takeWhileAlive } from '../../utils';
import { arrayUnique, hashString, parseUrlParams } from '@core/shared/utils';
import { PermissionsService } from './permissions.service';
import * as i0 from "@angular/core";
import * as i1 from "./access.service";
import * as i2 from "./permissions.service";
import * as i3 from "../../utils";
let AccessState = class AccessState {
  static {
    AccessState_1 = this;
  }
  // general --------------------------------------------------------------------------
  static accessData(name, extraParam = null, handleUnknown = 'null') {
    return createSelector([AccessState_1], state => {
      const accessorInfo = this._findDataAccessor(name, handleUnknown);
      if (!accessorInfo) return null;
      return this._executeDataAccessor(accessorInfo.plugin, accessorInfo.accessor, state.sessionInfo, extraParam);
    });
  }
  static sessionId(state) {
    return state.sessionId || null;
  }
  static referrerCode(state) {
    return state.referrerCode || null;
  }
  static authCodes(state) {
    return state.authCodes || [];
  }
  static loggedIn(state) {
    return state.sessionInfo != null;
  }
  static isReady(state) {
    return state.isReady || state.suppressIsReady;
  }
  // permissions ----------------------------------------------------------------------
  static isAdmin(state) {
    const p = state.sessionInfo?.permissions || [];
    return p.includes('ADMINISTRATOR') || p.includes('SUPERUSER');
  }
  static isSuperUser(state) {
    return (state.sessionInfo?.permissions || []).includes('SUPERUSER');
  }
  static permissions(state) {
    return state.sessionInfo?.permissions || [];
  }
  static activatedFeatures(state) {
    return state.sessionInfo?.activatedFeatures || [];
  }
  static hasPermission(permission) {
    return createSelector([AccessState_1], state => {
      const p = state.sessionInfo?.permissions || [];
      return p.indexOf(permission) >= 0 || p.indexOf('SUPERUSER') >= 0;
    });
  }
  static hasAuthCode(authCode) {
    return createSelector([AccessState_1], state => {
      const p = state.authCodes || [];
      return p.indexOf(authCode) >= 0;
    });
  }
  // payload / user data --------------------------------------------------------------
  static sessionData(state) {
    return state.sessionInfo?.session.sessionData || {};
  }
  constructor(accessService, permissionsService, persistenceFactory, ngPlatformId) {
    this.accessService = accessService;
    this.permissionsService = permissionsService;
    this.persistenceFactory = persistenceFactory;
    this.ngPlatformId = ngPlatformId;
    this.stateInitialized = false;
    this.persistedSessionToken = this.persistenceFactory.create(LOCALSTORAGE_SESSIONTOKEN_KEY, false, 'local');
    this.persistedAuthCode = this.persistenceFactory.create(AUTHCODE_ID_KEY, false, 'local');
    this.persistedReferrerCode = this.persistenceFactory.create(STORAGE_KEY_REFERRER_CODE, false, 'local');
    AccessState_1._findDataAccessor = (name, handleUnknown = 'null') => {
      return accessService.findDataAccessor(name, handleUnknown);
    };
    AccessState_1._executeDataAccessor = accessService.executeDataAccessor.bind(accessService);
  }
  ngxsOnInit(ctx) {
    if (isPlatformServer(this.ngPlatformId)) {
      // no login state when running SSR!
      this.updatePermissionsGuard([]);
      ctx.patchState({
        isReady: true,
        sessionId: null
      });
    } else {
      this.bindAccessServiceEvents(ctx);
      this.discoverUrlParams(ctx);
      this.restoreSessionId(ctx);
      this.stateInitialized = true;
    }
  }
  ngxsOnChanges(change) {
    // ngxsOnChanges is called once BEFORE ngxsOnInit, when changing to EMPTY state!
    // so we need to check if state is actually ready and should persist
    if (this.stateInitialized) {
      this.persistSessionId(change.currentValue.sessionId);
    }
  }
  bindAccessServiceEvents(ctx) {
    this.accessService.events.pipe(filter(event => event instanceof OnSessionStart), takeWhileAlive(this)).subscribe(({
      sessionInfo
    }) => {
      this.updatePermissionsGuard(sessionInfo?.permissions);
      ctx.patchState({
        isReady: true,
        sessionInfo: sessionInfo,
        sessionId: sessionInfo.session.sessionId
      });
    });
    this.accessService.events.pipe(filter(event => event instanceof OnSessionReloaded), takeWhileAlive(this)).subscribe(({
      sessionInfo
    }) => {
      ctx.patchState({
        sessionInfo: sessionInfo
      });
      this.updatePermissionsGuard(sessionInfo?.permissions);
    });
    this.accessService.events.pipe(filter(event => event instanceof OnSessionEnd), takeWhileAlive(this)).subscribe(event => {
      this.updatePermissionsGuard([]);
      ctx.patchState({
        isReady: true,
        sessionInfo: null,
        sessionId: null
      });
    });
  }
  discoverUrlParams(ctx) {
    // browser only
    const params = parseUrlParams(window.location.search);
    // check and store referrerId
    if (params[URL_PARAM_REFERRER_CODE]) {
      ctx.dispatch(new AccessActions.SetReferrerCode(params[URL_PARAM_REFERRER_CODE]));
    } else {
      const persistedRefCode = this.persistedReferrerCode.get();
      if (persistedRefCode) ctx.dispatch(new AccessActions.SetReferrerCode(persistedRefCode));
    }
    // check and store authCodes
    const authCodes = [];
    if (params[AUTHCODE_ID_KEY]) {
      if (Array.isArray(params[AUTHCODE_ID_KEY])) authCodes.push(...params[AUTHCODE_ID_KEY]);else authCodes.push(params[AUTHCODE_ID_KEY]);
    }
    const persistedAuthCode = this.persistedAuthCode.get();
    if (persistedAuthCode) {
      authCodes.push(...persistedAuthCode.split(','));
    }
    if (authCodes.length) {
      ctx.dispatch(new AccessActions.AddAuthCodes(authCodes));
    }
  }
  persistSessionId(sessionId) {
    if (sessionId) this.persistedSessionToken.set(sessionId);else this.persistedSessionToken.remove();
  }
  restoreSessionId(ctx) {
    let sessionId = this.persistedSessionToken.get();
    if (sessionId) {
      ctx.dispatch(new AccessActions.SetSessionId(sessionId));
      // ctx.patchState({
      // 	// isReady will be set to true when AccessData is available
      // 	isReady: false,
      // 	// must be set immediately so that it is available for early requests, even before session validation.
      // 	// otherwise an early request to a protected endpoint could return a 406 resulting in auto-logout.
      // 	sessionId,
      // });
      // this.accessService.setSessionid(sessionId);
    } else {
      this.updatePermissionsGuard([]);
      ctx.patchState({
        isReady: true,
        sessionId: null
      });
    }
  }
  updatePermissionsGuard(permissions) {
    this.permissionsService.loadPermissions(permissions);
  }
  setSessionId({
    setState,
    getState,
    patchState
  }, {
    sessionId
  }) {
    patchState({
      // must be set immediately so that it is available for early requests, even before session validation.
      // otherwise an early request to a protected endpoint could return a 406 resulting in auto-logout.
      sessionId
    });
    return from(this.accessService.setSessionId(sessionId));
  }
  refreshSession({
    setState,
    getState,
    patchState
  }) {
    this.accessService.reloadSession();
  }
  logout({
    setState,
    getState,
    patchState
  }) {
    this.updatePermissionsGuard([]);
    this.accessService.endSession();
    setState({
      ...getState(),
      isReady: true,
      sessionId: null,
      sessionInfo: null
    });
    return of(true);
  }
  suppressIsReady({
    setState,
    getState,
    patchState
  }) {
    patchState({
      suppressIsReady: true
    });
  }
  setReferrerCode({
    setState,
    getState,
    patchState
  }, {
    referrerCode
  }) {
    patchState({
      referrerCode
    });
    this.persistReferrerCode(referrerCode);
  }
  persistReferrerCode(referrerCode) {
    if (referrerCode) this.persistedReferrerCode.set(referrerCode);else this.persistedReferrerCode.remove();
  }
  addAuthCodes({
    setState,
    getState,
    patchState
  }, {
    authCodes,
    deleteOthers
  }) {
    const existingCodes = deleteOthers ? [] : getState().authCodes;
    let newCodes = [...existingCodes, ...authCodes];
    newCodes = arrayUnique(newCodes);
    patchState({
      authCodes: newCodes
    });
    this.persistedAuthCode.set(newCodes);
  }
  removeAuthCode({
    setState,
    getState,
    patchState
  }, action) {
    var _this = this;
    return _asyncToGenerator(function* () {
      /**
       * Note on requirements: state stores clear-text auth codes.
       * The incoming authCode value to find and remove may be a hash or a clear-text too.
       * We know that a sha-1 hash is 20 bytes, represented as 40 hexadecimal chars.
       */
      // we dont know if the incoming authCode is a hash or not.
      // so we always create the hash and check/remove that as well.
      const existingCodes = getState().authCodes;
      let filteredCodes = existingCodes.filter(code => {
        return code !== action.authCode;
      });
      if (action.authCode.length === 40) {
        const hashedCodes = yield Promise.all(filteredCodes.map(code => {
          return hashString('sha-1', code);
        }));
        filteredCodes = filteredCodes.filter((code, index) => {
          return hashedCodes[index] !== action.authCode;
        });
      }
      _this.persistedAuthCode.set(filteredCodes);
      patchState({
        authCodes: filteredCodes
      });
    })();
  }
  static {
    this.ɵfac = function AccessState_Factory(t) {
      return new (t || AccessState)(i0.ɵɵinject(i1.AccessService), i0.ɵɵinject(i2.PermissionsService), i0.ɵɵinject(i3.PersistStateFactory), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: AccessState,
      factory: AccessState.ɵfac
    });
  }
};
__decorate([Action(AccessActions.SetSessionId), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, AccessActions.SetSessionId]), __metadata("design:returntype", Observable)], AccessState.prototype, "setSessionId", null);
__decorate([Action(AccessActions.RefreshSession), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AccessState.prototype, "refreshSession", null);
__decorate([Action(AccessActions.Logout), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Observable)], AccessState.prototype, "logout", null);
__decorate([Action(AccessActions.SuppressIsReady), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AccessState.prototype, "suppressIsReady", null);
__decorate([Action(AccessActions.SetReferrerCode), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, AccessActions.SetReferrerCode]), __metadata("design:returntype", void 0)], AccessState.prototype, "setReferrerCode", null);
__decorate([Action(AccessActions.AddAuthCodes), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, AccessActions.AddAuthCodes]), __metadata("design:returntype", void 0)], AccessState.prototype, "addAuthCodes", null);
__decorate([Action(AccessActions.RemoveAuthCode), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, AccessActions.RemoveAuthCode]), __metadata("design:returntype", Promise)], AccessState.prototype, "removeAuthCode", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", String)], AccessState, "sessionId", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", String)], AccessState, "referrerCode", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], AccessState, "authCodes", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Boolean)], AccessState, "loggedIn", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Boolean)], AccessState, "isReady", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Boolean)], AccessState, "isAdmin", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Boolean)], AccessState, "isSuperUser", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], AccessState, "permissions", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], AccessState, "activatedFeatures", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], AccessState, "sessionData", null);
AccessState = AccessState_1 = __decorate([State({
  name: 'access',
  defaults: {
    sessionId: null,
    referrerCode: null,
    authCodes: [],
    sessionInfo: null,
    isReady: false,
    suppressIsReady: false
  }
}), AutoUnsubscribe(), __metadata("design:paramtypes", [AccessService, PermissionsService, PersistStateFactory, Object])], AccessState);
export { AccessState };