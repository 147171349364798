import { ItemRestraints } from "@core/shared";
export class RoleRestraints extends ItemRestraints {
  isDefaultRole(item) {
    return item.id <= 3;
  }
  canCreateItem(item) {
    if (item.permissions && item.permissions.find(p => p === 'SUPERUSER')) {
      if (this.access.isSU) return true;else return 'Superuser Berechtigung kann nur von Superusern vergeben werden.';
    }
    return this.requirePermission('ADMINISTRATOR');
  }
  canToggleItem(item) {
    return 'Benutzerrollen können nicht deaktiviert werden.';
  }
  canUpdateItem(item, oldItem) {
    return this.requirePermission('ADMINISTRATOR', () => {
      if (item.id === 1) {
        return 'Die Benutzerrolle Superuser kann nicht verändert werden.';
      }
      if (typeof item.permissions === 'undefined') return true;
      if (item.id === 2) {
        if (!item.permissions || !item.permissions.find(p => p === 'ADMINISTRATOR')) {
          return 'Die ADMINISTRATOR Berechtigung kann nicht von der Rolle Administrator entfernt werden.';
        }
      }
      if (item.id === 3) {
        if (!item.permissions || !item.permissions.find(p => p === 'REGISTERED')) {
          return 'Die REGISTERED Berechtigung kann nicht von der Rolle Registered entfernt werden.';
        }
      }
      if (item.permissions && item.permissions.find(p => p === 'SUPERUSER')) {
        if (this.access.isSU) return true;else return 'Superuser Berechtigung kann nur von Superusern vergeben werden.';
      }
      return true;
    });
  }
  canRemoveItem(item) {
    return 'Benutzerrollen können nicht gelöscht werden.';
  }
}