import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiIconsModule } from '@common/ui/frontend-shared/icons';
import { UiInputGroupModule } from '@common/ui/frontend-shared/input-group';
import { UiSpinnerModule } from '@common/ui/frontend-shared/spinner';
import { UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { Select, Store } from '@ngxs/store';
import { MembershipPluginsService } from '@common/membership/shared';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { Observable, combineLatest, map } from 'rxjs';
import { MembershipRegistrationFacade, MembershipRegistrationState } from '../state';
import { Angulartics2 } from 'angulartics2';
import * as i0 from "@angular/core";
import * as i1 from "../state";
import * as i2 from "@ngxs/store";
import * as i3 from "@common/membership/shared";
import * as i4 from "angulartics2";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../core/frontend-shared/src/lib/dynamic-components/dynamic-component.directive";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
function RegistrationFormsComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ui-spinner", 2);
  }
}
function RegistrationFormsComponent_ng_template_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function RegistrationFormsComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationFormsComponent_ng_template_2_ng_container_0_Template, 1, 0, "ng-container", 3);
  }
  if (rf & 2) {
    const componentWithParams_r3 = ctx.$implicit;
    i0.ɵɵproperty("dynamicComponent", componentWithParams_r3);
  }
}
/**
 * This component displays all forms that are required to start a membership.
 */
let RegistrationFormsComponent = class RegistrationFormsComponent {
  constructor(registration, store, pluginsService, cd, angulartics2) {
    this.registration = registration;
    this.store = store;
    this.pluginsService = pluginsService;
    this.cd = cd;
    this.angulartics2 = angulartics2;
    this.activePlugin$ = this.membershipType$.pipe(map(type => {
      if (!type) return null;
      return this.pluginsService.getPluginInstance(type);
    }));
    this.componentWithParams$ = combineLatest({
      plugin: this.activePlugin$,
      formData: this.formData$,
      state: this.pluginFormState$,
      displayMode: this.displayMode$,
      requireAccountData: this.requireAccountData$,
      submitsImmediately: this.enabledSummaryStep$.pipe(map(enableSummary => !enableSummary))
    }).pipe(map(data => {
      if (!data.plugin) return null;
      const component = data.plugin.getRegistrationComponent();
      return {
        inputs: {
          ...data,
          platform: 'frontend'
        },
        outputs: {
          onComplete: this.pluginFormCompleted,
          onSaveFormState: this.onSaveFormState
        },
        component
      };
    }));
    this.pluginTitle$ = this.activePlugin$.pipe(map(plugin => {
      const info = plugin.getStaticPluginInfo();
      return info.description || info.name;
    }));
    this.pluginFormCompleted = formData => {
      this.angulartics2.eventTrack.next({
        action: 'forms-submit',
        properties: {
          category: 'membership-registration'
        }
      });
      this.registration.setFormData(formData);
      this.registration.confirmStep();
    };
    this.onSaveFormState = pluginFormStateInfo => {
      this.registration.setPluginFormState(pluginFormStateInfo);
    };
  }
  ngAfterViewInit() {
    this.angulartics2.eventTrack.next({
      action: 'forms-start',
      properties: {
        category: 'membership-registration'
      }
    });
  }
  static {
    this.ɵfac = function RegistrationFormsComponent_Factory(t) {
      return new (t || RegistrationFormsComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.MembershipPluginsService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i4.Angulartics2));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationFormsComponent,
      selectors: [["membership-registration-forms"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [["loader", ""], [3, "ngIf", "ngIfElse"], ["label", "Lade Registrierung..."], [4, "dynamicComponent"]],
      template: function RegistrationFormsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, RegistrationFormsComponent_ng_template_0_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, RegistrationFormsComponent_ng_template_2_Template, 1, 1, "ng-template", 1);
          i0.ɵɵpipe(3, "async");
        }
        if (rf & 2) {
          const _r1 = i0.ɵɵreference(1);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 2, ctx.componentWithParams$))("ngIfElse", _r1);
        }
      },
      dependencies: [CommonModule, i5.NgIf, i5.AsyncPipe, CoreFrontendSharedModule, i6.DynamicComponentDirective, UiTabViewModule, UtilsFrontendSharedModule, UiButtonModule, UiSpinnerModule, i7.SpinnerComponent, UiInputGroupModule, UiIconsModule],
      styles: [".bottom-bar[_ngcontent-%COMP%]{display:flex;justify-content:center;--inputBorderRadius:20px}.steps[_ngcontent-%COMP%]{padding:0;margin:40px 0 0}@media (max-width: 767px){.steps[_ngcontent-%COMP%]{display:none}}@media (min-width: 768px){.steps[_ngcontent-%COMP%]{list-style:none;display:flex;justify-content:center;align-items:center}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{text-align:center;position:relative}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{background:var(--contentSoftColor);border:2px solid var(--contentSoftColor);padding:12px;border-radius:15px}.steps[_ngcontent-%COMP%]   .step.completed[_ngcontent-%COMP%]{color:var(--disabledTextColor)}.steps[_ngcontent-%COMP%]   .step.current[_ngcontent-%COMP%]{color:var(--primaryColor);border-color:var(--primaryColor)}.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%] > fa-icon[_ngcontent-%COMP%]{position:absolute;right:10px;top:10px;color:var(--primaryColor)}.steps[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{font-weight:700;font-size:30px}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]{margin:0 10px;height:2px;display:block;background:var(--disabledTextColor);position:relative;flex:1;opacity:.5;max-width:30%}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]{width:20px;position:absolute;right:-2px;top:-9px;fill:currentColor}}@media (min-width: 960px){.steps[_ngcontent-%COMP%]   .step[_ngcontent-%COMP%]{padding:20px;border-radius:20px}.steps[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{font-size:40px}.steps[_ngcontent-%COMP%]   .spacer[_ngcontent-%COMP%]{margin:0 20px}}.forms[_ngcontent-%COMP%]{margin:50px 0 30px}"],
      changeDetection: 0
    });
  }
};
__decorate([Select(MembershipRegistrationState.displayMode), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "displayMode$", void 0);
__decorate([Select(MembershipRegistrationState.allFormData), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "formData$", void 0);
__decorate([Select(MembershipRegistrationState.pluginFormState), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "pluginFormState$", void 0);
__decorate([Select(MembershipRegistrationState.requireAccountData), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "requireAccountData$", void 0);
__decorate([Select(MembershipRegistrationState.enabledSummaryStep), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "enabledSummaryStep$", void 0);
__decorate([Select(MembershipRegistrationState.membershipType), __metadata("design:type", Observable)], RegistrationFormsComponent.prototype, "membershipType$", void 0);
RegistrationFormsComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [MembershipRegistrationFacade, Store, MembershipPluginsService, ChangeDetectorRef, Angulartics2])], RegistrationFormsComponent);
export { RegistrationFormsComponent };