// import { VmClickToPlay, VmControl, VmControlGroup, VmControls, VmControlSpacer, VmCurrentTime, VmDash, VmDblClickFullscreen, VmDefaultControls, VmDefaultUi, VmEndTime, VmFile, VmFullscreenControl, VmHls, VmIcon, VmIconLibrary, VmLoadingScreen, VmMuteControl, VmPlaybackControl, VmPlayer, VmPoster, VmScrim, VmSlider, VmSpinner, VmTime, VmTimeProgress, VmTooltip, VmUi, VmVideo, VmVolumeControl } from '@vime/core';
let cache = null;
let loadingPromise = null;
export function loadVime() {
  if (cache) {
    // console.warn('cached vime')
    return Promise.resolve(cache);
  } else if (loadingPromise) {
    return loadingPromise;
  }
  loadingPromise = import('@vime/core' /* webpackPreload: true */ /* webpackChunkName:"vime-core" */).then(mod => {
    // console.warn('preload vime')
    const {
      VmClickToPlay,
      VmControl,
      VmControlGroup,
      VmControls,
      VmControlSpacer,
      VmCurrentTime,
      VmDash,
      VmDblClickFullscreen,
      VmDefaultControls,
      VmDefaultUi,
      VmEndTime,
      VmFile,
      VmFullscreenControl,
      VmHls,
      VmIcon,
      VmIconLibrary,
      VmLoadingScreen,
      VmMuteControl,
      VmPlaybackControl,
      VmPoster,
      VmScrim,
      VmSlider,
      VmSpinner,
      VmTime,
      VmTimeProgress,
      VmTooltip,
      VmUi,
      VmVideo,
      VmVolumeControl,
      createDispatcher,
      findPlayer,
      usePlayerContext,
      VmPlayer
    } = mod;
    [VmPlayer, VmControl, VmControlGroup, VmControlSpacer, VmControls, VmTime, VmCurrentTime, VmEndTime, VmTimeProgress, VmDash, VmFile, VmHls, VmVideo, VmDefaultControls, VmDefaultUi, VmIcon, VmIconLibrary, VmLoadingScreen, VmSlider, VmSpinner, VmTooltip, VmUi, VmPoster, VmScrim, VmClickToPlay, VmDblClickFullscreen, VmFullscreenControl, VmMuteControl, VmPlaybackControl, VmVolumeControl
    // VmSettings, VmSettingsControl, VmMenu, VmMenuItem, VmMenuRadio, VmMenuRadioGroup, VmSubmenu, 
    ].forEach(cmp => {
      // if (!customElements.get(cmp.is)) 
      customElements.define(cmp['is'], cmp);
    });
    cache = {
      createDispatcher,
      findPlayer,
      usePlayerContext,
      VmPlayer
    };
    loadingPromise = null;
    return cache;
  });
  return loadingPromise;
}