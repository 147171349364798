import { BehaviorSubject, map } from 'rxjs';
import { ModelActionHelper } from './model-action.helper';
export class ModelActionMenuService {
  constructor() {
    this.contextMenuSelection$ = new BehaviorSubject(null);
    this.contextMenuActionsAll$ = new BehaviorSubject([]);
    this.contextMenuActionsAllCustom$ = null;
  }
  setItemModelActions(actions) {
    this.contextMenuActionsAll$.next(actions);
  }
  setItemModelActions$(selection) {
    this.contextMenuActionsAllCustom$ = selection;
  }
  setContextMenuSelection(selection) {
    this.contextMenuSelection$.next(selection);
  }
  getSubscriptions() {
    // not very beautiful, but this allows to use a custom source without having to manage any subscriptions inside this service.
    const source$ = this.contextMenuActionsAllCustom$ || this.contextMenuActionsAll$;
    const contextMenuActionsForItem$ = ModelActionHelper.createMenuItemsFromItemModelActions$(this.contextMenuSelection$, source$);
    return {
      contextMenuActionsAll$: source$,
      contextMenuActionsForItem$: contextMenuActionsForItem$,
      enableContextMenu$: source$.pipe(map(actions => actions?.length > 0))
    };
  }
}