import * as deepmerge from 'deepmerge';
import { transformEnvNameToConfigName, transformKeysToLowerCase } from './helpers';
import { getDotProp, setDotProp } from '../utils';
import { configuration as appConfiguration } from '@@app-configuration';
import '../types';
import * as ReplacedConfig from './config.REPLACE';
// console.log('app-configuration resolved to',appConfiguration)
// sets nodejs traces to be more detailed than default 10 lines
Error['stackTraceLimit'] = 25;
export const APP_VERSION = {
  "version": VERSION,
  "timestamp": TIMESTAMP
};
/*
 * CAUTION: CLASS CONVERTS ALL PROPERTY KEYS TO LOWERCASE INTERNALLY!
 */
export class Config {
  static get env() {
    return {
      ...Config.configSources.localEnv
    };
  }
  static {
    this.overrides = new Map();
  }
  static initialize(localEnv) {
    this.load();
    this.setLocalEnvironment(localEnv);
    this.overrides = new Map();
    // console.dir(this.mergedEnv,{depth:null});
  }
  static load() {
    // implemented in config.BACKEND / config.FRONTEND
    let envConfig = ReplacedConfig.getEnvConfig();
    const envConfigConverted = {};
    let val;
    envConfig = this.autoCorrectEnv(envConfig);
    for (let path of Object.keys(envConfig)) {
      if (path) {
        val = envConfig[path];
        // all env values are STRINGS! Need to convert.
        // support boolean and integers.
        if (val === 'true') val = true;else if (val === 'false') val = false;else if (/^\d+$/.test(val)) val = parseInt(val, 10);
        // transform GROUP_MYNESTED_VALUE to group.mynested.value
        path = transformEnvNameToConfigName(path);
        // deeply set props specified with deep.value.path
        setDotProp(envConfigConverted, path, val);
      }
    }
    this.configSources = {
      env: envConfigConverted,
      localEnv: null
    };
  }
  static setLocalEnvironment(localEnv) {
    if (this.configSources.localEnv && Object.entries(this.configSources.localEnv).length > 0) return;
    this.configSources.localEnv = transformKeysToLowerCase(localEnv);
    this.mergedEnv = deepmerge.all([this.buildBaseConfig(), this.configSources.localEnv, this.configSources.env]);
    this.validateConfiguration();
    // console.log('>>>>>>>>>>>', this.configSources.localEnv, this.configSources.env, this.mergedEnv)
  }
  static buildBaseConfig() {
    return {
      version: APP_VERSION.version,
      versioninfo: APP_VERSION,
      permissions: ['SUPERUSER', 'ADMINISTRATOR', 'REGISTERED']
    };
  }
  static autoCorrectEnv(config) {
    // check environment variables for \n and trim where needed
    for (const path in config) {
      if (config[path].indexOf('\\n') > -1) {
        config[path] = config[path].replace(/\\n/g, "\n");
      }
    }
    return config;
  }
  static validateConfiguration() {
    const appRef = this.mergedEnv.app;
    if (!appRef) return; // should not happen in production but might be the case in testing scenarios
    // check given host strings for validity
    // LOWERCASE!
    ['frontendhost', 'adminhost', 'apihost'].forEach(key => {
      const host = appRef[key];
      // its possible the property isn't set in some situations
      if (!host) return;
      if (host.startsWith('http')) throw new Error('config error: app.' + key + ' must not contain protocol!');
      if (host.startsWith('//')) throw new Error('config error: app.' + key + ' must not start with //!');
      if (host.substr(-1) === '/') throw new Error('config error: app.' + key + ' must not end with /!');
    });
  }
  static getProperty(path) {
    if (!this.mergedEnv) throw new Error('trying to get config property before mergedEnv is ready!');
    if (this.overrides.has(path)) return this.overrides.get(path);else return getDotProp(this.mergedEnv, path);
  }
  static get(name, defaultValue) {
    name = name.toLowerCase();
    const match = this.getProperty(name);
    let val;
    if (match === null) {
      val = defaultValue;
    } else {
      val = typeof match === 'function' ? match(Config) : match;
    }
    // console.log('config get',name,val)
    return val;
  }
  static setOverride(name, value) {
    this.overrides.set(name.toLowerCase(), value);
  }
  static clearOverride(name) {
    this.overrides.delete(name.toLowerCase());
  }
  static getDebugData() {
    const data = deepmerge.all([{}, this.mergedEnv]);
    this.overrides.forEach((value, prop) => {
      setDotProp(data, prop, value);
    });
    return data;
  }
}
Config.initialize(appConfiguration);