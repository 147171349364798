import { __decorate, __metadata } from "tslib";
import { AccessState } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../core/frontend-shared/src/lib/feature/dynamic-components/components/replaceable-component.directive";
import * as i3 from "../../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i4 from "../../../../../../ui/frontend-shared/src/lib/card/card";
import * as i5 from "../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i6 from "@angular/router";
import * as i7 from "./login-form.component";
function LoginComponent_ng_template_4_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "user-login-form");
    i0.ɵɵelementContainerEnd();
  }
}
const _c0 = a0 => ({
  resetPasswordEnabled: a0
});
const _c1 = a1 => ({
  id: "user-login-form",
  inputs: a1
});
function LoginComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LoginComponent_ng_template_4_ng_container_0_Template, 2, 0, "ng-container", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("replaceableComponent", i0.ɵɵpureFunction1(3, _c1, i0.ɵɵpureFunction1(1, _c0, ctx_r0.resetPasswordEnabled)));
  }
}
function LoginComponent_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div");
    i0.ɵɵtext(2, "Du bist eingeloggt.");
    i0.ɵɵelement(3, "br")(4, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "a", 6);
    i0.ɵɵtext(6, "Logout");
    i0.ɵɵelementEnd()();
  }
}
const _c2 = ["*"];
export class LoginComponent {
  constructor() {
    this.resetPasswordEnabled = true;
  }
  static {
    this.ɵfac = function LoginComponent_Factory(t) {
      return new (t || LoginComponent)();
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: LoginComponent,
      selectors: [["user-login"]],
      inputs: {
        resetPasswordEnabled: "resetPasswordEnabled"
      },
      ngContentSelectors: _c2,
      decls: 8,
      vars: 6,
      consts: [["size", "small"], [1, "content"], [3, "ngIf"], ["class", "box", 4, "ngIf"], [4, "replaceableComponent"], [1, "box"], ["uiButton", "", "routerLink", "/user/logout", 1, "ui-button-secondary"]],
      template: function LoginComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "ui-content-centered")(1, "ui-card", 0)(2, "div", 1);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, LoginComponent_ng_template_4_Template, 1, 5, "ng-template", 2);
          i0.ɵɵpipe(5, "async");
          i0.ɵɵtemplate(6, LoginComponent_div_6_Template, 7, 0, "div", 3);
          i0.ɵɵpipe(7, "async");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", !i0.ɵɵpipeBind1(5, 2, ctx.loggedIn$));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(7, 4, ctx.loggedIn$));
        }
      },
      dependencies: [i1.NgIf, i2.ReplaceableComponentDirective, i3.UiContentCenteredComponent, i4.Card, i5.UiButtonComponent, i6.RouterLink, i7.LoginFormComponent, i1.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:flex;align-items:center;justify-content:center}.links[_ngcontent-%COMP%]{text-align:center;margin-top:10px}.links[_ngcontent-%COMP%] > div[_ngcontent-%COMP%] + div[_ngcontent-%COMP%]{margin-top:5px}.content[_ngcontent-%COMP%]:not(:empty){margin-bottom:10px;text-align:center}.content[_ngcontent-%COMP%]:not(:empty)     app-logo{margin:0 auto}"]
    });
  }
}
__decorate([Select(AccessState.loggedIn), __metadata("design:type", Observable)], LoginComponent.prototype, "loggedIn$", void 0);