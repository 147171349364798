import { __decorate, __metadata } from "tslib";
import { AppRequest, AppRequestMethod } from '@core/shared';
import { Expose, Model, Type } from '@core/shared/model';
import { CloudinaryItemModel } from '../cloudinary-item.model';
import { CloudinaryItemInfoBaseRequest } from './requests';
export class GetCloudinaryResourceResponse extends Model {}
__decorate([Expose(), __metadata("design:type", Object)], GetCloudinaryResourceResponse.prototype, "resource", void 0);
__decorate([Expose(), __metadata("design:type", Object)], GetCloudinaryResourceResponse.prototype, "assetData", void 0);
let GetCloudinaryResourceRequest = class GetCloudinaryResourceRequest extends CloudinaryItemInfoBaseRequest {
  static {
    this.Response = GetCloudinaryResourceResponse;
  }
};
GetCloudinaryResourceRequest = __decorate([AppRequest('admin/cloudinary', 'resource', {
  httpMethod: AppRequestMethod.POST
})], GetCloudinaryResourceRequest);
export { GetCloudinaryResourceRequest };
let GenerateCloudinaryTransformationsRequest = class GenerateCloudinaryTransformationsRequest extends Model {};
__decorate([Expose(), Type(() => CloudinaryItemModel), __metadata("design:type", CloudinaryItemModel)], GenerateCloudinaryTransformationsRequest.prototype, "item", void 0);
__decorate([Expose(), __metadata("design:type", Array)], GenerateCloudinaryTransformationsRequest.prototype, "transformations", void 0);
GenerateCloudinaryTransformationsRequest = __decorate([AppRequest('admin/cloudinary', 'generate-transformations', {
  httpMethod: AppRequestMethod.POST
})], GenerateCloudinaryTransformationsRequest);
export { GenerateCloudinaryTransformationsRequest };