import { VirtualPropertyBaseDefinition } from "@core/shared";
export class CompanyVirtualPropertyBaseDefinition extends VirtualPropertyBaseDefinition {
  getValidAccessGroups() {
    return ['managerOfCompany', 'admin', 'public'];
  }
  getGrantedAccessGroupsForUserAndItem(item, access) {
    const accessGroups = [];
    accessGroups.push('public');
    if (access.isAdmin) accessGroups.push('admin');
    const isManager = access.getAccessData('isManager', null, 'null');
    const managedCompany = access.getAccessData('managedCompany', null, 'null');
    if (isManager && managedCompany) {
      if (managedCompany.id === item.id) accessGroups.push('managerOfCompany');
    }
    return accessGroups;
  }
}