import { EventEmitter } from '@angular/core';
import { CRUD_ROUTE_STATE_LIST } from '@common/crud/frontend-shared';
import { CrudSearchQueryBuilder, ItemServiceHelper } from '@core/frontend-shared/api';
import { getDiff } from '@core/shared';
import { deepmergeCustom } from 'deepmerge-ts';
import { BehaviorSubject, Subject, of, timer } from 'rxjs';
import { debounce, map, takeUntil } from 'rxjs/operators';
export const defaultCrudListState = {
  paging: {
    itemsPerPage: 20,
    offset: 0
  },
  ordering: null,
  filters: {},
  permanentFilters: null
};
export class ListStateEvent {}
// event fired when pagination offset is reset programmatically due to changed filter/ordering
export class ResetPaginationEvent extends ListStateEvent {
  constructor(offset, itemsPerPage) {
    super();
    this.offset = offset;
    this.itemsPerPage = itemsPerPage;
  }
}
export class CrudListController {
  constructor(listConfig, service, state, dependenciesFactory) {
    this.listConfig = listConfig;
    this.service = service;
    this.state = state;
    this.dependenciesFactory = dependenciesFactory;
    this.currentOrdering$ = new BehaviorSubject(null);
    this.reorderingPermitted$ = this.currentOrdering$.pipe(map(ordering => {
      return ordering === null || ordering.field === 'ordering';
    }));
    // note that this ignores state.permanentFilters. It only reacts to filterFormService filters object.
    this.hasActiveFilters$ = new BehaviorSubject(false);
    this.filterFormService = null;
    this.isLoadingDependencies$ = new BehaviorSubject(false);
    this.listState$ = new BehaviorSubject(defaultCrudListState);
    this.listStateEvents$ = new EventEmitter();
    this.lockedState = {};
    this.dependencies = null;
    this.initialized = false;
    this.destroy$ = new Subject();
    this.itemServiceHelper = new ItemServiceHelper(this.service);
    this.items$ = new BehaviorSubject(null);
    this.validate();
    this.preloadDependencies();
    if (this.listConfig.loadAllItems) {
      this.service.query.setLimit(null);
      this.updateListState({
        paging: {
          offset: null,
          itemsPerPage: null
        }
      });
    } else if (typeof this.listConfig.itemsPerPage === 'number') {
      this.updateListState({
        paging: {
          offset: 0,
          itemsPerPage: this.listConfig.itemsPerPage
        }
      });
    }
    this.rangeInfo$ = this.service.getRangeInfo$().pipe(takeUntil(this.destroy$));
    this.listState$.pipe(debounce(s => {
      return typeof s?.debounce === 'number' ? timer(s.debounce) : of(true);
    }), takeUntil(this.destroy$)).subscribe(currentState => {
      this.handleUpdatedListState(currentState);
    });
  }
  validate() {
    if (typeof this.service === 'undefined') throw new Error('ItemTableComponent requires a [service]!');
    if (this.listConfig.reordering && !this.listConfig.loadAllItems) throw new Error('Reorderable table must not be paged. Set crudConfig.list.loadAllItems to true to fix!');
  }
  preloadDependencies() {
    if (this.dependenciesFactory) {
      this.isLoadingDependencies$.next(true);
      this.dependenciesFactory().subscribe(deps => {
        this.dependencies = deps;
        this.isLoadingDependencies$.next(false);
      });
    }
  }
  createItemsObservable() {
    return this.items$;
  }
  getSubscriptions() {
    return {
      rangeInfo$: this.rangeInfo$,
      loadingState$: this.service.loadingState$,
      isLoadingDependencies$: this.isLoadingDependencies$,
      currentOrdering$: this.currentOrdering$,
      reorderingPermitted$: this.reorderingPermitted$,
      hasActiveFilters$: this.hasActiveFilters$,
      listStateEvents$: this.listStateEvents$
    };
  }
  destroy() {
    this.destroy$.next(true);
  }
  // -------------  LIST STATE  -----------------------------------------------------------------
  /**
   * to initialize API fetching, either call updateListState or do it manually by calling loadItems.
   */
  // allows to lock properties of listState to be non-customizable via updateListState.
  lockListState(state) {
    const diff = getDiff(state, this.lockedState);
    const hasChanges = Object.keys(diff).length > 0;
    if (hasChanges) {
      // console.log('modify locked list state', Object.keys(diff), diff)
      this.lockedState = state;
      this.updateListState({});
    }
  }
  updateListState(patch, debounceMs = 10) {
    // docs: https://github.com/RebeccaStevens/deepmerge-ts/blob/main/docs/deepmergeCustom.md
    const customMerger = deepmergeCustom({
      mergeArrays: (values, utils, meta) => {
        // in case of arrays, exclude the first value from being merged (first entry = previous state value)
        if (meta.key === 'ordering' || meta.key === 'permanentFilters') {
          return utils.deepmerge(...values.slice(1));
        }
        return utils.actions.defaultMerge;
      }
    });
    const newState = customMerger(this.listState$.getValue(), patch, this.lockedState);
    const diff = getDiff(this.listState$.getValue(), newState);
    // sie effect: reset filterFormService
    if (patch.filters && Object.keys(patch.filters).length === 0) this.filterFormService.setValue({});
    const resetPagingIfNotExplicitlySet = () => {
      if (!diff.paging) newState.paging.offset = 0;
      this.listStateEvents$.emit(new ResetPaginationEvent(newState.paging.offset, newState.paging.itemsPerPage));
    };
    if (diff.filters) {
      resetPagingIfNotExplicitlySet();
    }
    if (diff.ordering) {
      resetPagingIfNotExplicitlySet();
    }
    if (diff.paging) {}
    newState.debounce = debounceMs;
    this.listState$.next(newState);
  }
  loadItems() {
    if (this.initialized) return;
    this.initialized = true;
    // call read with forced refresh to make sure data is being reloaded e.g. after re-login
    this.service.read(false, true).pipe(takeUntil(this.destroy$)).subscribe(items => {
      this.items$.next(items);
    });
  }
  reloadItems() {
    if (!this.initialized) throw new Error('CrudListController::reloadItems called before being initialized!');
    if (!this.service.hasReadItems) return;
    this.service.refreshAllItems().subscribe();
  }
  persistState(id) {
    if (!IS_PRODUCTION) console.warn('listController.persistState is not implemented yet!');
  }
  handleUpdatedListState(state) {
    // const diff = getDiff(state, prevState);
    this.service.query.setSortBy(null);
    this.service.setVirtualSortBy(null);
    if (state.ordering?.length) {
      if (state.ordering.length > 1) throw new Error('Sorting by multiple columns is not supported currently, if needed remove this error and finish implementation');
      state.ordering.forEach(ordering => {
        this._applySorting(ordering.field, ordering.order, ordering.clientSorting);
      });
    }
    this.itemServiceHelper.applyPaging(state.paging.offset, state.paging.itemsPerPage);
    // this.itemServiceHelper.resetPaging()
    this._updateFilters(state.filters, state.permanentFilters);
    if (!this.initialized) this.loadItems();else this.reloadItems();
  }
  // -------------  ORDERING/SORTING  -----------------------------------------------------------------
  getDefaultSortField() {
    return this.state?.[CRUD_ROUTE_STATE_LIST]?.ordering?.field || this.listConfig.sortField || 'id';
  }
  getDefaultSortOrdering() {
    const orderDirection = this.state?.[CRUD_ROUTE_STATE_LIST]?.ordering?.ordering || this.listConfig.sortOrdering;
    return orderDirection === 'DESC' ? -1 : 1;
  }
  _applySorting(sortField, sortOrder, clientSorting) {
    const order = sortOrder === -1 ? 'DESC' : 'ASC';
    if (clientSorting) {
      // frontend sorting
      if (!this.listConfig.loadAllItems) throw new Error('list column ' + sortField + ' has a clientSorting method but loadAllItems is disabled!');
      const requiresDependencies = this.sortingFunctionRequiresDependencies(clientSorting);
      if (!requiresDependencies) {
        this.service.setVirtualSortBy(clientSorting, order);
      } else {
        if (!this.isLoadingDependencies$.getValue()) {
          this.service.setVirtualSortBy((a, b) => {
            return clientSorting(a, b, this.dependencies);
          }, order);
        } else {
          // need to wait for dependencies to load before sorting can be applied!
          throw new Error('Oops! need to wait for dependencies to load before sorting can be applied! Not implemented!');
          // setTimeout(()=>{ this.reloadItems(event) },1000)
        }
      }
    } else {
      // backend sorting
      const newOrdering = {
        field: sortField,
        order
      };
      const curr = this.currentOrdering$.value;
      if (curr === null || curr.field !== newOrdering.field || curr.order !== newOrdering.order) {
        this.currentOrdering$.next(newOrdering);
        this.service.query.setSortBy(newOrdering);
      }
    }
  }
  sortingFunctionRequiresDependencies(func) {
    return func.length > 2;
  }
  // -------------  FILTERING  -----------------------------------------------------------------
  bindFilterFieldsetFormService(service) {
    this.filterFormService = service;
    if (this.state?.[CRUD_ROUTE_STATE_LIST]?.filters) {
      const filters = this.state?.[CRUD_ROUTE_STATE_LIST].filters;
      service.patchValue(filters);
      service.resetFormFieldValidation();
      this.updateListState({
        filters
      });
      // this.updateFilters(filters)
    }
  }
  getFilterFormService() {
    return this.filterFormService;
  }
  _updateFilters(filterState, customFilters) {
    if (!filterState) filterState = {}; // can be null sometimes, handle that situation
    if (!customFilters) customFilters = [];
    // do not set loading state here! 
    this.service.query.setFilter(null);
    this.service.query.setOr(null);
    this.hasActiveFilters$.next(false);
    function isApplicableFilterValue(value) {
      const isEmptyArray = Array.isArray(value) && value.length === 0;
      return value !== null && value !== '' && !isEmptyArray;
    }
    // first dimension to be treated as AND, second dimension treated as OR
    const crudQueryFilters = [];
    this.listConfig.filters.forEach(filterField => {
      const filterName = filterField.config.formControlName;
      const value = typeof filterState[filterName] !== 'undefined' ? filterState[filterName] : null;
      if (isApplicableFilterValue(value)) {
        // some fields have multiple filters assigned... e.g. text search within ID + name
        const filterOrGroup = filterField.config.filters.map((f, i) => {
          return {
            field: f.field,
            value,
            operator: f.operator
          };
        });
        crudQueryFilters.push(filterOrGroup);
      }
    });
    if (crudQueryFilters.length) {
      this.hasActiveFilters$.next(true);
    }
    crudQueryFilters.push(...customFilters);
    const search = new CrudSearchQueryBuilder();
    crudQueryFilters.forEach(filterGroup => {
      if (filterGroup.length === 1) {
        const filter = filterGroup[0];
        search.fromQueryFilter('$and', filter);
      } else {
        search.createSubSearch('$and', subSearch => {
          filterGroup.forEach(filter => {
            subSearch.fromQueryFilter('$or', filter);
          });
        });
      }
    });
    this.service.query.setSearch(search);
    // console.log('built crud search',customFilters,search.getSearchQuery(), this.service.query.queryObject);
    // crudQueryFilters.forEach(filterGroup=>{
    // 	const multiple = filterGroup.length>1;
    // 	filterGroup.forEach(filter=>{
    // 		if(multiple) this.service.query.setOr(filter);
    // 		else this.service.query.setFilter(filter);
    // 	})
    // })
  }
}