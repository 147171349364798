import { __decorate, __metadata } from "tslib";
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ConfigService } from '@core/shared/config';
import { Action, Selector, State, Store } from '@ngxs/store';
import { concat, first, interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@core/shared/config";
import * as i3 from "@angular/service-worker";
import * as i4 from "@ngxs/store";
// Interesting other concept: https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
export const VersioncheckActions = {
  FetchVersion: class {
    static {
      this.type = '[Versioncheck] FetchVersion';
    }
    constructor() {}
  },
  CheckForUpdate: class {
    static {
      this.type = '[Versioncheck] CheckForUpdate';
    }
    constructor() {}
  },
  UpdateAvailable: class {
    static {
      this.type = '[Versioncheck] UpdateAvailable';
    }
    constructor() {}
  },
  ActivateUpdate: class {
    static {
      this.type = '[Versioncheck] ActivateUpdate';
    }
    constructor() {}
  },
  PostponeUpdate: class {
    static {
      this.type = '[Versioncheck] PostponeUpdate';
    }
    constructor() {}
  }
};
let VersioncheckState = class VersioncheckState {
  static newVersionAvailable(state) {
    return state.newVersionAvailable;
  }
  constructor(http, config, appRef, updates, store, document) {
    this.http = http;
    this.config = config;
    this.appRef = appRef;
    this.updates = updates;
    this.store = store;
    this.document = document;
    // wait for app to stabilize first, before starting polling for updates with `interval()`.
    this.appIsStable$ = this.appRef.isStable.pipe(first(isStable => {
      return isStable === true;
    }));
    this.fetchInterval$ = interval(this.config.get('versioncheck.refreshinterval', 5 * 60) * 1000); // default to 5 minutes
    this.checkForUpdate$ = concat(this.appIsStable$, this.fetchInterval$);
    this.versionInfo = config.get('versioninfo');
    const isAdminApp = config.isPlatform('admin');
    this.appBaseUrl = config.buildPlatformUrl(isAdminApp ? 'admin' : 'frontend');
    // console.log('retrieved versioninfo',this.versionInfo)
    // For some time, ngsw is loaded on development server too.
    // This can cause long, seemingly endless page reload loops.
    // additional IS_PRODUCTION tries to suppress this behavior.
    // when having reload loop issue, manually clear all service workers from dev page.
    // Issue tracked in https://github.com/angular/angular/issues/47455
    if (updates.isEnabled && IS_PRODUCTION) {
      // console.log('Service worker is enabled');
      updates.versionUpdates.subscribe(event => {
        // since ng14, versionUpdates will emit even if no version is available! #2znmc58
        if (event.type === 'NO_NEW_VERSION_DETECTED') return;
        if (event.type === 'VERSION_INSTALLATION_FAILED') return;
        store.dispatch(new VersioncheckActions.UpdateAvailable());
      });
      this.checkForUpdate$.subscribe(() => {
        store.dispatch(new VersioncheckActions.CheckForUpdate());
      });
    }
  }
  // ngxsOnInit() {
  // 	this.store.dispatch(new VersioncheckActions.UpdateAvailable())
  // }
  checkForUpdate(ctx, action) {
    const postponed = ctx.getState().postponedUntil;
    if (postponed && postponed > Date.now()) {
      // console.log('postponed for '+(postponed-Date.now()))
      return;
    }
    this.updates.checkForUpdate();
  }
  updateAvailable(ctx, action) {
    const postponed = ctx.getState().postponedUntil;
    if (postponed && postponed > Date.now()) {
      // console.log('postponed for '+(postponed-Date.now()))
      return;
    }
    ctx.patchState({
      newVersionAvailable: true
    });
  }
  activateUpdate(ctx, action) {
    this.updates.activateUpdate().then(success => {
      this.document.location.reload();
    });
  }
  postponeUpdate(ctx, action) {
    ctx.patchState({
      newVersionAvailable: false,
      postponedUntil: Date.now() + 60 * 60 * 1000 // 1h in ms
    });
  }
  static {
    this.ɵfac = function VersioncheckState_Factory(t) {
      return new (t || VersioncheckState)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i3.SwUpdate), i0.ɵɵinject(i4.Store), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: VersioncheckState,
      factory: VersioncheckState.ɵfac
    });
  }
};
__decorate([Action(VersioncheckActions.CheckForUpdate), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, void 0]), __metadata("design:returntype", void 0)], VersioncheckState.prototype, "checkForUpdate", null);
__decorate([Action(VersioncheckActions.UpdateAvailable), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, void 0]), __metadata("design:returntype", void 0)], VersioncheckState.prototype, "updateAvailable", null);
__decorate([Action(VersioncheckActions.ActivateUpdate), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, void 0]), __metadata("design:returntype", void 0)], VersioncheckState.prototype, "activateUpdate", null);
__decorate([Action(VersioncheckActions.PostponeUpdate), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, void 0]), __metadata("design:returntype", void 0)], VersioncheckState.prototype, "postponeUpdate", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], VersioncheckState, "newVersionAvailable", null);
VersioncheckState = __decorate([State({
  name: 'versioncheck',
  defaults: {
    aborted: false,
    newVersionAvailable: false,
    postponedUntil: 0
  }
}), __metadata("design:paramtypes", [HttpClient, ConfigService, ApplicationRef, SwUpdate, Store, Document])], VersioncheckState);
export { VersioncheckState };