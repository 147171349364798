import { ClassTransformService, EditorDefinition, createChildModel } from '../model';
import { VirtualPropertyMapModel } from './virtual-property';
import { getModelsWithVirtualProperties$ } from './virtual-properties.decorator';
import { Subject, takeUntil } from 'rxjs';
import { VIRTUAL_PROPS_ACCESSOR } from './constants';
export function collectVirtualProperties(baseFeatures) {
  const allVProps = {};
  baseFeatures.forEach(base => {
    const virtualProperties = base.getSharedDefinitionCached().virtualProperties;
    if (!virtualProperties) return;
    Object.keys(virtualProperties).forEach(modelName => {
      if (!allVProps[modelName]) allVProps[modelName] = [];
      allVProps[modelName].push(...virtualProperties[modelName]);
    });
  });
  return allVProps;
}
export class VirtualPropertiesRegistry {
  constructor(vPropConfigurationMap, config) {
    this.config = config;
    this.registry = {};
    this.modelCache = {};
    this.metaCache = {};
    this.modelNamesToDebug = [];
    this.destroy$ = new Subject();
    Object.keys(vPropConfigurationMap).forEach(modelName => {
      this.registerVirtualProperties(modelName, vPropConfigurationMap[modelName]);
    });
    getModelsWithVirtualProperties$().pipe(takeUntil(this.destroy$)).subscribe(matches => {
      matches.forEach(({
        modelCtor,
        metadata
      }) => {
        this.createVPropsModel(metadata);
        this.assignMetadataToModelWithVirtualProperties(metadata);
      });
    });
    const modelNamesToDebugString = config.get('core.vprops.debug', false);
    if (modelNamesToDebugString) {
      this.modelNamesToDebug = modelNamesToDebugString.split(',');
      console.log('VPROPS DEBUG: Debugging is enabled for: ', this.modelNamesToDebug);
    }
  }
  createVPropsModel(metadata) {
    const modelCtor = metadata[0].target;
    // this.getVirtualPropertyConfigurations(modelCtor)
    const className = typeof modelCtor === 'string' ? modelCtor : this.getModelClassName(modelCtor);
    const VirtualPropsModel = this.generateVirtualPropertiesModelFor(className);
    this.modelCache[className] = VirtualPropsModel;
  }
  assignMetadataToModelWithVirtualProperties(metadata) {
    // iterate all models that have MediaAsset metadata assigned
    const modelCtor = metadata[0].target;
    const className = typeof modelCtor === 'string' ? modelCtor : this.getModelClassName(modelCtor);
    metadata.forEach(meta => {
      this.metaCache[className] = meta;
      const VirtualPropertiesModelCtor = this.modelCache[className];
      if (this.shouldDebug(className)) console.log('VPROPS DEBUG: Discovered VProps for Model ' + className + ':', VirtualPropertiesModelCtor);
      // we could throw an error but VirtualProperties are opt-in so we just return an empty object.
      if (!VirtualPropertiesModelCtor) return;
      this.assignMetadataToModelForVirtualProperties(meta.target, VirtualPropertiesModelCtor, meta);
    });
  }
  registerVirtualProperties(modelName, vPropConfigs) {
    if (!this.registry[modelName]) this.registry[modelName] = new Map();
    const reg = this.registry[modelName];
    vPropConfigs.forEach(def => {
      // if(def.name !== def.name.toLowerCase()) throw new Error('VirtualProperty names must only contain lowercase chars!');
      if (reg.has(def.name)) throw new Error('VirtualProperty with name ' + def.name + ' has already been defined!');
      if (def.canEdit.includes('public')) throw new Error('VirtualPropertiesRegistry Error: VirtualProperty.canEdit must not include "public"!');
      // if(!def.visibility) def.visibility = 'normal';
      reg.set(def.name, def);
    });
  }
  getVirtualPropertyConfigurations(className) {
    const reg = this.registry[className];
    // name = name.toLowerCase();
    if (!reg) return new Map();
    return reg;
  }
  getVirtualPropertyConfiguration(className, vPropName, allowNull = true) {
    if (!vPropName) throw new Error('VirtualPropertiesRegistry::getVirtualPropertyConfiguration called without correct argument!');
    const reg = this.getVirtualPropertyConfigurations(className);
    // name = name.toLowerCase();
    if (!reg.has(vPropName)) {
      if (allowNull) return null;
      throw new Error('no VirtualProperty config found for "' + vPropName + '" of Model ' + className);
    }
    const config = reg.get(vPropName);
    return config;
  }
  assignMetadataToModelForVirtualProperties(modelInfo, VirtualPropertiesModelCtor, meta) {
    const propertyName = meta.options.propertyKey || VIRTUAL_PROPS_ACCESSOR;
    // assign type metadata. VirtualProperty is now ready for transformation.
    ClassTransformService.getMetadataStorage().addTypeMetadata({
      target: modelInfo,
      propertyName: propertyName,
      reflectedType: null,
      typeFunction: () => VirtualPropertiesModelCtor,
      options: {}
    });
    // console.log('assigned type metadata to '+modelInfo.constructor, ClassTransformService.getMetadataStorage().findTypeMetadata(modelInfo, propertyName) )
  }
  generateDebugData() {
    const registeredClassNames = Object.keys(this.registry);
    const info = registeredClassNames.map(className => {
      const meta = this.metaCache[className];
      return {
        className,
        definitions: this.registry[className],
        model: this.modelCache[className]
      };
    });
    return info;
  }
  /**
   * create a transformable/validateable Model dynamically
   */
  generateVirtualPropertiesModelFor(name) {
    const VirtualPropertiesModel = createChildModel(VirtualPropertyMapModel, name + 'VirtualProperties');
    const definition = this.getVirtualPropertiesForModel(name);
    const metaStorage = ClassTransformService.getMetadataStorage();
    const debug = this.shouldDebug(name);
    if (debug) console.log('VPROPS DEBUG: Discovered VProps for Model ' + name + ':', definition);
    definition.forEach(def => {
      // class-transformer meta
      metaStorage.addExposeMetadata({
        target: VirtualPropertiesModel,
        propertyName: def.name,
        options: {}
      });
      // class-validator meta
      if (def.validators) {
        def.validators.forEach(validator => {
          // validators will be assigned to the constructor of passed object.
          // passing VirtualPropertiesModel would assign validators to VirtualPropertiesModel.constructor.
          // but it needs to be assigned to VirtualPropertiesModel.prototype.constructor, so we pass the prototype.
          // console.log('assign validator',def.name,validator)
          validator(VirtualPropertiesModel.prototype, def.name);
        });
      }
    });
    // editor definition
    EditorDefinition({}, {
      allowAllExposed: true
    })(VirtualPropertiesModel);
    if (debug) console.log('VPROPS DEBUG: Generated VProps Model for Model ' + name + ':', VirtualPropertiesModel);
    // console.log('generated VirtualPropertiesModel',definition,getAllClassTransformerMetadata(VirtualPropertiesModel))
    // console.log('generated VirtualPropertiesModel',VirtualPropertiesModel,getAllClassValidatorMetadata(VirtualPropertiesModel as any))
    return VirtualPropertiesModel;
  }
  getTransformFunctionsForVirtualProperty(className, vPropName) {
    const def = this.getVirtualPropertyConfiguration(className, vPropName, false);
    return this.getTransformFunctionsForVirtualPropertyType(def.type);
  }
  getTransformFunctionsForVirtualPropertyType(type) {
    let deserializeFn = null;
    let serializeFn = null;
    switch (type) {
      case 'json':
        deserializeFn = value => {
          if (typeof value === 'object' || value === null) return value;
          if (typeof value === 'string') return JSON.parse(value);
          throw new Error('invalid value passed to json deserializeFn: ' + value);
        };
        serializeFn = value => {
          if (typeof value === 'string' || value === null) return value;
          if (typeof value === 'object' || Array.isArray(value)) return JSON.stringify(value);
          throw new Error('invalid value passed to json serializeFn: ' + value);
        };
        break;
      case 'int':
        deserializeFn = value => {
          if (value === null) return null;
          if (typeof value === 'number' && !isNaN(value)) return value;
          if (typeof value === 'string') {
            const intVal = parseInt(value, 10);
            if (isNaN(intVal)) throw new Error('Invalid string passed to int deserializeFn: Not numeric');
            return intVal;
          }
          throw new Error('Invalid type passed to int deserializeFn: ' + typeof value);
        };
        serializeFn = value => {
          if (value === null) return null;
          if (typeof value === 'string') {
            if (isNaN(parseInt(value, 10))) throw new Error('Invalid string passed to int serializeFn: Not numeric');
            return value;
          }
          if (typeof value !== 'number') throw new Error('Invalid type passed to int serializeFn: ' + typeof value);
          if (isNaN(value)) return null;
          return value.toString();
        };
        break;
      case 'boolean':
        deserializeFn = value => {
          if (value === null) return null;
          if (typeof value === 'boolean') return value;
          if (value === '1') return true;else if (value === '0') return false;
          throw new Error('Invalid data passed to boolean deserializeFn: ' + value);
        };
        serializeFn = value => {
          if (value === null || value === '1' || value === '0') return value;
          if (typeof value === 'boolean') return value ? '1' : '0';
          if (typeof value === 'number') return value > 0 ? '1' : '0';
          throw new Error('Unsupported data passed to boolean serializeFn: ' + value);
        };
        break;
    }
    return {
      serializeFn,
      deserializeFn
    };
  }
  getVirtualPropertiesForModel(name) {
    return this.registry[name] || new Map();
  }
  // protected ensureValidClassName(className:ModelClassName) {
  // 	if(className.endsWith('Entity')) throw new Error('getMetadataForModel received className ending with -Entity. VirtualProperties should always refer to -Item models!')
  // }
  // either Entity or Item may be passed to VirtualPropertiesHelper.
  // The VirtualProperty system always refers to the name of ITEM class.
  // so if an Entity Ctor is passed, we need to extract the name of parent item class.
  getModelClassName(modelCtor) {
    let name = modelCtor.name || modelCtor.constructor.name;
    if (!name) throw new Error('cannot get modelClassName of ' + modelCtor);
    if (name.endsWith('Entity')) {
      const ParentItem = Object.getPrototypeOf(modelCtor);
      name = ParentItem.name;
    }
    return name;
  }
  modelHasVirtualPropertyMetadata(modelCtorOrName) {
    return !!this.getMetadataForModel(modelCtorOrName, false);
  }
  getMetadataForModel(modelCtorOrName, throwIfMissing = true) {
    const className = typeof modelCtorOrName === 'string' ? modelCtorOrName : this.getModelClassName(modelCtorOrName);
    if (!this.metaCache[className]) {
      if (throwIfMissing) {
        console.log(this.metaCache);
        throw new Error('Could not find VirtualProperty metadata for "' + className + '"!');
      } else {
        return null;
      }
    }
    return this.metaCache[className];
  }
  getModels() {
    return this.modelCache;
  }
  shouldDebug(modelCtorOrName) {
    const className = typeof modelCtorOrName === 'string' ? modelCtorOrName : this.getModelClassName(modelCtorOrName);
    return this.modelNamesToDebug.includes(className);
  }
}