import { FileLikeObject } from '../file-like-object/file-like-object.class';
import { BehaviorSubject } from 'rxjs';
import { OnItemCanceled, OnItemSuccess, OnItemError, OnItemConflict, OnItemProgress, OnItemUpload } from '../events/events';
import { EventEmitter } from '@angular/core';
import { clamp } from '@core/shared';
export class FileItem {
  get state() {
    return this._state.value;
  }
  constructor(some, options, uploader) {
    this.uploader = uploader;
    this.events = new EventEmitter();
    this.headers = [];
    this.withCredentials = true;
    this.overwrite = false;
    this.formData = [];
    this._nextIndex = 0;
    this.progress = 0;
    this.index = void 0;
    this.downloadUrl = null;
    this._state = new BehaviorSubject('idle');
    this.state$ = this._state.asObservable();
    this.file = new FileLikeObject(some);
    this._file = some;
    this.setOptions(options);
  }
  setOptions(options) {
    this.options = options;
    this.autoUpload = this.options.autoUpload;
  }
  getId() {
    return this.id;
  }
  setId(id) {
    this.id = id;
  }
  getFile() {
    return this._file;
  }
  _onPrepareUpload() {
    this.progress = 0;
  }
  _onUploadStart() {
    this._state.next('uploading');
    this.events.emit(new OnItemUpload(this));
  }
  _onUploadProgress(event) {
    const progress = 100 * event.loaded / event.total;
    this.progress = clamp(Math.round(progress * 100), 0, 100);
    this.events.emit(new OnItemProgress(this, this.progress));
  }
  _onSuccess(asset, response) {
    this._state.next('completed');
    this.progress = 100;
    this.index = void 0;
    this.events.emit(new OnItemSuccess(this, asset, response));
  }
  _onConflict(fileInfo, error) {
    this._state.next('conflict');
    this.events.emit(new OnItemConflict(this, fileInfo, error));
  }
  _onError(error) {
    this._state.next('error');
    this.progress = 0;
    this.index = void 0;
    this.events.emit(new OnItemError(this, error));
  }
  _onCancel() {
    this._state.next('idle');
    this.progress = 0;
    this.index = void 0;
    this.events.emit(new OnItemCanceled(this));
  }
  _onRemove() {}
}