import { InputSelectbuttonInstruction } from './input-selectbutton';
import { optionsInputDefaults } from './shared';
export class InputSelectbuttonYesNoInstruction extends InputSelectbuttonInstruction {
  constructor() {
    super(...arguments);
    this.defaults = {};
  }
  getDefaults() {
    return {
      ...optionsInputDefaults,
      options: [{
        value: true,
        label: 'Ja'
      }, {
        value: false,
        label: 'Nein'
      }]
    };
  }
}