import { Component } from '@angular/core';
import { AccessState } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import * as deepmerge from 'deepmerge';
import { FeatureMenuItem } from '@core/frontend-shared/feature';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { FeatureMenuItemService } from '@core/frontend-shared/feature';

@Component({
	selector: 'pflegetheke-toolbar',
	styleUrls:['./toolbar.component.scss'],
	template: `
	<ui-tabMenu [model]="menu$|async" [activeItem]="activeItem">
	<ng-template uiTemplate="item" let-item let-i="index" let-selected="selected">

		<div class="content" >
			<fa-icon [ngClass]="item.icon" *ngIf="item.icon && item.routerLink!=='notifications'" [icon]="item.icon" size="2x"></fa-icon>
			<notifications-info-icon *ngIf="item.routerLink==='notifications'"></notifications-info-icon>
			<div class="label">
				{{item.label}}
			</div>
		</div>

	</ng-template>
	</ui-tabMenu>
	`
})
@AutoUnsubscribe()
export class AppToolbarcomponent {

	@Select(AccessState.loggedIn) loggedIn$!:Observable<boolean>;
	@Select(AccessState.isSuperUser) isSU$!:Observable<boolean>;
	@Select(AccessState.accessData('isManager')) isManager$!:Observable<boolean>;

	protected _defaultMenu:FeatureMenuItem[] = [
		{ label: 'Anmelden', icon:'unlock', routerLink:'login' },
		{ label: 'Mein Profil', icon:'user', routerLink:'user' },
		// { label: 'Management', icon:'user', routerLink:'management', visible:false },
		{ label: 'Themen', icon:'chalkboard-teacher', routerLink:'questions' },
		// { label: 'Neues', icon:'bell', routerLink:'notifications' },
		{ label: 'Hilfe', icon:'question-circle', routerLink:'help' },
		{ label: 'Menu', icon:'bars', items:[
			{ label: 'Datenschutz', icon:'user-shield', routerLink:'privacy' },
			{ label: 'Impressum', icon:'info-circle', routerLink: 'imprint' },
			{ label: 'Logout', icon:'lock', routerLink:'user/logout' },
		] },
	];

	menu$:Observable<FeatureMenuItem[]>

	// get menu() {
	// 	return this._defaultMenu
	// }

	activeItem = 0;
	
	constructor(
		featureMenuItemService:FeatureMenuItemService,
	) {
		this.menu$ = combineLatest([
			featureMenuItemService.hasAccessibleMenuItems('managementToolbar'),
			this.loggedIn$,
			this.isManager$,
			this.isSU$,
		]).pipe(map(([hasManagerMenuItems,loggedIn,isMgr,isSU])=>{
			const m = deepmerge([],this._defaultMenu);

			findMenuItemDeeply(m,'login').visible = !loggedIn;
			findMenuItemDeeply(m,'user').visible = loggedIn;
			findMenuItemDeeply(m,'user/logout').visible = loggedIn;
			// m.find((item)=>item.routerLink==='user').visible = !hasManagerMenuItems;
			// m.find((item)=>item.routerLink==='management').visible = hasManagerMenuItems;
			return m
		}))

	}

}


function findMenuItemDeeply(menuItems:FeatureMenuItem[], routerLink:string) {
	for(const menuItem of menuItems) {
		if(menuItem.routerLink === routerLink) return menuItem;
		if(menuItem.items) {
			const result = findMenuItemDeeply(menuItem.items,routerLink);
			if(result !== false) return result;
		}
	}
	return false;
}


