import { FilterFieldInstruction } from './shared';
export class FilterTextInstruction extends FilterFieldInstruction {
  getDefaults() {
    return {
      width: null
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      width: 'width',
      placeholder: 'placeholder'
    };
    this.attributeMap = {
      'e2e-filter': 'formControlName'
    };
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-text').then(bundle => {
      return {
        component: bundle.InputTextComponent,
        module: bundle.UiInputTextModule
      };
    });
  }
}