import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { __decorate, __metadata } from "tslib";
import { Injector } from '@angular/core';
import { ACCESS_SERVICE_PLUGIN_TOKEN, AccessServicePlugin, BaseAccessService, ClientAppPlatformString, ConfigService, EndSessionRequest, GetSessionInfoRequest, OnSessionReloaded, OnSessionStart, SessionInfo } from '@core/shared';
import { Store } from '@ngxs/store';
import { AutoUnsubscribe } from '../../utils';
import { firstValueFrom } from 'rxjs';
import { AppMessageFactory } from '../../messages';
import { AppRequestService } from '../../api';
import { AccessActions } from './access.actions';
import { DevToolsRegistry } from '../../dev-tools';
import { FeatureProvidersRegistry } from '@core/shared/feature';
import * as i0 from "@angular/core";
import * as i1 from "@core/shared";
import * as i2 from "@ngxs/store";
import * as i3 from "../../api";
import * as i4 from "../../messages";
import * as i5 from "@core/shared/feature";
let AccessService = class AccessService extends BaseAccessService {
  constructor(config, store, api, messageFactory, injector, providersRegistry) {
    super();
    this.config = config;
    this.store = store;
    this.api = api;
    this.messageFactory = messageFactory;
    const debug = injector.get(DevToolsRegistry);
    const pluginCtors = providersRegistry.getProviders(ACCESS_SERVICE_PLUGIN_TOKEN);
    debug.exposeDebugFunction('AccessSessionInfo', {
      displayName: 'Access: SessionInfo'
    }, () => {
      return this._sessionInfo$;
    });
    debug.exposeDebugFunction('AccessServicePlugins', {
      displayName: 'Access: Service Plugins'
    }, () => {
      return pluginCtors;
    });
    this.setupPlugins(pluginCtors, injector);
  }
  // loads and returns sessionInfo, also provides it at this._sessionInfo$
  loadAndInitializeSession(sessionId) {
    var _this = this;
    return _asyncToGenerator(function* () {
      if (_this.hasSessionAlreadyLoaded(sessionId)) {
        _this.events.next(new OnSessionStart(_this.sessionInfo));
        return _this.sessionInfo;
      }
      const sessionInfo = yield _this.loadSessionInfo(sessionId);
      const requiredScope = _this.getRequiredSessionScope();
      if (!sessionInfo || sessionInfo.session.scope !== requiredScope) {
        console.warn('Session scope is invalid for this platform. Ending session.');
        yield _this.endSession();
        return null;
      }
      _this.events.next(new OnSessionStart(sessionInfo));
      return sessionInfo;
    })();
  }
  loadSessionInfo(sessionId) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      const response = yield firstValueFrom(_this2.api.sendRequest(GetSessionInfoRequest, {}));
      const sessionInfo = response.sessionInfo;
      const sessionActive = sessionInfo?.session?.sessionId === sessionId;
      _this2._sessionInfo$.next(sessionInfo);
      _this2._sessionId = sessionActive ? sessionId : null;
      return sessionInfo;
    })();
  }
  setSessionId(sessionId) {
    return super.setSessionId(sessionId).then(result => {
      if (result) {
        return true;
      } else {
        this.messageFactory.create().setSeverity('warn').setLife(30000).setMessage('Ihre Zugangsinformationen sind abgelaufen oder ungültig. Bitte versuchen Sie es erneut.');
        this.store.dispatch(new AccessActions.Logout());
        return false;
      }
    });
  }
  getAuthInfo() {
    const sess = this._sessionInfo$.value?.session;
    if (!sess) return null;
    return {
      authType: sess.authType,
      authId: sess.authId
    };
  }
  getRequiredSessionScope() {
    const platform = this.config.getPlatform();
    if (!platform) throw new Error('Configuration Error: platform is not specified');
    return platform === 'admin' ? 'admin' : 'frontend';
  }
  endSession() {
    var _superprop_getEndSession = () => super.endSession,
      _this3 = this;
    return _asyncToGenerator(function* () {
      // possibly called multiple times, so check if a session is actually there to end.
      if (!_this3._sessionId) return true;
      _superprop_getEndSession().call(_this3);
      // no await, this is just a side effect
      // must be called after endSession, else the backend will receive token header and try to validate the ended session.
      _this3.api.sendRequest(EndSessionRequest, {}).subscribe();
      return true;
    })();
  }
  reloadSession() {
    var _this4 = this;
    return _asyncToGenerator(function* () {
      const sessionInfo = yield _this4.loadSessionInfo(_this4._sessionId);
      _this4.events.next(new OnSessionReloaded(sessionInfo));
      return sessionInfo;
    })();
  }
  static {
    this.ɵfac = function AccessService_Factory(t) {
      return new (t || AccessService)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.AppRequestService), i0.ɵɵinject(i4.AppMessageFactory), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i5.FeatureProvidersRegistry));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: AccessService,
      factory: AccessService.ɵfac,
      providedIn: 'root'
    });
  }
};
AccessService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ConfigService, Store, AppRequestService, AppMessageFactory, Injector, FeatureProvidersRegistry])], AccessService);
export { AccessService };