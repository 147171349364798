import { __decorate, __metadata } from "tslib";
import { EventEmitter, Injector } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormStateService } from '@common/forms/shared';
import { AppMessageFactory } from "@core/frontend-shared";
import { AppRequestService } from '@core/frontend-shared/api';
import { CLASS_VALIDATOR_GLOBAL_CONFIG, CrudAction, DEFAULT_EDITOR_DEFINITION_ID, EditorModelFactory, ModelFactoryProvider, getSubModelMeta, Model } from '@core/shared/model';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { distinctUntilChangedDeep } from '@core/shared/utils';
import * as merge from 'deepmerge';
import { DynamicFormBuilder, setValidatorsToControls, setValuesForControls, validateAllFormFields } from 'ngx-dynamic-form-builder';
import { BehaviorSubject, combineLatest, of, Subject, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delayWhen, distinctUntilChanged, filter, map, share, skip, skipUntil, skipWhile, switchMap, take, tap, timeout } from 'rxjs/operators';
import { FormServiceSaveHandlerApiItem, FormServiceSaveHandlerApiRequest, FormServiceSaveHandlerNoop } from './form-service-save-handler';
export function isDynamicFormGroup(obj) {
  return obj instanceof UntypedFormGroup && typeof obj.patchDynamicFormBuilderOptions === 'function';
}
// TODO: rename to resetAllContainedFormArrays
function resetAllFormArrays(fg) {
  for (const key in fg.controls) {
    if (!{}.hasOwnProperty.call(fg.controls, key)) continue;
    const control = fg.controls[key];
    if (control instanceof UntypedFormArray) {
      control.clear();
    } else if (control instanceof UntypedFormGroup) {
      resetAllFormArrays(control);
    }
  }
}
/**
 * BaseFormService is a generic model-driven form service.
 *
 * TODO:
 * - fix ExtractModelFromData / PartialData types somehow
 * - status$ should become partially distinct:
 *   validation from VALID to VALID should re-emit, but VALID and PENDING is all false and indistinguishable

 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const test1:PartialData<{test:string}> = {test:'1'};
// const test2:PartialData<{test:string}[]> = [{test:'1'}, {}];
// const test3:{test:string}[] = [{test:'1'}, {}];
let id = 1;
let BaseFormService = class BaseFormService {
  get formGroup() {
    return this.formGroup$.value;
  }
  get initialized() {
    return this.initialized$.value;
  }
  get initializedValue() {
    return this.initialized$.value;
  }
  get formType() {
    if (this.editMode !== undefined) return 'editor';else return 'form';
  }
  // warning: FormService does not use injection system, its dependencies are handled by ModelFormServiceFactory!
  constructor(formStateService, message, modelFactoryProvider, injector) {
    this.formStateService = formStateService;
    this.message = message;
    this.modelFactoryProvider = modelFactoryProvider;
    this.injector = injector;
    // semi-constants for controlling state durations.
    this.MIN_DURATION_SAVING = 500;
    this.MIN_DURATION_SAVED = 2000;
    // formGroup
    this.formBuilder = new DynamicFormBuilder();
    this.formGroup$ = new BehaviorSubject(null);
    this.initialized$ = new BehaviorSubject(false);
    this.initializedValue$ = new BehaviorSubject(false);
    this.usesExternalFormGroup = false; // if true it means that formGroup was not created by this formService
    // value setup
    // if value is patched before initialization, it will be cached here until setting it is possible
    this.initialValue = null;
    this.immutableValues = {};
    // stores the value as calculated by formService, without any changes done by formControls. Includes immtables and initialValue
    this.inputValue = null;
    // auto-persisted model properties will always be submitted even if they are not listed in editor definition.
    // only certain readonly props should be available in data for persisting.
    // for example readonly ID must be available for saving to work, but creationDate is readonly and should not be re-saved.
    // these special prop IDs are listed in this.persistableReadonlyProps.
    this.persistableReadonlyProps = ['id'];
    this.editorDefinitionName = DEFAULT_EDITOR_DEFINITION_ID;
    this.maxNestedModelDepth = 5;
    // state
    this.isChanged$ = new BehaviorSubject(false);
    this.status$ = new BehaviorSubject('VALID');
    this._isBusyInternal$ = new BehaviorSubject(false);
    this.saveState$ = new BehaviorSubject('idle');
    this.hasTriedToSubmitForm$ = new BehaviorSubject(false);
    this.state$ = combineLatest({
      saveState: this.saveState$,
      status: this.status$,
      isBusy: this._isBusyInternal$,
      isChanged: this.isChanged$,
      hasTriedToSubmitForm: this.hasTriedToSubmitForm$
    }).pipe(map(({
      saveState,
      status,
      isBusy,
      isChanged,
      hasTriedToSubmitForm
    }) => {
      const busy = isBusy || saveState === 'saving';
      return {
        valid: status === 'VALID',
        status,
        busy,
        hasTriedToSubmitForm,
        saveState,
        changeState: isChanged ? 'changed' : 'unchanged'
      };
    }), distinctUntilChangedDeep()
    // shareReplay(1)
    );
    this.isBusy$ = this.state$.pipe(map(state => state.busy));
    // valueChanges and statusChanges are connected to the same-named observables of the currently managed form Group.
    // no piping/throttling or anything happens here.
    this.valueChanges$ = new Subject();
    this.statusChanges$ = new Subject();
    // use formValueChanges for behavior / user interaction. It is debounced etc
    // does NOT include readonly props!
    this._formValueChanges$ = new BehaviorSubject(null);
    this.formValueChanges$ = this._formValueChanges$.asObservable().pipe(filter(value => value !== null));
    this.editMode = undefined;
    // events
    this.onAfterInit = new EventEmitter();
    this.onBeforeSave = new EventEmitter();
    // TODO: should be specifically implemented at ModelFormService / CrudItemFormService
    // emits either responseData from server or an error wrapped in an AppMessage.
    // handling of errors is optional as AppMessages will auto-display if not handled manually.
    this.onAfterSave = new EventEmitter();
    // saving/submitting
    this.submitToApi = true; // can be disabled during debugging to stop submitting data to backend
    this.formSubscriptions = [];
    this._debug = false;
    this._id = id;
    id++;
    this.setupObservables();
    if (!IS_PRODUCTION) {
      setTimeout(() => {
        if (this.initialized) return;
        console.warn('it seems that a formService was created but not initialized.', this.modelFactory?.Model, this);
      }, 2000);
    }
    // console.log('created formService with id',this._id)
  }
  setupObservables() {
    this.formStateService.isBusy$.pipe(takeWhileAlive(this)).subscribe(isBusy => {
      this._isBusyInternal$.next(isBusy);
    });
    this.valueChanges$.pipe(
    // skipUntil was added to delay first emission to make sure patchinitialmodel is already finished
    // first it waited for timer(10) but this could cause issues when form value was changed during these 10ms.
    // so instead we wait for onAfterInit which emits right after initialValue has been set.
    skipUntil(timer(10)),
    // skip(1),
    debounceTime(100), distinctUntilChangedDeep({
      makeImmutable: true
    })).subscribe(data => {
      this.isChanged$.next(true);
      this._formValueChanges$.next(this.getValue());
    });
    // listen to statusChanges on regular formGroups
    this.statusChanges$
    // don't debounce or wait, statusChanges should be propagated immediately.
    .pipe(distinctUntilChanged()).subscribe(status => {
      if (this._debug) console.log(this.debugInfo() + ' statusChanges emitted', status, this.formGroup);
      this.status$.next(status);
    });
  }
  debug() {
    if (!this._debug) {
      this._debug = true;
      console.log(this.debugInfo() + ' Start debugging formService', this);
      // console.log(this.debugInfo()+' form value at debug start '+this.getValue()) // can lead to errors if using defaultFormService!
      this.state$.subscribe(state => console.log(this.debugInfo() + ' state$ emitted', state));
      setTimeout(() => {
        if (!this.initialized) console.warn(this.debugInfo() + ' has not been initialized', this);
      }, 3000);
    }
  }
  debugInfo() {
    const className = this.constructor.name.substring(0, 13) + `(${this._id})`;
    return `[DEBUG] ${className}`;
  }
  // ###########################################################################################################
  // ####  SETUP  ##############################################################################################
  // ###########################################################################################################
  setModel(input) {
    if (input instanceof EditorModelFactory) {
      this.setFactory(input);
    } else {
      if (!(input.prototype instanceof Model)) {
        throw new Error('Given Model Class does not inherit Model!');
      }
      const factory = this.modelFactoryProvider.createEditorModelFactory(input);
      this.setFactory(factory);
    }
  }
  setFactory(factory) {
    this.modelFactory = factory;
  }
  setEditorDefinition(name) {
    this.editorDefinitionName = name;
    // Tif called post-initialization, the form needs to be recreated - decision was made to call reinit manually!
    // if(this.initialized) this.reinitialize();
  }
  setEditMode(mode) {
    this.editMode = mode;
    // Tif called post-initialization, the form needs to be recreated - decision was made to call reinit manually!
    // if(this.initialized) this.reinitialize();
  }
  getEditMode() {
    return this.editMode;
  }
  getEditorDefinition() {
    return this.editorDefinitionName;
  }
  // call after all wanted setup / setX methods have been called
  // TODO: calling initialized$ before applying initial value is not a good solution.
  // but when changing order, it works worse. need to figure this out...
  initialize() {
    if (this.initialized) {
      if (this._debug) console.log(this.debugInfo() + '.initialize skipped', this);
      return Promise.resolve(false);
    }
    if (this._debug) console.log(this.debugInfo() + '.initialize started', this);
    return this.buildFormGroup().then(() => {
      if (this._debug) console.log(this.debugInfo() + '.initialize completed');
      if (this._debug) console.log(this.debugInfo() + ' patching initialValue ' + this.initialValue);
      if (!IS_PRODUCTION && !this.formGroup) {
        throw new Error('BaseFormService initialization failure: No formGroup available. Did buildFormGroup() fail?');
      }
      this.initialized$.next(true);
      this.validateImmutableValues();
      this.applyInitialValue();
      this.initializedValue$.next(true);
      this.onAfterInit.emit();
    });
  }
  /** reinitialization is required if EditorDefinition or EditMode changes */
  reinitialize() {
    if (this.usesExternalFormGroup) throw new Error('Cannot reinitialize formServices that are using externally set FormGroups!');
    this.unbindFormGroupListeners();
    this.formGroup$.next(null);
    this.initialized$.next(false);
    this.initializedValue$.next(false);
    this.initialize();
  }
  // ###########################################################################################################
  // ####  VALUE HANDLING  #####################################################################################
  // ###########################################################################################################
  getValue(includeReadonly = false) {
    if (!this.formGroup) return null;
    let value;
    // this.formGroup.value
    if (!includeReadonly) value = this.formGroup.value; // includes only editable props
    else value = this.formGroup.getRawValue(); // includes editable + readonly props
    if (Array.isArray(value)) throw new Error('BaseFormService.getValue does not support array data!');
    if (this.modelFactory?.Model) {
      value = this.modelFactory.fromData(value);
    }
    // add/lock readonly/immutable props to correct values
    // these must be applied AFTER Model transformation because the editorDefinition may not include immutableValue props.
    // but if immutableValues were set they must have higher priority than editorDefinition.
    value = this.applyImmutableValues(value);
    value = this.applyPersistableReadonlyProps(value);
    return value;
  }
  getValueIncludingReadonly() {
    return this.getValue(true);
  }
  hasValue() {
    const value = this.getValue();
    return !!value;
  }
  _setValue(inputValue) {
    if (this._debug) console.log(this.debugInfo() + '._setValue', inputValue);
    if (Array.isArray(inputValue)) throw new Error('BaseFormService._setValue does not support array data!');
    let value;
    // TODO: should a difference check between current and new value happen?
    const initial = this.getInitialValue();
    if (!(inputValue instanceof Model) && !this.modelFactory) {
      // looks like a non-Model driven form
      value = this.applyImmutableValues({
        ...initial,
        ...inputValue
      });
    } else {
      if (!this.modelFactory) throw new Error('Cannot create value because modelFactory is missing!');
      // try to convert values to correct model
      if (!(inputValue instanceof this.modelFactory.Model)) {
        value = this.modelFactory.fromData(this.applyImmutableValues({
          ...initial,
          ...inputValue
        }));
        // console.log(this.debugInfo()+'._setValue applyImmutables -> fromData',value)
      } else {
        value = this.modelFactory.patch(inputValue, this.applyImmutableValues({}));
        // console.log(this.debugInfo()+'._setValue applyImmutables -> patch',value)
      }
    }
    this.reloadFormValue(value);
  }
  setInitialValue(inputValue) {
    if (this._debug) console.log(this.debugInfo() + '.setInitialValue (initialValue) ', inputValue);
    this.initialValue = inputValue;
    // Tif called post-initialization, the form needs to be recreated to reflect changed initialValue
    // if(this.initialized) this.reinitialize();
    // if(!this.initialized) {
    // 	throw Error('cannot set initial value after initialization!')
    // }
  }
  setValue(inputValue) {
    if (!this.initialized) {
      throw Error('cannot set value before initialization! Use setInitialValue');
    } else {
      if (this._debug) console.log(this.debugInfo() + '.setValue ', inputValue);
      this._setValue(inputValue);
    }
  }
  patchValue(inputValue) {
    // TODO: deep merge using class transformer
    this._setValue({
      ...this.getValue(true),
      ...inputValue
    });
  }
  reloadFormValue(value) {
    if (!this.initialized) {
      if (this._debug) console.log(this.debugInfo() + '.reloadFormValue [ignored due to calling pre-initialization]', value);
    } else {
      if (this._debug) console.log(this.debugInfo() + '.reloadFormValue post-initialize', value);
      this.inputValue = value;
      const fg = this.formGroup;
      if (fg instanceof UntypedFormArray) {
        throw new Error('FormArray is not supported by BaseFormService, must use ArrayFormService!');
      }
      if (isDynamicFormGroup(fg)) {
        // TODO: setObject will currently merge FormArray entries with existing ones.
        // to get a true SET object, we currently need to reset all formArrays found within the form before using setObject.
        // https://github.com/EndyKaufman/ngx-dynamic-form-builder/issues/195
        resetAllFormArrays(fg);
        const dynamicForm = fg;
        // formGroup setObject has no solution for passing in custom Class-transform options.
        // so there was need to inline the "setObject" function and modify it to use our own isntanceToPlain transformation.
        // fg.setObject((value || {}) as TData);
        const dfbSetObjectModified = object => {
          setValuesForControls(this.formBuilder, dynamicForm, this.modelFactoryProvider.classTransformService.instanceToPlain(object));
          setValidatorsToControls(this.formBuilder, dynamicForm, dynamicForm.root);
        };
        dfbSetObjectModified(value || {});
        // (fg as DynamicFormGroup<TData>).refresh();
        // fg.updateValueAndValidity(); // would make tests fail when using DFB v2!
      } else {
        // in case of Custom FormGroup, value may be incomplete.
        // so we can only use patchValue as missing properties would lead to errors using setValue.
        fg.patchValue(value);
        fg.updateValueAndValidity();
      }
      this.updateFormControlDisabledState();
    }
  }
  cloneFormValue(value) {
    if (Array.isArray(value)) return merge([], value);else if (typeof value === 'object') return merge({}, value);
    throw Error('CloneFormValue failed: value is invalid: ' + value);
  }
  applyInitialValue() {
    // it is a bit delicate to decide if a default value should be patched or not.
    // we cannot _setValue([]) on FormArray - it would empty it.
    // A initialValue may exist, or not.
    // the formGroup may have been created during initialization or a custom one was passed to the service.
    // If a custom formgroup was passed, initialValue will be derived from it, but a custom initialValue could be set too.
    if (!this.dataIsArray()) {
      // will set initialValue, immutables, updates FormControlDisabled state
      this._setValue(this.dataIsArray() ? [] : {});
    }
  }
  // ###########################################################################################################
  // ####  EDITOR DEFINITION  ##################################################################################
  // ###########################################################################################################
  getEditorModelInfo() {
    return this.modelFactory.getEditorModelInfo();
  }
  getModelPropTypeInfo(name) {
    const modelInfo = this.getEditorModelInfo();
    if (modelInfo.nestedDefinitions.has(name)) {
      // a nested definition exists for this prop so it has to have a type too
      const nestedDefinitionName = modelInfo.nestedDefinitions.get(name);
      const meta = getSubModelMeta(this.modelFactory.Model, name);
      return {
        Model: meta.typeFunction(),
        isArray: meta.reflectedType === Array,
        definitionName: nestedDefinitionName
      };
    } else {
      return false;
    }
  }
  // ###########################################################################################################
  // ####  IMMUTABLES  #########################################################################################
  // ###########################################################################################################
  /**
   * locks controls to passed values.
   */
  setImmutableValues(immutables, disableImmutableFormControls = true) {
    this.immutableValues = {
      ...this.immutableValues,
      ...immutables
    };
    this.disableImmutableFormControls = disableImmutableFormControls;
    if (this.initialized) {
      // initial immutables will be checked during validation. 
      this.validateImmutableValues();
      if (disableImmutableFormControls) this.updateFormControlDisabledState();
      // make sure immutables are being patched
      this.patchValue({});
    }
  }
  validateImmutableValues() {
    if (!IS_PRODUCTION) {
      // assert that all passed immutable values are registered as readonly properties in used EditorDefinition
      if (this.modelFactory) {
        const readonlyPropNames = [...this.getEditorModelInfo().readonlyProps];
        for (const key in this.immutableValues) {
          if (Object.prototype.hasOwnProperty.call(this.immutableValues, key)) {
            const existsInReadonly = readonlyPropNames.includes(key);
            if (!existsInReadonly) console.warn("FormService misconfiguration: Property '" + key + "' was passed as immutable, but is not marked as readonly in EditorDefinition!");
          }
        }
      }
    }
  }
  // applyImmutableValues ensures that immutableValues will always be the same both when getting and setting values.
  applyImmutableValues(value) {
    // no cloning! would destroy model
    // const outValue = this.cloneFormValue(value);
    for (const key in this.immutableValues) {
      if ({}.hasOwnProperty.call(this.immutableValues, key)) {
        value[key] = this.immutableValues[key];
      }
    }
    return value;
  }
  // applyPersistableReadonlyProps ensures thatpersistableReadonlyProps will always be available in value both while setting and getting.
  // opposed to applyImmutableValues, these are only applied when getting a value, because setting a new value should update the inputValue instead of forcing existing data.
  // value may (when setting form value) or may not (when getting form value) include readonly props.
  // only certain readonly props should be available in data for persisting.
  // for example readonly ID must be available for saving to work, but creationDate is readonly and should not be re-saved.
  // these special prop IDs are listed in this.persistableReadonlyProps.
  applyPersistableReadonlyProps(value) {
    if (this.inputValue) {
      this.persistableReadonlyProps.forEach(name => {
        if (typeof this.inputValue[name] !== 'undefined') value[name] = this.inputValue[name];
      });
    }
    return value;
  }
  updateFormControlDisabledState() {
    if (!this.formGroup) return;
    // custom event hook allowing to manipulate formGroup controls state
    this.onUpdateFormControlReadonlyState.emit(this.formGroup);
    const readonlyPropNames = this.getAllReadonlyPropNames();
    if (!readonlyPropNames.length) return;
    const disableImmutables = group => {
      if (!(group instanceof UntypedFormGroup) || group instanceof UntypedFormArray) return;
      // iterate all formControls and set their state accordingly.
      for (const propName of readonlyPropNames) {
        group.controls[propName]?.disable();
      }
    };
    if (this.formGroup instanceof UntypedFormArray) {
      this.formGroup.controls.forEach(group => {
        disableImmutables(group);
      });
    } else {
      disableImmutables(this.formGroup);
    }
  }
  getAllReadonlyPropNames() {
    let readonlyPropNames = [];
    // editorDefinition readonly props
    if (this.modelFactory) {
      readonlyPropNames = [...this.getEditorModelInfo().readonlyProps];
    }
    // immutableValues are applied last, these have highest priority
    if (this.immutableValues && this.disableImmutableFormControls) {
      readonlyPropNames = readonlyPropNames.concat(Object.keys(this.immutableValues).filter(name => {
        return {}.hasOwnProperty.call(this.immutableValues, name);
      }));
    }
    return readonlyPropNames;
  }
  // ###########################################################################################################
  // ####  FORM GROUP  #########################################################################################
  // ###########################################################################################################
  getCrudAction() {
    let group;
    if (this.editMode === 'edit') group = CrudAction.UPDATE;
    if (this.editMode === 'new') group = CrudAction.CREATE;
    if (this.editMode === 'clone') group = CrudAction.CREATE;
    if (this._debug) console.log(this.debugInfo() + ' ValidationGroup', group);
    return group;
  }
  buildFormControlsConfig() {
    if (this._debug) console.log(this.debugInfo() + '.buildFormControlsConfig');
    const defaults = this.getDefaultValue();
    if (this._debug) console.log(this.debugInfo() + ' default model values:', defaults);
    const config = {};
    for (const k in defaults) {
      if (Object.prototype.hasOwnProperty.call(defaults, k)) config[k] = defaults[k];
    }
    return config;
  }
  // allows to delay form setup (buildForm) until required information is available.
  // used e.g. in CrudItemFormService
  resolveFormDependencies() {
    return Promise.resolve().then(() => {
      if (this._debug) console.log(this.debugInfo() + '.resolveFormDependencies done');
    });
  }
  // required to make form-array sub-forms work fine
  setFormGroup(form) {
    if (this._debug) console.log(this.debugInfo() + '.setFormGroup', form, form.value);
    if (this.dataIsArray()) throw Error('formService is in array mode, use setFormArray instead of setFormGroup!');
    const fg = this.enhanceFormGroup(form);
    this.formGroup$.next(fg);
    this.usesExternalFormGroup = true;
    // if a custom initialValue is already set, dont overwrite it!
    if (!this.initialValue) this.initialValue = this.cloneFormValue(form.value);
    this.bindFormGroupListeners();
  }
  setFormArray(form) {
    if (this._debug) console.log(this.debugInfo() + '.setFormArray', form, form.value);
    if (!this.dataIsArray()) throw Error('formService is in formgroup mode, use setFormGroup instead of setFormArray!');
    const fa = this.enhanceFormGroup(form);
    this.formGroup$.next(fa);
    this.usesExternalFormGroup = true;
    // if a custom initialValue is already set, dont overwrite it!
    if (!this.initialValue) this.initialValue = this.cloneFormValue(fa.value);
    this.bindFormGroupListeners();
  }
  buildFormGroup() {
    if (this._debug) console.log(this.debugInfo() + '.buildFormGroup', this.modelFactory, this);
    if (this.formGroup) return Promise.resolve();
    if (!this.modelFactory) return Promise.reject('modelFactory is not available');
    this.configurateFactory();
    if (this._debug) console.log(this.debugInfo() + '.buildFormGroup loaded EditorDefinition', this.editorDefinitionName, this.modelFactory.Model);
    return this.resolveFormDependencies().then(() => {
      const defaultValueMap = this.buildFormControlsConfig();
      if (this._debug) console.log(this.debugInfo() + '.buildFormGroup', this.modelFactory.Model, defaultValueMap);
      const fg = this.createDynamicFormGroupAsGeneric(this.modelFactory.Model, defaultValueMap);
      this.formGroup$.next(fg);
      if (this._debug) console.log(this.debugInfo() + ' created formGroup: ', this.formGroup);
      // this.formGroup.reset(); // would reset state but value too!
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      this.bindFormGroupListeners();
    });
  }
  configurateFactory() {
    this.modelFactory.setEditorDefinitionName(this.editorDefinitionName);
    this.modelFactory.setGroups([this.getCrudAction()]);
  }
  enhanceFormGroup(fg) {
    const fgWithService = fg;
    fgWithService.formService = this;
    Object.defineProperty(fgWithService, 'rootFormService', {
      get: () => {
        return fgWithService.root.formService;
      }
    });
    return fgWithService;
  }
  resetForm(resetValue = true) {
    if (resetValue) {
      // resets state + nulls all controls
      this.formGroup.reset();
      // re-patch default values
      // this.reloadFormValue(this.getInitialValue());
      // better than reloading initialValue as it includes immutables and class-transformer
      this._setValue({});
    } else {
      // only reset state
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
    }
    this.resetFormFieldValidation();
    this.hasTriedToSubmitForm$.next(false);
    this.saveState$.next('idle');
  }
  registerSubForm(form, param) {
    // TODO: seems unused, remove soon
    throw new Error('registerSubForm called. unclear if this is still in use.');
    // if(this._debug) console.log(this.debugInfo()+'.registerSubForm',param,form)
    // const fg = (this.formGroup as FormGroup);
    // const oldControl = fg.controls[param];
    // fg.removeControl(param);
    // fg.addControl(param,form);
    // form.patchValue(oldControl.value);
  }
  isChildForm() {
    if (!this.formGroup) throw new Error('isChildForm called before formGroup is available');
    if (!this.formGroup.formService) throw new Error('isChildForm called before formGroup has been decorated with formService reference');
    return this.formGroup.rootFormService !== this;
  }
  bindFormGroupListeners() {
    if (this._debug) console.log(this.debugInfo() + '.bindFormGroupListeners', this.formGroup);
    this.unbindFormGroupListeners();
    this.formSubscriptions.push(this.formGroup.valueChanges.subscribe(data => {
      this.valueChanges$.next(data);
    }));
    this.formSubscriptions.push(this.formGroup.statusChanges.subscribe(data => {
      this.statusChanges$.next(data);
    }));
    // statusChanges will not emit immediately, so propagate current status to isValid
    // TODO: can use startWith pipe?
    this.status$.next(this.formGroup.status);
  }
  unbindFormGroupListeners() {
    this.formSubscriptions.forEach(subscr => subscr.unsubscribe());
    this.formSubscriptions = [];
  }
  createDynamicFormGroupAsGeneric(model, data = {}) {
    if (this.dataIsArray()) {
      throw new Error('this method must not be used with FormArray mode!');
    }
    const fg = this.createDynamicFormGroup(model, data);
    return this.enhanceFormGroup(fg);
  }
  // does not work with FormArray, createDynamicFormGroupAsGeneric is a utility allowing for easy use with TFormGroup
  // NOTE: data must only contain primitive values!
  // Angular will treat arrays as a configuration structure here!
  createDynamicFormGroup(model, data = {}) {
    const modelInfo = this.getEditorModelInfo();
    const allowedNestedModels = modelInfo.nestedModelProps;
    if (this._debug) console.log(this.debugInfo() + ' createDynamicFormGroup - editorModel name / allowedNestedModels:', modelInfo.name, allowedNestedModels, modelInfo.definition.data);
    const form = this.formBuilder.rootFormGroup(model, data, {
      classValidatorOptions: {
        ...CLASS_VALIDATOR_GLOBAL_CONFIG,
        groups: [this.getCrudAction()]
      },
      classTransformOptions: this.modelFactoryProvider.classTransformService.createOptionsFor(model, data, 'toInstance', {
        groups: [this.getCrudAction()]
      }),
      maxNestedModelDepth: this.maxNestedModelDepth,
      allowedNestedModels
    });
    if (this._debug) console.log(this.debugInfo() + ' createDynamicFormGroup - result:', form.controls);
    return form;
  }
  // ###########################################################################################################
  // ####  FORM STATE + VALIDATION  ############################################################################
  // ###########################################################################################################
  markAllTouched(form) {
    this._applyFuncToAllControls(form, control => {
      control.markAsTouched({
        onlySelf: true
      });
    }, true);
  }
  markAllUntouched(form) {
    this._applyFuncToAllControls(form, control => {
      control.markAsUntouched({
        onlySelf: true
      });
    }, true);
  }
  markAllDirty(form) {
    this._applyFuncToAllControls(form, control => {
      control.markAsDirty({
        onlySelf: true
      });
    }, true);
  }
  /**
   * updateValueAndValidity will not revalidate children, only ancestors can be re-run.
   * So to force full revalidation it must be called for all controls individually
   */
  revalidateAll(form) {
    this._applyFuncToAllControls(form, control => {
      control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true
      });
    }, true);
  }
  _applyFuncToAllControls(form, func, callFuncForGroupsToo = false) {
    if (form instanceof UntypedFormArray) {
      form.controls.forEach(control => {
        if (control instanceof UntypedFormControl) func(control);else this._applyFuncToAllControls(control, func, callFuncForGroupsToo); // if its no FormControl, it must be FormGroup|FormArray
      });
    } else if (form instanceof UntypedFormGroup) {
      for (const k in form.controls) {
        if (!Object.prototype.hasOwnProperty.call(form.controls, k)) continue;
        const control = form.get([k]); // pass as array to support field names containing dots
        if (control instanceof UntypedFormControl) func(control);else this._applyFuncToAllControls(control, func, callFuncForGroupsToo); // if its no FormControl, it must be FormGroup|FormArray
      }
    }
    if (callFuncForGroupsToo) func(form);
  }
  // as of ngxDynamicFormBuilder v2 there is no "unvalidated" form state anymore!
  resetFormFieldValidation() {
    this.markAllUntouched(this.formGroup);
    this.formGroup.updateValueAndValidity();
  }
  // will re-validate the whole formGroup structure and "attach" validation logic for newly added children
  refreshFormState() {
    // refresh calls setValidatorsToControls + re-patches current value
    this.formGroup.root.refresh();
    // const formBuilder = new DynamicFormBuilder();
    // setValidatorsToControls(formBuilder, this.formGroup.root as any, this.formGroup.root as any);
    // this.formGroup.patchValue(this.formGroup.value);
  }
  // ###########################################################################################################
  // ####  API / SAVING  #######################################################################################
  // ###########################################################################################################
  // TODO: AutoSave + EditMode should be part of ModelFormService only?
  enableAutoSave(enable = true) {
    // if(this.autoSaveSubscription) {
    // 	this.autoSaveSubscription.unsubscribe()
    // }
    // if(enable) {
    // 	this.autoSaveSubscription = this._formValueChanges$.asObservable().pipe(
    // 		skip(1),
    // 		debounceTime(400)
    // 	).subscribe(value=>{
    // 		console.log(this.debugInfo()+' autosave triggered, current form value is:',value)
    // 		if(this.editMode === 'edit') this.save()
    // 	})
    // } else {
    // 	this.autoSaveSubscription = undefined;
    // }
  }
  setSaveHandler(input) {
    if (typeof input === 'string') {
      if (input === 'noop') {
        this.saveHandler = new FormServiceSaveHandlerNoop(this, this.injector);
      } else if (input === 'item') {
        this.saveHandler = new FormServiceSaveHandlerApiItem(this, this.injector);
      } else {
        this.saveHandler = new FormServiceSaveHandlerApiRequest(this, this.injector);
      }
    } else if (typeof input === 'function') {
      this.saveHandler = new input(this, this.injector);
    } else {
      this.saveHandler = input;
    }
  }
  getSaveHandler() {
    if (!this.saveHandler) {
      // default behavior: try to submit to api
      const handler = this.determineAppropriateSaveHandler();
      this.setSaveHandler(handler);
    }
    return this.saveHandler;
  }
  setApiService(service /*,data?:PartialData<TData>*/) {
    if (service instanceof AppRequestService) {
      this.setSaveHandler('request');
    } else {
      const handler = new FormServiceSaveHandlerApiItem(this, this.injector);
      handler.setService(service);
      this.saveHandler = handler;
    }
  }
  determineAppropriateSaveHandler() {
    const definitionOptions = this.getEditorModelInfo().definition.options;
    if (definitionOptions.useCrudEndpoint) {
      return FormServiceSaveHandlerApiItem;
    } else if (definitionOptions.useAppRequest) {
      return FormServiceSaveHandlerApiRequest;
    } else {
      throw Error('determineAppropriateSaveHandler failed. No appropriate handler could be found. Please pass a handler to formService.setSaveHandler manually.');
    }
  }
  save() {
    this.hasTriedToSubmitForm$.next(true);
    const saveExecutor = this.validateAllFormFields(true).pipe(switchMap(state => {
      if (this._debug) console.log(this.debugInfo() + ' pre-save', state, this.formGroup['classValidatorErrors'], this.formGroup.status);
      const value = this.getValue();
      if (!state.valid || state.saveState === 'saving') {
        return of(false);
      }
      this.onBeforeSave.emit(value);
      if (this._debug) console.log(this.debugInfo() + ' data to submit: ', value);
      return this.createSaveObservable(value);
    }));
    // subscribing here triggers execution of save logic eagerly.
    // this will make sure saving it executed even if no more subscribers are being added. 
    // a dummy error handler needs to be added to make sure throwing an error won't bubble up to global error handler.
    saveExecutor.subscribe({
      error: err => {}
    });
    return saveExecutor;
  }
  getValidityAndValue() {
    this.hasTriedToSubmitForm$.next(true);
    const tmp = this.validateAllFormFields(true).pipe(switchMap(state => {
      const value = this.getValue();
      return of({
        value,
        isValid: state.valid
      });
    }), share());
    tmp.subscribe();
    return tmp;
  }
  // TODO: should save functionality + validateAllFormFields only be part of ModelFormService?
  // note that as of ngxDynamicFormBuilder v2 validation is running asynchronously!
  validateAllFormFields(emitEvent = true) {
    if (this._debug) console.log(this.debugInfo() + '.validateAllFormFields');
    this.markAllTouched(this.formGroup);
    this.markAllDirty(this.formGroup);
    // TODO: required call? support formArrays?
    if (this.formGroup instanceof UntypedFormGroup) validateAllFormFields(this.formGroup);
    this.revalidateAll(this.formGroup);
    let source;
    if (this.formGroup.status === 'PENDING') {
      source = this.formGroup.statusChanges.pipe(
      // tap(()=>console.log('statuschange',this.formGroup.status)),
      skipWhile(status => status === 'PENDING'), take(1));
    } else {
      source = of(this.formGroup.status);
    }
    const runner = source.pipe(
    // tap(console.log),
    switchMap(() => this.state$), take(1), share());
    runner.subscribe();
    return runner;
    // // dynamicFormBuilder/classValidator runs validation eagerly but asynchronously.
    // const asyncValidationSource = this.formGroup.statusChanges.pipe(
    // 	// tap(()=>console.log('statuschange',this.formGroup.status)),
    // 	skipWhile(status=>status==='PENDING')
    // );
    // // angular validators can also be synchonous in which case statusChanges would not re-emit if state has not changed.
    // const timeoutValidationSource = timer(50).pipe(
    // 	// tap(()=>console.log('timout',this.formGroup.status)),
    // 	map(()=>this.formGroup.status), 
    // 	filter(status=>status!=='PENDING')
    // );
    // const source = isDynamicFormGroup(this.formGroup) 
    // 	? asyncValidationSource 
    // 	: merge(asyncValidationSource,timeoutValidationSource);
  }
  createSaveObservable(value) {
    const handler = this.getSaveHandler();
    const request = handler.execute(value);
    this.saveState$.next('saving');
    const boundRequest = this.bindSubmissionToFormState(request);
    return boundRequest;
  }
  // req observable returns either TData (when used with ModelService)
  // or AppRequest response (= RequestResponse<TData>) (when used with AppRequestService)
  bindSubmissionToFormState(req) {
    const startTime = Date.now();
    const handler = req.pipe(
    // add a min-length for saving so that UI does not flicker too much
    delayWhen(() => timer(startTime - Date.now() + this.MIN_DURATION_SAVING)), catchError(error => {
      this.saveState$.next('idle');
      this.onAfterSave.emit({
        error
      });
      return throwError(() => error);
    }), tap(result => {
      if (this.saveHandler.returnsUpdatedFormData) this.setValue(result);
    }), switchMap(responseData => {
      return this.saveState$
      // .pipe(skip(1)) // skip first emission (immediate due to being a subject)
      .pipe(take(1)) // subscribe only once (to the SECOND emission)
      .pipe(tap(val => {
        this.saveState$.next('saved');
        this.hasTriedToSubmitForm$.next(false);
        this.isChanged$.next(false);
        this.handleApiResponse(responseData);
        // go to idle state after MIN_DURATION_SAVED if state is still saved
        this.saveState$.pipe(timeout({
          each: this.MIN_DURATION_SAVED,
          with: () => of('saved')
        })) // emit "saved" after 2 seconds
        .pipe(skip(1)) // skip first emission (immediate due to being a subject)
        .pipe(take(1)) // subscribe only once (to the SECOND emission)
        // .pipe(takeWhileAlive(this))
        .subscribe(saveState => {
          if (saveState === 'saved') this.saveState$.next('idle');
        });
      }));
    }), share());
    return handler;
  }
  handleApiResponse(responseData) {
    if (this.saveHandler.returnsUpdatedFormData) {
      const item = this.modelFactory.fromData(responseData);
      this.onAfterSave.emit({
        responseData,
        item
      });
    } else {
      this.onAfterSave.emit({
        responseData
      });
    }
  }
};
BaseFormService = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [FormStateService, AppMessageFactory, ModelFactoryProvider, Injector])], BaseFormService);
export { BaseFormService };