import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { __decorate, __metadata } from "tslib";
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AutoUnsubscribe, isE2E, takeWhileAlive } from '../../utils';
// eslint-disable-next-line local-rules/no-ngx-permissions-import -- allowed here
import { NgxPermissionsGuard, NgxRolesService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { FeatureRouterLinkResolverService } from '../../feature/router-link-resolver/feature-router-link-resolver.service'; // deep to fix circular dependency!
import { AccessState } from './access.state';
import { PermissionsService } from './permissions.service';
import * as i0 from "@angular/core";
import * as i1 from "./permissions.service";
import * as i2 from "ngx-permissions";
import * as i3 from "@angular/router";
import * as i4 from "../../feature/router-link-resolver/feature-router-link-resolver.service";
let PermissionsGuard = class PermissionsGuard extends NgxPermissionsGuard {
  constructor(permissionsService, rolesService, router, routerLinkResolver) {
    super(permissionsService, rolesService, router);
    this.routerLinkResolver = routerLinkResolver;
    this.loggedIn$.pipe(takeWhileAlive(this)).subscribe(loggedIn => this.loggedIn = loggedIn);
  }
  canActivate(route, state) {
    var _superprop_getCanActivate = () => super.canActivate,
      _this = this;
    return _asyncToGenerator(function* () {
      // It is necessary to wait until end of event loop, so route and state have their final state
      yield _this.waitUntilEndOfEventLoop();
      const isSU = yield _this.permissionsService.hasPermission('SUPERUSER');
      // addDefaultRedirectTo depends on login state, so it must be called AFTER permissionsService is ready.
      // but super.canActivate already requires the redirectTo to be set, so it must be called BEFORE that.
      _this.addDefaultRedirectTo(route);
      const allowActivation = isSU || (yield _superprop_getCanActivate().call(_this, route, state));
      return allowActivation;
    })();
  }
  waitUntilEndOfEventLoop() {
    return new Promise(resolve => setTimeout(resolve));
  }
  canLoad(route) {
    this.addDefaultRedirectTo(route);
    const allowed = super.canLoad(route);
    return allowed;
  }
  addDefaultRedirectTo(route) {
    if (route.data?.permissions && route.data.permissions.redirectTo === undefined) {
      let link;
      if (!this.loggedIn) {
        // if a visitor is not logged in at all, we want to redirect to login.
        link = this.routerLinkResolver.resolveRouterLink('login', true);
      } else {
        // user is logged in but has no permission to access the requested route. Redirect to error page.
        link = this.routerLinkResolver.resolveRouterLink('error-403', true);
      }
      if (link) route.data.permissions.redirectTo = link;
      if (isE2E()) {
        console.log('PermissionsGuard configurated redirect: ' + route.data.permissions.redirectTo);
      }
    }
  }
  static {
    this.ɵfac = function PermissionsGuard_Factory(t) {
      return new (t || PermissionsGuard)(i0.ɵɵinject(i1.PermissionsService), i0.ɵɵinject(i2.NgxRolesService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.FeatureRouterLinkResolverService));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: PermissionsGuard,
      factory: PermissionsGuard.ɵfac,
      providedIn: 'root'
    });
  }
};
__decorate([Select(AccessState.loggedIn), __metadata("design:type", Observable)], PermissionsGuard.prototype, "loggedIn$", void 0);
PermissionsGuard = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [PermissionsService, NgxRolesService, Router, FeatureRouterLinkResolverService])], PermissionsGuard);
export { PermissionsGuard };