var LocalState_1;
import { __decorate, __metadata } from "tslib";
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { LocalActions } from './local.actions';
import * as i0 from "@angular/core";
let LocalState = class LocalState {
  static {
    LocalState_1 = this;
  }
  static getSettings(state) {
    return state.settings;
  }
  static get(key, defaultValue) {
    return createSelector([LocalState_1], state => {
      if (typeof state.settings[key] !== 'undefined') return state.settings[key];
      if (typeof defaultValue !== 'undefined') return defaultValue;
      return null;
    });
  }
  constructor() {}
  set({
    getState,
    patchState
  }, action) {
    patchState({
      settings: {
        ...getState().settings,
        [action.name]: action.data
      }
    });
  }
  static {
    this.ɵfac = function LocalState_Factory(t) {
      return new (t || LocalState)();
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: LocalState,
      factory: LocalState.ɵfac
    });
  }
};
__decorate([Action(LocalActions.Set), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, LocalActions.Set]), __metadata("design:returntype", void 0)], LocalState.prototype, "set", null);
__decorate([Selector(), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], LocalState, "getSettings", null);
LocalState = LocalState_1 = __decorate([State({
  name: 'local',
  defaults: {
    settings: {}
  }
}), __metadata("design:paramtypes", [])], LocalState);
export { LocalState };