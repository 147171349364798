import { Config, ConfigService } from '@core/shared';
// Export this function to Angular's AOT to work
export function configFactory() {
  return Config;
}
export function serviceFactory() {
  return new ConfigService();
}
export const ConfigServiceProvider = {
  provide: ConfigService,
  useFactory: serviceFactory
};