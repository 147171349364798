import { DynamicFieldInstruction } from './shared';
export class InputCalendarInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      showTime: false,
      format: 'dd.mm.yy',
      icon: true,
      inline: false,
      outputFormat: false,
      preserveTime: true
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      showTime: 'showTime',
      dateFormat: 'format',
      icon: 'icon',
      inline: 'inline',
      outputFormat: 'outputFormat',
      preserveTime: 'preserveTime'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/input-calendar').then(bundle => {
      return {
        component: bundle.InputCalendar,
        module: bundle.UiInputCalendarModule
      };
    });
  }
}