import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiCardModule } from '@common/ui/frontend-shared/card';
import { UiContainerModule } from '@common/ui/frontend-shared/container';
import { UiContentCenteredModule } from '@common/ui/frontend-shared/content-centered';
import { UiSpinnerModule } from '@common/ui/frontend-shared/spinner';
import { AccessState, CoreFrontendSharedModule, ReplaceableComponent } from '@core/frontend-shared';
import { ScopeState } from '@core/frontend-shared/scope/scope.state';
import { Select, Store } from '@ngxs/store';
import { AutoUnsubscribe, UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { combineLatest, Observable } from 'rxjs';
import { RegistrationCheckoutComponent } from './registration-checkout.component';
import { RegistrationCompleteComponent } from './registration-complete.component';
import { RegistrationFormsComponent } from './registration-forms.component';
import { MembershipDisplayMode, MembershipRegistrationFacade, MembershipRegistrationMode, MembershipRegistrationState, RegistrationStep } from '../state';
import { Header } from '@common/ui/frontend-shared/shared';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { RegistrationSummaryComponent } from './registration-summary.component';
import { RegistrationTypeSelectionComponent } from './registration-type-selection.component';
import * as i0 from "@angular/core";
import * as i1 from "../state";
import * as i2 from "@ngxs/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../core/frontend-shared/src/lib/utils/directives/let.directive";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/container/container.component";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/card/card";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/spinner/spinner.component";
import * as i8 from "../../../../../ui/frontend-shared/src/lib/message/message";
import * as i9 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
function RegistrationWrapperComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function RegistrationWrapperComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "ui-card");
    i0.ɵɵtemplate(2, RegistrationWrapperComponent_ng_template_0_ng_container_2_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵproperty("uiContainer", !ctx_r0.inline);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
function RegistrationWrapperComponent_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function RegistrationWrapperComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_1_ng_container_0_Template, 1, 0, "ng-container", 3);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-content-centered");
    i0.ɵɵelement(1, "ui-spinner");
    i0.ɵɵelementEnd();
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵprojection(1);
    i0.ɵɵelement(2, "br")(3, "br");
    i0.ɵɵelementEnd();
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-type-selection")(1, "membership-registration-forms");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_0_ng_template_0_Template, 2, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const currentStep_r16 = i0.ɵɵnextContext().$implicit;
    const ctx_r17 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngIf", currentStep_r16 === ctx_r17.RegistrationStep.membershipTypeSelection || currentStep_r16 === ctx_r17.RegistrationStep.forms);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-type-selection");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-forms");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_Template, 1, 0, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_Template, 1, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const currentStep_r16 = i0.ɵɵnextContext().$implicit;
    const ctx_r18 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngIf", currentStep_r16 === ctx_r18.RegistrationStep.membershipTypeSelection);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r16 === ctx_r18.RegistrationStep.forms);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-checkout");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-summary");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_ng_template_0_Template, 1, 0, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_ng_template_1_Template, 1, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const info_r27 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", info_r27.requiresPayment);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !info_r27.requiresPayment);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_ng_template_0_Template, 2, 2, "ng-template", 6);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r19 = i0.ɵɵnextContext(6);
    i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx_r19.innerRegistrationInfo$));
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-complete");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_0_Template, 1, 1, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_1_Template, 2, 2, "ng-template", 0)(2, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_2_Template, 2, 3, "ng-template", 0)(3, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_ng_template_3_Template, 1, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const currentStep_r16 = ctx.$implicit;
    const ctx_r15 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngIf", ctx_r15.inline);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r15.inline);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r16 === ctx_r15.RegistrationStep.summary);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r16 === ctx_r15.RegistrationStep.complete);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_div_0_Template, 4, 0, "div", 7)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_ng_template_1_Template, 4, 4, "ng-template", 6);
    i0.ɵɵpipe(2, "async");
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngIf", ctx_r11.headerFacet);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(2, 2, ctx_r11.currentStep$));
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Zahlung abschlie\u00DFen");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "p");
    i0.ɵɵtext(3, "Ihr Zugang wurde noch nicht freigeschaltet, da die Zahlung noch aussteht.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "p");
    i0.ɵɵtext(5, "Diese Funktion steht leider noch nicht zur Verf\u00FCgung. Bitte kontaktieren Sie stattdessen den Support.");
    i0.ɵɵelementEnd();
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, " TODO: selecting membership type is not implemented atm. ");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-forms");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-checkout");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-summary");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_ng_template_0_Template, 1, 0, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_ng_template_1_Template, 1, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const info_r37 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", info_r37.requiresPayment);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !info_r37.requiresPayment);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_ng_template_0_Template, 2, 2, "ng-template", 6);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r34 = i0.ɵɵnextContext(6);
    i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx_r34.innerRegistrationInfo$));
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "membership-registration-complete");
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_0_Template, 1, 0, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_1_Template, 1, 0, "ng-template", 0)(2, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_2_Template, 2, 3, "ng-template", 0)(3, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_ng_template_3_Template, 1, 0, "ng-template", 0);
  }
  if (rf & 2) {
    const currentStep_r31 = ctx.$implicit;
    const ctx_r30 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngIf", currentStep_r31 === ctx_r30.RegistrationStep.membershipTypeSelection);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r31 === ctx_r30.RegistrationStep.forms);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r31 === ctx_r30.RegistrationStep.summary);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", currentStep_r31 === ctx_r30.RegistrationStep.complete);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Mitgliedschaft fortsetzen");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 8)(3, "ui-message");
    i0.ɵɵtext(4, " Bitte pr\u00FCfen Sie Ihre vorhandenen Eingaben und f\u00FCllen Sie ggf. fehlende Angaben neu aus.");
    i0.ɵɵelement(5, "br");
    i0.ɵɵtext(6, " Wenn Ihre aktuelle Mitgliedschaft noch g\u00FCltig ist, werden diese Tage zur neuen Mitgliedschaft hinzugef\u00FCgt. ");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_ng_template_8_Template, 4, 4, "ng-template", 6);
    i0.ɵɵpipe(9, "async");
  }
  if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(9, 1, ctx_r13.currentStep$));
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_0_Template, 3, 4, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_1_Template, 6, 0, "ng-template", 0)(2, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_ng_template_2_Template, 10, 3, "ng-template", 0);
  }
  if (rf & 2) {
    const mode_r10 = ctx.$implicit;
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngIf", mode_r10 === ctx_r9.MembershipRegistrationMode.create);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", mode_r10 === ctx_r9.MembershipRegistrationMode.completePayment);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", mode_r10 === ctx_r9.MembershipRegistrationMode.registered);
  }
}
function RegistrationWrapperComponent_ng_template_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_2_ng_template_0_Template, 3, 3, "ng-template", 6);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngLet", i0.ɵɵpipeBind1(1, 1, ctx_r7.mode$));
  }
}
function RegistrationWrapperComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_2_ng_template_0_Template, 2, 0, "ng-template", 4);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtemplate(2, RegistrationWrapperComponent_ng_template_2_ng_template_2_Template, 2, 3, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r8 = i0.ɵɵreference(3);
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx_r2.isBusy$))("ngIfElse", _r8);
  }
}
const _c0 = [[["ui-header"]]];
const _c1 = ["ui-header"];
/**
 * this is starting point of registration.
 */
let RegistrationWrapperComponent = class RegistrationWrapperComponent {
  constructor(registration, store) {
    this.registration = registration;
    this.store = store;
    this.inline = false; // indicates component is rendered in compact view somewhere within another page
    this.RegistrationStep = RegistrationStep;
    this.MembershipRegistrationMode = MembershipRegistrationMode;
    this.innerRegistrationInfo$ = combineLatest({
      requiresPayment: this.requiresPayment$
    });
    // if user is logged in, configurate state to handle completion or renewal of existing membership.
    const loggedIn = store.selectSnapshot(AccessState.loggedIn);
    const membershipPaymentPending = store.selectSnapshot(AccessState.accessData('membershipPaymentPending'));
    if (loggedIn) {
      if (membershipPaymentPending) {
        registration.setRegistrationMode(MembershipRegistrationMode.completePayment);
      } else {
        registration.setRegistrationMode(MembershipRegistrationMode.registered);
      }
    }
  }
  ngOnInit() {
    const displayMode = this.inline ? MembershipDisplayMode.compact : MembershipDisplayMode.full;
    this.registration.setDisplayMode(displayMode);
  }
  static {
    this.ɵfac = function RegistrationWrapperComponent_Factory(t) {
      return new (t || RegistrationWrapperComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.Store));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationWrapperComponent,
      selectors: [["membership-registration-wrapper"]],
      contentQueries: function RegistrationWrapperComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, Header, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerFacet = _t.first);
        }
      },
      inputs: {
        inline: "inline"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 2,
      consts: [[3, "ngIf"], ["mainWrapper", ""], [3, "uiContainer"], [4, "ngTemplateOutlet"], [3, "ngIf", "ngIfElse"], ["main", ""], [3, "ngLet"], ["class", "ui-card-header", 4, "ngIf"], [1, "ui-card-header"]],
      template: function RegistrationWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵtemplate(0, RegistrationWrapperComponent_ng_template_0_Template, 3, 2, "ng-template", 0)(1, RegistrationWrapperComponent_ng_template_1_Template, 1, 1, "ng-template", 0)(2, RegistrationWrapperComponent_ng_template_2_Template, 4, 4, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.inline);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.inline);
        }
      },
      dependencies: [CommonModule, i3.NgIf, i3.NgTemplateOutlet, i3.AsyncPipe, CoreFrontendSharedModule, UtilsFrontendSharedModule, i4.NgLetDirective, UiContainerModule, i5.UiContainerComponent, UiCardModule, i6.Card, UiButtonModule, UiSpinnerModule, i7.SpinnerComponent, UiMessageModule, i8.UiMessageComponent, UiContentCenteredModule, i9.UiContentCenteredComponent, RegistrationTypeSelectionComponent, RegistrationFormsComponent, RegistrationSummaryComponent, RegistrationCheckoutComponent, RegistrationCompleteComponent],
      styles: ["[_nghost-%COMP%]{margin:0;display:block;padding:30px 0}\n\n/*# sourceMappingURL=registration-wrapper.component.ts-angular-inline--66.css.map*/", ".reset-wrapper[_ngcontent-%COMP%]{margin:40px auto 0;text-align:center}\n\n/*# sourceMappingURL=registration-wrapper.component.ts-angular-inline--67.css.map*/"],
      changeDetection: 0
    });
  }
};
__decorate([Select(ScopeState.scope), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "scope$", void 0);
__decorate([Select(MembershipRegistrationState.mode), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "mode$", void 0);
__decorate([Select(MembershipRegistrationState.isBusy), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "isBusy$", void 0);
__decorate([Select(MembershipRegistrationState.currentStep), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "currentStep$", void 0);
__decorate([Select(MembershipRegistrationState.requiresPayment), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "requiresPayment$", void 0);
__decorate([Select(MembershipRegistrationState.redirectInProgress), __metadata("design:type", Observable)], RegistrationWrapperComponent.prototype, "redirectInProgress$", void 0);
RegistrationWrapperComponent = __decorate([ReplaceableComponent(), AutoUnsubscribe(), __metadata("design:paramtypes", [MembershipRegistrationFacade, Store])], RegistrationWrapperComponent);
export { RegistrationWrapperComponent };