/* eslint-disable no-case-declarations */
/* eslint-disable eqeqeq */
import { arraySplitByCondition } from '../utils';
const operators = {
  $eq: (item, filter) => filter.value == item[filter.field],
  $eqL: (item, filter) => filter.value.toLowerCase() == item[filter.field].toLowerCase(),
  $ne: (item, filter) => filter.value != item[filter.field],
  $neL: (item, filter) => filter.value.toLowerCase() != item[filter.field].toLowerCase(),
  $gt: (item, filter) => filter.value > item[filter.field],
  $lt: (item, filter) => filter.value < item[filter.field],
  $gte: (item, filter) => filter.value >= item[filter.field],
  $lte: (item, filter) => filter.value <= item[filter.field],
  $starts: (item, filter) => ('' + item[filter.field]).startsWith(filter.value),
  $startsL: (item, filter) => ('' + item[filter.field]).toLowerCase().startsWith(filter.value.toLowerCase()),
  $ends: (item, filter) => ('' + item[filter.field]).endsWith(filter.value),
  $endsL: (item, filter) => ('' + item[filter.field]).toLowerCase().endsWith(filter.value.toLowerCase()),
  $cont: (item, filter) => ('' + item[filter.field]).includes(filter.value),
  $contL: (item, filter) => ('' + item[filter.field]).toLowerCase().includes(filter.value.toLowerCase()),
  $isnull: (item, filter) => item[filter.field] === null,
  $notnull: (item, filter) => item[filter.field] !== null,
  $in: (item, filter) => filter.value.includes(item[filter.field]),
  $notin: (item, filter) => !filter.value.includes(item[filter.field]),
  $inL: (item, filter) => filter.value.map(val => val.toLowerCase()).includes(item[filter.field].toLowerCase()),
  $notinL: (item, filter) => !filter.value.map(val => val.toLowerCase()).includes(item[filter.field].toLowerCase())
};
export function parseQueryFilterString(f) {
  const parts = f.split('||');
  if (parts.length > 3 || parts.length < 2) throw new Error('parseQueryFilterString received invalid expression');
  if (!operators[parts[1]]) throw new Error('parseQueryFilterString received unknown operator ID "' + parseQueryFilterString + '"');
  return {
    field: parts[0],
    operator: parts[1],
    value: parts[2] || undefined
  };
}
export function filterItemsByQueryFilters(items, filters, mode) {
  if (mode === 'and') {
    let filtered = items;
    filters.forEach(filter => {
      const comparator = operators[filter.operator];
      if (!comparator) throw new Error('filterItemsByQueryFilters Error: Operator ' + filter.operator + ' is not supported');
      filtered = filtered.filter(item => comparator(item, filter));
    });
    return filtered;
  } else {
    if (!filters.length) return items;
    const passed = [];
    let remaining = items;
    filters.forEach(filter => {
      const comparator = operators[filter.operator];
      if (!comparator) throw new Error('filterItemsByQueryFilters Error: Operator ' + filter.operator + ' is not supported');
      const [newPassed, newRemaining] = arraySplitByCondition(remaining, item => comparator(item, filter));
      passed.push(...newPassed);
      remaining = newRemaining;
    });
    return passed;
  }
}