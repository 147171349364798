import { PagedItemService } from './paged.item-service';
export class ItemServiceHelper {
  constructor(service) {
    this.service = service;
  }
  applyPaging(offset, itemsPerPage) {
    if (this.service instanceof PagedItemService) {
      if (!itemsPerPage) itemsPerPage = null;
      this.service.configurate(itemsPerPage, offset);
    }
  }
  resetPaging() {
    this.service.query.setOffset(0);
  }
}