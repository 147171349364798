import { __decorate, __metadata } from "tslib";
import { ActivatedRoute } from '@angular/router';
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { PageFacade, PageState } from '@common/page/frontend-shared';
import { PatchProfileSettingsRequest } from '@common/users/shared';
import { LocalMessageHandlerService } from '@core/frontend-shared';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/forms/frontend-shared";
import * as i3 from "@common/page/frontend-shared";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i6 from "../../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i7 from "../../../../../../../forms/frontend-shared/src/lib/simple-fieldset/simple-fieldset.component";
import * as i8 from "../../../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../../../../ui/frontend-shared/src/lib/input-selectbutton/input-selectbutton";
import * as i11 from "../../../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
import * as i12 from "@angular/common";
const _c0 = () => ({
  label: "Speichern",
  icon: "save"
});
let ProfileSettingsComponent = class ProfileSettingsComponent {
  constructor(messages, formServiceFactory, page, route) {
    this.messages = messages;
    this.page = page;
    this.route = route;
    this.pageThemeOptions = [{
      value: 'auto',
      label: 'Automatisch'
    }, {
      value: 'dark',
      label: 'Dunkel'
    }, {
      value: 'light',
      label: 'Hell'
    }];
    this.instructions = [];
    this.formService = formServiceFactory.createModelForm(PatchProfileSettingsRequest);
    this.formService.onAfterSave.subscribe(({
      responseData,
      error
    }) => {
      if (error) {
        this.messages.push(error);
      }
    });
    this.formService.setEditorDefinition('default');
    const profileInfo = route.parent.snapshot.data['profileData'];
    if (!profileInfo) throw new Error('ProfileData not found at route data');
    this.formService.setInitialValue(profileInfo.profileData);
    this.formService.initialize();
  }
  updatePageTheme(e) {
    this.page.setTheme(e.value);
  }
  static {
    this.ɵfac = function ProfileSettingsComponent_Factory(t) {
      return new (t || ProfileSettingsComponent)(i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i2.ModelFormServiceFactory), i0.ɵɵdirectiveInject(i3.PageFacade), i0.ɵɵdirectiveInject(i4.ActivatedRoute));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: ProfileSettingsComponent,
      selectors: [["user-profile-settings"]],
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 9,
      vars: 10,
      consts: [[3, "instructions", "formService"], ["orientation", "vertical", "help", "Die Website kann hell oder dunkel angezeigt werden.<br>\n\tW\u00E4hlen Sie aus, was Sie angenehmer finden!<br>\n\t'Automatisch' nutzt die Einstellung des Browsers / Systems.", 1, "ui-margin-medium", 3, "label"], [1, "ui-width-stretch", 3, "ngModel", "options", "onChange"], [1, "ui-clearfix"], ["uiButton", "", 3, "formSubmitButton", "formSubmitButtonOptions"]],
      template: function ProfileSettingsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "h2");
          i0.ɵɵtext(1, "Einstellungen");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(2, "simple-fieldset", 0);
          i0.ɵɵelementStart(3, "ui-field-wrapper", 1)(4, "ui-input-selectbutton", 2);
          i0.ɵɵlistener("onChange", function ProfileSettingsComponent_Template_ui_input_selectbutton_onChange_4_listener($event) {
            return ctx.updatePageTheme($event);
          });
          i0.ɵɵpipe(5, "async");
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(6, "app-messages");
          i0.ɵɵelementStart(7, "div", 3);
          i0.ɵɵelement(8, "button", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("instructions", ctx.instructions)("formService", ctx.formService);
          i0.ɵɵadvance();
          i0.ɵɵproperty("label", "Darstellung der Seite");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngModel", i0.ɵɵpipeBind1(5, 7, ctx.pageTheme$))("options", ctx.pageThemeOptions);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("formSubmitButton", ctx.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(9, _c0));
        }
      },
      dependencies: [i5.FieldWrapperComponent, i6.UiButtonComponent, i7.SimpleFieldsetComponent, i8.FormSubmitButtonDirective, i9.NgControlStatus, i9.NgModel, i10.InputSelectButton, i11.AppMessagesComponent, i12.AsyncPipe],
      styles: ["@media (max-width: 959px){h2[_ngcontent-%COMP%]{display:none}}"]
    });
  }
};
__decorate([Select(PageState.themeConfiguration), __metadata("design:type", Observable)], ProfileSettingsComponent.prototype, "pageTheme$", void 0);
ProfileSettingsComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [LocalMessageHandlerService, ModelFormServiceFactory, PageFacade, ActivatedRoute])], ProfileSettingsComponent);
export { ProfileSettingsComponent };