import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { SESS_REQUIRE_NEW_PASSWORD, UpdatePasswordRequest } from '@common/users/shared';
import { AccessFacade, AccessState, ConfigService, LocalMessageHandlerService } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/forms/frontend-shared";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../ui/frontend-shared/src/lib/field-wrapper/field-wrapper.component";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/input-text/input-text";
import * as i8 from "../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i9 from "../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
const _c0 = () => ({
  label: "Speichern"
});
function UserForceNewPasswordComponent_div_0_form_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "form", 6)(1, "ui-field-wrapper", 7);
    i0.ɵɵelement(2, "ui-input-text", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ui-field-wrapper", 7);
    i0.ɵɵelement(4, "ui-input-text", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 10);
    i0.ɵɵelement(6, "app-messages")(7, "button", 11);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("formGroup", ctx_r2.formService.formGroup);
    i0.ɵɵadvance();
    i0.ɵɵproperty("placeholder", "Passwort");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("placeholder", "Passwort Wiederh.");
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("formSubmitButton", ctx_r2.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(5, _c0));
  }
}
function UserForceNewPasswordComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2, 3)(3, "div", 4)(4, "h2");
    i0.ɵɵtext(5, "Passwort zur\u00FCcksetzen");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "p");
    i0.ɵɵtext(7, "Bitte geben Sie Ihr neues Passwort ein.");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(8, "br");
    i0.ɵɵtemplate(9, UserForceNewPasswordComponent_div_0_form_9_Template, 8, 6, "form", 5);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap("position-overlay");
    i0.ɵɵadvance(9);
    i0.ɵɵproperty("ngIf", ctx_r0.formService.initialized);
  }
}
/**
 * Important: We cannot rely on Observables in here.
 * When login status changes, this component will be recreated.
 * This will create a code loop.
 * So we need to do a redirect to another route to break the loop.
 */
let UserForceNewPasswordComponent = class UserForceNewPasswordComponent {
  constructor(cd, access, messages, config, formServiceFactory) {
    this.cd = cd;
    this.access = access;
    this.messages = messages;
    this.config = config;
    this.display = false;
    this.formService = formServiceFactory.createModelForm(UpdatePasswordRequest);
    this.formService.setImmutableValues({
      forcedUpdate: true
    });
    this.formService.onAfterSave.subscribe(({
      responseData,
      error
    }) => {
      if (error) {
        this.messages.push(error);
      } else {
        this.display = false;
        this.access.refreshSession();
        this.cd.markForCheck();
      }
    });
    this.formService.initialize();
  }
  ngOnInit() {
    const requireNewPassword$ = this.sessionData$.pipe(map(sessionData => {
      return sessionData[SESS_REQUIRE_NEW_PASSWORD] || false;
    }), takeWhileAlive(this));
    requireNewPassword$.subscribe(requireNewPassword => {
      this.display = requireNewPassword;
      this.cd.markForCheck();
    });
  }
  static {
    this.ɵfac = function UserForceNewPasswordComponent_Factory(t) {
      return new (t || UserForceNewPasswordComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.AccessFacade), i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i1.ConfigService), i0.ɵɵdirectiveInject(i2.ModelFormServiceFactory));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: UserForceNewPasswordComponent,
      selectors: [["user-force-new-password"]],
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 1,
      vars: 1,
      consts: [["class", "wrapper", 3, "class", 4, "ngIf"], [1, "wrapper"], [1, "dialog"], ["dialog", ""], [1, "content"], [3, "formGroup", 4, "ngIf"], [3, "formGroup"], [3, "placeholder"], ["type", "password", "formControlName", "password", 1, "ui-width-stretch"], ["type", "password", "formControlName", "password2", 1, "ui-width-stretch"], [1, "ui-margin"], ["uiButton", "", "icon", "unlock", 1, "ui-width-stretch", 3, "formSubmitButton", "formSubmitButtonOptions"]],
      template: function UserForceNewPasswordComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, UserForceNewPasswordComponent_div_0_Template, 10, 3, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.display);
        }
      },
      dependencies: [i3.NgIf, i4.FieldWrapperComponent, i5.UiButtonComponent, i6.ɵNgNoValidate, i6.NgControlStatus, i6.NgControlStatusGroup, i6.FormGroupDirective, i6.FormControlName, i7.InputTextComponent, i8.FormSubmitButtonDirective, i9.AppMessagesComponent],
      styles: [".wrapper[_ngcontent-%COMP%]{position:fixed;z-index:1000}.dialog[_ngcontent-%COMP%]{background:var(--contentColor);color:var(--contentTextColor);padding:8px 12px 12px;border-radius:4px}.dialog[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{padding:0 0 10px}.wrapper.position-overlay[_ngcontent-%COMP%]{background:var(--invertedOverlaySoftColor);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);inset:0}.wrapper.position-overlay[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center}.wrapper.position-overlay[_ngcontent-%COMP%]   .dialog[_ngcontent-%COMP%]{position:static}@media (min-width: 768px){.dialog[_ngcontent-%COMP%]{padding:20px 20px 22px}.position-overlay[_ngcontent-%COMP%]   .dialog[_ngcontent-%COMP%], .position-modal[_ngcontent-%COMP%]   .dialog[_ngcontent-%COMP%]{bottom:var(--trackingConsentPositionBottom, 20px);left:initial;left:20px;width:600px;min-width:400px;max-width:40%}}"]
    });
  }
};
__decorate([Select(AccessState.sessionData), __metadata("design:type", Observable)], UserForceNewPasswordComponent.prototype, "sessionData$", void 0);
UserForceNewPasswordComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [ChangeDetectorRef, AccessFacade, LocalMessageHandlerService, ConfigService, ModelFormServiceFactory])], UserForceNewPasswordComponent);
export { UserForceNewPasswordComponent };