import { FileTypeDetectionHelper } from './file-type-detection.helper';
// escape all regex special chars (except | )
function escapeRegExp(string) {
  // $& means the whole matched string
  return string.replace(/[.*+?^${}()[\]\\]/g, '\\$&');
}
export function buildTypeFilter(input) {
  let filter = null;
  if (typeof input !== 'undefined') {
    if (typeof input === 'function') {
      filter = input;
    } else {
      let regex;
      if (input instanceof RegExp) {
        regex = input;
      } else if (Array.isArray(input)) {
        const stringifiedOptions = input.join("|");
        regex = new RegExp(".?(" + escapeRegExp(stringifiedOptions) + ")");
      } else {
        throw new Error('Invalid cloudinary filter ' + input);
      }
      filter = (req, file, cb) => {
        const info = FileTypeDetectionHelper.extractFileTypeInfo(file);
        if (info.extension.match(regex) || info.mime.match(regex)) {
          cb(null, true);
        } else {
          cb(new Error(`Der Dateityp ${info.extension || info.mime} ist nicht erlaubt.`), false);
        }
      };
    }
  }
  return filter;
}