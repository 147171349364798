import { customMetadataStorage } from '../custom-metadata/storage';
// whitelist a Model field to be allowed to contain html.
// will be stripped from all other fields. 
// stripping is implemented in @core/backend-shared/api/pipes/request-validation.
export function AllowHtml(options = {}) {
  return function (object, propertyName) {
    customMetadataStorage.addMetadata({
      metaType: 'AllowHtml',
      target: object instanceof Function ? object : object.constructor,
      propertyName: propertyName,
      options
    });
  };
}