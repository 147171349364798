/**
 * class-transformer lacks true support for ES6 Maps.
 * it can guess that it is a map when reflect metadata is enabled.
 * Even then, it may fail when the value is null/empty.
 * This is a custom decorator which aims to provide functionality as expected when using a Map<string, SubModel>.
 */
import { isConstructor } from '../../utils/is-constructor';
import { Transform, Type } from '../class-transformer';
export function TypedMap(type) {
  return (target, propertyKey) => {
    const ctor = isConstructor(type) ? type : type();
    // @Type is too generic, it does not know a map is expected in case the given data is empty.
    Type(() => ctor)(target, propertyKey);
    Transform(params => {
      /**
       * class-transformer is nasty...
       * its Map support relies on either an actual Map being passed (not possible on JSON deserialization)
       * or on type reflection working correctly.
       * (see isSubValueMap, https://github.com/typestack/class-transformer/blob/develop/src/TransformOperationExecutor.ts#L259)
       * It may be a good idea to implement a better @Type decorator which allows to set Array/Set/Map explicitly.
       *
       * params.value contains an already "prepared" value which makes it useless.
       * Without setting @Type, the subobjects properties will be stripped because they are not exposed.
       * When setting @Type, the value will be messed up because it does not know if to expect an object or a map/array of objects.
       * however, params.obj seems to refer to the raw input data, so we access it to do a manual transformation of the data correctly.
       */
      const rawValue = params.obj[params.key];
      let map;
      if (!(rawValue instanceof Map)) {
        map = new Map(Object.entries(rawValue || {}));
      } else {
        map = rawValue;
      }
      map.forEach((entry, key) => {
        if (!(entry instanceof ctor)) {
          map.set(key, params.executor.transform(undefined, entry, ctor, undefined, false, 0));
        }
      });
      return map;
    }, {
      // during serialization, the data must not be transformed to a map!
      toClassOnly: true
    })(target, propertyKey);
  };
}