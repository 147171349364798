import { UserConversion } from '../constants';
export var UserConversionHelper;
(function (UserConversionHelper) {
  function getUserAssociations(user) {
    if (!user) return null;
    return {
      isCompanyMember: !!user.companyId,
      isCompanyManager: !!user.managingId
    };
  }
  UserConversionHelper.getUserAssociations = getUserAssociations;
  function getPossibleConversions(user) {
    const assoc = getUserAssociations(user);
    if (!user || !assoc) return null;
    if (assoc.isCompanyManager) {
      return [UserConversion.ToCompanyMember];
    } else if (assoc.isCompanyMember) {
      return [UserConversion.ToCompanyManager];
    } else {
      return [];
    }
  }
  UserConversionHelper.getPossibleConversions = getPossibleConversions;
  function getLabel(convType) {
    if (convType === UserConversion.ToCompanyManager) return 'Zu Manager-Account konvertieren';
    if (convType === UserConversion.ToCompanyMember) return 'Zu Teammitglied konvertieren';
    throw new Error('unknown UserConversion passed: ' + convType);
  }
  UserConversionHelper.getLabel = getLabel;
})(UserConversionHelper || (UserConversionHelper = {}));