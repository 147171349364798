import { Expose, Transform } from 'class-transformer-global-storage';
import { PermissionsHelper } from '../../access/permissions.helper';
/**
 * if no rule is matching,...
 * - prop will not be exposed if exposeIfAny is present
 * - prop will be exposed if only hideIfAny is present
 * If both expose and hide is present, hide will have higher priority.
 *
 * Superuser behavior:
 * - any expose by permission rule will pass
 * - any hide by permission rule will fail
 * - rules based on platform will work as usual
 */
export function DynamicExpose(rules, transformOptions = {}) {
  const autoExpose = rules.hideIfAny && !rules.exposeIfAny;
  return (target, propertyKey) => {
    if (autoExpose) {
      Expose({
        toClassOnly: transformOptions.toClassOnly,
        toPlainOnly: transformOptions.toPlainOnly
      })(target, propertyKey);
    }
    Transform(({
      value,
      options,
      executor,
      dependencies
    }) => {
      if (!dependencies) {
        console.trace('Custom decorator received no dependencies!');
        throw new Error('Custom decorator received no dependencies!');
      }
      let exposeValue = autoExpose;
      const isSU = dependencies.permissions.includes('SUPERUSER');
      // nothing we need to check if there is no value that could be exposed.
      if (typeof value === 'undefined') return undefined;
      if (rules.exposeIfAny) {
        if (anyRuleIsMatching(rules.exposeIfAny, dependencies, 'expose', isSU)) exposeValue = true;
      }
      if (rules.hideIfAny) {
        const hidingRules = isSU ? filterOutPermissionChecks(rules.hideIfAny) : rules.hideIfAny;
        if (anyRuleIsMatching(hidingRules, dependencies, 'hide', isSU)) exposeValue = false;
      }
      if (exposeValue) {
        return value;
      } else {
        return undefined;
      }
    }, transformOptions)(target, propertyKey);
  };
}
function anyRuleIsMatching(rules, deps, type, isSU) {
  for (const rule of rules) {
    const matchingResults = [];
    if (rule.platform) {
      // Superuser check has no effect on platform checks
      matchingResults.push(deps.config.isPlatform(rule.platform));
    } else matchingResults.push(null);
    if (rule.permissions) {
      if (isSU) {
        matchingResults.push(type === 'expose' ? true : false);
      } else {
        matchingResults.push(PermissionsHelper.hasAllPermissions(deps.permissions, rule.permissions));
      }
    } else matchingResults.push(null);
    if (rule.noPermissions) {
      if (isSU) {
        matchingResults.push(type === 'expose' ? true : false);
      } else {
        matchingResults.push(deps.permissions.length === 0);
      }
    } else matchingResults.push(null);
    if (matchingResults.every(result => result === null)) {
      throw new Error('DynamicExpose: Empty rules are not allowed!');
    }
    // useful for debugging
    // console.log('rule '+JSON.stringify(rule)+' results [platform,permissions,noPermission]: '+matchingResults.join(','))
    const anyCheckFailed = matchingResults.some(result => result === false);
    if (!anyCheckFailed) return true;
  }
  return false;
}
function filterOutPermissionChecks(rules) {
  const filtered = [];
  for (const rule of rules) {
    if (rule.platform) {
      filtered.push({
        platform: rule.platform
      });
    }
  }
  return filtered;
}