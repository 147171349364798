import { __decorate, __metadata } from "tslib";
import { AppRequest, AppRequestMethod } from '@core/shared';
import { Expose, Model } from '@core/shared/model';
import { CloudinaryItemModel } from '../cloudinary-item.model';
export class CloudinaryItemInfoBaseRequest extends Model {}
__decorate([Expose(), __metadata("design:type", String)], CloudinaryItemInfoBaseRequest.prototype, "url", void 0);
__decorate([Expose(), __metadata("design:type", String)], CloudinaryItemInfoBaseRequest.prototype, "type", void 0);
__decorate([Expose(), __metadata("design:type", String)], CloudinaryItemInfoBaseRequest.prototype, "publicId", void 0);
__decorate([Expose(), __metadata("design:type", String)], CloudinaryItemInfoBaseRequest.prototype, "extension", void 0);
export class GetCloudinaryItemInfoResponse extends CloudinaryItemModel {}
let GetCloudinaryItemInfoRequest = class GetCloudinaryItemInfoRequest extends CloudinaryItemInfoBaseRequest {
  static {
    this.Response = GetCloudinaryItemInfoResponse;
  }
};
GetCloudinaryItemInfoRequest = __decorate([AppRequest('cloudinary', 'item-info', {
  httpMethod: AppRequestMethod.POST,
  clientCache: {
    seconds: 30
  }
})], GetCloudinaryItemInfoRequest);
export { GetCloudinaryItemInfoRequest };