import { ProtocolLogger } from '@core/shared';
import { convertToSubject } from '@core/shared/utils';
import { map, merge, scan, Subject } from 'rxjs';
/** A logger which exposes its data on dev console (@common/console, not dev tools!) */
export class DevConsoleProtocolLogger extends ProtocolLogger {
  constructor(protocol) {
    super(protocol);
    this.next$ = new Subject();
    this.clear$ = new Subject();
    this.fullLog$ = merge(this.next$.pipe(map(log => {
      return {
        cmd: 'log',
        log
      };
    })), this.clear$.pipe(map(() => {
      return {
        cmd: 'clear'
      };
    }))).pipe(scan((aggrLog, command) => {
      if (command.cmd === 'clear') return [];
      if (command.cmd === 'log') {
        aggrLog.push(command['log']);
        return aggrLog;
      }
    }, []), convertToSubject([]));
    // this.next$.subscribe(data=>{
    // 	console.log('protocol emitted',data)
    // })
  }
  log(entry) {
    this.next$.next(entry);
  }
  clearLog() {
    this.clear$.next(true);
  }
}