import { __decorate } from "tslib";
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
function PageErrorMessageComponent_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("(", ctx_r0.error.status, ")");
  }
}
let PageErrorMessageComponent = class PageErrorMessageComponent {
  constructor() {
    this.e2eErrorCode = '0';
  }
  isNumber(val) {
    return typeof val === 'number';
  }
  ngOnInit() {
    this.e2eErrorCode = '' + this.error.status;
  }
  static {
    this.ɵfac = function PageErrorMessageComponent_Factory(t) {
      return new (t || PageErrorMessageComponent)();
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: PageErrorMessageComponent,
      selectors: [["page-error-message"]],
      hostVars: 1,
      hostBindings: function PageErrorMessageComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("e2e-error-page", ctx.e2eErrorCode);
        }
      },
      inputs: {
        error: "error"
      },
      decls: 7,
      vars: 3,
      consts: [[4, "ngIf"]],
      template: function PageErrorMessageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ui-content-centered")(1, "div")(2, "h1");
          i0.ɵɵtext(3);
          i0.ɵɵtemplate(4, PageErrorMessageComponent_span_4_Template, 2, 1, "span", 0);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "p");
          i0.ɵɵtext(6);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("", ctx.error.summary, " ");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.isNumber(ctx.error.status));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.error.message);
        }
      },
      dependencies: [i1.NgIf, i2.UiContentCenteredComponent],
      styles: ["[_nghost-%COMP%]{text-align:center}\n\n/*# sourceMappingURL=error-message.component.ts-angular-inline--33.css.map*/", "div[_ngcontent-%COMP%]{max-width:100%}\n\n/*# sourceMappingURL=error-message.component.ts-angular-inline--34.css.map*/", "h1[_ngcontent-%COMP%], h3[_ngcontent-%COMP%]{margin:0}\n\n/*# sourceMappingURL=error-message.component.ts-angular-inline--35.css.map*/"]
    });
  }
};
PageErrorMessageComponent = __decorate([AutoUnsubscribe()], PageErrorMessageComponent);
export { PageErrorMessageComponent };