import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { UiMessageModule } from '@common/ui/frontend-shared/message';
import { AccessState, CoreFrontendSharedModule } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';
import { Observable, combineLatest, map } from 'rxjs';
import { Router } from '@angular/router';
import { MembershipPluginsService } from '@common/membership/shared';
import { MembershipRegistrationFacade, MembershipRegistrationMode, MembershipRegistrationState, RegistrationStep } from '../state';
import * as i0 from "@angular/core";
import * as i1 from "../state";
import * as i2 from "@common/membership/shared";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/button/button";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/message/message";
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Die Registrierung konnte nicht abgeschlossen werden.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "ui-message", 3);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 4);
    i0.ɵɵlistener("click", function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_0_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r6.backToForm());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", status_r1.submissionError, " ");
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_0_p_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(4).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("Fehlermeldung: ", status_r1.paymentError, "");
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Zahlung war nicht erfolgreich");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "ui-message", 6)(3, "p");
    i0.ɵɵtext(4, "Ihre Mitgliedschaft wurde erstellt, ist aber noch nicht nutzbar, da es w\u00E4hrend der Zahlung einen Fehler gab.");
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(5, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_0_p_5_Template, 2, 1, "p", 7);
    i0.ɵɵelementStart(6, "p");
    i0.ɵɵtext(7, "Kontaktieren Sie bitte unseren Support, um Ihre Mitgliedschaft zu aktivieren.");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", status_r1.paymentError);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_ng_template_2_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r20);
      const actionBtn_r18 = restoredCtx.$implicit;
      const ctx_r19 = i0.ɵɵnextContext(6);
      return i0.ɵɵresetView(ctx_r19.actionButtonNavigation(actionBtn_r18.link));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const actionBtn_r18 = ctx.$implicit;
    i0.ɵɵproperty("label", actionBtn_r18.label);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Ihre Mitgliedschaft wurde erstellt!");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_ng_template_2_Template, 1, 1, "ng-template", 0);
    i0.ɵɵpipe(3, "async");
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 1, ctx_r15.dynamicButton$));
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_ng_template_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_ng_template_2_ng_template_2_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r25);
      const actionBtn_r23 = restoredCtx.$implicit;
      const ctx_r24 = i0.ɵɵnextContext(7);
      return i0.ɵɵresetView(ctx_r24.actionButtonNavigation(actionBtn_r23.link));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const actionBtn_r23 = ctx.$implicit;
    i0.ɵɵproperty("label", actionBtn_r23.label);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, "Ihre Registrierung wurde abgeschlossen und Ihre Mitgliedschaft wurde aktiviert. Viel Erfolg beim Lernen!.");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_ng_template_2_ng_template_2_Template, 1, 1, "ng-template", 0);
    i0.ɵɵpipe(3, "async");
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 1, ctx_r21.dynamicButton$));
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2");
    i0.ɵɵtext(1, "Registrierung erfolgreich");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_ng_template_2_Template, 4, 3, "ng-template", 0);
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(4).$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !status_r1.submissionResponse.paymentPending);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_0_Template, 4, 3, "ng-template", 0)(1, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_ng_template_1_Template, 3, 1, "ng-template", 0);
  }
  if (rf & 2) {
    const status_r1 = i0.ɵɵnextContext(3).$implicit;
    const ctx_r10 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", status_r1.mode === ctx_r10.MembershipRegistrationMode.registered);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", status_r1.mode === ctx_r10.MembershipRegistrationMode.create);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_0_Template, 8, 1, "ng-template", 1)(1, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_ng_template_1_Template, 2, 2, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r11 = i0.ɵɵreference(2);
    const status_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngIf", status_r1.paymentError || status_r1.paymentPending)("ngIfElse", _r11);
  }
}
function RegistrationCompleteComponent_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_0_Template, 5, 1, "ng-template", 1)(1, RegistrationCompleteComponent_ng_template_0_ng_template_0_ng_template_1_Template, 3, 2, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const _r5 = i0.ɵɵreference(2);
    const status_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngIf", status_r1.submissionError)("ngIfElse", _r5);
  }
}
function RegistrationCompleteComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RegistrationCompleteComponent_ng_template_0_ng_template_0_Template, 3, 2, "ng-template", 0);
  }
  if (rf & 2) {
    const status_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", status_r1.submissionResponse);
  }
}
export class RegistrationCompleteComponent {
  constructor(registration, pluginsService, router) {
    this.registration = registration;
    this.pluginsService = pluginsService;
    this.router = router;
    this.activePlugin$ = this.membershipType$.pipe(map(type => this.pluginsService.getPluginInstance(type)));
    this.dynamicButton$ = this.activePlugin$.pipe(map(plugin => {
      if (plugin.getRegistrationCompletedButton) {
        return plugin.getRegistrationCompletedButton();
      }
      return null;
    }));
    this.MembershipRegistrationMode = MembershipRegistrationMode;
    this.status$ = combineLatest({
      mode: this.mode$,
      loggedIn: this.loggedIn$,
      paymentError: this.paymentError$,
      submissionError: this.submissionError$,
      submissionResponse: this.submissionResponse$
    });
  }
  backToForm() {
    this.registration.setPluginFormState(null);
    this.registration.goToStep(RegistrationStep.forms);
  }
  goToLogin() {
    // Issue: registration may be on same page as login. In this case, the button wont do anything cause route is already activated.
    // this.router.navigate(this.routerLinkResolver.resolveRouterLink('login'))
  }
  actionButtonNavigation(link) {
    this.router.navigate(link);
  }
  static {
    this.ɵfac = function RegistrationCompleteComponent_Factory(t) {
      return new (t || RegistrationCompleteComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.MembershipPluginsService), i0.ɵɵdirectiveInject(i3.Router));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationCompleteComponent,
      selectors: [["membership-registration-complete"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[3, "ngIf"], [3, "ngIf", "ngIfElse"], ["submissionSuccess", ""], ["type", "error"], ["uiButton", "", "label", "Zur\u00FCck zum Formular", 3, "click"], ["successAndNoPaymentIssues", ""], ["type", "warn"], [4, "ngIf"], ["uiButton", "", 3, "label", "click"]],
      template: function RegistrationCompleteComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, RegistrationCompleteComponent_ng_template_0_Template, 1, 1, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.status$));
        }
      },
      dependencies: [CoreFrontendSharedModule, CommonModule, i4.NgIf, i4.AsyncPipe, UtilsFrontendSharedModule, UiButtonModule, i5.UiButtonComponent, UiMessageModule, i6.UiMessageComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
__decorate([Select(AccessState.loggedIn), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "loggedIn$", void 0);
__decorate([Select(MembershipRegistrationState.mode), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "mode$", void 0);
__decorate([Select(MembershipRegistrationState.paymentError), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "paymentError$", void 0);
__decorate([Select(MembershipRegistrationState.submissionError), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "submissionError$", void 0);
__decorate([Select(MembershipRegistrationState.submissionResponse), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "submissionResponse$", void 0);
__decorate([Select(MembershipRegistrationState.requireAccountData), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "requireAccountData$", void 0);
__decorate([Select(MembershipRegistrationState.credentialsByMail), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "credentialsByMail$", void 0);
__decorate([Select(MembershipRegistrationState.membershipType), __metadata("design:type", Observable)], RegistrationCompleteComponent.prototype, "membershipType$", void 0);