import { __decorate, __metadata } from "tslib";
import { EventEmitter } from '@angular/core';
import { LocalFacade, LocalState } from '@common/localstate/frontend-shared';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ScreenService } from '@common/ui/frontend-shared/screen-service';
import * as i0 from "@angular/core";
import * as i1 from "@common/localstate/frontend-shared";
import * as i2 from "@ngxs/store";
import * as i3 from "@common/ui/frontend-shared/screen-service";
export class LayoutSidebarService {
  constructor(localstate, _store, screen) {
    this.localstate = localstate;
    this._store = _store;
    this.screen = screen;
    this._hasSidebar = new BehaviorSubject(false);
    this._hoveringMenu = new BehaviorSubject(false);
    this._staticMenuDesktopInactive = new BehaviorSubject(false);
    this._staticMenuMobileActive = new BehaviorSubject(false);
    this._menuToggled = new BehaviorSubject(false);
    this.hasSidebar$ = this._hasSidebar.asObservable();
    this.hoveringMenu$ = this._hoveringMenu.asObservable();
    this.staticMenuDesktopInactive$ = this._staticMenuDesktopInactive.asObservable();
    this.staticMenuMobileActive$ = this._staticMenuMobileActive.asObservable();
    this.menuToggled$ = this._menuToggled.asObservable();
    this.onMoveMenuScrollerBar$ = new EventEmitter();
    this.anchored$ = combineLatest({
      isLargeDesktop: screen.isLargeDesktop$,
      pinMenu: this.pinMenu$,
      mobileMenuActive: this._staticMenuMobileActive
    }).pipe(map(info => {
      return info.isLargeDesktop ? info.pinMenu : info.mobileMenuActive;
    }));
  }
  enableSidebar(enable) {
    this._hasSidebar.next(enable);
  }
  onClickOutside() {
    this._menuToggled.next(false);
    if (this._staticMenuMobileActive.value) this.hideMenu();
  }
  moveMenuScrollerBar() {
    this.onMoveMenuScrollerBar$.emit();
  }
  showMenu(event) {
    this._menuToggled.next(true);
    this.screen.isLargeDesktop$.pipe(take(1)).subscribe(isLargeDesktop => {
      if (isLargeDesktop) {
        this._staticMenuDesktopInactive.next(!this._staticMenuDesktopInactive.value);
      } else {
        this._staticMenuMobileActive.next(!this._staticMenuMobileActive.value);
      }
    });
    if (event) event.preventDefault();
  }
  hideMenu() {
    this._staticMenuMobileActive.next(false);
  }
  onClickPinMenu(event) {
    const currentState = this._store.selectSnapshot(({
      local
    }) => local.settings['pinMenu'] || false);
    this.localstate.set('pinMenu', !currentState);
    if (event) event.preventDefault();
  }
  onMouseEnterMenu(event) {
    this._hoveringMenu.next(true);
  }
  onMouseLeaveMenu(event) {
    this._hoveringMenu.next(false);
  }
  toggleMenuHovering() {
    this._hoveringMenu.next(!this._hoveringMenu.value);
  }
  static {
    this.ɵfac = function LayoutSidebarService_Factory(t) {
      return new (t || LayoutSidebarService)(i0.ɵɵinject(i1.LocalFacade), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.ScreenService));
    };
  }
  static {
    this.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
      token: LayoutSidebarService,
      factory: LayoutSidebarService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([Select(LocalState.get('pinMenu', true)), __metadata("design:type", Observable)], LayoutSidebarService.prototype, "pinMenu$", void 0);