import { __decorate, __metadata } from "tslib";
import { ElementRef } from "@angular/core";
import { ConfigService } from "@core/shared/config";
import { PageFacade } from "@common/page/frontend-shared";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { skipWhile, take } from "rxjs/operators";
import { MaintenanceFacade, MaintenanceState } from "../state/maintenance.state";
import * as i0 from "@angular/core";
import * as i1 from "../state/maintenance.state";
import * as i2 from "@core/shared/config";
import * as i3 from "@common/page/frontend-shared";
import * as i4 from "@angular/common";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/button/button";
const _c0 = ["logoContent"];
function MaintenanceModeComponent_div_0_div_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "p");
    i0.ɵɵtext(2, "Weitere Informationen:");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const text_r3 = ctx.$implicit;
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(text_r3);
  }
}
function MaintenanceModeComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3)(2, "div", 4);
    i0.ɵɵprojection(3, 0, ["#logoContent", ""]);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div")(5, "h3", 5);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "h1", 5);
    i0.ɵɵtext(8, "Wartungsmodus aktiv!");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "div", 6)(10, "p");
    i0.ɵɵtext(11, "Aktuell ist die Seite im Wartungsmodus.");
    i0.ɵɵelement(12, "br");
    i0.ɵɵtext(13, " Bitte versuchen Sie es etwas sp\u00E4ter erneut.");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(14, MaintenanceModeComponent_div_0_div_14_Template, 5, 1, "div", 7);
    i0.ɵɵpipe(15, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "div", 8)(17, "button", 9);
    i0.ɵɵlistener("click", function MaintenanceModeComponent_div_0_Template_button_click_17_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onClickReload());
    });
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.pageTitle);
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(15, 2, ctx_r0.text$));
  }
}
function MaintenanceModeComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "fa-icon", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("icon", ctx_r1.faWrench);
  }
}
const _c1 = [[["", "id", "logo"]]];
const _c2 = ["#logo"];
export class MaintenanceModeComponent {
  constructor(facade, config, page) {
    this.facade = facade;
    this.config = config;
    this.page = page;
    // enabledInConfig$ = new BehaviorSubject(false);
    this.enabled$ = this.enabledInState$;
    this.faWrench = faWrench;
    this.pageTitle = config.get('pageTitle');
    this.enabled$.pipe(skipWhile(v => {
      return v !== true;
    }), take(1)).subscribe(enabled => {
      page.setTitle('Wartungsmodus aktiv!');
      page.setMeta([{
        name: 'robots',
        content: 'noindex,nofollow'
      }]);
    });
    // this.SUMMEnabled$.subscribe(enabled=>{
    // 	const bodyCls = this.document.body.classList;
    // 	if(enabled) bodyCls.add('SUMM-enabled')
    // })
  }
  onClickReload() {
    window.location.reload();
  }
  static {
    this.ɵfac = function MaintenanceModeComponent_Factory(t) {
      return new (t || MaintenanceModeComponent)(i0.ɵɵdirectiveInject(i1.MaintenanceFacade), i0.ɵɵdirectiveInject(i2.ConfigService), i0.ɵɵdirectiveInject(i3.PageFacade));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: MaintenanceModeComponent,
      selectors: [["maintenance-mode"]],
      viewQuery: function MaintenanceModeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.logoRef = _t.first);
        }
      },
      ngContentSelectors: _c2,
      decls: 4,
      vars: 6,
      consts: [["class", "overlay", 4, "ngIf"], ["class", "summ", 4, "ngIf"], [1, "overlay"], [1, "infobox"], [1, "logowrapper"], [1, "ui-margin-remove"], [1, "infotext"], [4, "ngIf"], [1, "ui-margin"], ["uiButton", "", "label", "Seite neu laden", 3, "click"], [1, "summ"], ["size", "2x", "title", "Wartungsmodus aktiv!", 3, "icon"]],
      template: function MaintenanceModeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, MaintenanceModeComponent_div_0_Template, 18, 4, "div", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵtemplate(2, MaintenanceModeComponent_div_2_Template, 2, 1, "div", 1);
          i0.ɵɵpipe(3, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.enabled$));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 4, ctx.SUMMEnabled$));
        }
      },
      dependencies: [i4.NgIf, i5.FaIconComponent, i6.UiButtonComponent, i4.AsyncPipe],
      styles: [".overlay[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:9998;background:var(--backgroundColor);transition:background-color .3s}.overlay[_ngcontent-%COMP%]{display:flex;justify-content:center;align-items:center}.infobox[_ngcontent-%COMP%]{background:var(--contentColor);color:var(--textColor);padding:20px;width:600px;max-width:90%;position:relative}.infotext[_ngcontent-%COMP%]{margin:30px 0}.logowrapper[_ngcontent-%COMP%]{position:absolute;width:100px;right:0;top:0}.summ[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:9998;border:5px solid var(--maintenanceWarningColor);pointer-events:none}.summ[_ngcontent-%COMP%]   fa-icon[_ngcontent-%COMP%]{color:var(--maintenanceWarningColor);position:absolute;top:15px;left:75px}"]
    });
  }
}
__decorate([Select(MaintenanceState.SUMaintenanceModeEnabled), __metadata("design:type", Observable)], MaintenanceModeComponent.prototype, "SUMMEnabled$", void 0);
__decorate([Select(MaintenanceState.maintenanceModeEnabled), __metadata("design:type", Observable)], MaintenanceModeComponent.prototype, "enabledInState$", void 0);
__decorate([Select(MaintenanceState.maintenanceModeText), __metadata("design:type", Observable)], MaintenanceModeComponent.prototype, "text$", void 0);