
module.exports.getEnvConfig = function getEnvConfig() {
	if( (typeof (window) === 'object') && (typeof (window).__env__ === 'object') ) {
		// index.html will first load env-config.js (generated on container startup by env-config.sh)
		// this will populate window.__env__.
		// in SSR mode, this file is not located in distFolder, instead express will redirect accordingly.
		// console.log('found window env',(window).__env__)
		return (window).__env__;
	}
	else if( (typeof (process) === 'object') && (typeof (process).env === 'object') ) {
		// SSR service itself will use env from process.env.
		// in parallel, frontend request for env-config.js will be redirected to static env file.
		// console.log('found process env',(process).env)
		return (process).env;
	} else {
		console.log('no environment found at window or process')
	}
	return {}
}