import { ConfigService } from '@core/shared';
import { MEDIA_ASSET_PROVIDER_TOKEN } from '../media-assets';
export class MediaAssetProvidersBaseService {
  constructor(providersRegistry, injector) {
    this.providersRegistry = providersRegistry;
    this.injector = injector;
    this.providers = new Map();
    this.validate();
    const pluginCtors = providersRegistry.getProviders(MEDIA_ASSET_PROVIDER_TOKEN);
    this.setupProviders(pluginCtors, this.injector);
  }
  validate() {
    if (!this.providersRegistry) throw new Error(this.constructor.name + ": FeatureProvidersRegistry not injected correctly!");
    if (!this.injector) throw new Error(this.constructor.name + ": SharedInjector not set correctly!");
  }
  setupProviders(pluginCtors, injector) {
    pluginCtors.forEach(ctor => {
      const instance = new ctor();
      instance.setInjector(injector);
      this.providers.set(instance.providerId, instance);
    });
  }
  getProvider(typeOrItem) {
    const providerId = typeof typeOrItem === 'object' ? typeOrItem.provider : typeOrItem;
    const p = this.providers.get(providerId);
    if (!p) throw new Error('Unknown MediaAssetProvider requested: ' + providerId);
    return p;
  }
  getDefaultProviderId() {
    const config = this.injector.get(ConfigService);
    const pId = config.get('media.defaultProvider', 'cloudinary');
    if (!this.providers.has(pId)) throw new Error('MediaAssetProvidersFrontendService detected invalid defaultProvider: ' + pId + ' does not exist!');
    return pId;
  }
  getRegisteredProviderIds() {
    return Array.from(this.providers.keys());
  }
  getRegisteredProvidersOptionList() {
    return this.getRegisteredProviderIds().map(id => {
      return {
        value: id,
        label: this.providers.get(id).name
      };
    });
  }
}