import { __decorate, __metadata } from "tslib";
import { ActivatedRoute } from '@angular/router';
import { ModelFormServiceFactory } from '@common/forms/frontend-shared';
import { InputPasswordRepeatedInstruction, InputTextInstruction } from '@common/instructions/frontend-shared';
import { PageFacade } from '@common/page/frontend-shared';
import { PatchProfileCommonsRequest } from '@common/users/shared';
import { LocalMessageHandlerService } from '@core/frontend-shared';
import { AppRequestService } from '@core/frontend-shared/api';
import { ReplaceableComponent } from '@core/frontend-shared/feature';
import { ConfigService } from '@core/shared/config';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@common/forms/frontend-shared";
import * as i3 from "@core/shared/config";
import * as i4 from "@core/frontend-shared/api";
import * as i5 from "@common/page/frontend-shared";
import * as i6 from "@angular/router";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "../../../../../../../ui/frontend-shared/src/lib/button/button";
import * as i9 from "../../../../../../../forms/frontend-shared/src/lib/simple-fieldset/simple-fieldset.component";
import * as i10 from "../../../../../../../forms/frontend-shared/src/lib/form-submit-button/form-submit-button.directive";
import * as i11 from "../../../../../../../messages/frontend-shared/src/lib/components/app-messages.component";
const _c0 = () => ({
  label: "Speichern",
  icon: "save"
});
let ProfileCommonsComponent = class ProfileCommonsComponent {
  constructor(messages, formServiceFactory, config, api, page, route) {
    this.messages = messages;
    this.config = config;
    this.api = api;
    this.page = page;
    this.route = route;
    this.usernameIsEmail = this.config.get('users.usernameIsEmail', false);
    this.usernameLabel = this.usernameIsEmail ? 'E-Mail-Adresse / Benutzername' : 'Benutzername';
    this.instructions = [new InputTextInstruction({
      formControlName: 'username',
      placeholder: this.usernameLabel
    }), new InputTextInstruction({
      formControlName: 'firstName',
      placeholder: "Vorname"
    }), new InputTextInstruction({
      formControlName: 'lastName',
      placeholder: "Nachname"
    }), !this.usernameIsEmail ? new InputTextInstruction({
      formControlName: 'email',
      placeholder: "E-Mail-Adresse"
    }) : null, new InputPasswordRepeatedInstruction({
      formControlName1: 'password',
      formControlName2: 'password2',
      usePlaceholders: true
    })];
    this.formService = formServiceFactory.createModelForm(PatchProfileCommonsRequest);
    this.formService.onAfterSave.subscribe(({
      responseData,
      error
    }) => {
      if (error) {
        this.messages.push(error);
      }
    });
    this.formService.setEditorDefinition('default');
    const profileInfo = route.parent.snapshot.data['profileData'];
    if (!profileInfo) throw new Error('ProfileData not found at route data');
    this.formService.setInitialValue(profileInfo.profileData);
    this.formService.initialize();
  }
  static {
    this.ɵfac = function ProfileCommonsComponent_Factory(t) {
      return new (t || ProfileCommonsComponent)(i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService), i0.ɵɵdirectiveInject(i2.ModelFormServiceFactory), i0.ɵɵdirectiveInject(i3.ConfigService), i0.ɵɵdirectiveInject(i4.AppRequestService), i0.ɵɵdirectiveInject(i5.PageFacade), i0.ɵɵdirectiveInject(i6.ActivatedRoute));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: ProfileCommonsComponent,
      selectors: [["user-profile-commons"]],
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      decls: 12,
      vars: 5,
      consts: [[1, "ui-margin-remove-top"], ["icon", "lock"], [3, "instructions", "formService"], [1, "ui-clearfix"], ["uiButton", "", 3, "formSubmitButton", "formSubmitButtonOptions"]],
      template: function ProfileCommonsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "h2", 0);
          i0.ɵɵtext(1, "Mein Benutzerkonto");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "p");
          i0.ɵɵtext(3, "Felder, die mit einem \"");
          i0.ɵɵelement(4, "fa-icon", 1);
          i0.ɵɵtext(5, "\" markiert sind, k\u00F6nnen nicht von Ihnen bearbeitet werden.\nFalls an diesen Daten eine \u00C4nderung vorgenommen werden muss, kontaktieren Sie bitte den Support.");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "simple-fieldset", 2)(7, "br")(8, "br")(9, "app-messages");
          i0.ɵɵelementStart(10, "div", 3);
          i0.ɵɵelement(11, "button", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("instructions", ctx.instructions)("formService", ctx.formService);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("formSubmitButton", ctx.formService)("formSubmitButtonOptions", i0.ɵɵpureFunction0(4, _c0));
        }
      },
      dependencies: [i7.FaIconComponent, i8.UiButtonComponent, i9.SimpleFieldsetComponent, i10.FormSubmitButtonDirective, i11.AppMessagesComponent],
      styles: ["@media (max-width: 959px){h2[_ngcontent-%COMP%]{display:none}}"]
    });
  }
};
ProfileCommonsComponent = __decorate([ReplaceableComponent(), __metadata("design:paramtypes", [LocalMessageHandlerService, ModelFormServiceFactory, ConfigService, AppRequestService, PageFacade, ActivatedRoute])], ProfileCommonsComponent);
export { ProfileCommonsComponent };