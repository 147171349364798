import { buildMessage, matches, ValidateBy } from "class-validator";
const IS_SIMPLE_TEXT = "IS_SIMPLE_TEXT";
export function IsSimpleText(validationOptions) {
  return ValidateBy({
    name: IS_SIMPLE_TEXT,
    constraints: [/^[^<>|!$%&=@#:;'*~]+$/, ''],
    validator: {
      validate: (value, args) => matches(value, args.constraints[0], args.constraints[0]),
      defaultMessage: buildMessage((eachPrefix, args) => 'Es sind keine Sonderzeichen erlaubt.', validationOptions)
    }
  }, {
    message: 'Es sind keine Sonderzeichen erlaubt.',
    ...validationOptions
  });
}