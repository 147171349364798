import { ItemRestraints } from '../restraints/restraints';
export class SessionRestraints extends ItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return true;
  }
  canToggleItem(item) {
    return true;
  }
  canUpdateItem(item, oldItem) {
    return true;
  }
  canRemoveItem(item) {
    return true;
  }
}