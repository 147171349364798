import { __decorate, __metadata } from "tslib";
import { ComponentFactoryResolver, createNgModule, EnvironmentInjector, Type } from '@angular/core';
import { AutoUnsubscribe, hasAutoUnsubscribeEnabled, takeWhileAlive } from '../../utils';
import { map, take } from 'rxjs/operators';
import { getRouteInputMetadata } from '../route-input.decorator';
import { ClonedRouterOutlet, isComponentFactoryResolver, OutletInjector } from './cloned-router-outlet';
import { FeatureDynamicComponentsService } from '../../feature';
import * as i0 from "@angular/core";
import * as i1 from "../../feature";
let CustomRouterOutlet = class CustomRouterOutlet extends ClonedRouterOutlet {
  constructor(dynamicComponentsService) {
    super();
    this.dynamicComponentsService = dynamicComponentsService;
  }
  // ngOnInit(): void {
  // 	if (!this.activated) {
  // 	  // If the outlet was not instantiated at the time the route got activated we need to populate
  // 	  // the outlet when it is initialized (ie inside a NgIf)
  // 	  const context = this.parentContexts.getContext(this.name);
  // 	  if (context && context.route) {
  // 		if (context.attachRef) {
  // 		  // `attachRef` is populated when there is an existing component to mount
  // 		  this.attach(context.attachRef, context.route);
  // 		} else {
  // 		  // otherwise the component defined in the configuration is created
  // 		  this.activateWith(context.route, context.resolver || null);
  // 		}
  // 	  }
  // 	}
  // }
  // called from https://github.com/angular/angular/blob/41c6f7a0796193728981572c8bef900b23da6c3f/packages/router/src/operators/activate_routes.ts#L204
  // highly interesting file, but pretty much impossible to manipulate.
  // activateWith now only contains our custom logic, all default logic has been placed in separate methods so that it is easier to update when needed.
  activateWith(activatedRoute,
  // eslint-disable-next-line deprecation/deprecation
  resolverOrInjector) {
    let component = this.activateWith_prepareAndGetComponentCtor(activatedRoute);
    // custom logic for fetching 
    this.dynamicComponentsService.resolveComponentReplacement(component).pipe(take(1)).subscribe(componentInfo => {
      if (componentInfo) {
        component = componentInfo.component;
        this.activateCustomComponent(activatedRoute, componentInfo, resolverOrInjector);
        // custom
        this.patchRouteInputs(component);
        this.activateWith_finalize();
        // console.log('replacementComponent', ReplacementComponent);
      } else {
        super.activateWith(activatedRoute, resolverOrInjector);
      }
    });
  }
  // like activateWith_createComponent, but uses ComponentInfo and its passed Module if specified.
  activateCustomComponent(activatedRoute, componentInfo,
  // eslint-disable-next-line deprecation/deprecation
  resolverOrInjector) {
    const component = componentInfo.component;
    const location = this.location;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const childContexts = this.parentContexts.getOrCreateContext(this.name).children;
    const injector = new OutletInjector(activatedRoute, childContexts, location.injector);
    if (resolverOrInjector && isComponentFactoryResolver(resolverOrInjector)) {
      const factory = resolverOrInjector.resolveComponentFactory(component);
      // eslint-disable-next-line deprecation/deprecation
      this.activated = location.createComponent(factory, location.length, injector);
    } else {
      // create a new NgModuleRef from componentInfo as child of this component's injector (=injector of parent module)
      const ngModuleRef = componentInfo.module ? createNgModule(componentInfo.module, injector) : undefined;
      // only ngModuleRef or environmentInjector must be defined!
      const environmentInjector = ngModuleRef ? undefined : resolverOrInjector ?? this.environmentInjector;
      this.activated = location.createComponent(component, {
        index: location.length,
        injector,
        environmentInjector,
        ngModuleRef
      });
    }
  }
  getFutureRouteSnapshot() {
    const snapshot = this._activatedRoute._futureSnapshot;
    return snapshot;
  }
  patchRouteInputs(Component) {
    const metas = getRouteInputMetadata(Component);
    if (!metas.length) return;
    const snapshot = this.getFutureRouteSnapshot();
    const route = this._activatedRoute;
    let usesObservables = false;
    // check for requested observables, memorize data at inputProp['useObservable']
    metas.forEach(inputProp => {
      // const assumeObservable = inputProp.property.endsWith('$');
      const explicitObservableSetting = typeof inputProp.settings.observable === 'boolean';
      const useObservable = explicitObservableSetting || false;
      if (useObservable) usesObservables = true;
      inputProp['useObservable'] = useObservable;
    });
    const component = this.component;
    // for unknown reasons, AutoUnsubscribe / replaced ngOnDestroy will not trigger reliably when added right here.
    // so instead we do a check if AutoUnsubscribe is already assigned and error if not.
    if (usesObservables && !hasAutoUnsubscribeEnabled(component)) {
      throw new Error('Components using RouteInput in observable mode must be decorated with @AutoUnsubscribe()! - ' + component.constructor.name);
    }
    const assertPropertyExists = (obj, key, type) => {
      if (typeof obj[key] === 'undefined') throw new Error('@RouteInput error: route has no ' + type + ' type data named ' + key);
    };
    metas.forEach(inputProp => {
      const useObservable = inputProp['useObservable'];
      let propValue;
      if (useObservable) {
        propValue = route[inputProp.type].pipe(map(record => {
          assertPropertyExists(record, inputProp.name, inputProp.type);
          return record[inputProp.name];
        }), takeWhileAlive(component));
      } else {
        assertPropertyExists(snapshot[inputProp.type], inputProp.name, inputProp.type);
        propValue = snapshot[inputProp.type][inputProp.name];
      }
      component[inputProp.property] = propValue;
      // console.log('patched',inputProp.property,propValue,component)
    });
  }
  static {
    this.ɵfac = function CustomRouterOutlet_Factory(t) {
      return new (t || CustomRouterOutlet)(i0.ɵɵdirectiveInject(i1.FeatureDynamicComponentsService));
    };
  }
  static {
    this.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
      type: CustomRouterOutlet,
      selectors: [["custom-router-outlet"]],
      exportAs: ["outlet"],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
};
CustomRouterOutlet = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [FeatureDynamicComponentsService])], CustomRouterOutlet);
export { CustomRouterOutlet };