import { DynamicFieldInstruction } from './shared';
// <ui-item-metadata-table [item]="formService.formGroup.getRawValue()" ></ui-item-metadata-table>
export class ButtonListInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      label: " ",
      buttons: []
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      buttons: "buttons"
    };
    this.attributeMap = {};
    this.isInput = false;
    this.setConfig(config);
  }
  load() {
    return import('@common/ui/frontend-shared/button-list').then(bundle => {
      return {
        component: bundle.ButtonListComponent,
        module: bundle.UiButtonListModule
      };
    });
  }
}