import { __decorate } from "tslib";
import { EventEmitter } from '@angular/core';
import { ComponentCtor, FormInstructionInterface } from '@core/frontend-shared';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { ItemEditMode, getItemModelText, isConstructor } from '@core/shared';
import { BehaviorSubject, filter, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { CrudTemplate } from '../crud-templates.interface';
import { CrudItemViewComponent } from './crud-item-view.component';
import * as i0 from "@angular/core";
function CrudEditorComponent_ui_header_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div", 6);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const title_r6 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(title_r6);
  }
}
function CrudEditorComponent_ui_header_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-header");
    i0.ɵɵtemplate(1, CrudEditorComponent_ui_header_1_div_1_Template, 4, 1, "div", 4);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 1, ctx_r0.title$));
  }
}
function CrudEditorComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crud-fieldset", 8);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const formService_r7 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("instructions", ctx_r1.innerFormComponent ? null : ctx_r1.instructions)("formService", formService_r7)("innerFormComponent", ctx_r1.innerFormComponent)("submitShortcut", ctx_r1.submitShortcut)("contextGroups", i0.ɵɵpipeBind1(1, 5, ctx_r1.crudManager.contextGroups$));
  }
}
function CrudEditorComponent_ui_footer_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-footer");
    i0.ɵɵelement(1, "crud-item-toolbar", 9);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("formService", i0.ɵɵpipeBind1(2, 3, ctx_r2.formService$))("item", i0.ɵɵpipeBind1(3, 5, ctx_r2.fullFormValue$))("config", ctx_r2.crudManager.config.editor);
  }
}
function CrudEditorComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ui-spinner");
  }
}
const _c0 = [[["ui-header"]], [["ui-footer"]]];
const _c1 = ["ui-header", "ui-footer"];
let CrudEditorComponent = class CrudEditorComponent extends CrudItemViewComponent {
  constructor() {
    super(...arguments);
    this.innerFormComponent = null;
    this.showTitle = false; // disabled as we currently display the same title text in top bar already
    this.submitShortcut = this.crudManager.config.editor.submitShortcut;
    this.formService$ = new BehaviorSubject(null);
    // @Output() onSave:EventEmitter<any> = new EventEmitter();
    this.onAbort = new EventEmitter();
    this.fullFormValue$ = this.formService$.pipe(filter(fs => !!fs),
    // switch to initialized to make sure service is ready, then map back to pass the service itself
    switchMap(fs => {
      return fs.valueChanges$.pipe(startWith(null), map(() => fs.getValueIncludingReadonly()));
    }));
    this.title$ = this.crudManager.modeAndItem$.pipe(map(({
      mode,
      item
    }) => {
      if (this.customTitle) return this.customTitle;
      if (mode === 'batch') return getItemModelText('batch', this.crudManager.Model);
      if (mode === 'view') return getItemModelText('viewItem', item);
      if (mode === 'new') return getItemModelText('newItem', item);
      if (mode === 'edit') return getItemModelText('editItem', item);
      if (mode === 'clone') return getItemModelText('cloneItem', item);
      if (!mode) return "lade...";
      return "[Unbekannter Editor-Modus]";
    }), takeWhileAlive(this));
  }
  bindObservables() {
    super.bindObservables();
    this.title$.pipe(takeWhileAlive(this)).subscribe(title => {
      this.crudManager.setPageTitle(title);
    });
  }
  refresh() {
    const itemId = this.routeItemId$.getValue();
    this.itemController.loadItem(itemId).subscribe({
      next: item => {
        this.formService$.getValue().setValue(item);
      }
    });
  }
  loadItem(itemId, isRefresh = false) {
    if (typeof itemId !== 'number') throw new Error('CrudItemView.loadItem received non-numeric value for itemId');
    this.itemController.loadItem(itemId).subscribe({
      next: item => {
        this.crudManager.configurateModeAndItem({
          mode: 'edit',
          item
        });
      },
      error: () => {
        this.messages.create(getItemModelText('itemNotExists', {
          id: itemId
        })).setSeverity('warn');
        this.router.navigate(['../'], {
          relativeTo: this.route
        });
      }
    });
  }
  handleRouteChange(params, data) {
    if (params.id) {
      const itemId = typeof params.id === 'number' ? params.id : parseInt(params.id, 10);
      this.routeItemId$.next(itemId);
    } else {
      if (data.mode === 'new' || this.route.routeConfig.path === 'new') {
        of(this.itemController.newItem()).subscribe({
          next: item => {
            this.crudManager.configurateModeAndItem({
              mode: 'new',
              item
            });
          }
        });
      } else if (data.mode === 'batch' || this.route.routeConfig.path === 'batch') {
        this.crudManager.configurateModeAndItem({
          mode: 'batch',
          item: null
        });
      } else {
        console.warn(params, data, this.route);
        throw new Error('CrudEditorComponent was unable to understand Route Configuration!');
      }
    }
  }
  handleConfigurationUpdate(config) {
    const fs = config.formService;
    const oldFs = this.formService$.getValue();
    this.instructions = config.instructions;
    this.innerFormComponent = this.getInnerFormComponent(fs.getEditMode());
    this.cd.markForCheck();
    if (fs !== oldFs) {
      this.formService$.next(fs);
      this.subscribeToFormService(fs);
    } else {
      // not beautiful but a working solution to force recreation of crud-fieldset
      this.formService$.next(null);
      setTimeout(() => {
        this.formService$.next(fs);
        this.subscribeToFormService(fs);
        this.cd.markForCheck();
      });
    }
  }
  subscribeToFormService(fs) {
    this.subscribeToOnAfterSave(fs);
  }
  subscribeToOnAfterSave(fs) {
    fs.onAfterSave.asObservable().pipe(takeWhileAlive(this),
    // auto-unsubscribe when this.formService$ is changed to another service
    takeUntil(this.formService$.pipe(filter(usedFs => {
      return usedFs !== fs;
    })))).subscribe(({
      responseData,
      item,
      error
    }) => {
      if (!error) {
        const fsEditMode = fs.getEditMode();
        // TODO: could be replaced by more elegant crudManager.navigateTo(...)
        if (this.crudManager.config.editor.closeOnSave) {
          this.closeView();
        } else if (fsEditMode === 'new' || fsEditMode === 'clone') {
          if (this.crudManager.enableEdit) {
            const id = item.id;
            if (typeof id !== 'number') {
              console.warn('CrudEditor onAfterSave subscriber received item without a valid ID. Aborting navigation to item edit view.', id, item);
              return;
            }
            this.router.navigate(['../', id], {
              relativeTo: this.route
            });
          } else {
            this.router.navigate(['../'], {
              relativeTo: this.route
            });
          }
        } else if (fsEditMode === 'batch') {
          this.router.navigate(['../'], {
            relativeTo: this.route
          });
        } else {
          if (item) this.crudManager.configurateModeAndItem({
            item: item
          });
          // this.refresh()
        }
      }
    });
  }
  getInnerFormComponent(mode) {
    const tmpl = this.crudManager.templates[CrudTemplate.EditorInnerForm];
    if (!tmpl) return null;
    if (isConstructor(tmpl)) {
      return tmpl;
    } else {
      return tmpl[mode];
    }
  }
  static {
    this.ɵfac = /*@__PURE__*/(() => {
      let ɵCrudEditorComponent_BaseFactory;
      return function CrudEditorComponent_Factory(t) {
        return (ɵCrudEditorComponent_BaseFactory || (ɵCrudEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrudEditorComponent)))(t || CrudEditorComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudEditorComponent,
      selectors: [["crud-editor"]],
      hostVars: 1,
      hostBindings: function CrudEditorComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("e2e-crud-editor", true);
        }
      },
      inputs: {
        showTitle: "showTitle",
        customTitle: "customTitle",
        submitShortcut: "submitShortcut",
        instructions: "instructions"
      },
      outputs: {
        onAbort: "onAbort"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 9,
      vars: 7,
      consts: [[3, "fillHeight"], [4, "ngIf"], [3, "ngIf", "ngIfElse"], ["loading", ""], ["class", "toolbar", 4, "ngIf"], [1, "toolbar"], [1, "left"], [1, "center"], [3, "instructions", "formService", "innerFormComponent", "submitShortcut", "contextGroups"], [3, "formService", "item", "config"]],
      template: function CrudEditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "ui-panel", 0);
          i0.ɵɵtemplate(1, CrudEditorComponent_ui_header_1_Template, 3, 3, "ui-header", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵtemplate(3, CrudEditorComponent_ng_template_3_Template, 2, 7, "ng-template", 2);
          i0.ɵɵpipe(4, "async");
          i0.ɵɵprojection(5, 1);
          i0.ɵɵtemplate(6, CrudEditorComponent_ui_footer_6_Template, 4, 7, "ui-footer", 1)(7, CrudEditorComponent_ng_template_7_Template, 1, 0, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const _r4 = i0.ɵɵreference(8);
          i0.ɵɵproperty("fillHeight", true);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.showTitle);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 5, ctx.formService$))("ngIfElse", _r4);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.crudManager.config.editor.showButtons);
        }
      },
      encapsulation: 2
    });
  }
};
CrudEditorComponent = __decorate([AutoUnsubscribe()], CrudEditorComponent);
export { CrudEditorComponent };