import { __decorate } from "tslib";
import { AutoUnsubscribe } from '../../utils';
import { share, take } from 'rxjs';
import { BaseItemService } from './base-item-service';
import { ClientCacheRequestMode } from '../../client-cache';
/**
 * SimpleItemService will fetch all items at once.
 */
// not injected, use ItemServiceFactory/CrudServiceFactory to create!
let SimpleItemService = class SimpleItemService extends BaseItemService {
  refreshAllItems() {
    if (!this.hasReadItems) return;
    const req = this.crud.readManyWithRangeInfo({
      cache: ClientCacheRequestMode.wipeCache
    }).pipe(take(1), share());
    req.subscribe(chunk => {
      this.updateItemListAndRange(chunk.items, chunk.rangeInfo);
    });
    return req;
  }
  _readItems() {
    this.assertInitialized();
    if (this.hasReadItems) return;
    this.hasReadItems = true;
    this.crud.readManyWithRangeInfo().subscribe(chunk => {
      this.updateItemListAndRange(chunk.items, chunk.rangeInfo);
    });
  }
};
SimpleItemService = __decorate([AutoUnsubscribe()], SimpleItemService);
export { SimpleItemService };