import { __decorate, __metadata } from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { signal } from "@angular/core";
import { AccessFacade, AppRequestService, AutoUnsubscribe, LocalMessageHandlerService } from '@core/frontend-shared';
import { AppState, AppStateFacade } from '@core/frontend-shared/app-state';
import { PingServerRequest } from '@core/shared';
import { Actions, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@core/frontend-shared";
import * as i2 from "@core/frontend-shared/app-state";
import * as i3 from "@ngxs/store";
import * as i4 from "@angular/common";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "../../../../../ui/frontend-shared/src/lib/content-centered/content-centered.component";
import * as i7 from "../../../../../ui/frontend-shared/src/lib/button/button";
function PageConnectionErrorComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3);
    i0.ɵɵprojection(3, 0, ["#logoContent", ""]);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "ui-content-centered")(5, "div")(6, "h1", 4);
    i0.ɵɵelement(7, "fa-icon", 5);
    i0.ɵɵtext(8, " Verbindungsfehler ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "p");
    i0.ɵɵtext(10, " Leider ist der Server aktuell nicht erreichbar. Bitte pr\u00FCfen Sie, ob Sie mit dem Internet verbunden sind und versuchen Sie es in K\u00FCrze erneut. ");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(11, "app-messages");
    i0.ɵɵelementStart(12, "p")(13, "button", 6);
    i0.ɵɵlistener("click", function PageConnectionErrorComponent_div_0_Template_button_click_13_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.clickRetry());
    });
    i0.ɵɵelementEnd()()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(13);
    i0.ɵɵproperty("loading", ctx_r0.loading());
  }
}
const _c0 = [[["", "id", "logo"]]];
const _c1 = ["#logo"];
/**
 * error component used by permissions guard
 */
let PageConnectionErrorComponent = class PageConnectionErrorComponent {
  constructor(access, appFacade, api, events, messages) {
    this.access = access;
    this.appFacade = appFacade;
    this.api = api;
    this.events = events;
    this.messages = messages;
    // connectionError$ = of(true)
    this.loading = signal(false);
  }
  clickRetry() {
    this.messages.clear();
    this.loading.set(true);
    this.api.sendRequest(PingServerRequest, {}).subscribe({
      // ApiErrorInterceptor creates no AppMessage on error 0, so error handler will receive
      // a httpErrorResponse(0) or an AppMessage in case of a different error code.
      error: error => {
        this.loading.set(false);
        if (error instanceof HttpErrorResponse) {
          this.messages.createAndDisplay("Die Verbindung zum Server ist fehlgeschlagen.");
        } else {
          this.messages.add(error);
        }
      },
      next: res => {
        this.loading.set(false);
        this.access.refreshSession();
        this.appFacade.setConnectionError(false);
      }
    });
    this.access.refreshSession();
  }
  static {
    this.ɵfac = function PageConnectionErrorComponent_Factory(t) {
      return new (t || PageConnectionErrorComponent)(i0.ɵɵdirectiveInject(i1.AccessFacade), i0.ɵɵdirectiveInject(i2.AppStateFacade), i0.ɵɵdirectiveInject(i1.AppRequestService), i0.ɵɵdirectiveInject(i3.Actions), i0.ɵɵdirectiveInject(i1.LocalMessageHandlerService));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: PageConnectionErrorComponent,
      selectors: [["page-connection-error"]],
      features: [i0.ɵɵProvidersFeature([LocalMessageHandlerService])],
      ngContentSelectors: _c1,
      decls: 2,
      vars: 3,
      consts: [["class", "overlay", 4, "ngIf"], [1, "overlay"], [1, "infobox"], [1, "logowrapper"], [1, "ui-margin-remove"], ["icon", "exclamation-triangle"], ["uiButton", "", "label", "Erneut versuchen", "icon", "sync-alt", 1, "ui-button-contrast", 3, "loading", "click"]],
      template: function PageConnectionErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵtemplate(0, PageConnectionErrorComponent_div_0_Template, 14, 1, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.connectionError$));
        }
      },
      dependencies: [i4.NgIf, i5.FaIconComponent, i6.UiContentCenteredComponent, i7.UiButtonComponent, i4.AsyncPipe],
      styles: [".overlay[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:9998;background:var(--invertedOverlaySoftColor);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);transition:background-color .3s}.overlay[_ngcontent-%COMP%]{display:flex;justify-content:center;align-items:center}.infobox[_ngcontent-%COMP%]{background:var(--contentColor);color:var(--textColor);padding:20px 120px 20px 20px;width:600px;max-width:90%;position:relative}.infotext[_ngcontent-%COMP%]{margin:30px 0}.logowrapper[_ngcontent-%COMP%]{position:absolute;width:100px;right:0;top:0}"],
      changeDetection: 0
    });
  }
};
__decorate([Select(AppState.connectionError), __metadata("design:type", Observable)], PageConnectionErrorComponent.prototype, "connectionError$", void 0);
PageConnectionErrorComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [AccessFacade, AppStateFacade, AppRequestService, Actions, LocalMessageHandlerService])], PageConnectionErrorComponent);
export { PageConnectionErrorComponent };