import { __decorate, __metadata } from "tslib";
import { ChangeDetectorRef, signal } from '@angular/core';
import { CrudManagerLayoutBase } from './layout-base';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared';
import { filter } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { CrudManagerService } from '../crud-manager.service';
import { extendsConstructor } from '@core/shared';
import { CrudManager2Component } from '../crud-manager2.component';
import * as i0 from "@angular/core";
import * as i1 from "../crud-manager.service";
import * as i2 from "@angular/router";
function CrudManagerLayoutTableComponent_ui_message_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ui-message");
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", ctx_r0.infoMessage, i0.ɵɵsanitizeHtml);
  }
}
function CrudManagerLayoutTableComponent_crud_item_table_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crud-item-table", 5);
  }
  if (rf & 2) {
    i0.ɵɵproperty("@fadeInOut", undefined);
  }
}
let CrudManagerLayoutTableComponent = class CrudManagerLayoutTableComponent extends CrudManagerLayoutBase {
  constructor(crudManager, cd, route, router) {
    super(crudManager, cd, route, router);
    this.crudManager = crudManager;
    this.cd = cd;
    this.route = route;
    this.router = router;
    this.editorActive = signal(false);
    this.router.events.pipe(takeWhileAlive(this), filter(e => e instanceof RoutesRecognized)).subscribe(e => {
      this.reactToNewRouteSnapshot(e.state.root);
    });
    this.reactToNewRouteSnapshot(this.route.snapshot);
  }
  // to animate root route ViewChildren (table) in/out when navigating to child editor routes,
  // we need to detect BEGINNING of navigation to a child CRUD route.
  reactToNewRouteSnapshot(snapshot) {
    let childRoute = snapshot;
    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;
    }
    const isCrudManagerBaseRoute = extendsConstructor(childRoute.component, CrudManager2Component);
    this.editorActive.set(!isCrudManagerBaseRoute);
  }
  static {
    this.ɵfac = function CrudManagerLayoutTableComponent_Factory(t) {
      return new (t || CrudManagerLayoutTableComponent)(i0.ɵɵdirectiveInject(i1.CrudManagerService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i2.Router));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: CrudManagerLayoutTableComponent,
      selectors: [["crud-manager-layout-table"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 2,
      consts: [[4, "ngIf"], ["class", "item-table", 4, "ngIf"], [1, "crud-manager-router-outlet"], ["outlet", "outlet"], [3, "innerHTML"], [1, "item-table"]],
      template: function CrudManagerLayoutTableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrudManagerLayoutTableComponent_ui_message_0_Template, 2, 1, "ui-message", 0)(1, CrudManagerLayoutTableComponent_crud_item_table_1_Template, 1, 1, "crud-item-table", 1);
          i0.ɵɵelement(2, "custom-router-outlet", 2, 3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.editorActive() && ctx.infoMessage);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.editorActive());
        }
      },
      styles: ["[_nghost-%COMP%]{display:contents;padding:0}.crud-manager-router-outlet[_ngcontent-%COMP%] +   *{position:absolute;display:flex;flex-direction:column;inset:0;z-index:1000;background:var(--contentColor)}@media (min-width:1280px){ui-message[_ngcontent-%COMP%]{flex:none}.item-table[_ngcontent-%COMP%]{flex:1;overflow:auto;height:initial}}\n\n/*# sourceMappingURL=layout-table.component.ts-angular-inline--37.css.map*/"],
      data: {
        animation: [trigger('fadeInOut', [state('void', style({
          opacity: 0
        })), transition('void <=> *', animate(300))])]
      }
    });
  }
};
CrudManagerLayoutTableComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [CrudManagerService, ChangeDetectorRef, ActivatedRoute, Router])], CrudManagerLayoutTableComponent);
export { CrudManagerLayoutTableComponent };