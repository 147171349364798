import { __decorate, __metadata } from "tslib";
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { UiButtonModule } from '@common/ui/frontend-shared/button';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { MembershipPluginsService } from '@common/membership/shared';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { Observable } from 'rxjs';
import { MembershipRegistrationFacade, MembershipRegistrationState } from '../state';
import { UiInputSelectButtonModule } from '@common/ui/frontend-shared/input-selectbutton';
import { FormsModule } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "../state";
import * as i2 from "@common/membership/shared";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../ui/frontend-shared/src/lib/input-selectbutton/input-selectbutton";
let RegistrationTypeSelectionComponent = class RegistrationTypeSelectionComponent {
  constructor(registration, pluginsService, cd) {
    this.registration = registration;
    this.pluginsService = pluginsService;
    this.cd = cd;
    this.selectablePlugins = this.pluginsService.getPluginsList();
    this.selectablePluginOptions = this.selectablePlugins.map(plugin => {
      return {
        value: plugin.id,
        label: plugin.name
      };
    });
    this.pluginFormCompleted = formData => {
      this.registration.setFormData(formData);
      this.registration.confirmStep();
    };
    this.onSaveFormState = pluginFormStateInfo => {
      this.registration.setPluginFormState(pluginFormStateInfo);
    };
    this.onSelectType = event => {
      this.registration.setMembershipType(event.value);
    };
  }
  static {
    this.ɵfac = function RegistrationTypeSelectionComponent_Factory(t) {
      return new (t || RegistrationTypeSelectionComponent)(i0.ɵɵdirectiveInject(i1.MembershipRegistrationFacade), i0.ɵɵdirectiveInject(i2.MembershipPluginsService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
      type: RegistrationTypeSelectionComponent,
      selectors: [["membership-registration-type-selection"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 4,
      consts: [[3, "options", "ngModel", "onChange"]],
      template: function RegistrationTypeSelectionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div")(1, "p");
          i0.ɵɵtext(2, "Wie sind Sie versichert?");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "ui-input-selectbutton", 0);
          i0.ɵɵlistener("onChange", function RegistrationTypeSelectionComponent_Template_ui_input_selectbutton_onChange_3_listener($event) {
            return ctx.onSelectType($event);
          });
          i0.ɵɵpipe(4, "async");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("options", ctx.selectablePluginOptions)("ngModel", i0.ɵɵpipeBind1(4, 2, ctx.membershipType$));
        }
      },
      dependencies: [CommonModule, i3.AsyncPipe, FormsModule, i4.NgControlStatus, i4.NgModel, CoreFrontendSharedModule, UiButtonModule, UiInputSelectButtonModule, i5.InputSelectButton],
      styles: ["[_nghost-%COMP%]{margin:0 0 20px;display:block}p[_ngcontent-%COMP%]{margin:0 0 10px}\n\n/*# sourceMappingURL=registration-type-selection.component.ts-angular-inline--65.css.map*/"],
      changeDetection: 0
    });
  }
};
__decorate([Select(MembershipRegistrationState.displayMode), __metadata("design:type", Observable)], RegistrationTypeSelectionComponent.prototype, "displayMode$", void 0);
__decorate([Select(MembershipRegistrationState.membershipType), __metadata("design:type", Observable)], RegistrationTypeSelectionComponent.prototype, "membershipType$", void 0);
RegistrationTypeSelectionComponent = __decorate([AutoUnsubscribe(), __metadata("design:paramtypes", [MembershipRegistrationFacade, MembershipPluginsService, ChangeDetectorRef])], RegistrationTypeSelectionComponent);
export { RegistrationTypeSelectionComponent };